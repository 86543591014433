import { bumpSagas } from 'companion-app-components/flux/bump';
import { categoriesSagas } from 'companion-app-components/flux/categories';
import { datasetsSagas } from 'companion-app-components/flux/datasets';
import { postponedActionsSagas } from 'companion-app-components/flux/postponed-actions';
import { profileSagas } from 'companion-app-components/flux/profile';
import { tagsSagas } from 'companion-app-components/flux/tags';
import { userInfoSagas } from 'companion-app-components/flux/user-info';
import { scheduledTransactionsSagas } from 'companion-app-components/flux/scheduled-transactions';
import { transactionsSagas as transactionsCoreSagas } from 'companion-app-components/flux/transactions';
import rulesSagas from 'companion-app-components/flux/rename-rules/renameRulesSagas';
import investmentHoldingsSagas from 'companion-app-components/flux/investmentHoldings/sagas';
import memorizedRulesSagas from 'companion-app-components/flux/memorized-rules/memorizedRulesSagas';
import npsSagas from 'companion-app-components/flux/nps/npsSagas';
import { configFeatureFlagsSagas } from 'companion-app-components/flux/config-feature-flags';
import { budgetItemsSagas } from 'companion-app-components/flux/budget-items';
import { categoryGroupsSagas } from 'companion-app-components/flux/category-groups';
import { categoryGroupListsSagas } from 'companion-app-components/flux/category-group-lists';
import preferencesSagas from 'companion-app-components/flux/preferences/sagas';

import accountsSagas from 'data/accounts/sagas';  // wraps bit.dev
import authSagas from 'utils/auth/authSagas'; // wraps bit.dev
import accountDiscoverySagas from 'components/Dialogs/AccountDiscovery/sagas';
import budgetsSagas from 'companion-app-components/flux/budgets/budgetsSagas';
import documentSagas from 'data/documents/sagas';
import institutionSagas from 'data/institutions/sagas';
import institutionLoginsSagas from 'data/institutionLogins/sagas';
import persistSagas from 'data/persist/sagas';
import qcsApiSagas from 'data/apiUtil/apiCall';
import subscriptionSagas from 'data/subscriptions/subscriptionsSagas';
import transactionSagas from 'data/transactions/sagas';
import preferencesV2Sagas from 'data/preferencesV2/preferencesV2Sagas';
import jobStatus from 'data/jobStatus/sagas';
import syncSentimentsSagas from 'data/syncSentiment/syncSentimentSagas';
import accountBalancesSagas from 'data/accountBalances/sagas';
import payeesSagas from 'data/payees/sagas';
import entitlementsSagas from 'data/entitlements/entitlementsSagas';
import mcRulesSagas from 'data/mcRules/mcRulesSagas';
import importSagas from 'data/import/importSagas';
import appSagas from 'data/app/sagas';
import ssoSagas from 'data/sso/ssoSagas';
import feedbackSagas from 'data/feedback/feedbackSagas';

const createSagas = (store) => {

  const sagaTasks = [

    // TODO: MAKE SURE THERE ARE NO DUPLICATE ENTRIES
    //  ADD THINGS IN ALPHABETICAL ORDER PLEASE
    accountBalancesSagas,
    accountsSagas,
    accountDiscoverySagas,
    appSagas,
    authSagas,
    budgetsSagas,
    budgetItemsSagas,
    bumpSagas,
    categoriesSagas,
    categoryGroupsSagas,
    categoryGroupListsSagas,
    configFeatureFlagsSagas,
    datasetsSagas,
    documentSagas,
    entitlementsSagas,
    feedbackSagas,
    importSagas,
    institutionSagas,
    institutionLoginsSagas,
    investmentHoldingsSagas,
    jobStatus,
    mcRulesSagas,
    memorizedRulesSagas,
    npsSagas,
    payeesSagas,
    persistSagas,
    postponedActionsSagas,
    preferencesSagas,
    preferencesV2Sagas,
    profileSagas,
    qcsApiSagas,
    rulesSagas,
    scheduledTransactionsSagas,
    ssoSagas,
    subscriptionSagas,
    syncSentimentsSagas,
    tagsSagas,
    transactionsCoreSagas,
    transactionSagas,
    userInfoSagas,
    // TODO: MAKE SURE THERE ARE NO DUPLICATE ENTRIES
    //  ADD THINGS IN ALPHABETICAL ORDER PLEASE
  ].reduce((tasksAccumulator, sagas) => [...tasksAccumulator, ...sagas.map(store.runSaga)], []);

  return sagaTasks;
};

export default createSagas;
