
import React from 'react';

import { makeStyles } from 'tss-react/mui';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import useQPreferences from 'components/QPreferences/useQPreferences';

const useStyles = makeStyles()((theme) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px 8px 0px 0px',
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const CategoryHeaderAcme = () => {
  const { classes } = useStyles();

  const { datasetPreferences, setDatasetPreference } = useQPreferences();
  const applyRules = datasetPreferences?.defaultCreateRuleToChecked;

  const boxClick = () => setDatasetPreference({ defaultCreateRuleToChecked: !applyRules });

  return (
    <div className={classes.headerContainer}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          onClick={boxClick}
          checked={applyRules}
          color={'primary'}
        />
        <Typography style={{ marginLeft: 4 }} variant={'caption'}>
          Create a rule to also use this category for future transactions
        </Typography>
      </div>
    </div>
  );
};

export default CategoryHeaderAcme;
