import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import _ from 'lodash';
import ReactDatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import { RRule } from 'rrule';

import { scheduledTransactionsUtils, scheduledTransactionsActions, scheduledTransactionsTypes } from 'companion-app-components/flux/scheduled-transactions';
import { transactionsTypes } from 'companion-app-components/flux/transactions';
import { configFeatureFlagsSelectors } from 'companion-app-components/flux/config-feature-flags';
import { ClientConfigFlags } from 'utils/clientConfigFlags';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from 'tss-react/mui';

import AmountField, { ShowSignEnum } from 'components/QuickenControls/AmountField';
import QChoiceChips from 'components/QuickenControls/QChoiceChips';
import { rruleFromRecurrence } from 'data/scheduledTransactions/scheduledTransactionsUtils';
import Dump from 'components/Dump';

const useStyles = makeStyles()((_theme) => ({
  amountField: {
    textAlign: 'left',
  },
}));

function getVirtualBaseDate(scheduledTransaction) {
  let virtualBaseDate;
  const dueOnDate = DateTime.fromISO(scheduledTransaction.dueOn, { zone: 'utc' });
  if (dueOnDate && scheduledTransaction.recurrence) {
    try {
      const ruleBack = new RRule({
        tzid: 'utc',
        ...rruleFromRecurrence(scheduledTransaction.recurrence),
        dtstart: dueOnDate.minus({ years: 1 }).toJSDate(),
        until: dueOnDate.toJSDate(),
      });
      virtualBaseDate = ruleBack.before(dueOnDate.toJSDate(), false);
    } catch (e) {
      assert(false, e); // bugsnag investigation
    }
  }
  return virtualBaseDate;
}

function initialValues(scheduledTransaction) {
  const negator = scheduledTransactionsUtils.negatorFromCOA(scheduledTransaction.transaction && scheduledTransaction.transaction.coa);
  const virtualShowSign = Math.sign(scheduledTransaction?.transaction?.amount || -1) !== negator ? ShowSignEnum.ALWAYS : ShowSignEnum.NEVER;
  const virtualFrequency = scheduledTransactionsUtils.frequencyAliasFromRecurrence(scheduledTransaction.recurrence);
  const amount = scheduledTransaction.transaction && !Number.isNaN(scheduledTransaction.transaction.amount) ?
    String(negator < 0 ? Math.abs(scheduledTransaction.transaction.amount) : scheduledTransaction.transaction.amount) :
    undefined;

  const dueOnDate = DateTime.fromISO(scheduledTransaction.dueOn, { zone: 'utc' });
  const virtualBaseDate = getVirtualBaseDate(scheduledTransaction);

  return {
    ...scheduledTransaction,
    dueOn: dueOnDate && dueOnDate.isValid ? dueOnDate.toLocaleString(DateTime.DATE_SHORT) : '',
    transaction: {
      ...scheduledTransaction.transaction,
      amount,
    },
    virtualShowSign,
    virtualFrequency,
    virtualBaseDate,
  };
}

const ScheduledTransactionLightForm = (props) => {
  const { submitted, renderProps, scheduledTransaction } = props;

  const { classes } = useStyles();

  const dispatch = useDispatch();

  const renderPropsCallback = useCallback((formikProps) => {
    if (renderProps) {
      renderProps({
        remove: () => {
          dispatch(scheduledTransactionsActions.deleteScheduledTransaction(scheduledTransactionsTypes.mkScheduledTransaction({
            id: formikProps.values.id,
            isDeleted: true,
          })));
          if (submitted) {
            submitted();
          }
        },
        submit: () => {
          if (formikProps.dirty) {
            formikProps.submitForm();
            formikProps.isValid && submitted();
          } else if (submitted) {
            submitted();
          }
        },
      });
    }
  }, [renderProps, dispatch, submitted]);

  const showScheduledTxnDayFrequency = useSelector((state) => configFeatureFlagsSelectors
    .getFeatureFlagForUserOrDataset(state, ClientConfigFlags.showScheduledTxnDayFrequency), shallowEqual);

  const frequencyAliasItems = useMemo(
    () => (showScheduledTxnDayFrequency) 
      ? scheduledTransactionsUtils.frequencyAliases
      : scheduledTransactionsUtils.frequencyAliases.filter(
        (freqAlias) => freqAlias !== scheduledTransactionsUtils.frequencyAliasTypes.dayQuicken
      ),
    [showScheduledTxnDayFrequency]
  );

  return (
    <Formik
      key={props.scheduledTransaction && props.scheduledTransaction.id}
      initialValues={initialValues(props.scheduledTransaction)}
      validateOnChange
      onSubmit={(values, _actions) => {
        const dueOnLuxon = values.dueOn && DateTime.fromFormat(values.dueOn, 'D');
        const dueOn = dueOnLuxon?.isValid ? dueOnLuxon.toISODate() : undefined;
        const recurrence = scheduledTransactionsUtils.recurrenceFromFrequencyAlias(values.virtualFrequency, dueOn);
        const negator = scheduledTransactionsUtils.negatorFromCOA(values.transaction.coa);
        const amount = (values.transaction.amount.includes('+') || values.transaction.amount.includes('-')) ? Number(values.transaction.amount) : Number(values.transaction.amount) * negator;
        dispatch(scheduledTransactionsActions.updateScheduledTransaction(scheduledTransactionsTypes.mkScheduledTransaction({
          ...values,
          dueOn,
          recurrence: scheduledTransactionsTypes.mkRecurrence(recurrence),
          transaction: new transactionsTypes.CashFlowTransaction({
            ...values.transaction,
            amount,
          }),
        })));
        if (props.submitted) {
          props.submitted();
        }
      }}
    >
      {(formikProps) =>
        <Form>
          <Dump obj={formikProps} />
          <Grid container spacing={4}>

            <Grid item xs={12}>
              <Field
                name="transaction.payee"
                validate={(value) => value && value.length > 0 ? undefined : 'required'}
              >
                {({ field, form: { touched, errors } }) =>
                  <TextField
                    {...field}
                    id="payee"
                    label="Name"
                    error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
                    helperText={_.get(touched, field.name) && _.get(errors, field.name)}
                    InputProps={{
                      autoComplete: 'off',
                    }}
                    variant={'outlined'}
                    margin="dense"
                    fullWidth
                    autoFocus
                    onFocus={(event) => event.target.select()}
                    size="small"
                  />}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <Field
                name="transaction.amount"
                validate={(value) => value === undefined || value === null || value === '' ? 'required' : undefined}
              >
                {({ field, form: { touched, errors, values } }) => (
                  <AmountField
                    {...field}
                    showSign={values.virtualShowSign}
                    showAmountAdornment
                    editable
                    omitCents={false}
                    currencySymbol="USD"
                    amountType="amount"
                    id="amount"
                    label="Amount"
                    textFieldVariant={'outlined'}
                    marginProp="normal"
                    fullWidth
                    autoComplete="off"
                    error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
                    helperText={_.get(touched, field.name) && _.get(errors, field.name)}
                    classes={{ inputField: classes.amountField }}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <Field
                name="virtualFrequency"
                validate={(value) => value === undefined || value === null || value === '' ? 'required' : undefined}
              >
                {({ field, form: { touched, errors } }) => (
                  <FormControl
                    {...field}
                    error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
                  >
                    <InputLabel shrink>Frequency</InputLabel>
                    <Input
                      id="frequency"
                      multiline
                      disableUnderline
                      inputComponent={QChoiceChips}
                      inputProps={{
                        items: frequencyAliasItems,
                        selectedItems: field.value ? [field.value] : [],
                        onSelected: (items) => {
                          const frequency = items && items.length === 1 ? items[0] : undefined;
                          if (frequency) {
                            const virtualBaseDate = getVirtualBaseDate(scheduledTransaction);
                            if (!touched.dueOn && virtualBaseDate) {
                              const luxonBaseDate = DateTime.fromJSDate(virtualBaseDate);
                              const isoBaseDate = luxonBaseDate.toUTC().toISODate();
                              const recurrence = scheduledTransactionsUtils.recurrenceFromFrequencyAlias(frequency, isoBaseDate);

                              if (recurrence && luxonBaseDate) {
                                const projectedDate = luxonBaseDate.plus({ years: 1 }).toJSDate();

                                const [newReccurance, newDtStart] = scheduledTransactionsUtils.getEqualantFrequencyForSpecialCase(frequency, recurrence, virtualBaseDate);

                                const ruleForward = new RRule({
                                  tzid: 'utc',
                                  ...rruleFromRecurrence(newReccurance),
                                  dtstart: newDtStart,
                                  until: projectedDate,
                                });

                                const jsTodayUTCDate = DateTime.local()
                                  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                                  .setZone('utc', { keepLocalTime: true })
                                  .toJSDate();
                                const jsDueOn = ruleForward.after(jsTodayUTCDate, false);

                                if (jsDueOn) {
                                  const luxonDueOn = DateTime.fromJSDate(jsDueOn);
                                  const localeDueOn = luxonDueOn.toUTC().toLocaleString(DateTime.DATE_SHORT);
                                  formikProps.setFieldValue('dueOn', localeDueOn);
                                }
                              }
                            }
                            formikProps.setFieldValue('virtualFrequency', frequency);
                          }
                        },
                      }}
                    />
                    {_.get(touched, field.name) && _.get(errors, field.name) &&
                    <FormHelperText id="frequency-helper-text">
                      {_.get(errors, field.name)}
                    </FormHelperText>}
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <Field
                name="dueOn"
                validate={(value) => {
                  let errorMessage;
                  if (!value) {
                    errorMessage = 'required';
                  } else if (formikProps.initialValues.dueOn !== value) {
                    const dueOn = DateTime.fromFormat(value, 'D');
                    const now = DateTime.local().startOf('day');
                    if (!dueOn.isValid) {
                      errorMessage = 'invalid date format';
                    } else if (dueOn < now) {
                      errorMessage = "can't change to past dates";
                    } else if (formikProps.values.recurrence && formikProps.values.recurrence.endOn) {
                      const endOn = DateTime.fromISO(formikProps.values.recurrence.endOn);
                      if (endOn.isValid && dueOn > endOn) {
                        errorMessage = `can't use dates after ${formikProps.values.recurrence.endOn}`;
                      }
                    }
                  }
                  return errorMessage;
                }}
              >
                {({ field, form: { values, touched, errors } }) => {
                  const date = DateTime.fromFormat(field.value, 'D');
                  const selected = date.isValid ? date.toJSDate() : new Date();
                  return (
                    <ReactDatePicker
                      id="next-date"
                      selected={selected}
                      onChange={(jsDate) => {
                        if (jsDate) {
                          formikProps.setFieldValue(field.name, DateTime.fromJSDate(jsDate).toFormat('D'));
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Escape') {
                          e.stopPropagation();
                        }
                      }}
                      minDate={DateTime.local().toJSDate()}
                      maxDate={values.recurrence?.endOn ? DateTime.fromISO(values.recurrence.endOn).toJSDate() : undefined}
                      allowSameDay
                      disabledKeyboardNavigation
                      customInputRef="inputRef"
                      customInput={
                        <TextField
                          InputProps={{
                            ...field,
                            autoComplete: 'off',
                          }}
                          label="Next Date"
                          variant={'outlined'}
                          margin="dense"
                          fullWidth
                          error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
                          helperText={_.get(touched, field.name) && _.get(errors, field.name)}
                          size="small"
                        />
                      }
                    />
                  );
                }}
              </Field>
            </Grid>

            {renderPropsCallback ? renderPropsCallback(formikProps) : null}

          </Grid>
        </Form>}
    </Formik>
  );
};

ScheduledTransactionLightForm.propTypes = {
  scheduledTransaction: PropTypes.object,
  renderProps: PropTypes.func,
  submitted: PropTypes.func,
};

export default ScheduledTransactionLightForm;
