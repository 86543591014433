
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';

// import { isMobile, isTablet } from 'react-device-detect';

export const styles = (theme) => ({
  root: {
    lineHeight: '24px',
    cursor: 'pointer',
    fontWeight: 400,
    height: 20,
    '&:hover': {
      // background: isMobile || isTablet ? null : 'rgba(220,230,235, 0.5)',
      // transition: '.5s ease',
      // borderRadius: '3px',
    },
  },
  thinFont: {
    lineHeight: '20px',
    fontWeight: 400,
  },
  secondary: {
    color: theme.palette.text.secondary,
  },
  primary: {
    color: theme.palette.text.primary,
  },
});

class QTypography extends PureComponent {

  render() {
    const { thinFont, clickable, classes, textSecondary, textPrimary, className, sx, ...other } = this.props;
    return (
      <Typography
        className={classNames(clickable ? classes.root : '', thinFont ? classes.thinFont : '', textSecondary ? classes.secondary : '', textPrimary ? classes.primary : '', className)}
        classes={{
          root: className,
        }}
        sx={sx}
        {...other}
      />
    );
  }
}

QTypography.propTypes = {
  // values parent can pass in
  thinFont: PropTypes.bool,
  textSecondary: PropTypes.bool,
  textPrimary: PropTypes.bool,
  clickable: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  sx: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(QTypography, styles);
