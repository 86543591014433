import React from 'react';
import { connect } from 'react-redux';
import compose from 'utils/compose';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from 'tss-react/mui';

import * as actions from 'data/apiUtil/actions';
import { getLogger } from 'companion-app-components/utils/core';

import { ImageGalleryStyled } from './styledComponents';

const log = getLogger('components/AccountsList/index.js');

export const styles = (_theme) => ({
  root: {
  },
});


// ==============================================================
//
// Displays a DocumentBrowser modal window
//
// Props
//
// documents - an array of QCS documents to retrieve and display
// selectedIds = function, passed array of document ID's user selected and took action on
// action - string of the action button text to describe the action
//
export class DocumentBrowser extends React.Component {

  constructor(props) {
    super(props);

    log.log('Building Document Browser');
    this.state = {
      images: [],
    };
  }

  componentDidMount() {
    this.processProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.documents !== this.props.documents) {
      this.processProps(nextProps);
    }
  }

  processProps = (props) => {
    log.log('Process Props', props);

    if (props.documents && this.props.documents.length > 0) {
      this.state.images = [];
      props.documents.forEach((docObj) => {
        const path = `documents/${docObj.id}/original`;
        const obj = {
          reqId: docObj.id,
          path,
          data: null,
          verb: 'get',
          cb: this.reqReturn,
        };
        log.log('Make API Call to:', obj);
        this.props.makeQCSApiCall(obj);
      });
    } else {
      this.setState({ images: [] });
      this.props.onClose();
    }
  };

  reqReturn = (err, data, id) => {

    if (err) {
      log.log(err);
      // error 400 means the docID has been deleted
      if (err.response.status === 400) {
        const newArray = this.state.images;
        newArray.push({ id, url: 'unavailable', name: 'unavailable' });
        this.setState({ images: newArray });
      }

    } else {
      const newArray = this.state.images;
      if (!newArray.find((item) => item.id === id)) {
        newArray.push({ id, url: data.data.url, name: data.data.name });
        this.setState({ images: newArray });
      }
    }
  };

  render() {

    const { classes, selectedIds, onClose } = this.props;

    return (
      <Dialog
        fullWidth
        open
        classes={classes}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle> View Attachments </DialogTitle>
        <ImageGalleryStyled
          id="qimg-gallery"
          images={this.state.images}
          selectedImages={selectedIds}
        >
        </ImageGalleryStyled>
      </Dialog>
    );
  }
}

DocumentBrowser.propTypes = {
  classes: PropTypes.object,
  documents: PropTypes.array,

  makeQCSApiCall: PropTypes.func,
  onClose: PropTypes.func,
  selectedIds: PropTypes.func,
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    makeQCSApiCall: (data) => dispatch(actions.makeQCSApiCall(data)),
  };
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(DocumentBrowser, styles));
