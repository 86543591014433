import { select, takeEvery, put } from 'redux-saga/effects';

import { getLogger, tracker } from 'companion-app-components/utils/core';
import { authSelectors } from 'companion-app-components/flux/auth';
import { accountsActions, accountsSelectors } from 'companion-app-components/flux/accounts';
import { transactionsActions } from 'companion-app-components/flux/transactions';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import * as actions from 'companion-app-components/flux/preferences/actions';
import * as preferencesSelectors from 'companion-app-components/flux/preferences/selectors';

import { getInstitutionLoginsById } from 'data/institutionLogins/selectors';
import * as transactionsSelectors from 'data/transactions/selectors';
import { getBalanceForAccountAtDate } from 'data/accountBalances/retrievers';
import { initBalMismatchPrefs } from 'data/accountBalances/utils';
import { APP_STRING_NOT_APPLICABLE } from 'utils/constants';

import isAcme from 'isAcme';

const log = getLogger('data/accountBalances/sagas');

let checkedBalances = false;

// ====================================================
// VALIDATE BALANCES
export function* validateBalances() {
  let balMismatchPref = yield select(
    preferencesSelectors.getSharedPreferencesByPath,
    { group: 'dataset', path: ['balanceMismatchPrefs'] },
  );

  const accountsPending = yield select(accountsSelectors.getLoadPending);
  const transactionsPending = yield select(transactionsSelectors.getLoadPending);
  const accountsLoading = yield select(accountsSelectors.getIsLoading);
  const transactionsLoading = yield select(transactionsSelectors.getIsLoading);
  const datasetId = yield select(authSelectors.getDatasetId);
  const showEvent = (yield select(featureFlagsSelectors.getFeatureFlags)).get('flagBalanceMismatchWithEvent');

  if (!isAcme && !accountsLoading && !transactionsLoading && !transactionsPending && !accountsPending && !checkedBalances) {
    checkedBalances = true;
    let setPreferenceForBalMismatch = false;
    log.log('VALIDATE BALANCES FOR ALL ACCOUNTS ------ ');

    const accountsById = yield select(accountsSelectors.getAccountsById);
    const institutionLogins = yield select(getInstitutionLoginsById);

    accountsById.forEach((account) => {
      if (account.type !== 'INVESTMENT') {
        const bal1 = getBalanceForAccountAtDate({
          accountId: account.id,
          date: account.balanceAsOfOn,
          balanceType: 'currentBalance',
        });

        const deltaAmount = (Number(bal1) - Number(account.balanceAsOf)).toFixed(2);
        const zeroValue = Number(0).toFixed(2);
        const resetBalMismatchPref =
          deltaAmount === zeroValue &&
          balMismatchPref?.byId &&
          balMismatchPref?.byId[account.id]?.mismatchAmount &&
          balMismatchPref?.byId[account.id]?.mismatchAmount !== zeroValue;

        const institutionLogin = account.institutionLoginId ? institutionLogins.get(account.institutionLoginId) : undefined;

        if (deltaAmount !== zeroValue) {
          const oldEvent = {
            datasetId,
            accountId: account.id,
            accountName: account.name,
            accountType: account.type,
            accountSubType: account.subType,
            institutionId: institutionLogin?.institutionId ?? APP_STRING_NOT_APPLICABLE,
            institutionName: institutionLogin?.name ?? 'manual',
            channel: institutionLogin?.channel ?? APP_STRING_NOT_APPLICABLE,
            brandingId: institutionLogin?.brandingId ?? APP_STRING_NOT_APPLICABLE,
            aggStatus: institutionLogin?.aggregators?.get?.(0)?.aggStatus ?? APP_STRING_NOT_APPLICABLE,
            delta: deltaAmount,
          };
          log.log('Balance Event Sent: ', oldEvent);
          tracker.track(tracker.events.accountBalanceMismatch, oldEvent);
        }

        if ((deltaAmount !== zeroValue && deltaAmount !== balMismatchPref?.byId[account.id]?.mismatchAmount) || resetBalMismatchPref) {
          setPreferenceForBalMismatch = true;
          balMismatchPref = initBalMismatchPrefs(account.id, deltaAmount, balMismatchPref);

          if (showEvent && zeroValue !== deltaAmount) {
            const event = {
              datasetId,
              accountId: account.id,
              accountName: account.name,
              accountType: account.type,
              accountSubType: account.subType,
              institutionId: institutionLogin?.institutionId ?? APP_STRING_NOT_APPLICABLE,
              institutionName: institutionLogin?.name ?? 'manual',
              channel: institutionLogin?.channel ?? APP_STRING_NOT_APPLICABLE,
              brandingId: institutionLogin?.brandingId ?? APP_STRING_NOT_APPLICABLE,
              aggStatus: institutionLogin?.aggregators?.get?.(0)?.aggStatus ?? APP_STRING_NOT_APPLICABLE,
              delta: deltaAmount,
            };
            log.log('New Balance Mismatch Event Sent: ', event);
            tracker.track(tracker.events.accountBalanceMismatchPeriodic, event);
          }
        }
      }
    });

    if (setPreferenceForBalMismatch) {
      const prefObj = {
        section: 'shared',
        group: 'dataset',
        preference: { balanceMismatchPrefs: balMismatchPref },
      };
      yield put(actions.setPreference(prefObj, { context: 'setBalanceMismatchPreferences' }));
    }
  }
}


// ====================================================
// ACTION WATCHERS to trigger SAGAS calls

export function* getAccountsSuccessActionWatcher() {
  yield takeEvery(accountsActions.getAccountsSuccess, validateBalances);
}
export function* getTransactionsSuccessActionWatcher() {
  yield takeEvery(transactionsActions.getTransactionsSuccess, validateBalances);
}


// ====================================================
// EXPORTS

export default [
  getAccountsSuccessActionWatcher,
  getTransactionsSuccessActionWatcher,
];


