import React, { useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Map as ImmutableMap } from 'immutable';
import { makeStyles } from 'tss-react/mui';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';
import UploadIcon from '@mui/icons-material/CloudUpload';

import { uploadDocument, deleteDocument, getDocument } from 'data/documents/actions';
import { getDocumentsForAttachments, MAX_TX_ATTACHMENTS } from 'data/documents/selectors';
import { mkDocument } from 'data/documents/types';
import { DIALOG_TYPE as DIALOG_CONFIRMATION, mkConfirmationDialogProps } from 'components/Dialogs/ConfirmationDialog';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { DIALOG_TYPE_EMPTY_DIALOG } from 'components/Dialogs/EmptyDialog';
import QButton from 'components/QButton';
import BoxField from 'components/Transactions/BoxField';

const useStyles = makeStyles()(() => ({
  input: {
    minWidth: '0 !important',
  },
  chip: {
    maxWidth: 'calc(100% - 74px)',
  },
  boxChip: {
    maxWidth: 'calc(100% - 34px)',
  },
  uploadButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  uploadIcon: {
    marginBottom: 2,
    marginRight: 4,
  },
  boxRoot: {
    paddingRight: '86px !important',
    flexWrap: 'wrap',
  },
  boxInput: {
    flexBasis: 0,
  },
}));

const AttachmentsField = React.memo((props) => {
  const { attachments, onChange, textFieldProps, dropzoneRef, onDropzoneChanged, readOnly, box, ...autocompleteProps } = props;

  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const dropzone = useDropzone();
  const { acceptedFiles, isDragActive } = dropzone;
  const { onClick, onFocus, onBlur, onKeyDown, tabIndex, ...dropzoneRootProps } = dropzone.getRootProps();
  useImperativeHandle(dropzoneRef, () => dropzone, [dropzone]);
  useEffect(() => { onDropzoneChanged?.(); }, [isDragActive, onDropzoneChanged]);

  const documents = useSelector((state) => getDocumentsForAttachments(state, attachments));
  useEffect(() => {
    if (onChange) {
      const updatedAttachments = attachments?.map((attachment) =>
        documents.find((document) => document.id === attachment.id ||
          (document.clientId && attachment.clientId && (document.clientId === attachment.clientId))) || attachment);
      if (!_.isEqual(attachments, updatedAttachments)) {
        onChange(undefined, updatedAttachments);
      }
    }
  }, [documents, attachments, onChange]);

  const [prevAcceptedFiles, setPrevAcceptedFiles] = useState();
  const [uploadingClientIds, setUploadingClientIds] = useState([]);
  const [errorClientIds, setErrorClientIds] = useState([]);
  useEffect(() => {
    if (!_.isEqual(prevAcceptedFiles, acceptedFiles) && acceptedFiles.length) {
      setPrevAcceptedFiles(acceptedFiles);
      if ((attachments?.length || 0) + (acceptedFiles.length || 0) > MAX_TX_ATTACHMENTS) {
        dispatch(createDialog(mkRootUiData({
          id: 'max-attachemnts-limit',
          type: DIALOG_CONFIRMATION,
          allowNesting: true,
          props: ImmutableMap(mkConfirmationDialogProps({
            title: 'Too many attachments',
            content: `Sorry, you can only add ${MAX_TX_ATTACHMENTS} items per transaction.`,
            confirmLabel: 'Got it',
            denyLabel: null,
          })),
        })));
      }
      const acceptedAttachemnts = acceptedFiles
        .slice(0, MAX_TX_ATTACHMENTS - (attachments?.length || 0))
        .map((file) => {
          const clientId = uuidv4().toUpperCase();
          setUploadingClientIds((uploadingClientIdsCurrent) => [...uploadingClientIdsCurrent, clientId]);
          new Promise((resolve, reject) =>
            dispatch(uploadDocument({
              clientId,
              document: mkDocument({
                name: file.name,
                documentType: 'OTHER',
                contentType: file.type || 'application/octet-stream',
                clientId,
                size: file.size,
              }),
              data: file,
            }, { resolve, reject })))
            .then((_response) => {
              setUploadingClientIds((uploadingClientIdsCurrent) => uploadingClientIdsCurrent.filter((uploadingClientId) => uploadingClientId !== clientId));
              setErrorClientIds((errorClientIdsCurrent) => errorClientIdsCurrent.filter((errorClientId) => errorClientId !== clientId));
            })
            .catch((_error) => {
              setUploadingClientIds((uploadingClientIdsLast) => uploadingClientIdsLast.filter((uploadingClientId) => uploadingClientId !== clientId));
              setErrorClientIds((errorClientIdsCurrent) => [...errorClientIdsCurrent, clientId]);
            });
          return { clientId, name: file.name };
        });
      onChange?.(undefined, [...(attachments || []), ...acceptedAttachemnts]);
    }
  }, [acceptedFiles, attachments, onChange, dispatch, prevAcceptedFiles, setPrevAcceptedFiles]);

  const renderInput = (params) => {
    let ret;
    if (box) {
      ret = (
        <BoxField
          {...autocompleteProps}
          {...params}
          label="Attachments"
          placeholder=" "
          readOnly
          startAdornment={params.InputProps.startAdornment}
          endAdornment={
            <QButton
              id="attach-button"
              onClick={() => dropzone.open()}
              aria-label="add attachment"
              className={classes.uploadButton}
            >
              <UploadIcon className={classes.uploadIcon} /> UPLOAD
            </QButton>
          }
          classes={{ root: classes.boxRoot, input: classes.boxInput }}
        />
      );
    } else {
      ret = (
        <TextField
          {...params}
          sharedcomponentid={'ATTACHMENTS_FIELD'}
          size={props.size}
          label="Attachments"
          placeholder={attachments?.length ? null : 'Add your attachment'}
          margin="normal"
          variant="outlined"
          fullWidth
          // inputProps={{
          //   className: classNames(params.inputProps?.className, classes.input),
          // }}
          InputProps={{
            ...params.InputProps,
            readOnly: true,
            ...(readOnly ? { disableUnderline: readOnly } : undefined),
            startAdornment: [
              ...(readOnly ? [] : [(
                <InputAdornment
                  key="attachment-icon"
                  position="start"
                >
                  <IconButton
                    id="attach-button"
                    onClick={() => dropzone.open()}
                    aria-label="add attachment"
                    size="large"
                  >
                    <AttachFileIcon />
                  </IconButton>
                </InputAdornment>
              )]),
              ...(params.InputProps.startAdornment || []),
            ],
            inputProps: {
              ...params.inputProps,
              className: cx(params.inputProps?.className, classes.input),
            },
          }}
          {...textFieldProps}
        />
      );
    }

    return ret;
  };

  return (
    <>
      <input {...dropzone.getInputProps()} />
      <Autocomplete
        {...dropzoneRootProps}
        id="attachments"
        multiple
        options={attachments || []}
        value={attachments || []}
        getOptionLabel={(attachment) => attachment.name || documents?.get(attachment.id)?.name}
        onChange={(event, newValue, reason, details) => {
          if (!readOnly) {
            onChange?.(event, newValue, reason, details);
            if (reason === 'remove-option' && details.option.id) {
              dispatch(deleteDocument(mkDocument({ id: details.option.id })));
            }
          }
        }}
        open={false}
        disableClearable
        forcePopupIcon={false}
        size={props.size}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const tagProps = getTagProps({ index });
            return (
              <Chip
                size={props.size}
                key={option.id || option.clientId}
                label={option.name || documents?.get(option.id)?.name}
                {...tagProps}
                className={cx(tagProps.className, box ? classes.boxChip : classes.chip)}
                disabled={uploadingClientIds.includes(option.clientId)}
                {...(uploadingClientIds.includes(option.clientId) && { deleteIcon: <CircularProgress size={20} /> })}
                {...(readOnly && { onDelete: null })}
                icon={errorClientIds.includes(option.clientId) ? <Tooltip title="Can't upload your attachment"><ErrorOutlineIcon /></Tooltip> : undefined}
                onClick={() => {
                  if (option.id) {
                    new Promise((resolve, reject) => dispatch(getDocument(mkDocument({ id: option.id }), { resolve, reject })))
                      .then((result) => {
                        if (assert(result?.data?.url, "can't get attachment from the server")) {
                          const popup = window.open(result.data.url, result.data.name, 'width=800,height=600');
                          if (!popup) {
                            dispatch(createDialog(mkRootUiData({
                              id: 'show-attachment',
                              type: DIALOG_TYPE_EMPTY_DIALOG,
                              allowNesting: true,
                              props: ImmutableMap({
                                title: `${result.data.name} (enable browser pop ups to download)`,
                                maxWidth: 'lg',
                                content: <iframe
                                  title={result.data.name}
                                  width={800}
                                  height={600}
                                  src={result.data.url}
                                />
                                ,
                              }),
                            })));
                          }
                        }
                      })
                      .catch((error) => assert(false, error));
                  }
                }}
              />
            );
          }) || []}
        renderInput={renderInput}
        {...autocompleteProps}
      />
    </>
  );
});

AttachmentsField.propTypes = {
  textFieldProps: PropTypes.object,
  attachments: PropTypes.array,
  onChange: PropTypes.func,
  dropzoneRef: PropTypes.object,
  onDropzoneChanged: PropTypes.func,
  readOnly: PropTypes.bool,
  size: PropTypes.string,
  box: PropTypes.bool,
};


export default AttachmentsField;
