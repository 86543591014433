import React, { FC, ReactNode, useRef } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import VirtualList from 'react-tiny-virtual-list-dmk';
import { List as ImmutableList } from 'immutable';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';

import { transactionsUtils } from 'companion-app-components/flux/transactions';

import Dump from 'components/Dump';
import QButton from 'components/QButton';
import ZeroStateView from 'components/ZeroStateView';
import CategoryField from 'components/QuickenControls/CategoryField';
import AmountField, { ShowSignEnum, ShowColorEnum } from 'components/QuickenControls/AmountField';

import { isBankPending } from 'data/transactions/utils';

import zeroStateIcon from 'assets/zero-state-images/safebox.svg';

import { styles } from './styles';

const useStyles = makeStyles()(styles as Record<string, any>);

interface TransactionSelectListProps {
  clickFunc: (event: any) => void;
  highlight: ImmutableList<string> | undefined;
  hideCategory?: boolean;
  multiSelect: boolean;
  onReset: () => void;
  filterByType: ({ amount }: { amount: any; }) => boolean;
  selectorProps?: Record<string, any>;
  transactionListSelector: any;
  filter: any;
}

const TransactionSelectList: FC<TransactionSelectListProps> = ({ selectorProps = {}, clickFunc,
  highlight, hideCategory, multiSelect, onReset, filterByType, transactionListSelector, filter,
}) => {

  const displayNodes = useRef<ReactNode[]>([]);
  const { classes } = useStyles();

  const acctTxns = useSelector((state) => transactionListSelector(state, {
    ...selectorProps,
    filter,
  }));

  const checkHighlight = (id) => {
    if (multiSelect) {
      // @ts-expect-error: Property 'contains' does not exist on type 'string'.
      return highlight?.contains(id) || false;
    }
    return highlight === id;
  };

  const getAcctTxns = () => filterByType ? acctTxns.filter(filterByType) : acctTxns;

  const generateTxns = () => {
    let acctTxns2 = getAcctTxns();
    acctTxns2 = acctTxns2 && acctTxns2.filter((txn) => !isBankPending(txn));
    displayNodes.current = [];
    let i = 0;
    acctTxns2.map((txn) => {
      const isSplit = transactionsUtils.isSplitTxn(txn);
      if (txn.payee) {
        displayNodes.current.push(
          <div
            role="presentation"
            key={i}
            id={JSON.stringify(txn)}
            onClick={clickFunc}
            className={checkHighlight(txn.id) ? classes.txnLineShaded : classes.txnLine}
          >
            <Dump obj={txn} />
            <Typography variant="body2" noWrap className={classes.dater}>
              {moment(txn.postedOn).format('MMM Do, YYYY')}
            </Typography>
            <Typography variant="body2" noWrap className={classes.payer}>
              {txn.payee}
            </Typography>
            {!hideCategory && (
              isSplit &&
              <Typography variant="body2" noWrap className={classes.payer}>
                {'---Split---'}
              </Typography>)}
            {!hideCategory && (
              !isSplit && <CategoryField
                variant="body2"
                classNameWhenNotEditable={classes.typer}
                disableTooltip
                value={txn.coa}
                longCats={false}
                autoFocus={false}
                noWrap
              />
            )}
            <AmountField
              showSign={ShowSignEnum.POSITIVE_ONLY}
              showColor={ShowColorEnum.POSITIVE_ONLY}
              color={txn.amount > 0 ? 'green' : undefined}
              value={txn.amount}
              variant="body2"
              className={classes.amter}
              noWrap
            />
            <div className={classes.checkWrap}>
              {checkHighlight(txn.id) && <CheckIcon className={classes.checkIcon} />}
              {!checkHighlight(txn.id) && <AddIcon className={classes.selectIcon} />}
            </div>
          </div>,
        );
        i += 1;
        return '';
      }
      return '';
    });
  };

  const acctTxns2 = getAcctTxns();

  if (!acctTxns2?.size) {
    return (
      <div className={classes.stateView}>
        <ZeroStateView
          icon={zeroStateIcon}
          primary="We couldn't find any transactions"
          secondary={
            <>
              You might have searched for something too specific.
              <br />
              Try resetting the search if so
            </>
          }
          classes={{ secondary: classes.zeroState, root: classes.zeroRoot }}
        />
        <div className={classes.zeroReset}>
          <QButton onClick={onReset} variant="text">
            Click Here
          </QButton>
          <Typography style={{ color: '#666', lineHeight: '32px' }}>
            to reset the search.
          </Typography>
        </div>
      </div>
    );
  }
  if (acctTxns2 && displayNodes.current) {
    generateTxns();
  }
  return (
    acctTxns2.size &&
    (
      <VirtualList
        // @ts-expect-error: Property 'id' does not exist on type 'IntrinsicAttributes
        id={'transaction-choice-list'}
        height={360}
        itemCount={displayNodes.current.length}
        overscanCount={30}
        itemSize={60}
        renderItem={({ style, index }) => (displayNodes.current[index] ?
          <div style={style} key={index}>
            {displayNodes.current[index]}
          </div>
          : ''
        )}
        className={classes.VL}
      />
    )
  );
};

export default TransactionSelectList;
