import React, { FC, useRef, useMemo, useState, useEffect } from 'react';
import cn from 'classnames';
import { List, Map } from 'immutable';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import * as selectors from 'data/app/selectors';
import { getAccountNodesTree } from 'data/accountNodes/selectors';
import { appRefreshAll as refreshAllAction } from 'data/app/actions';

import { featurePages } from 'containers/App/paths';
import useQPreferences from 'components/QPreferences/useQPreferences';
import { showWhatsNewDialog } from 'components/Dialogs/WhatsNew/actions';
import { showReleaseNotesDialog } from 'components/Dialogs/ReleaseNotes/actions';
import { doHelpSupportModal as doHelpSupportModalAction } from 'components/Dialogs/HelpSupportDialog/actions';
import { getLoadPendingState as getPrefLoadPending } from 'companion-app-components/flux/preferences/selectors';

import { HELP_MENU_ITEMS } from 'utils/constants';

import MoreIcon from 'assets/more.svg';

import { checkAndTriggerWhatsNewDialog } from '../utils';

const currentVersion = process.env.APP_VERSION;
let whatsNewData;
try {
  // eslint-disable-next-line
  whatsNewData = require(`/static/whats-new${currentVersion}/WhatsNew.json`);
  if (whatsNewData?.version === currentVersion) {
    whatsNewData = {
      count: whatsNewData.pages,
      pages: whatsNewData.order.split(','),
    };
  }
} catch {
  whatsNewData = {};
}

interface MoreButtonProps {
  hide: boolean;
  showRefreshIcon: boolean;
  classes: Record<string, any>;
  featureFlags: Map<string, any>;
}

const MoreButton: FC<MoreButtonProps> = ({ classes, hide, showRefreshIcon, featureFlags }) => {

  const [showMenuItems, setShowMenuItems] = useState(false);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userPreferences, setUserPreference } = useQPreferences();
  
  const moreButtonRef = useRef(null);
  const timer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  
  const isUserPrefLoading = useSelector(getPrefLoadPending).shared.user;
  const disableRefreshButton = useSelector((state) => selectors.disableRefreshButton(state));
  const isRefreshing = useSelector((state) => selectors.isRefreshing(state));
  // TODO: type assigning wont be needed once new app-component is installed
  const accountNodesTree: List<any> = useSelector(getAccountNodesTree);
  const hasWhatsNewJSON = whatsNewData?.pages?.length > 0;
  
  const dispatchShowWhatsNewDialog = () => dispatch(showWhatsNewDialog(whatsNewData, () => dispatch(showReleaseNotesDialog())));
  const dispatchShowReleaseNotesDialog = () => dispatch(showReleaseNotesDialog());
  
  const objMenuItemHandlers = {
    whatsNew: dispatchShowWhatsNewDialog,
    releaseNotes: dispatchShowReleaseNotesDialog,
    supportFAQ: () => dispatch(doHelpSupportModalAction()),
  };

  const moreOptions = useMemo(() => {
    const nav = featurePages.find((x) => x.path === '/settings');
    const allOptions = HELP_MENU_ITEMS.map(({ key, label }) => ({
      key,
      body: label,
      disabled: key === 'whatsNew' && !hasWhatsNewJSON,
      onClick: objMenuItemHandlers[key],
    }));
    if (!hide) {
      if (showRefreshIcon) {
        if (!disableRefreshButton && !isRefreshing) {
          allOptions.push({
            key: 'refresh',
            body: 'Refresh',
            disabled: false,
            onClick: () => dispatch(refreshAllAction(undefined, { context: 'refreshAll' })),
          });
        } else if (isRefreshing) {
          allOptions.push({
            key: 'refreshing',
            body: <>
              <IconButton
                aria-label="Refresh All Busy"
                classes={{ root: classes.buttonRoot }}
                className={classes.spinnerButton}
              >
                <CircularProgress size={16} />
              </IconButton>
              Refreshing 
              {/* eslint-disable-next-line react/jsx-closing-tag-location */}
            </>,
            disabled: true,
            onClick: undefined,
          });
        }
      }
      if (nav && (!nav.featureFlag || featureFlags.get(nav.featureFlag))) {
        allOptions.push({
          key: 'settings',
          body: 'Settings',
          disabled: false,
          onClick: () => navigate('/settings'),
        });
      }
    }
    return allOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hide, showRefreshIcon, disableRefreshButton, isRefreshing, classes, featurePages]);

  useEffect(() => () => clearTimeout(timer.current), []);
  useEffect(() => {
    const debounceDialogTrigger = debounce(() => {
      checkAndTriggerWhatsNewDialog({
        isUserPrefLoading,
        userPreferences,
        setUserPreference,
        currentVersion,
        hasWhatsNewJSON,
        dispatchShowReleaseNotesDialog,
        dispatchShowWhatsNewDialog,
      });
    }, 2000);

    if (accountNodesTree.size > 0) { 
      debounceDialogTrigger(); 
    }
  }, [isUserPrefLoading, userPreferences?.whatsNewViewed, accountNodesTree.size]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ButtonBase
        className={classes.navMenuItem}
        ref={moreButtonRef}
        onMouseOver={() => setShowMenuItems(true)}
        onMouseLeave={() => setShowMenuItems(false)}
      >
        <div>
          <img src={MoreIcon} alt="more" />
        </div>
        <div className={cn(classes.navText, classes.navItemText)}>
          More
        </div>
        <ChevronRightIcon className={classes.mainNavChevronRightIcon} />
      </ButtonBase>
      <Menu
        id="main-nav-sub-menu"
        // @ts-expect-error: The expected type comes from property 'anchorEl' which is declared here on type 'IntrinsicAttributes & MenuProps'
        anchorEl={moreButtonRef}
        open={showMenuItems}
        autoFocus={false}
        className={cn(classes.mainNavSubMenu, classes.moreNavSubMenu)}
      >
        <Divider sx={{ color: '#ccc', opacity: '0.7' }}>Help</Divider>
        {moreOptions.map(({ key, body, disabled, onClick }) => (
          <>
            <MenuItem
              key={key}
              onClick={() => {
                setShowMenuItems(false);
                onClick();
              }}
              className={classes.menuItems}
              onMouseOver={() => {
                clearTimeout(timer.current);
                setShowMenuItems(true);
              }}
              onMouseLeave={() => {
                timer.current = setTimeout(() => {
                  setShowMenuItems(false);
                }, 1000);
              }}
              disabled={disabled}
            >
              {body}
            </MenuItem>
            {key === 'supportFAQ' && <Divider />}
          </>
        ))}
      </Menu>
    </>
  );
};

export default MoreButton;
