/*
 * RegCategoryField
 */

// BASE
import React, { useState, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import { categoriesTypes } from 'companion-app-components/flux/categories';
import { transactionsUtils } from 'companion-app-components/flux/transactions';

// CUSTOM COMPONENTS
import QCheckbox from 'components/QCheckbox';
import CategoryField from 'components/QuickenControls/CategoryField';
import CategoryHeaderAcme from 'components/QuickenControls/CategoryField/categoryHeaderAcme';
import QTypography from 'components/MUIWrappers/QTypography';
import QTip from 'components/QuickenControls/QTip';

// SELECTORS, ACTIONS, UTILS
import { isAcme } from 'isAcme';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { isOldEdge } from 'utils/utils';

// MUI COMPONENTS
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// PATH RELATIVE IMPORTS
import { styles } from './styles';

const isIe = require('is-iexplorer') || isOldEdge();  // eslint-disable-line
const categoryFieldInputProps = {
  style: {
    height: isIe ? 27 : 24,
  },
};

const propDefaults = {
  defaultPropogateStatus: false,
};
const propTypes = {
  defaultPropogateStatus: PropTypes.bool,
  txn: PropTypes.object,
  wrapText: PropTypes.bool,
  editable: PropTypes.bool,
  forceStatic: PropTypes.bool,
  longCats: PropTypes.bool,
  showSplitWindow: PropTypes.bool,
  inputRef: PropTypes.func,
  onChange: PropTypes.func,
  context: PropTypes.string,
};

// STYLES HOOK CREATION
const useStyles = makeStyles()(styles);

const RegCategoryField = (props) => {

  // PROPS ============================================================================
  const { txn, wrapText, editable, forceStatic, longCats, showSplitWindow, inputRef, defaultPropogateStatus, onChange, context } = props;
  const theme = useTheme();

  // STATE ============================================================================
  const [propagateStatus, setPropagateStatus] = useState(defaultPropogateStatus);

  // SELECTORS ==========================================================================
  const showCategorySettings = useSelector((state) => featureFlagsSelectors.getFeatureFlags(state).get('showCategorySettings'), shallowEqual);

  // STYLES ============================================================================
  const { classes } = useStyles(props);

  // INTERNAL FUNCTIONS
  const handleHeaderCheckboxClick = () => setPropagateStatus(!propagateStatus);

  // RENDER ============================================================================

  const categoryFieldHeader = (isAcme ?
    <CategoryHeaderAcme />
    :
    <CategoryMenuHeader
      checked={propagateStatus}
      boxClick={handleHeaderCheckboxClick}
      classes={classes}
      payee={txn.payee}
    />);
  const categoryFieldMenuClose = useCallback(() => setPropagateStatus(false), []);
  const categoryFieldOnChange = useCallback((coa) => {
    const e = { target: { value: coa, doPropogate: propagateStatus } };
    onChange(e, 'category');
  }, [propagateStatus, onChange]);

  if (!transactionsUtils.isSplitTxn(txn)) {
    return (
      <CategoryField
        header={txn.payee?.length > 0 ? categoryFieldHeader : undefined}
        menuClose={!isAcme ? categoryFieldMenuClose : undefined}
        onChange={categoryFieldOnChange}
        disableUnderline={theme.components.register.noUnderline}
        name={editable ? 'regCatEditable' : 'static'}
        returnSelectionMethod
        editable={editable}
        createEnabled={showCategorySettings}
        recommendedCategoryType={txn.amount > 0 ? categoriesTypes.CategoryTypeEnum.INCOME : categoriesTypes.CategoryTypeEnum.EXPENSE}
        clickable={!forceStatic && !showSplitWindow}
        value={txn.coa}
        fontSize={theme.components.register.fontSize.default}
        classNameWhenNotEditable={classes.regItemCore}
        autoFocus
        tooltip={!wrapText}
        longCats={longCats}
        inputRef={inputRef}
        InputProps={categoryFieldInputProps}
        context={context}
      />
    );
  }
  return (
    <QTypography
      clickable
      thinFont
      className={classes.regItemCore}
    >
      --- Split ---
    </QTypography>
  );
};

RegCategoryField.propTypes = propTypes;
RegCategoryField.defaultProps = propDefaults;

export default (RegCategoryField);


/*
 CATEGORY MENU HEADER (QUICKEN ONLY) - this is the addition to the category field
 */

export const CategoryMenuHeader = (props) => {

  const { classes, boxClick, checked, payee } = props;

  return (
    <div className={classes.headerContainer}>
      <div style={{ display: 'flex', alignItems: isAcme ? 'center' : 'flex-start' }}>
        <QCheckbox
          onClick={boxClick}
          checked={checked}
          classes={{ boxBorder: classes.headerCheckboxBorder, root: classes.headerCheckbox }}
        />
        <Typography variant={'body1'}>
          Apply changes to similar transactions
        </Typography>
        <QTip
          title={'Quicken will look forwards and backwards in ' +
          `your transaction list and update all transactions with the payee name "${payee}" to use the ` +
          'selected category.  Additionally, this "rule" will be applied going forward to downloaded transactions'}
        >
          <HelpOutlineIcon
            className={classes.helpIcon}
          />
        </QTip>
      </div>
    </div>
  );
};

CategoryMenuHeader.propTypes = {
  checked: PropTypes.bool,
  boxClick: PropTypes.func,
  classes: PropTypes.object,
  payee: PropTypes.string,
};
