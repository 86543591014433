// @flow
import { createSelector } from 'reselect';
import { List, OrderedMap, Map as ImmutableMap } from 'immutable';
import moment from 'moment/moment';

import { accountsSelectors, accountsUtils } from 'companion-app-components/flux/accounts';

import { getAllBalancesByAccountId } from 'data/accountBalances/selectors';

import { isAcme, isQuicken } from 'isAcme';

import { AccountNode, accountTypes } from './types';

const isSeparateAccount = (account) => (isQuicken && account.isExcludedFromBudgets && account.isExcludedFromReports && account.type !== 'INVESTMENT') ||
  (isQuicken && account.isExcludedFromReports && account.type === 'INVESTMENT');

function getL1GroupingDataCompanion(account) {
  switch (account.type) {
    case 'BANK':
    case 'CASH':
    case 'CREDIT':
      return {
        id: 'BANKING',
        displayLabel: 'Banking',
        displayOrder: 1,
        svgIconId: 'IconBanking',
        routeTo: '/transactions',
        type: accountTypes.banking,
      };
    case 'INVESTMENT':
      return {
        id: 'INVESTMENT',
        displayLabel: 'Investments',
        displayOrder: 2,
        svgIconId: 'IconInvestment',
        routeTo: '/investments',
        type: accountTypes.investment,
      };
    case 'LOAN':
    case 'OTHER_ASSET':
    case 'ASSET':
    case 'OTHER_LIABILITY':
    case 'REAL_ESTATE':
    case 'VEHICLE':
      return {
        id: 'ASSETS_LIABILITIES',
        displayLabel: 'Property & Debt',
        displayOrder: 3,
        svgIconId: 'IconLoan',
        routeTo: '/transactions',
        type: accountTypes.liabilities,
      };
    case 'GOAL':
      return {
        id: 'GOALS',
        displayLabel: 'Savings Goals',
        displayOrder: 4,
        svgIconId: 'IconLoan',
        routeTo: '/goals',
        type: accountTypes.goals,
      };
    default:
      return {
        id: 'OTHER',
        displayLabel: 'Other',
        displayOrder: 5,
        svgIconId: 'IconBanking',
        routeTo: '/transactions',
        type: accountTypes.other,
      };
  }
}

function getL1GroupingData(account) {
  if (isSeparateAccount(account)) {
    return {
      id: 'SEPARATE',
      displayLabel: 'Separate',
      displayOrder: 6,
      svgIconId: 'IconBanking',
      routeTo: account.type === accountTypes.investment ? '/investments' : '/transactions',
      type: accountTypes.separate,
    };
  }

  return getL1GroupingDataCompanion(account);
}

function hasL2Grouping(id, numberOfAccounts) {
  // log.log('Checking if node has L2 grouping', id, numberOfAccounts);
  switch (id) {
    case 'BANKING':
    case 'INVESTMENT':
    case 'ASSET':
    case 'LOAN':
    case 'PROPERTY':
    case 'ASSETS_LIABILITIES':
      return numberOfAccounts > 0;
    default:
      return false;
  }
}

function getL2GroupingDataCompanion(node, account) {
  switch (account.type) {
    case 'BANK':
    case 'CASH':
    case 'CREDIT':
      if (account.type === 'CASH' || account.subType === 'CHECKING') {
        const idCashChecking = isSeparateAccount(account) ? 'CASH_CHECKING_SEPARATE' : 'CASH_CHECKING';
        return {
          id: idCashChecking,
          displayLabel: 'Cash & Checking',
          displayOrder: 1,
          routeTo: node.routeTo,
        };
      }
      if (account.type === 'CREDIT') {
        const idCredit = isSeparateAccount(account) ? 'CREDIT_SEPARATE' : 'CREDIT';
        return {
          id: idCredit,
          displayLabel: 'Credit',
          displayOrder: 2,
          routeTo: node.routeTo,
        };
      }
      if (account.subType === 'SAVINGS' || account.subType === 'CD' || account.subType === 'MONEY_MARKET') {
        const idSavings = isSeparateAccount(account) ? 'SAVINGS_SEPARATE' : 'SAVINGS';
        return {
          id: idSavings,
          displayLabel: 'Savings',
          displayOrder: 3,
          routeTo: node.routeTo,
        };
      }
      return {
        id: 'OTHER_BANKING',
        displayLabel: 'Other Banking',
        displayOrder: 4,
        routeTo: node.routeTo,
      };
    case 'INVESTMENT':
      switch (account.subType) {
        case 'BROKERAGE':
          return {
            id: 'BROKERAGE',
            displayLabel: 'Brokerage',
            displayOrder: 1,
            routeTo: node.routeTo,
          };
        case 'IRA':
        case 'KEOGH':
        case 'SEP_IRA':
        case 'SIMPLE_IRA':
        case '401K':
        case '403B':
        case 'ROTH_IRA':
        case 'ROTH_401K':
          return {
            id: 'RETIREMENT',
            displayLabel: 'Retirement',
            displayOrder: 2,
            routeTo: node.routeTo,
          };
        case 'UGMA_UTMA':
        case '529':
        case 'OTHER_INVESTMENT':
        default:
          return {
            id: 'OTHER_INVESTMENT',
            displayLabel: 'Other Investments',
            displayOrder: 3,
            routeTo: node.routeTo,
          };
      }
    case 'OTHER_ASSET':
    case 'REAL_ESTATE':
    case 'VEHICLE':
      return {
        id: 'PROPERTY',
        displayLabel: 'Property',
        displayOrder: 1,
        routeTo: node.routeTo,
      };
    case 'LOAN':
    case 'OTHER_LIABILITY':
      return {
        id: 'DEBT',
        displayLabel: 'Debt',
        displayOrder: 1,
        routeTo: node.routeTo,
      };
    default:
      return {
        id: 'OTHER',
        displayLabel: 'Other',
        displayOrder: 1,
        routeTo: node.routeTo,
      };
  }
}

const investmentSubIds = ['BROKERAGE', 'RETIREMENT', 'OTHER_INVESTMENT'];

function getL2GroupingData(node, account) {

  if (account.isExcludedFromAccountBar) {
    return {
      id: 'HIDDEN',
      displayLabel: 'Hidden',
      displayOrder: 4,
      routeTo: account.type === accountTypes.investment ? '/investments' : node.routeTo,
      type: node.type,
    };
  }

  return getL2GroupingDataCompanion(node, account);
}

const findOrCreateNodeForGroup = (list, data, depth) => {
  let foundNode = list.find((node) => node.id === data.id);
  if (!foundNode) {
    list.push(foundNode = { ...data, depth, children: [] });
  }
  return foundNode;
};

const rollupAccountNodes = (node, balancesByAccountId, doIncludeGoalTxnsInBalances) => { /* eslint-disable no-param-reassign */
  let allAccountIds = [];
  let sumEndingAccountBalances = 0.0;
  let sumOnlineAccountBalances = 0.0;
  let sumCPOnlineAccountBalances = 0.0;
  let sumCPAvailableAccountBalances = 0.0;
  let sumCurrentAccountBalances = 0.0;
  let sumFinalAccountBalances = 0.0;
  let sumGoalsAccountBalances = 0.0;
  let earliestTransactionDate = null;
  let currency = null;

  if ((!doIncludeGoalTxnsInBalances || node.id !== 'GOALS') && node.children) {
    const isSeparateOrHiddenAcWithBankingAc = (node.id === accountTypes.separate || node.id === accountTypes.hidden) && node.children?.filter((child) => 
      child?.account?.type !== accountTypes?.investment)?.length > 0;
    node.children = node.children.map((child) => {
      const skipTotalSumForSeparateOrHidden = isSeparateOrHiddenAcWithBankingAc && (child?.account?.type === accountTypes.investment || investmentSubIds.includes(child?.id));
      const childAfterRollup = rollupAccountNodes(child, balancesByAccountId, doIncludeGoalTxnsInBalances);
      allAccountIds = allAccountIds.concat(childAfterRollup.allAccountIds.toArray());
      if (!skipTotalSumForSeparateOrHidden) {
        sumEndingAccountBalances += childAfterRollup.sumEndingAccountBalances;
        sumCurrentAccountBalances += childAfterRollup.sumCurrentAccountBalances;
        sumOnlineAccountBalances += childAfterRollup.sumOnlineAccountBalances;
        sumCPOnlineAccountBalances += childAfterRollup.sumCPOnlineAccountBalances;
        sumCPAvailableAccountBalances += childAfterRollup.sumCPAvailableAccountBalances;
        sumFinalAccountBalances += childAfterRollup.sumFinalAccountBalances;
        sumGoalsAccountBalances += childAfterRollup.sumGoalsAccountBalances;
      }

      if (!earliestTransactionDate) {
        earliestTransactionDate = childAfterRollup.earliestTransactionDate;
      } else if (childAfterRollup.earliestTransactionDate && moment(childAfterRollup.earliestTransactionDate).isBefore(earliestTransactionDate)) {
        earliestTransactionDate = moment(childAfterRollup.earliestTransactionDate);
      }

      if (!currency) {
        currency = childAfterRollup.currency;
      } else if (currency !== childAfterRollup.currency) {
        currency = 'N/A';
      }

      return childAfterRollup;
    });
    node.children = List(node.children);
  }

  if (node.account) {
    allAccountIds.push(node.account.id);
    const balancesForAccount = balancesByAccountId.get(node.account.id);
    if (balancesForAccount) {
      let currentAccountBalance;
      if (isAcme && node.account.type === 'LOAN' && balancesForAccount.onlineBalance != null) { // special casing for Simplifi Loan connected accounts - replace currentBalance with onlineBalance
        currentAccountBalance = typeof balancesForAccount.onlineBalance === 'number' ? balancesForAccount.onlineBalance : 0.0;
      } else {
        currentAccountBalance = typeof balancesForAccount.currentBalance === 'number' ? balancesForAccount.currentBalance : 0.0;
      }
      sumCurrentAccountBalances += currentAccountBalance;

      sumEndingAccountBalances += typeof balancesForAccount.endingBalance === 'number' ? balancesForAccount.endingBalance : 0.0;
      sumOnlineAccountBalances += typeof balancesForAccount.onlineBalance === 'number' ? balancesForAccount.onlineBalance : 0.0;
      sumCPAvailableAccountBalances += typeof balancesForAccount.cpAvailableBalance === 'number' ? balancesForAccount.cpAvailableBalance : 0.0;
      sumFinalAccountBalances += typeof balancesForAccount.finalBalance === 'number' ? balancesForAccount.finalBalance : 0.0;
      sumGoalsAccountBalances += typeof balancesForAccount.goalsBalance === 'number' ? balancesForAccount.goalsBalance : 0.0;

      const cpOnlineBalance = typeof balancesForAccount.cpOnlineBalance === 'number' ? balancesForAccount.cpOnlineBalance : 0.0;
      sumCPOnlineAccountBalances += (['BANK', 'CREDIT'].includes(node.account?.type) && !accountsUtils.isAccountManual(node.account)) ?
        cpOnlineBalance : currentAccountBalance;

      if (!earliestTransactionDate) {
        earliestTransactionDate = balancesForAccount.earliestTransactionDate;
      } else if (balancesForAccount.earliestTransactionDate && moment(balancesForAccount.earliestTransactionDate).isBefore(earliestTransactionDate)) {
        earliestTransactionDate = moment(balancesForAccount.earliestTransactionDate);
      }
    }

    if (!currency) {
      currency = node.account.currency;
    } else if (currency !== node.account.currency) {
      currency = 'N/A';
    }
  }

  node.allAccountIds = memoizedAccountIdList(allAccountIds);
  node.sumEndingAccountBalances = sumEndingAccountBalances;
  node.sumOnlineAccountBalances = sumOnlineAccountBalances;
  node.sumCPOnlineAccountBalances = sumCPOnlineAccountBalances;
  node.sumCPAvailableAccountBalances = sumCPAvailableAccountBalances;
  node.sumCurrentAccountBalances = sumCurrentAccountBalances;
  node.sumFinalAccountBalances = sumFinalAccountBalances;
  node.sumGoalsAccountBalances = sumGoalsAccountBalances;
  node.currency = currency;
  node.earliestTransactionDate = earliestTransactionDate;
  node.hide = node.account ? node.account.isExcludedFromAccountBar : false;

  return new AccountNode(node);
};

const accountIdsCache = {};

function memoizedAccountIdList(ids) {

  const normalizedIds = ids.sort((a, b) => a > b);
  const key = JSON.stringify(normalizedIds);
  if (!accountIdsCache[key]) {
    accountIdsCache[key] = List(ids);
  }
  return accountIdsCache[key];

}


function hasHiddenChild(node) {
  return node.children && node.children.find((x) => x.account.isExcludedFromAccountBar);
}

export const getAccountNodesTree = createSelector(
  accountsSelectors.getAccountsById,
  getAllBalancesByAccountId,
  (accountsById, balancesByAccountId, doShowGoalAccounts) => {
    const doIncludeGoalTxnsInBalances = false;
    // reduce all accounts into L1 groupings
    let l1Nodes = accountsById.reduce((list, account) => {
      if (doShowGoalAccounts || account.type !== 'GOAL') {
        const allNodes = findOrCreateNodeForGroup(list, {
          id: 'all',
          displayLabel: isAcme ? 'Net Worth' : 'Total',
          routeTo: '/transactions',
          displayOrder: 0,
          expandable: false,
        }, 1);

        if (!isSeparateAccount(account)) {
          allNodes.children.push({
            id: account.id || account.clientId,
            depth: 2,
            displayLabel: assert(account.name, 'name not defined'),
            routeTo: '/transactions',
            account,
          });
        }

        const l1Group = findOrCreateNodeForGroup(list, getL1GroupingData(account), 1);
        l1Group.children.push({
          id: account.id || account.clientId,
          depth: 2,
          displayLabel: assert(account.name, 'name not defined'),
          routeTo: account.type === accountTypes.investment ? '/investments' : '/transactions',
          account,
        });

        if (l1Group.id === accountTypes.separate) {
          if (account.type !== accountTypes.investment) {
            l1Group.routeTo = '/transactions';
          }
        }
      }
      return list;
    }, []);

    // after creating all l1Nodes, sort by display order
    l1Nodes.sort((a, b) => a.displayOrder - b.displayOrder);

    // expand children in L1 groupings into L2 groupings (if required) and perform sorting
    l1Nodes.forEach((node) => {
      if (node.id !== 'all') {
        if (hasL2Grouping(node.id, node.children.length) || hasHiddenChild(node)) {
          node.expandable = true;
          node.children = node.children.reduce((list, child) => {

            const l2Group = findOrCreateNodeForGroup(list, getL2GroupingData(node, child.account), 2);
            l2Group.children.push({
              id: child.account.id || child.account.clientId,
              depth: 3,
              displayLabel: assert(child.account.name, 'name not defined'),
              routeTo: l2Group.routeTo,
              account: child.account,
            });

            return list;
          }, []);
          node.children.sort((a, b) => a.displayOrder - b.displayOrder);
          node.children.forEach((l3Node) => {
            l3Node.expandable = (l3Node?.depth === 2 && l3Node.id !== accountTypes.hidden) || l3Node?.children?.length > 1;
            l3Node.children.sort((a, b) => a.displayLabel?.localeCompare(
              b.displayLabel,
              undefined,
              { numeric: true, sensitivity: 'base' }
            ));
          });
        } else {
          node.children.sort((a, b) => a.displayLabel.localeCompare(
            b.displayLabel,
            undefined,
            { numeric: true, sensitivity: 'base' }
          ));
          node.children.forEach((l3Node) => {
            l3Node.isLeaf = true;
            l3Node.expandable = (l3Node?.depth === 2 && l3Node.id !== accountTypes.hidden) || l3Node?.children?.length > 1;
          });
        }
      } else {
        // order net-worth children based on grouping data too
        node.children.sort((a, b) => {
          let compareVal = getL1GroupingData(a.account).displayOrder - getL1GroupingData(b.account).displayOrder;
          if (compareVal === 0) {
            compareVal = getL2GroupingData(a, a.account).displayOrder - getL2GroupingData(b, b.account).displayOrder;
          }
          return compareVal;
        });
      }
    });

    l1Nodes = l1Nodes.map((node) => rollupAccountNodes(node, balancesByAccountId, doIncludeGoalTxnsInBalances));

    // instrument code for debug purpose to get user context
    const netWorth = l1Nodes.find((node) => node.id === 'all');
    const sumCurrentAccountBalances = l1Nodes
      .filter((node) => node.id !== 'all' && node.id !== 'SEPARATE')
      .reduce((total, node) => total + (typeof node.sumCurrentAccountBalances === 'number' ? node.sumCurrentAccountBalances : 0), 0);
    if (netWorth && netWorth.sumCurrentAccountBalances.toFixed(2) !== sumCurrentAccountBalances.toFixed(2)) {
      verify(false, 'Net Worth sumCurrentAccountBalances mismatch');
      // crashReporterInterface.reportError('NetWorthMismatch', (event) => event.addMetadata('nodes', l1Nodes));
    }
    const sumOnlineAccountBalances = l1Nodes
      .filter((node) => node.id !== 'all' && node.id !== 'SEPARATE')
      .reduce((total, node) => total + (typeof node.sumOnlineAccountBalances === 'number' ? node.sumOnlineAccountBalances : 0), 0);
    if (netWorth && netWorth.sumOnlineAccountBalances.toFixed(2) !== sumOnlineAccountBalances.toFixed(2)) {
      verify(false, 'Net Worth sumOnlineAccountBalances mismatch');
      // crashReporterInterface.reportError('NetWorthMismatch', (event) => event.addMetadata('nodes', l1Nodes));
    }

    return List(l1Nodes);
  }
);

const getAccountsByIdForNode = (node) => {
  let flattenedList = [[node.id === accountTypes.hidden ? `${node.id}_${node.type}` : node.id, node]];
  if (node.children) {
    flattenedList = node.children.reduce((list, child) => (
      list.concat(getAccountsByIdForNode(child))
    ), flattenedList);
  }
  return flattenedList;
};

export const getAccountNodesById = createSelector(
  getAccountNodesTree,
  (tree) => {
    if (!tree || tree.size === 0) {
      return OrderedMap();
    }
    return OrderedMap(tree.reduce((list, node) => (
      list.concat(getAccountsByIdForNode(node))
    ), []));
  }
);

export const getBankAccounts = createSelector(
  getAccountNodesTree,
  accountsSelectors.getAccountsById,
  (allNodes, allAccounts) => {
    const bankNodes = new Map();
    allNodes
      .forEach((node) => {
        node.allAccountIds
          .forEach((id) => {
            const childAcct = allAccounts.get(id);
            if (childAcct?.type === 'BANK' || childAcct.subType === 'SAVINGS') bankNodes.set(id, childAcct);
          });
      });
    return ImmutableMap(bankNodes);
  }
);


