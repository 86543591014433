import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { List as ImmutableList, Map as ImmutableMap } from 'immutable';
import { Field, Form, Formik } from 'formik';
import ReactDatePicker from 'react-datepicker';
import moment, { Moment } from 'moment';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { get, omit } from 'lodash';

import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import MUISplitIcon from '@mui/icons-material/CallSplit';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import {
  frequencyAliases,
  frequencyAliasTypes,
  frequencyAliasFromRecurrence,
  recurrenceFromFrequencyAlias,
  negatorFromType,
  stTypes,
  STTypeNamesEnum,
  getEqualantFrequencyForSpecialCase,
} from 'companion-app-components/flux/scheduled-transactions/scheduledTransactionsUtils';
import {
  createScheduledTransaction,
  updateScheduledTransaction,
  deleteScheduledTransaction,
} from 'companion-app-components/flux/scheduled-transactions/scheduledTransactionsActions';
import {
  STTypeEnum,
  mkScheduledTransaction,
  mkRecurrence,
  RecurrenceType,
} from 'companion-app-components/flux/scheduled-transactions/scheduledTransactionsTypes';
import { mkSplit } from 'companion-app-components/flux/transactions/transactionsTransformers';
import { transactionsTypes, transactionsUtils } from 'companion-app-components/flux/transactions';
import { categoriesSelectors } from 'companion-app-components/flux/categories';
import { Category } from 'companion-app-components/flux/categories/categoriesTypes';
import { chartOfAccountsTypes } from 'companion-app-components/flux/chart-of-accounts';
import { authSelectors } from 'companion-app-components/flux/auth';
import { configFeatureFlagsSelectors } from 'companion-app-components/flux/config-feature-flags';

import { updateNewTags } from 'data/transactions/utils';
import AccountField from 'components/QuickenControls/AccountField';
import { ShowSignEnum } from 'components/QuickenControls/AmountField';
import QButton from 'components/QButton';
import DownshiftField from 'components/QuickenControls/DownshiftField';
import RendersCount from 'components/RendersCount';
import deleteIcon from 'assets/icon-delete-black.svg';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import SplitsAndDetailsPanel from 'components/SplitsAndDetailsPanel';
import { ClientConfigFlags } from 'utils/clientConfigFlags';
import { APP_STRING_SPLIT } from 'utils/constants';
import { DIALOG_TYPE as DIALOG_CONFIRMATION, mkConfirmationDialogProps } from '../../Dialogs/ConfirmationDialog';
import styles from './styles';
import {
  monthsIntervalMap,
  frequenciesEditableInterval,
  setVirtualAmount,
} from './utils';
import RecrAmountField from './RecrAmountField';
import RecrVirtualField from './RecrVirtualField';
import RecrCategoryField from './RecrCategoryField';
import RecrTagField from './RecrTagField';

interface ScheduledTransactionFormQuickenProps {
  scheduledTransaction: Record<string, any>;
  selectedTab?: string;
  label: string;
  isConfirmationDialogOpen: boolean;
  isFormDirty: boolean;
  setDirtyForm: (isFormDirty: boolean) => void;
  discardCb?: () => void;
  onSubmit?: (data: Record<string, any>) => void;
  onClose: () => void;
  showConfirmationDialog: (showConfirmation: boolean, cb?: () => void) => void;
  showCloseButton: boolean;
  changeDetailsMode?: () => void;
  deleteUserVerificationFromPayload?: boolean;
  hideDeleteIcon?: boolean;
}

type SetNewVirtualRecurrenceParams = {
  dueOnValue: Moment;
  overrideVirtualFreq?: Record<string, any>;
  overwriteIntervalFlag?: boolean;
};

const useStyles = makeStyles()(styles);

const ScheduledTransactionFormQuicken: React.FC<ScheduledTransactionFormQuickenProps> = ({
  scheduledTransaction,
  selectedTab,
  label,
  isConfirmationDialogOpen,
  isFormDirty,
  setDirtyForm,
  discardCb,
  onSubmit,
  onClose,
  showConfirmationDialog,
  showCloseButton,
  changeDetailsMode,
  deleteUserVerificationFromPayload,
  hideDeleteIcon,
}) => {
  const { classes } = useStyles();
  const isQWinDataset = useSelector(authSelectors.getIsWinDataset);
  const personalIncomeCategory: Category = useSelector(categoriesSelectors.getPersonalIncomeCategory);
  const showScheduledTxnDayFrequency = useSelector((state) => configFeatureFlagsSelectors.getFeatureFlagForUserOrDataset(
    state,
    ClientConfigFlags.showScheduledTxnDayFrequency,
  ));
  const dispatch = useDispatch();
  const dispatchCreateScheduledTransactionAction = (data) =>
    dispatch(createScheduledTransaction(data, { undo: { userMessage: 'Recurring transaction created.' } }));
  const dispatchUpdateScheduledTransactionAction = (data) =>
    dispatch(updateScheduledTransaction(data, { undo: { userMessage: 'Recurring transaction updated.' } }));
  const dispatchDeleteScheduledTransactionAction = (data) =>
    dispatch(deleteScheduledTransaction(data, { undo: { userMessage: 'Recurring transaction deleted.' } }));
  const makeDialog = (data) => dispatch(createDialog(mkRootUiData(data)));

  const initialScheduledTransaction: RecurrenceType = React.useMemo(() => {
    const schTxn = scheduledTransaction.transaction;
    const frequencyAlias = frequencyAliasFromRecurrence(scheduledTransaction.recurrence);
    const dueOnDate = DateTime.fromISO(scheduledTransaction.dueOn);
    const endOnDate = scheduledTransaction.recurrence && DateTime.fromISO(scheduledTransaction.recurrence.endOn);
    const virtualEndOn = endOnDate && endOnDate.isValid ? endOnDate.toLocaleString(DateTime.DATE_SHORT) : 'Never';
    const negator = negatorFromType(scheduledTransaction.type);
    const virtualShowSign = Math.sign(schTxn?.amount || -1) !== negator ? ShowSignEnum.ALWAYS : ShowSignEnum.NEVER;

    const amount = schTxn && !Number.isNaN(schTxn.amount) ? String(negator < 0 ? Math.abs(schTxn.amount) : schTxn.amount) : undefined;
    const immutableTags = schTxn && schTxn.tags ? ImmutableList(schTxn.tags) : undefined;
    const items = schTxn && schTxn.split && schTxn.split.items && schTxn.split.items.map((x) => ({
      ...x,
      amount: String(x.amount),
      tags: ImmutableList(x.tags),
    }));

    return {
      type: (selectedTab && STTypeEnum[selectedTab]) || '',
      ...scheduledTransaction,
      dueOn: dueOnDate && dueOnDate.isValid ? dueOnDate.toLocaleString(DateTime.DATE_SHORT) : '',
      transaction: {
        payee: '',
        accountId: undefined,
        coa: undefined,
        ...schTxn,
        split: {
          items,
        },
        tags: immutableTags,
        amount,
      },
      virtualRecurrence: {
        ...scheduledTransaction.recurrence,
        endOn: virtualEndOn,
      },
      virtualFrequency: frequencyAlias,
      virtualShowSign,
      virtualEndOn,
      virtualAmount: setVirtualAmount(scheduledTransaction, amount),
    };
  }, [scheduledTransaction, selectedTab]);

  let type = initialScheduledTransaction?.type;
  const { transaction } = initialScheduledTransaction;
  const [state, setState] = React.useState({
    stateScheduledTxn: initialScheduledTransaction,
    splitViewOpen: Boolean(transaction.split && transaction.split.items && transaction.split.items.length !== 0),
  });

  const { stateScheduledTxn, splitViewOpen } = state;
  const frequencyAliasItems = showScheduledTxnDayFrequency && isQWinDataset
    ? frequencyAliases
    : frequencyAliases.filter((freqAlias) => freqAlias !== frequencyAliasTypes.dayQuicken);
  const isTypeGrossPaycheck = type === 'GROSS_PAYCHECK';

  const deleteSchTxn = (clientId, id, payee) => () => {
    makeDialog({
      id: 'delete-schedule-transaction-confirmation-details',
      type: DIALOG_CONFIRMATION,
      props: ImmutableMap(mkConfirmationDialogProps({
        title: 'Delete Schedule Transaction',
        content: `Are you sure you want to delete the schedule transaction for "${payee || ''}"?`,
        confirmLabel: 'Delete Schedule Transaction',
        confirmAction: (_reason, _closeEvent) => {
          dispatchDeleteScheduledTransactionAction(mkScheduledTransaction({
            clientId,
            id,
            isDeleted: true,
          }));

          if (onClose) { onClose(); }
        },
        denyLabel: 'Go Back',
      })),
    });
  };

  return (
    <>
      <RendersCount id={'scheduledTxnForm'} />
      <Formik
        initialValues={stateScheduledTxn}
        validateOnChange
        onSubmit={(values, actions) => {
          const dueOnMoment = values.dueOn && moment(values.dueOn);
          const dueOn = dueOnMoment && dueOnMoment.isValid() ? dueOnMoment.format('YYYY-MM-DD') : undefined;
          const endOnMoment = values.virtualEndOn && moment(values.virtualEndOn);
          const endOn = endOnMoment && endOnMoment.isValid() ? endOnMoment.format('YYYY-MM-DD') : '';
          const negator = negatorFromType(values.type);
          const amount = (values.transaction.amount.includes('+') || values.transaction.amount.includes('-')) ? Number(values.transaction.amount) : Number(values.transaction.amount) * negator;
          const recurrence = values.virtualFrequency === frequencyAliasTypes.custom ?
            { ...stateScheduledTxn.recurrence, endOn } :
            { ...values.virtualRecurrence, endOn };

          // QWIN needs clientId for split Items
          if (transactionsUtils.isSplitTxn(values.transaction)) {
            // eslint-disable-next-line no-param-reassign
            values.transaction.split.items = values.transaction.split?.items?.filter((splitItem) =>
              splitItem.coa.id !== '0' && Number(splitItem.amount) !== 0).map((spliItem) =>
              ({
                ...spliItem,
                ...(!spliItem.id && !spliItem.clientId ? { clientId: uuidv4().toUpperCase() } : {}),
              }));
          }

          const split = (values.transaction.split && values.transaction.split.items)
            ? mkSplit(values.transaction.split)
            : ImmutableMap({ items: [] });
          const txns = new transactionsTypes.CashFlowTransaction({ ...values.transaction, split, amount });
          const data = mkScheduledTransaction({
            ...(deleteUserVerificationFromPayload && values.id ?
              omit(values, ['isUserVerified']) : { ...values, isUserVerified: true }
            ),
            dueOn,
            overrideNextDueOn: dueOn,
            overrideNextAmount: amount,
            recurrence: mkRecurrence(recurrence),
            transaction: updateNewTags(txns, true),
          });
          if (values.id) {
            dispatchUpdateScheduledTransactionAction(data);
          } else {
            dispatchCreateScheduledTransactionAction(data);
          }

          actions.setSubmitting(false);
          if (changeDetailsMode) {
            changeDetailsMode();
          } else {
            onSubmit?.(data);
            onClose();
          }
          setDirtyForm?.(false);
        }}
      >
        {(formikProps) => {
          setDirtyForm?.(formikProps.dirty);
          const { values: { dueOn, virtualFrequency, virtualRecurrence } } = formikProps;

          const setNewVirtualRecurrence = ({ dueOnValue, overrideVirtualFreq, overwriteIntervalFlag = false }: SetNewVirtualRecurrenceParams) => {
            const newValues = overrideVirtualFreq || { virtualFrequency };
            const { virtualRecurrence: { interval: initialInterval } } = formikProps.initialValues;
            let overwriteInterval = (overwriteIntervalFlag && initialInterval !== virtualRecurrence.interval
              && frequenciesEditableInterval.includes(newValues?.virtualFrequency)) ? virtualRecurrence.interval : undefined;
            if (initialInterval && !overwriteInterval) {
              overwriteInterval = initialInterval;
            }
            let newRecurrence = recurrenceFromFrequencyAlias(newValues.virtualFrequency, dueOnValue.format('YYYY-MM-DD'), overwriteInterval);
            const [, dtStart] = getEqualantFrequencyForSpecialCase(newValues.virtualFrequency, null, dueOnValue.toDate());
            const newDueOnValue = DateTime.fromJSDate(dtStart);
            if (newValues.virtualFrequency === frequencyAliasTypes.twiceAYearQuicken && (newDueOnValue.month + 1) >= 6) {
              newRecurrence = {
                ...newRecurrence,
                byDates: newRecurrence.byDates.reverse(),
              };
            }

            newValues.virtualRecurrence = {
              ...newRecurrence,
            };

            formikProps.setValues({
              ...formikProps.values,
              ...newValues,
              virtualEndOn: newRecurrence?.endOn ?? formikProps.values.virtualEndOn,
              dueOn: newDueOnValue.toFormat('MM/dd/yyyy'),
            });
          };

          return (
            <Form
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  if (showConfirmationDialog) {
                    showConfirmationDialog(false);
                    if (onClose) {
                      onClose();
                    }
                  }
                  if (formikProps.dirty) {
                    showConfirmationDialog(true);
                  } else if (onClose) {
                    onClose();
                  }
                }
              }}
            >
              <section className={classNames(classes.formSection, classes.headerSection)}>
                <div className={classes.headerSpace}>
                  {label && <Typography variant="overline" className={classes.labelSpace}>{label}</Typography>}
                  {showCloseButton && (
                    <IconButton
                      aria-label="close series properties"
                      onClick={() => {
                        if (formikProps.dirty) {
                          showConfirmationDialog(true);
                        } else if (onClose) {
                          onClose();
                        }
                      }}
                      style={{ padding: 0 }}
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </div>

                <Field
                  name="transaction.payee"
                  validate={(value) => value && value.length > 0 ? undefined : 'required'}
                >
                  {({ field, form: { touched, errors } }) => (
                    <TextField
                      {...field}
                      id="payee"
                      label="Name"
                      placeholder="Name"
                      error={Boolean(get(touched, field.name) && get(errors, field.name))}
                      helperText={get(touched, field.name) && get(errors, field.name)}
                      InputProps={{
                        classes: {
                          root: classes.payeeInput,
                          underline: classes.underlineOverride,
                        },
                        autoComplete: 'off',
                      }}
                      margin="dense"
                      fullWidth
                    />
                  )}
                </Field>

                <Grid container>
                  <Grid item xs={6} md lg className={classes.inputLeft}>
                    <RecrAmountField
                      classes={classes}
                      name="transaction.amount"
                      formikProps={formikProps}
                      isTypeGrossPaycheck={isTypeGrossPaycheck}
                    />
                  </Grid>

                  <Grid item xs={6} md lg className={classes.inputRight}>
                    <Field
                      name="virtualFrequency"
                      validate={(value) => value && value.length > 0 ? undefined : 'required'}
                    >
                      {({ field: { ...field }, form: { values, touched, errors } }) => (
                        <DownshiftField
                          key={get(values, field.name)}
                          initialItemSelected={get(values, field.name)}
                          onSelected={(frequencyAlias) => {
                            let dueOnValue = values.dueOn && moment(values.dueOn);
                            dueOnValue = dueOnValue && dueOnValue.isValid() ? dueOnValue : moment();
                            formikProps.setFieldValue(field.name, frequencyAlias);
                            setNewVirtualRecurrence({ dueOnValue, overrideVirtualFreq: { [field.name]: frequencyAlias } });
                          }}
                          items={frequencyAliasItems}
                          textFieldProps={{
                            InputProps: {
                              ...field,
                              classes: { underline: classes.underlineOverride },
                              disabled: isTypeGrossPaycheck,
                            },
                            id: 'frequency',
                            label: 'Frequency',
                            margin: 'normal',
                            fullWidth: true,
                            autoComplete: 'off',
                            error: Boolean(get(touched, field.name) && get(errors, field.name)),
                            helperText: get(touched, field.name) && get(errors, field.name),
                          }}
                          lightWeight
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                {virtualRecurrence && (
                  <RecrVirtualField
                    classes={classes}
                    virtualFrequency={virtualFrequency}
                    virtualRecurrence={virtualRecurrence}
                    dueOnValue={dueOn}
                    formikProps={formikProps}
                    setNewVirtualRecurrence={setNewVirtualRecurrence}
                    isTypeGrossPaycheck={isTypeGrossPaycheck}
                    monthsIntervalMap={monthsIntervalMap}
                    isQWinDataset={isQWinDataset}
                  />
                )}
              </section>

              <section className={classes.formSection}>
                <Field
                  name="transaction.accountId"
                  validate={(value) => value && value.length > 0 ? undefined : 'required'}
                >
                  {({ field: { ...field }, form: { values, touched, errors } }) => (
                    <AccountField
                      key={get(values, field.name)}
                      onSelected={(account) => formikProps.setFieldValue(field.name, account ? account.id : undefined)}
                      initialAccountId={get(values, field.name)}
                      id="account"
                      label={formikProps.values.type === STTypeEnum.INCOME
                        ? 'Deposit to'
                        : 'Pay from'}
                      margin="normal"
                      autoComplete="off"
                      fullWidth
                      error={Boolean(get(touched, field.name) && get(errors, field.name))}
                      helperText={get(touched, field.name) && get(errors, field.name)}
                      InputProps={{
                        ...field,
                        classes: { underline: classes.underlineOverride },
                        disabled: isTypeGrossPaycheck,
                      }}
                    />
                  )}
                </Field>
                <Field
                  name="type"
                  validate={(value) => value && value.length > 0 ? undefined : 'required'}
                >
                  {({ field: { ...field }, form: { values, touched, errors } }) => (
                    <DownshiftField
                      key={get(values, field.name)}
                      initialItemSelected={field.value}
                      onSelected={(txnType) => {
                        formikProps.setFieldValue(field.name, txnType);
                        type = txnType;
                        if (touched && touched.transaction && !touched.transaction.isExcludedFromReports) {
                          formikProps.setFieldValue('transaction.isExcludedFromReports', txnType === STTypeEnum.TRANSFER);
                        }

                        switch (txnType) {
                          case STTypeEnum.INCOME:
                            if (personalIncomeCategory) {
                              formikProps.setFieldValue('transaction.coa', { id: personalIncomeCategory.id, type: chartOfAccountsTypes.CoaTypeEnum.CATEGORY }); // personal income
                            } else {
                              formikProps.setFieldValue('transaction.coa', { id: '0', type: chartOfAccountsTypes.CoaTypeEnum.UNCATEGORIZED }); // Uncategorized
                            }
                            break;
                          case STTypeEnum.TRANSFER:
                            formikProps.setFieldValue('transaction.coa', { id: '2', type: chartOfAccountsTypes.CoaTypeEnum.BALANCE_ADJUSTMENT }); // Transfer
                            break;
                          case STTypeEnum.BILL:
                          case STTypeEnum.SUBSCRIPTION:
                          case STTypeEnum.EXPENSE:
                          default:
                            formikProps.setFieldValue('transaction.coa', { id: '0', type: chartOfAccountsTypes.CoaTypeEnum.UNCATEGORIZED }); // Uncategorized
                            break;
                        }
                      }}
                      items={stTypes}
                      itemToString={(item) => STTypeNamesEnum[item] || item}
                      textFieldProps={{
                        InputProps: {
                          ...field,
                          classes: { underline: classes.underlineOverride },
                          disabled: isTypeGrossPaycheck,
                        },
                        id: 'type',
                        label: 'Type',
                        margin: 'normal',
                        autoComplete: 'off',
                        fullWidth: true,
                        error: Boolean(get(touched, field.name) && get(errors, field.name)),
                        helperText: get(touched, field.name) && get(errors, field.name),
                      }}
                      lightWeight
                    />
                  )}
                </Field>

                <Grid
                  container
                  alignItems="center"
                >
                  <Grid item xs={6} md lg className={classNames({ [classes.inputLeft]: !splitViewOpen })}>
                    {(splitViewOpen || (formikProps.values.transaction.split.items && formikProps.values.transaction.split.items.length !== 0))
                      ? (
                        <TextField
                          label={formikProps.values.type === STTypeEnum.TRANSFER ? 'Account' : 'Category'}
                          value={APP_STRING_SPLIT}
                          margin="dense"
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            disabled: true,
                            classes: {
                              underline: classes.underlineOverride,
                              disabled: classes.defaultOverride,
                            },
                          }}
                        />
                      ) : (
                        <RecrCategoryField
                          name="transaction.coa"
                          formikProps={formikProps}
                          classes={classes}
                        />
                      )}
                  </Grid>
                  {!splitViewOpen &&
                    <Grid item md className={classes.inputRight}>
                      <div>
                        <FormControlLabel
                          label={<><MUISplitIcon />Split</>}
                          labelPlacement="start"
                          control={<Switch
                            color="primary"
                            checked={splitViewOpen}
                            value={splitViewOpen}
                            id="split-switch"
                            onChange={() => {
                              formikProps.setFieldValue('transaction.split.items', []);
                              // this.setState({ splitViewOpen: !splitViewOpen });
                              setState({ ...state, splitViewOpen: !splitViewOpen });
                            }}
                          />}
                        />
                      </div>
                    </Grid>}
                </Grid>

                {splitViewOpen &&
                  <div className={classes.mainSplitTxnsContainer}>
                    <SplitsAndDetailsPanel
                      txn={new transactionsTypes.CashFlowTransaction({ ...formikProps.values.transaction, amount: formikProps.values.virtualAmount, type: formikProps.values.type })}
                      closeFn={() => setState({ ...state, splitViewOpen: !splitViewOpen })}
                      splitSwitch={() => setState({ ...state, splitViewOpen: !splitViewOpen })}
                      onAmountChange={(amount) => {
                        formikProps.setFieldValue('transaction.amount', Math.abs(amount));
                        formikProps.setFieldValue('virtualAmount', setVirtualAmount(formikProps.values, amount));
                      }}
                      saveFn={(txnform) => {
                        formikProps.setFieldValue('transaction.split.items', txnform?.split?.items || null);
                        formikProps.setFieldValue('transaction.coa', txnform.coa);
                      }}
                      onChangeTxnState={({ split }) => formikProps.setFieldValue('transaction.split.items', split.items)}
                      hideFooter
                      showCurrencySymbol
                      showDetails={false}
                      showSplits={splitViewOpen}
                      forceAutoSave
                      editable={!isTypeGrossPaycheck}
                    />
                  </div>}
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    md
                    lg
                    className={classNames({
                      [classes.inputLeft]: virtualFrequency !== frequencyAliasTypes.onlyOnce,
                    })}
                  >
                    <Field
                      name="dueOn"
                      validate={(value) => {
                        let errorMessage;
                        if (!value || !value.length) {
                          errorMessage = 'required';
                        } else if (!moment(value).isValid()) {
                          errorMessage = 'invalid';
                        }
                        return errorMessage;
                      }}
                    >
                      {({ field, form: { values, touched, errors } }) => {
                        const date = moment(field.value);
                        const selected = date.isValid() ? date : moment();
                        return (
                          <div className={classes.datePickerWrapper}>
                            <ReactDatePicker
                              id="next-date"
                              selected={selected.toDate()}
                              popperPlacement={!changeDetailsMode ? 'top-start' : 'bottom'}
                              onChange={(jsDate, e) => {
                                const dueOnValue = (e.key ? date : moment(jsDate));
                                setNewVirtualRecurrence({ dueOnValue, overwriteIntervalFlag: true });
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Escape') {
                                  e.stopPropagation();
                                }
                              }}
                              onClickOutside={() => {
                                const dueOnValue = moment(dueOn, 'l');
                                setNewVirtualRecurrence({ dueOnValue, overwriteIntervalFlag: true });
                              }}
                              minDate={moment().toDate()}
                              maxDate={moment(values.virtualEndOn).toDate()}
                              // dateFormat="MM/DD/YYYY"
                              allowSameDay
                              disabledKeyboardNavigation
                              customInputRef="inputRef"
                              customInput={
                                <TextField
                                  InputProps={{
                                    ...field,
                                    classes: { underline: classes.underlineOverride },
                                    autoComplete: 'off',
                                  }}
                                  InputLabelProps={{
                                    classes: { root: classes.ellipser },
                                  }}
                                  label="Recurrence Date"
                                  margin="dense"
                                  fullWidth
                                  error={Boolean(get(touched, field.name) && get(errors, field.name))}
                                  helperText={get(touched, field.name) && get(errors, field.name)}
                                />
                              }
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </Grid>
                  {virtualFrequency !== frequencyAliasTypes.onlyOnce &&
                    <Grid item xs={6} md lg className={classes.inputRight}>
                      <Field
                        name="virtualEndOn"
                        validate={(value) => value === 'Never' || value === '' || moment(value).isValid() ? undefined : 'invalid'}
                      >
                        {({ field, form: { values, touched, errors } }) => {
                          const date = moment(field.value);
                          const selected = date.isValid() ? date : moment();
                          return (
                            <div className={classes.datePickerWrapper}>
                              <ReactDatePicker
                                id="end-date"
                                selected={selected.toDate()}
                                popperPlacement={!changeDetailsMode ? 'top-end' : 'bottom'}
                                onChange={(jsDate, e) =>
                                  formikProps.setFieldValue(field.name, (e.key ? date : moment(jsDate)).format('l'))}
                                onKeyDown={(e) => {
                                  if (e.key === 'Escape') {
                                    e.stopPropagation();
                                  }
                                }}
                                minDate={moment(values.dueOn).toDate()}
                                allowSameDay
                                customInputRef="inputRef"
                                customInput={
                                  <TextField
                                    InputProps={{
                                      ...field,
                                      onBlur: (e) => {
                                        if (!field.value) {
                                          formikProps.setFieldValue(field.name, 'Never');
                                        }
                                        field.onBlur(e);
                                      },
                                      classes: { underline: classes.underlineOverride },
                                      autoComplete: 'off',
                                    }}
                                    label="End Date"
                                    margin="dense"
                                    fullWidth
                                    error={Boolean(get(touched, field.name) && get(errors, field.name))}
                                    helperText={get(touched, field.name) && get(errors, field.name)}
                                  />
                                }
                              />
                            </div>
                          );
                        }}
                      </Field>
                    </Grid>}
                </Grid>

                {(splitViewOpen || (formikProps.values.transaction.split.items &&
                  formikProps.values.transaction.split.items.length !== 0))
                  ? (
                    <TextField
                      label="Tags"
                      value={APP_STRING_SPLIT}
                      margin="dense"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        disabled: true,
                        classes: {
                          underline: classes.underlineOverride,
                          disabled: classes.defaultOverride,
                        },
                      }}
                    />
                  ) : (
                    <RecrTagField
                      name="transaction.tags"
                      formikProps={formikProps}
                      classes={classes}
                    />
                  )}
              </section>

              <section className={classes.buttonsSection}>
                <Grid
                  container
                  direction="row"
                  justifyContent={hideDeleteIcon ? 'flex-end' : 'space-between'}
                  alignItems="center"
                  spacing={0}
                >
                  {!hideDeleteIcon && (
                    <Grid item>
                      <QButton
                        disabled={!formikProps.values.id}
                        id="delete-transaction"
                        aria-label="delete"
                        variant="outlined"
                        style={{ width: 36, minWidth: 36, marginRight: 8, backgroundColor: 'transparent' }}
                        onClick={deleteSchTxn(formikProps.values.clientId, formikProps.values.id, formikProps.values.transaction.payee)}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </QButton>
                    </Grid>
                  )}
                  <Grid item>
                    <QButton
                      id="cancel-transaction"
                      aria-label="cancel"
                      variant="outlined"
                      style={{ marginRight: 15 }}
                      onClick={() => {
                        if (formikProps.dirty) {
                          showConfirmationDialog(true);
                        } else if (changeDetailsMode && formikProps.values.id) {
                          changeDetailsMode();
                        } else {
                          onClose();
                        }
                      }}
                    >
                      Cancel
                    </QButton>
                    <QButton
                      type="submit"
                      id="submit-transaction"
                      disabled={formikProps.isSubmitting || (formikProps.values.id && !formikProps.dirty)}
                      onClick={(e) => {
                        e.preventDefault();
                        formikProps.submitForm();
                      }}
                      variant="contained"
                      width={140}
                    >
                      {stateScheduledTxn.id ? 'Update' : 'Create'}
                    </QButton>
                  </Grid>
                </Grid>

              </section>

              <Dialog
                open={isConfirmationDialogOpen && (isFormDirty || formikProps.dirty)}
                keepMounted
                onClose={() => {
                  setDirtyForm(formikProps.dirty);
                  showConfirmationDialog(false);
                }}
              >
                <DialogContent>
                  <DialogContentText className={classes.discardText}>
                    Are you sure you want to discard all changes?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setDirtyForm(formikProps.dirty);
                      showConfirmationDialog(false);
                    }}
                    color="primary"
                    style={{ marginRight: 14 }}
                  >
                    Go Back
                  </Button>
                  <QButton
                    onClick={() => {
                      showConfirmationDialog(false);
                      if (discardCb) {
                        discardCb();
                      } else if (onClose) {
                        onClose();
                      }
                    }}
                    variant="contained"
                  >
                    Discard
                  </QButton>
                </DialogActions>
              </Dialog>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ScheduledTransactionFormQuicken;
