import React from 'react';
import PropTypes from 'prop-types';
import compose from 'utils/compose';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import store from 'store';
import _ from 'lodash';

import { datasetsSelectors } from 'companion-app-components/flux/datasets';
import { authSelectors } from 'companion-app-components/flux/auth';
import { accountsSelectors, accountsUtils } from 'companion-app-components/flux/accounts';

import { withStyles } from 'tss-react/mui';
import { withTheme } from '@emotion/react';
import { transactionListMetrics } from 'data/transactionList/selectors';
import * as transactionSelectors from 'data/transactions/selectors';
import { isExistingTxn } from 'data/transactions/utils';

import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
//  import transactionsIcon from 'assets/nav-menu/accounts.svg';
import transactionsIcon from 'assets/nav-menu/accounts.svg';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import RegisterHeader from 'components/TransactionRegister/RegisterHeader';
import BulkEditPanel from 'components/BulkEditPanel';
import BulkEditPopover from 'components/Transactions/BulkEditPopover';
import DocumentBrowser from 'components/DocumentBrowser';
import QCurrency from 'components/QCurrency';
import SplitsAndDetailsPanel from 'components/SplitsAndDetailsPanel';
// import QuickenSounds from 'components/QuickenSounds';

import Paper from '@mui/material/Paper';
import VirtualList from 'react-tiny-virtual-list-dmk';

import Typography from '@mui/material/Typography';

import PanelUpIcon from 'assets/icon-arrow-right.inline.svg';
import { getLogger } from 'companion-app-components/utils/core';

import { isMobile, isTablet } from 'react-device-detect';
import { isAcme } from 'isAcme';

import {
  RegisterContainer, styles, MIN_TX_HEIGHT,
} from './styles';

import TransactionRow from '../TransactionRow2';
import { TxFooter } from './components';

const log = getLogger('components/TransactionPage/index.js');

const REDISPLAY_DELAY = 5;

export class TransactionPage extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      displayNodes: [],
      displayHeights: {},
      scrollIndex: null,
      // domNodes: [],
      bodyOffset: 0,
      parent: null,
    };
    this.updateTimer = null;
    this.recalcWindow = Date.now();

    this.headerEl = null;
    this.footerEl = null;
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateSize);
    setTimeout(() => {
      document
        .getElementById('txn-register-virtual-list')
        ?.addEventListener?.('scroll', this.onScroll);
    }, 7000);
    setTimeout(this.recalcHeights, 1000);
  }

  UNSAFE_componentWillReceiveProps(_nextProps) {
    if (this.props.adapt !== _nextProps.adapt) {
      setTimeout(() => {
        this.forceUpdate();
      }, 0);
    }
    if (this.props.uiState !== _nextProps.uiState) {
      setTimeout(this.recalcHeights, REDISPLAY_DELAY);
    }
    this.state.scrollIndex = null;
  }

  UNSAFE_componentWillUpdate() {
    this.updatePf = moment().valueOf();
  }

  componentDidUpdate() {
    if (this.state.scrollIndex) {
      this.props.callbacks.scrollToTransaction(null);
      this.state.scrollIndex = null;
    }
    if (!isMobile && !isTablet) {
      setTimeout(this.recalcHeights, REDISPLAY_DELAY);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize);
    document
      .getElementById('txn-register-virtual-list')
      ?.removeEventListener?.('scroll', this.onScroll);
  }

  getItemSize = (index) => {
    const node = this.state.displayNodes[index];

    if (node && node.node) {
      const el = document.getElementById(node.node.props.id);
      if (el) {
        return el.offsetHeight;
      }
      const { id } = node.node.props;
      return this.state.displayHeights[id] ? this.state.displayHeights[id] : node.height;
    }
    return 0;
  };

  _setState = (x) => {
    if (x) {
      if (this.updateTimer) {
        clearTimeout(this.updateTimer);
      }
      this.updateTimer = setTimeout(() => {
        this.updateTimer = null;
        this.updateDimensions(x);
      }, REDISPLAY_DELAY + 5);
    } else {
      this.forceUpdate();
    }
  };


  /* eslint-disable react/no-find-dom-node */
  // calcVirtualScrollerHeight = () => {
  //
  //   this.parent = this.parent || document.getElementById(this.props.wrapperId);
  //
  //   if (this.parent && this.footerEl && this.headerEl) {
  //     const parentHeight = this.parent.getBoundingClientRect().height;
  //     const regHeight = (this.footerElHeight) + this.headerElHeight;
  //
  //     return parentHeight - regHeight;
  //   }
  //   return 0;
  // };


  makeId = (row, acct) => `txRow:${this.props.accountIds.hashCode()}${row}:${acct}`;

  /* eslint-disable no-param-reassign */
  rowInnerRef = (x, _key) => {
    if (x && x.id) {
      this.state.displayHeights[x.id] = x.offsetHeight;
      // this.state.domNodes[x.id] = x;
      if (x.className.indexOf('appear') < 0) {
        x.className = `${x.className} appear`;
      }
    }
  };

  regComponentRef = (key, x) => {
    this[key] = x;
    if (this.footerEl && this.headerEl) {
      this.footerElHeight = this.footerEl.offsetHeight;
      this.headerElHeight = this.headerEl.offsetHeight;
      this.state.bodyOffset = this.footerElHeight + this.headerElHeight;
      if (!this.state.parent) {
        this.forceUpdate();
      }
      this.state.parent = this.state.parent || document.getElementById(this.props.wrapperId);
    }
  };

  recalcHeights = (newState) => {
    let needUpdate = false;
    let lastNodeRect = null;
    let nodeMap = this.state.displayNodes.map((dnode) => {
      if (dnode.node && dnode.node.props) {
        return document.getElementById(dnode.node.props.id); // this.state.domNodes[dnode.node.props.id]);
      }
      return null;
    });
    nodeMap = nodeMap.filter((x) => x && x.offsetHeight !== 0);
    nodeMap.sort((a, b) => {
      if (a && b) {
        const rectA = a.getBoundingClientRect();
        const rectB = b.getBoundingClientRect();
        return (rectA.top - rectB.top);
      }
      return 0;
    });
    nodeMap.forEach((node, index) => {
      if (node) {
        const actualHeight = node.offsetHeight;
        const compareHeight = this.state.displayHeights[node.id];
        const nodeRect = node.getBoundingClientRect();
        const nodeHeight = nodeRect.height.toFixed(0);
        const heightFromLastNode = lastNodeRect ? (nodeRect.bottom - lastNodeRect.bottom).toFixed(0) : nodeHeight;
        if (nodeHeight !== heightFromLastNode) {
          log.log('Height MISMATCH ', index, nodeHeight, heightFromLastNode, compareHeight);
        }

        if ((actualHeight && compareHeight && (actualHeight !== compareHeight)) || (nodeHeight !== heightFromLastNode)) {
        // if ((actualHeight && compareHeight && (actualHeight !== compareHeight))) {
          needUpdate = true;
          // We purposefully do not use setState to ensure we don't trigger uneeded updates
          this.state.displayHeights[node.id] = actualHeight;
        }
        lastNodeRect = nodeRect;
      }

      return true;
    });

    if (this.virtualList) {
      this.virtualList.recomputeSizes(0);
    }

    // establish recalc windows of 1 seconds in length
    if (Date.now().valueOf() - this.recalcWindow.valueOf() > 1000) {
      this.recalcWindow = Date.now();
      this.numUpdates = 0;
    }

    // only re-update up to 5 times per recalc window
    if (needUpdate && this.numUpdates < 5) {
      this.state = { ...this.state, ...newState };
      this._setState(); // this will force an update
      this.numUpdates += 1;
      return false;
    }
    return true;
  };

  updateSize = () => {
    this.numUpdates = 0;
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }
    this.resizeTimer = setTimeout(this.forceUpdate(), 100);
  };

  updateDimensions = (newState = {}) => {
    if (this.recalcHeights(newState)) {
      this.setState({
        ...newState,
      });
    }
  };

  onSizeChange = () => {
    this.numUpdates = 0;
    setTimeout(this.recalcHeights, 250);
  };

  onScroll = () => {
    if (!this.props.wrapText || !this.props.longCats) { return; }
    setTimeout(_.throttle(this.recalcHeights, 500), REDISPLAY_DELAY);
  };

  //-------------------------------------------------------
  // Render all transactions
  //
  // This function populates this.state.displayNodes
  // and this.state.displayHeights.  displayNodes are an array of
  // REACT node types for rendering, displayHeights is an array of objects
  // containing the actual DOM references as well as their offsetHeight's
  //
  // These arrays can be used to manage virtual scrolling
  //
  renderTxList = (callbacks, accountIds, acctTxns, showBalance) => {

    const { uiState, theme } = this.props;
    let editable = false;

    this.state.displayNodes = [];

    let acctKey = 'acctKey';
    acctKey = accountIds.forEach((v) => `${acctKey}:${v}`);

    acctTxns.keySeq().forEach((key, indexMonth) => {
      let shade = false;
      const keyPrefix = `TxSep:${acctKey}`;

      const shortKey = key.slice(key.indexOf(':') + 1);
      const sectionOpen = uiState.openSections.has(shortKey);


      //----------------------------------
      // push the section header
      //----------------------------------
      let count = this.state.displayNodes.length;

      let node = (
        <div
          id={`${this.makeId(shortKey, acctKey)}:${sectionOpen ? 'OPEN' : 'CLOSED'}`}
          ref={(x) => this.rowInnerRef(x, key)}
          key={`${keyPrefix}${shortKey}`}
        >
          <MenuItem
            disableGutters
            style={{ padding: 0, height: 'auto', minHeight: 'auto' }}
          >
            <Paper
              elevation={0}
              className={classNames(this.props.classes.txSepSummary)}
              onClick={() => callbacks.openCloseSection(shortKey)}
            >
              <IconButton className={this.props.classes.txSepIcon} size="large">
                <PanelUpIcon
                  width={15}
                  height={15}
                  fill="#777"
                  className={sectionOpen ? this.props.classes.turnDown90 : this.props.classes.turnUp}
                />
              </IconButton>
              <Typography variant="subtitle1" className={this.props.classes.txSepHeading}>
                {shortKey}
              </Typography>
            </Paper>
          </MenuItem>
        </div>
      );

      if (!this.props.hideDividers && acctTxns.get(key).size) {
        this.state.displayNodes.push({ node, height: theme.components.register.sectionHeaderHeight + 1 });
      }

      if (sectionOpen || this.props.hideDividers) {
        const acctTxnsSet = new Set(acctTxns.get(key));
        acctTxnsSet.forEach((txn, indexTxn) => {
          //----------------------------------
          // push the transactions in this section
          //----------------------------------
          editable = (uiState.txnBeingEdited && uiState.txnBeingEdited.id === txn.id);
          const txnToRender = editable ? uiState.txnBeingEdited : txn;

          count = this.state.displayNodes.length;

          node = this.renderTransactionEditRow(
            callbacks,
            txnToRender,
            editable,
            shade,
            false,
            showBalance,
            this.makeId(txnToRender.id, acctKey),
            this.rowInnerRef,
            count,
            (indexMonth === 0 && indexTxn === 0), // Show qCardId on the first txn only
          );
          this.state.displayNodes.push({ node, height: MIN_TX_HEIGHT[this.props.registerComfort] });

          if (this.props.scrollToId && (String(this.props.scrollToId) === String(txn.id))) {
            this.state.scrollIndex = this.state.displayNodes.length - 1;
          }
          shade = !shade;
        });
      }
    });

    // render virtual row
    let virtualRow;
    if (uiState.showNewTxn) {
      if (uiState.txnBeingEdited && !isExistingTxn(uiState.txnBeingEdited)) {
        virtualRow = this.renderTransactionEditRow(
          callbacks,
          uiState.txnBeingEdited,
          true,
          true,
          true,
          showBalance,
          this.makeId(uiState.txnBeingEdited.clientId || 'lastrow', acctKey),
          this.rowInnerRef,
          'new',
          false,
        );
      }

      if (this.props.sortOrder === 'ascending') {
        this.state.displayNodes.push({ node: virtualRow, height: MIN_TX_HEIGHT[this.props.registerComfort] });
        if (String(this.props.scrollToId) === String(uiState.txnBeingEdited && uiState.txnBeingEdited.id)) {
          this.state.scrollIndex = this.state.displayNodes.length - 1;
        }
      } else {
        this.state.displayNodes.unshift({ node: virtualRow, height: MIN_TX_HEIGHT[this.props.registerComfort] });
        if (String(this.props.scrollToId) === String(uiState.txnBeingEdited && uiState.txnBeingEdited.id)) {
          this.state.scrollIndex = 0;
        }
      }
    }

  };



  //-------------------------------------------------------
  // Render Transaction Edit Row
  //
  renderTransactionEditRow = (
    callbacks,
    txn,
    editable,
    _shade = false,
    lastRow = false,
    showBalance = true,
    id,
    refFn,
    scriptId,
    addQcardID = false,
  ) => {

    // const noEdit = this.props.uiState.showSplitWindow; // || !this.props.uiState.simpleEdit;
    const { regEditableFields, flashTx } = this.props.uiState;

    const doFlash = flashTx && (flashTx === txn.clientId || flashTx === txn.id);

    // console.log("Rendering ", txn.payee);

    // do not show transactions for loan accounts
    if (!this.props.hideLoanTransactions
      || !accountsUtils.isLoanAccount(accountsSelectors.getAccountById(store.getState(), txn.accountId))) {

      return (
        <TransactionRow
          key={`txnPage:${txn.id}`}
          qcardId={addQcardID ? 'qCard-transaction-first-row' : undefined}
          accountIds={this.props.accountIds}
          refFn={refFn}
          sizesChanged={this.onSizeChange}
          id={id}
          callbacks={callbacks}
          txn={txn}
          editable={Boolean(editable)}
          lastRow={Boolean(lastRow)}
          onRowSelect={callbacks.onRowSelect}
          showAttachmentColumn={Boolean(this.props.showAttachmentColumn && this.props.uiState.showAttachmentColumnInHeader)}
          sortOrder={this.props.sortOrder}
          sortBy={this.props.sortBy}
          currEditFieldName={editable ? this.props.currEditFieldName : null}
          accounts={this.props.accounts}
          regEditableFields={regEditableFields}
          simpleEdit={editable ? this.props.uiState.simpleEdit && !this.props.splitsInDialog : false}
          selectedTxns={this.props.uiState.selectedTxns}
          showBalance={Boolean(showBalance)}
          doubleAmounts={this.props.doubleAmounts}
          deleteDownloaded={this.props.deleteDownloaded}
          editDownloaded={this.props.editDownloaded}
          showSplitWindow={editable ? Boolean(this.props.uiState.showSplitWindow && !this.props.splitsInDialog) : false}
          showSplitColumn={this.props.showSplitColumn}
          showSelectColumn={this.props.showSelectColumn}
          showReviewedColumn={this.props.showReviewedColumn}
          showAccountColors={this.props.showAccountColors}
          longCats={this.props.longCats}
          flashTx={Boolean(doFlash)}
          onFocusField={callbacks.onFocusField}
          showCurrencySymbol={this.props.showCurrencySymbol}
          showAccounts={this.props.accountIds.size !== 1}
          registerComfort={this.props.registerComfort}
          headerFields={this.props.headerFields}
          wrapText={this.props.wrapText}
          scriptId={`${this.props.wrapperId}-${String(scriptId)}`}
          wrapperId={this.props.wrapperId}
          calendar={this.props.calendar}
          allAccountIds={this.props.allAccountIds}
        />
      );
    }
    return null;
  };

  hideEdit = () => this.props.callbacks.hideModal('bulkEdit');

  render() {

    const { callbacks, accountIds, acctTxns, classes, loadPending, longCats,
      splitsDetailsInDialog, zeroStateMessage, zeroStateStyle, theme } = this.props;

    const { showSplitWindow, simpleEdit, txnBeingEdited } = this.props.uiState;

    const showSplitsDetailsDialog = splitsDetailsInDialog && (showSplitWindow || !simpleEdit);

    const showBalance = this.props.sortBy === 'date' && this.props.accountIds.size === 1;

    if (acctTxns) {
      this.renderTxList(callbacks, accountIds, acctTxns, showBalance);
    }


    // identify dataset type
    const dataset = this.props.datasetsById.get(this.props.datasetId);

    const scrollIndex = this.state.displayNodes.length > this.state.scrollIndex ? this.state.scrollIndex : null;

    const zeroStateContainer = (
      <div className={classes.zeroStateContainer}> { /* zeroStateStyle injected prop @ ProjectedBalancesCalendar */}
        {!zeroStateStyle &&
          <img alt="No Transactions" src={transactionsIcon} style={{ marginTop: 10, height: 64, width: 64 }} />}
        <br />
        <div
          className={classes.zeroStateTextBody}
          style={zeroStateStyle && { ...zeroStateStyle }}
        >
          {loadPending ? 'Transactions Loading...' : zeroStateMessage
            || "You don't have any transactions in this account yet"}
        </div>
      </div>
    );

    const ret = (
      <>
        {this.props.uiState.showBulkEdit && (isAcme ?
          <BulkEditPopover
            txnArray={this.props.uiState.selectedTxns}
            closeFn={this.hideEdit}
            saveFn={callbacks.bulkEditSave}
          />
          :
          <BulkEditPanel
            txnArray={this.props.uiState.selectedTxns}
            inDialog
            autoFocus
            closeFn={this.hideEdit}
            saveFn={callbacks.bulkEditSave}
            longCats={this.props.longCats}
          />
        )}

        {this.props.uiState.showDocumentBrowserTxn &&
          <DocumentBrowser
            onClose={() => callbacks.hideModal('documentBrowser')}
            documents={this.props.uiState.showDocumentBrowserTxn.attachments}
            selectedIds={callbacks.delDocIds}
            action="Delete"
          />}

        {showSplitsDetailsDialog && txnBeingEdited &&
          <SplitsAndDetailsPanel
            autoFocus
            inDialog
            txn={txnBeingEdited}
            closeFn={() => callbacks.hideModal('splits')}
            saveFn={this.props.callbacks.saveTransaction}
            deleteFn={() => callbacks.handleMenu('delete', txnBeingEdited)}
            onAmountChange={callbacks.onAmountChange}
            longCats={longCats}
            showCurrencySymbol={this.props.showCurrencySymbol}
            showDetails={!simpleEdit}
            showSplits={showSplitWindow}
            unMatchAction={() => callbacks.handleMenu('unMatch', txnBeingEdited)}
            goToTransferAction={() => callbacks.handleMenu('transfer', txnBeingEdited)}
            getSaveFn={callbacks.detailsRefSaveFn}
            splitSwitch={(on) => on ? callbacks.handleMenu('split', txnBeingEdited) : callbacks.handleMenu('hideSplit', txnBeingEdited)}
          />}

        <RegisterContainer
          className={classNames('register-container', { tightmargins: this.props.tightMargins })}
          onKeyDown={(e) => callbacks.regFieldKey(e)}
        >
          <RegisterHeader
            innerRegRef={(e) => { this.regComponentRef('headerEl', e); }}
            callbacks={this.props.callbacks}
            uiState={this.props.uiState}
            showBalance={showBalance}
            accountIds={this.props.accountIds}
            showControls={this.props.showControls}
            headerFields={this.props.headerFields}
            doubleAmounts={this.props.doubleAmounts}
            showAccountColors={this.props.showAccountColors}
            acctTxns={this.props.acctTxns}
            noNew={this.props.noNew}
            sizeChanged={this.props.sizeChanged}
            hideSearch={this.props.hideSearch}
            hideColumnsHeader={this.props.hideColumnsHeader}
            transactionListMetrics={this.props.transactionListMetrics}
            reminderSetting={this.props.reminderSetting}
            sortBy={this.props.sortBy}
            sortOrder={this.props.sortOrder}
            draggableFields={Boolean(this.props.regFieldsOrderChange)}
            dataset={dataset}
            showDebtErrorMessage={this.props.showDebtErrorMessage}
            calendarDate={this.props.calendarDate}
          />
          {this.props.acctTxns.size || this.props.uiState.showNewTxn ?
            <>
              {this.props.sizeToContent &&
                <div>
                  {this.state.displayNodes.map((item) => item.node)}
                </div>}

              {!this.props.sizeToContent &&
                <VirtualList
                  height={(this.state.parent ? this.state.parent.clientHeight : this.state.bodyOffset) - this.state.bodyOffset}
                  itemCount={this.state.displayNodes.length}
                  overscanCount={8}
                  itemSize={this.getItemSize}
                  scrollToIndex={scrollIndex}
                  scrollToAlignment="start"
                  ref={(x) => { this.virtualList = x; }}
                  renderItem={({ index, style }) => (
                    <div
                      key={index}
                      style={{ ...style, height: 'auto' }}
                    >
                      {this.state.displayNodes[index] ? this.state.displayNodes[index].node : ''}
                    </div>)}
                  className={classes.VL}
                  style={{ overflowX: 'hidden' }}
                  id="txn-register-virtual-list"
                />}
            </>
            :
            <>
              {zeroStateContainer}
            </>}

          <TxFooter
            footerRef={(x) => this.regComponentRef('footerEl', x)}
            hideFooter={this.props.hideFooter}
            hideSearch={this.props.hideSearch}
            classes={this.props.classes}
            transactionListMetrics={this.props.transactionListMetrics}
            theme={theme}
          />

        </RegisterContainer>
      </>
    );
    return ret;
  }
}

TransactionPage.defaultProps = {
  zeroStateMessage: "You don't have any transactions in this account yet",
};

TransactionPage.propTypes = {

  // Specified
  callbacks: PropTypes.object,
  scrollToId: PropTypes.string,
  currEditFieldName: PropTypes.string,
  hideDividers: PropTypes.bool,
  hideSearch: PropTypes.bool,
  zeroStateMessage: PropTypes.string,
  zeroStateStyle: PropTypes.object,
  reminderSetting: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  regFieldsOrderChange: PropTypes.func,

  // Generated
  classes: PropTypes.object,
  theme: PropTypes.object,
  accounts: PropTypes.object,
  transactionListMetrics: PropTypes.object,
  loadPending: PropTypes.bool,
  datasetId: PropTypes.string,
  datasetsById: PropTypes.object,

  // From Parent
  accountIds: PropTypes.object,  // Precisely, an immutable OrderedMap
  acctTxns: PropTypes.object,
  showControls: PropTypes.bool,
  noNew: PropTypes.bool,
  tightMargins: PropTypes.bool,
  sizeChanged: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideColumnsHeader: PropTypes.bool,
  calendar: PropTypes.bool,
  splitsInDialog: PropTypes.bool,
  allAccountIds: PropTypes.object,

  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  wrapperId: PropTypes.string,   // required
  adapt: PropTypes.number,
  calendarDate: PropTypes.object,

  // PREFS
  doubleAmounts: PropTypes.bool,
  editDownloaded: PropTypes.bool,
  deleteDownloaded: PropTypes.bool,
  showAttachmentColumn: PropTypes.bool,
  showSplitColumn: PropTypes.bool,
  showReviewedColumn: PropTypes.bool,
  showSelectColumn: PropTypes.bool,
  longCats: PropTypes.bool,
  showCurrencySymbol: PropTypes.bool,
  registerComfort: PropTypes.string,
  headerFields: PropTypes.object.isRequired,
  wrapText: PropTypes.bool,
  showAccountColors: PropTypes.bool,
  sizeToContent: PropTypes.bool,

  // uiState
  uiState: PropTypes.object,

  // Feature Flags
  hideLoanTransactions: PropTypes.bool,
  splitsDetailsInDialog: PropTypes.bool,

  // DEBT NOT SUPPORTED IN QUICKEN WEB
  showDebtErrorMessage: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  return {
    accounts: accountsSelectors.getAccountsById(state),
    transactionListMetrics: transactionListMetrics(state, ownProps.acctTxns),
    loadPending: transactionSelectors.getLoadPending(state),
    hideLoanTransactions: featureFlagsSelectors.getFeatureFlags(state).get('hideLoanTransactions'),
    splitsDetailsInDialog: featureFlagsSelectors.getFeatureFlags(state).get('splitsDetailsInDialog'),
    datasetId: authSelectors.getDatasetId(state),
    datasetsById: datasetsSelectors.getDatasetsById(state),
  };
}

function mapDispatchToProps(_dispatch) {
  return {

  };
}

TransactionPage.whyDidYouRender = true;

export default compose(
  withTheme,
  QCurrency(),
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(TransactionPage, styles));
