import * as React from 'react';
import moment from 'moment';
import { DateTime } from 'luxon';
import { Field, FormikProps, FormikValues } from 'formik';
import { get } from 'lodash';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ReactDatePicker from 'react-datepicker';

import { frequencyAliasTypes } from 'companion-app-components/flux/scheduled-transactions/scheduledTransactionsUtils';

import DownshiftField from 'components/QuickenControls/DownshiftField';
import {
  weeksIntervalMap,
  yearsIntervalMap,
  byDayMap,
  getByMonthDaysMap,
  hasByMonthDayOrLastDay,
} from './utils';

interface RecrVirtualFieldProps {
  classes: Record<string, string>;
  virtualFrequency: string;
  virtualRecurrence: Record<string, any>;
  dueOnValue: string;
  formikProps: FormikProps<FormikValues>;
  setNewVirtualRecurrence: (data: Record<string, any>) => void;
  isTypeGrossPaycheck: boolean;
  monthsIntervalMap: Record<string, any>;
  isQWinDataset: boolean;
}

const RecrVirtualField: React.FC<RecrVirtualFieldProps> = ({
  classes,
  virtualFrequency,
  virtualRecurrence,
  dueOnValue,
  formikProps,
  setNewVirtualRecurrence,
  isTypeGrossPaycheck,
  monthsIntervalMap,
  isQWinDataset,
}) => {

  const currentFrequency = React.useRef(virtualFrequency);

  const handleFrequencyChange = (newFrequency: string) => {
    if (isQWinDataset 
        && currentFrequency.current === frequencyAliasTypes.quarterlyQuicken 
        && newFrequency === frequencyAliasTypes.monthlyQuicken) {
      formikProps.setFieldValue('virtualRecurrence.interval', 1);
    }
    currentFrequency.current = newFrequency;
  };

  React.useEffect(() => {
    handleFrequencyChange(virtualFrequency);
  }, [virtualFrequency]); // eslint-disable-line react-hooks/exhaustive-deps

  const dueOnMoment = (dueOnValue && moment(dueOnValue)) || moment();
  const daysInMonth = dueOnMoment && dueOnMoment.isValid() ? dueOnMoment.daysInMonth() : 31;
  const sortItems = (a, b) => (parseInt(a, 10) - parseInt(b, 10));
  const onSelected = (name: string, value: number | string) => formikProps.setFieldValue(name, value);
  const parseDate = (dateStr: string, year: number) => (dateStr && dateStr.length === 5 ? `${dateStr}/${year}` : dateStr);

  const onWeeklyDateChange = (name: string, value: string) => {
    // check newDate is not in the past
    let newDueOnDate = moment(dueOnMoment).day(value);
    newDueOnDate = newDueOnDate.isSameOrAfter(dueOnMoment) ? newDueOnDate : newDueOnDate.day(7);
    onSelected(name, value);
    onSelected('dueOn', newDueOnDate.format('l'));
  };

  const onMonthlyDateChange = (name: string, value: string) => {
    onSelected(name, value);
    onSelected('dueOn', dueOnMoment.date(parseInt(value, 10)).format('l'));
  };

  const getDatesForQuarterlyReminder = () => {
    const dateObj = DateTime.fromISO(moment(formikProps.values.dueOn).format('YYYY-MM-DD'));
    const date = dateObj.toFormat('LL/dd');
    const date2 = dateObj.plus({ months: 3 }).toFormat('LL/dd');
    const date3 = dateObj.plus({ months: 6 }).toFormat('LL/dd');
    const date4 = dateObj.plus({ months: 9 }).toFormat('LL/dd');
    return date.concat(', ', date2, ', ', date3, ', ', date4);
  };

  const onTwiceAMonthDateChange = (changeIndex: number) => (name: string, value: number) => {
    const hasByMonthDay = !hasByMonthDayOrLastDay(formikProps.values.virtualRecurrence);
    const byMonthValue = !hasByMonthDay ? virtualRecurrence.byMonthDayOrLastDay[0] : virtualRecurrence.byMonthDay[0];
    const firstDay = changeIndex === 0 ? value : byMonthValue;
    const firstDate = moment(dueOnMoment).date(firstDay);
    let newValue = value;

    if (hasByMonthDay && value >= 28) {
      const endOfMonth = moment(formikProps.values.dueOn).endOf('month').date();
      newValue = -(endOfMonth - newValue);
    }

    onSelected(name, newValue);
    onSelected('dueOn', firstDate.format('l'));
  };

  const onTwiceAYearDateChange = (changeIndex) => (name, date) => {
    const dates = [...virtualRecurrence.byDates];
    if (changeIndex === 1) {
      dates[0] = dueOnMoment;
      dates[1] = date;

      if (dates[0].isAfter(dates[1], 'day') && dates[0].year() === dates[1].year()) {
        dates.reverse();
      }

      onSelected('dueOn', dates[0].format('l'));
      onSelected('virtualRecurrence.byDates[0]', dates[0].format('MM/DD'));
      onSelected('virtualRecurrence.byDates[1]', dates[1].format('MM/DD'));
    } else if (date.format('MM/DD') !== dates[0]) {
      setNewVirtualRecurrence({ dueOnValue: date, overwriteIntervalFlag: true });
      onSelected(name, date.format('MM/DD'));
    }
  };

  const renderRecuerrenceDateField = (args: Record<string, any>) => (
    <Field
      name={args.name}
      validate={(value: string) => {
        let errorMessage: string = '';
        if (!value || !value.length) {
          errorMessage = 'required';
        } else if (!moment(value, 'MM/DD').isValid()) {
          errorMessage = 'invalid';
        }
        return errorMessage;
      }}
    >
      {({ field: fieldData, form: { _values, touched, errors } }) => {
        const field = { ...fieldData };
        if (args.fixValue) {
          field.value = args.fixValue(field.value);
        }
        const date = moment(field.value);
        const selected = date.isValid() ? date : moment();
        return (
          <div className={classes.datePickerWrapper}>
            <ReactDatePicker
              disabled={args?.isTypeGrossPaycheck}
              id={args.id}
              selected={selected.toDate()}
              onChange={(jsDate, e) => (args.onChange(field.name, (e.key ? date : moment(jsDate))))}
              onClickOutside={() => (args.onChange(field.name, date))}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  e.stopPropagation();
                }
              }}
              minDate={args.minDate}
              maxDate={args.maxDate}
              popperPlacement={
                args.id === 'byDates-twice-year-first' ? 'bottom-start' : 'bottom-end'
              }
              allowSameDay
              disabledKeyboardNavigation
              customInputRef="inputRef"
              customInput={
                <TextField
                  InputProps={{
                    ...field,
                    classes: { underline: classes.underlineOverride },
                    autoComplete: 'off',
                  }}
                  InputLabelProps={{
                    classes: { root: classes.ellipser },
                  }}
                  label={args.label}
                  margin="dense"
                  fullWidth
                  error={Boolean(get(touched, field.name) && get(errors, field.name))}
                  helperText={get(touched, field.name) && get(errors, field.name)}
                />
              }
            />
          </div>
        );
      }}
    </Field>
  );

  const renderRecurrenceTextField = (args) => (
    <Field name={args.name}>
      {({ field: fieldData }) => {
        const field = { ...fieldData };
        if (args.fixValue) {
          field.value = args.fixValue(field.value);
        }
        return <TextField
          {...field}
          id={args.id}
          label={args.label}
          InputProps={{
            classes: {
              underline: classes.underlineOverride,
            },
            readOnly: args.readOnly,
            autoComplete: 'off',
          }}
          margin="dense"
          fullWidth
          disabled={args.isTypeGrossPaycheck}
        />;
      }}
    </Field>
  );

  const renderRecurrenceDownshiftField = (args) => {
    let items = args.itemMap;
    if (args.sortItems) {
      items = items.sort(args.sortItems);
    }
    return (
      <Field
        name={args.name}
        validate={(value) => `${value}` && `${value}`.length > 0 ? undefined : 'required'}
      >
        {({ field: fieldData, form: { values, touched, errors } }) => {
          const field = { ...fieldData };
          if (args.fixValue) {
            field.value = args.fixValue(field.value, values.virtualRecurrence);
          }
          return <DownshiftField
            key={`${args.id}-${get(values, field.name)}`}
            initialItemSelected={`${field.value}`}
            onSelected={(day) => args.onSelected(field.name, day)}
            items={[...items.keys()]}
            itemToString={(item) => items.get(item)}
            textFieldProps={{
              InputProps: {
                ...field,
                classes: { underline: classes.underlineOverride },
                disabled: args?.isTypeGrossPaycheck,
              },
              id: args.id,
              label: args.label,
              margin: 'normal',
              fullWidth: true,
              autoComplete: 'off',
              error: Boolean(get(touched, field.name) && get(errors, field.name)),
              helperText: get(touched, field.name) && get(errors, field.name),
            }}
            lightWeight
          />;
        }}
      </Field>
    );
  };

  const renderInterval = (itemMap, id, label = 'Every') => renderRecurrenceDownshiftField({
    sortItems,
    itemMap,
    id,
    label,
    name: 'virtualRecurrence.interval',
    onSelected: (name, interval) => onSelected(name, parseInt(interval, 10)),
    isTypeGrossPaycheck,
  });

  let singleColumn: React.ReactNode;
  let leftColumn: React.ReactNode;
  let rightColumn: React.ReactNode;

  switch (virtualFrequency) {
    case frequencyAliasTypes.dayQuicken:
      leftColumn = (
        <Field
          name="virtualRecurrence.interval"
          margin="dense"
          validate={(value) => {
            if (!value || value?.length <= 0) return 'required';
            const onlyNums = +value;
            if (Number.isNaN(onlyNums) || onlyNums < 1 || onlyNums > 365) {
              return 'Please enter numeric value between 1 and 365';
            }
            return null;
          }}
        >
          {({ field: fieldData, form: { _values, touched, errors } }) => {
            const field = { ...fieldData };
            return (
              <TextField
                {...field}
                id="intervalDay"
                label="Every"
                InputProps={{
                  disabled: isTypeGrossPaycheck,
                  classes: {
                    underline: classes.underlineOverride,
                  },
                  autoComplete: 'off',
                  endAdornment: (
                    <InputAdornment position="end">
                      day{field?.value > 1 && 's'}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  formikProps.setFieldValue(field.name, e.target.value);
                }}
                error={Boolean(get(touched, field.name) && get(errors, field.name))}
                helperText={get(touched, field.name) && get(errors, field.name)}
              />
            );
          }}
        </Field>
      );
      break;
    case frequencyAliasTypes.weeklyQuicken:
      leftColumn = renderInterval(weeksIntervalMap, 'interval-weekly');
      rightColumn = renderRecurrenceDownshiftField({
        name: 'virtualRecurrence.byDay[0]',
        onSelected: onWeeklyDateChange,
        itemMap: byDayMap,
        id: 'byDay-weekly',
        label: 'Day',
        isTypeGrossPaycheck,
      });
      break;
    case frequencyAliasTypes.every2WeeksQuicken:
      singleColumn = renderRecurrenceDownshiftField({
        name: 'virtualRecurrence.byDay[0]',
        onSelected: onWeeklyDateChange,
        itemMap: byDayMap,
        id: 'byDay-biWeekly',
        label: 'Every 2 weeks on',
        isTypeGrossPaycheck,
      });
      break;
    case frequencyAliasTypes.twiceAMonthQuicken: {
      const fixValue = (value, virtualRecurrenceObj) => {
        if (value <= 0 && !hasByMonthDayOrLastDay(virtualRecurrenceObj)) {
          const endOfMonth = moment(formikProps.values.dueOn).endOf('month').date();
          return value + endOfMonth;
        }
        return value;
      };
      singleColumn = renderInterval(monthsIntervalMap, 'interval-twice-month');
      leftColumn = renderRecurrenceDownshiftField({
        name: hasByMonthDayOrLastDay(formikProps.values.virtualRecurrence) ? 'virtualRecurrence.byMonthDayOrLastDay[0]' : 'virtualRecurrence.byMonthDay[0]',
        onSelected: onTwiceAMonthDateChange(0),
        sortItems,
        itemMap: getByMonthDaysMap(daysInMonth),
        id: 'byMonthDay-twice-month-first',
        fixValue,
        label: 'First on',
        isTypeGrossPaycheck,
      });
      rightColumn = renderRecurrenceDownshiftField({
        name: hasByMonthDayOrLastDay(formikProps.values.virtualRecurrence) ? 'virtualRecurrence.byMonthDayOrLastDay[1]' : 'virtualRecurrence.byMonthDay[1]',
        onSelected: onTwiceAMonthDateChange(1),
        sortItems,
        itemMap: getByMonthDaysMap(daysInMonth),
        id: 'byMonthDay-twice-month-second',
        fixValue,
        label: 'Second on',
        isTypeGrossPaycheck,
      });
    }
      break;
    case frequencyAliasTypes.everyFourWeeksQuicken:
      singleColumn = renderRecurrenceDownshiftField({
        name: 'virtualRecurrence.byDay[0]',
        onSelected: onWeeklyDateChange,
        itemMap: byDayMap,
        id: 'byDay-four-Weekly',
        label: 'Every 4 weeks on',
        isTypeGrossPaycheck,
      });
      break;
    case frequencyAliasTypes.monthlyQuicken:
      leftColumn = renderInterval(monthsIntervalMap, 'interval-monthly');
      rightColumn = renderRecurrenceDownshiftField({
        name: hasByMonthDayOrLastDay(formikProps.values.virtualRecurrence) ? 'virtualRecurrence.byMonthDayOrLastDay[0]' : 'virtualRecurrence.byMonthDay[0]',
        onSelected: onMonthlyDateChange,
        sortItems,
        itemMap: getByMonthDaysMap(daysInMonth),
        id: 'byMonthDay-monthly',
        label: 'Day',
        isTypeGrossPaycheck,
      });
      break;
    case frequencyAliasTypes.quarterlyQuicken:
      singleColumn = renderRecurrenceTextField({
        name: 'virtualOnDates',
        id: 'onDates-quarterly',
        label: 'On Dates',
        readOnly: true,
        fixValue: getDatesForQuarterlyReminder,
        isTypeGrossPaycheck,
      });
      break;
    case frequencyAliasTypes.twiceAYearQuicken: {
      const transactionYear = (dueOnMoment && dueOnMoment.year()) || moment().year();

      const fixValue = (position) => (value) => {
        const dates = [...virtualRecurrence.byDates].map((date) => moment(date, 'MM/DD').month() + 1);
        const increaseYear = position === 1 && (dates[0] >= 6) && (dates[1] <= 6); // When is second date, and first date is in second half of the year
        return value && parseDate(value, increaseYear ? transactionYear + 1 : transactionYear);
      };

      singleColumn = renderInterval(yearsIntervalMap, 'interval-twice-year');
      leftColumn = renderRecuerrenceDateField({
        name: 'virtualRecurrence.byDates[0]',
        onChange: onTwiceAYearDateChange(0),
        id: 'byDates-twice-year-first',
        label: 'First on',
        fixValue: fixValue(0),
        minDate: moment().toDate(),
        isTypeGrossPaycheck,
      });
      rightColumn = renderRecuerrenceDateField({
        name: 'virtualRecurrence.byDates[1]',
        onChange: onTwiceAYearDateChange(1),
        id: 'byDates-twice-year-second',
        label: 'Second on',
        fixValue: fixValue(1),
        minDate: moment().toDate(),
        isTypeGrossPaycheck,
      });
    }
      break;
    case frequencyAliasTypes.toPayEstimatedTaxesQuicken:
      singleColumn = renderRecurrenceTextField({
        name: 'virtualOnDates',
        id: 'onDates-tax-pay',
        label: 'On Dates',
        readOnly: true,
        fixValue: () => {
          const dates = virtualRecurrence?.byDates?.map((date) => 
            DateTime.fromFormat(date, 'MM/dd'));
          const adjustedDates = dates.map((date) => {
            if (date.month === 1 && date.day === 15) {
              return date.plus({ year: 1 });
            }
            return date;
          });
          return adjustedDates.sort().map((date) => 
            date.toFormat('MM/dd/yyyy')).join(', ');
        },
        isTypeGrossPaycheck,
      });
      break;
    case frequencyAliasTypes.yearlyQuicken:
      singleColumn = renderInterval(yearsIntervalMap, 'interval-yearly');
      break;
    default: break;
  }

  const leftColumnClass = classNames({ [classes.inputLeft]: Boolean(rightColumn) });

  return (
    <>
      {singleColumn && (
        <Grid container>
          {singleColumn && <Grid item xs>{singleColumn}</Grid>}
        </Grid>
      )}
      {(leftColumn || rightColumn) && (
        <Grid container>
          {leftColumn && <Grid item xs={6} md className={leftColumnClass}>{leftColumn}</Grid>}
          {(leftColumn && rightColumn) && <Grid item xs={6} md lg className={classes.inputRight}>{rightColumn}</Grid>}
        </Grid>
      )}
    </>
  );
};

export default RecrVirtualField;
