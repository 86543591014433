import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'utils/compose';
import { makeStyles } from 'tss-react/mui';
import DialogHoc from 'components/DialogHoc';

import DownloadButton from '../DownloadButton';

const useStyles = makeStyles()(() => ({
  downloadLink: {
    display: 'flex',
    flex: 1,
  },
}));

const SingleFeatureModal = (props) => {
  const { feature, setDialog, onClose, showDownloadButton } = props;
  const { classes } = useStyles(props);

  const onCloseHandler = () => onClose && onClose();

  const btsArray = [...(showDownloadButton ? 
    [
      <div className={classes.downloadLink}>
        <DownloadButton />
      </div>,
    ] : []), 'OK'];

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (feature) {
      setDialog(feature.label, feature.description, onCloseHandler, btsArray);
    }
  }, [feature]);

  return null;
};

SingleFeatureModal.propTypes = {
  // DialogHoc
  setDialog: PropTypes.func,
  // Component
  onClose: PropTypes.func,
  feature: PropTypes.object,
  showDownloadButton: PropTypes.bool,
};

export default compose(
  DialogHoc(),
)(SingleFeatureModal);
