import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utils/compose';
import { withTheme } from '@emotion/react';
import withRouterLegacy from 'components/withRouterLegacy';
import { isTablet } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import { safeRefIn } from 'utils/utils';
import { noop } from 'lodash';

import { getLogger } from 'companion-app-components/utils/core';
import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { categoriesTypes, categoriesActions, categoriesSelectors } from 'companion-app-components/flux/categories';
import { chartOfAccountsUtils, chartOfAccountsTypes, chartOfAccountsSelectors } from 'companion-app-components/flux/chart-of-accounts';

import NestedQMenu from 'components/NestedQMenu';
import QTip from 'components/QuickenControls/QTip';
import QIconButton from 'components/QIconButton';
import QTypography from 'components/MUIWrappers/QTypography';

import TextField from '@mui/material/TextField';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';

import { getMostPopularCatsByAccountId } from 'data/transactions/selectors';
import { coaIncludedInList } from '../helpers';

const log = getLogger('components/QuickenControls/CategoryFieldEditable/index.js');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const isIe = require('is-iexplorer');

const inputHeight = isIe ? 27 : 24;

const NONE_COA_NODE = {
  id: 'NONE',
  coa: { type: 'NONE', id: '0' },
  name: '- None -',
  fullPathName: '- None -',
  inList: true,
};

class CategoryFieldEditable extends PureComponent {

  constructor(props) {
    super(props);

    const coaId = chartOfAccountsUtils.getCoaId(props.value, props.allowBlank); // allow blank
    const coaNode = this.getCoaNode(coaId, props);

    this.state = {
      tfValue: coaId === 'BLANK' ? '' : this.getDisplayName(coaNode),
      isTransfer: this.isTransfer(coaNode),
      query: null,
      showMenu: false,
      placeholder: null,
      inProgress: false,
      categoriesList: null,
    };
    this.currentHover = null;
    this.inputRef = null;
    this.fieldRef = undefined;
  }

  componentDidMount() {
    if (this.props.initialFocus && this.inputRef) {
      this.inputRef.focus();
    }
    if (this.props.openMenu) {
      this.setState({ showMenu: true });
    }
    if (this.props.categoriesById) {
      this.setState({
        categoriesList: this.props.categoriesById.toList(),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (nextProps.coaNodesById) {
      if (nextProps.value !== this.props.value
        || nextProps.coaNodesById !== this.props.coaNodesById
        || nextProps.longCats !== this.props.longCats) {

        const coaId = chartOfAccountsUtils.getCoaId(nextProps.value, nextProps.allowBlank);
        const coaNode = this.getCoaNode(coaId, nextProps);
        // todo: if coaNode cannot be found, set it to uncategorized coaNode

        if (coaNode) {
          this.setState({ isTransfer: this.isTransfer(coaNode), tfValue: this.getDisplayName(coaNode, nextProps) });
        } else {
          this.setState({ isTransfer: false, tfValue: '' });
        }

        if (!this.props.value ||
          (this.inputRef && safeRefIn(nextProps, ['value', 'id']) !== safeRefIn(this.props, ['value', 'id']))) {
          if (this.inputRef && this.props.autoFocus) {
            if (isTablet) {
              setTimeout(() => {
                if (this.inputRef) {
                  this.inputRef.focus();
                  this.inputRef.select();
                }
              }, 250);
            } else {
              this.inputRef.focus();
              this.inputRef.select();
            }
          }
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { state, props } = this;
    if (prevProps.categoriesById !== props.categoriesById && state.newCategoryClientId) {
      const newCategoryCreated = props.categoriesById.find((category) => category.clientId === state.newCategoryClientId);
      if (newCategoryCreated) {
        const newNode = props.coaNodesById.get(newCategoryCreated.id);
        const newCOA = newNode && newNode.coa;
        if (newCOA) {
          props.onChange(newCOA);
          this.setState({ // eslint-disable-line react/no-did-update-set-state
            newCategoryClientId: undefined,
            inProgress: false,
          });
        }
      }
    }
  }

  getCoaNode = (coaId, props) => {
    let ret = props.coaNodesById ? props.coaNodesById.get(coaId) : null;
    if (!ret && coaId === 'NONE') {
      return NONE_COA_NODE;
    }

    if (chartOfAccountsUtils.isTransferCoaNotFound(props.value)) {
      ret = props.coaNodesById.get(chartOfAccountsUtils.TRANSFER_NOT_FOUND_COA_ID);
    }
    return ret;
  };

  getDisplayName = (node, props = this.props) => {
    if (node) {
      return props.longCats ? node.fullPathName : node.name;
    }
    return ''; // was N/A, but making it just BLANK, which is invalid cat
  };

  isTransfer = (node) => node && node.coa.type === 'ACCOUNT';


  handleRequestClose = () => {
    log.log('On Request Close...');
    this.setState({ showMenu: false });
    if (this.props.menuClose) {
      this.props.menuClose();
    }
  };

  menuOnChange = (item) => {
    if (item) {
      if (item.id === 'viewAll') {
        this.setState({ query: null, showMenu: true });
        return;
      }
      if (this.props.returnSelectionMethod) {
        // this.props.onChange({ coa: item.coa, selMethod: 'Menu' });
        this.props.onChange(item.coa);
      } else {
        this.props.onChange(item.coa);
      }
      this.handleRequestClose();
      this.setState({ query: null });
      if (this.props.menuClose) {
        this.props.menuClose();
      }
    }
  };

  renderCOAMenuNode = (coaNode) => {
    const { stType, categoriesById, accountsById, onlyL1, filterFn } = this.props;

    if (!onlyL1 && coaNode.children) {

      const menuItems = coaNode.children.map((childNode) => (
        this.renderCOAMenuNode(childNode)
      ));

      return { label: coaNode.name, value: coaNode, subMenu: menuItems };
    }

    if (coaNode.inList && (!filterFn || filterFn(coaNode.coa)) &&
      coaIncludedInList(coaNode.coa, categoriesById, accountsById, stType)) {
      return { label: coaNode.name, value: coaNode };
    }
    return { nop: true };

  };

  buildMatchedString = (match, indexOfMatch) => {
    const { theme } = this.props;
    const parentSplit = match.indexOf(':');
    if (parentSplit !== -1 && parentSplit < indexOfMatch) { // need to grey out parent
      return (
        <QTypography>
          <span style={{ color: theme.palette.text.secondary }}>{match.slice(0, parentSplit)}</span>
          <span>{match.slice(parentSplit, indexOfMatch)}</span>
          <span style={{ fontWeight: theme.typography.fontWeightMedium }}>{match.slice(indexOfMatch, indexOfMatch + this.state.query.length)}</span>
          <span>{match.slice(indexOfMatch + this.state.query.length)}</span>
        </QTypography>
      );
    }

    const index = indexOfMatch === -1 ? 0 : indexOfMatch;
    const queryLength = index + this.state.query.replace(':', ' : ').length;

    // no parent so no greying out
    return (
      <QTypography>
        {match.slice(0, index)}
        <span style={{ fontWeight: theme.typography.fontWeightMedium }}>{match.slice(index, queryLength)}</span>
        {match.slice(queryLength)}
      </QTypography>
    );
  };

  filterOptions = (options, query) => {
    let items = [];
    const lowerCasedQuery = query?.toLowerCase();

    options.forEach((option) => {
      if (!option.nop && !option.isSubheader && option.value && option.value.fullPathName
        && option.label && option.value.fullPathName?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
        && (!this.props.filterFn || this.props.filterFn(option.value.coa))) {
        const name = option.value.fullPathName.replace(':', ' : ');
        const boldIndex = name?.toLowerCase().indexOf(lowerCasedQuery);
        items.push({
          label: name,
          customRender: this.buildMatchedString(name, boldIndex),
          value: option.value,
        });
      }
      if (option.subMenu) {
        items = items.concat(this.filterOptions(option.subMenu, query));
      }
    });
    return items;
  };

  dropDownClick = () => {
    this.setState({ showMenu: !this.state.showMenu, query: null });
    if (this.inputRef) setTimeout(() => this.inputRef?.focus(), 50);
  };

  handleInputFocus = (event) => {
    event.target.focus();
    event.target.select();  // automatically highlights the input value
    // this.setState({ showMenu: true });
  };

  handleQueryChange = (event) => {
    this.setState({ query: event.target.value });
  };

  handleKeyDown = (event) => {
    if (this.state.showMenu && event.key !== 'Tab' && event.key !== 'Enter') {
      event.stopPropagation();
    } else if (event.key !== 'Shift' && event.key !== 'Escape' && event.key !== 'Enter' && event.key !== 'Tab') {
      this.setState({ showMenu: !this.state.showMenu });
    } else if (event.key === 'Enter' && (this.state.showMenu || this.state.query !== null)) {
      event.stopPropagation();
    } else if (event.key === 'Tab' && (this.state.showMenu || this.state.query?.length > 0) && this.currentHover) {
      if (this.currentHover.onSelect) {
        event.stopPropagation();  // if need to wait for category creation
        this.currentHover.onSelect();
      } else if (this.currentHover.value.coa) {
        this.props.onChange(this.currentHover.value.coa);
      }
    } else if (event.key === 'Escape') {
      this.handleRequestClose();
    }
  };

  handleCurrentHover = (current) => {
    if (this.currentHover !== current) this.currentHover = current;
  };

  handleNewCategoryClick = (newCatLabel, parentNode = null) => {
    const newCategoryClientId = uuidv4().toUpperCase();
    let type = this.props.recommendedCategoryType || categoriesTypes.CategoryTypeEnum.EXPENSE;
    if (parentNode && parentNode.coa && parentNode.coa.type === chartOfAccountsTypes.CoaTypeEnum.CATEGORY && parentNode.coa.id) {
      const parentCategory = this.props.categoriesById.get(parentNode.coa.id);
      type = parentCategory && parentCategory.type ? parentCategory.type : categoriesTypes.CategoryTypeEnum.EXPENSE;
    }
    if (assert(newCatLabel, `category name is required (name = '${newCatLabel}') #bug-trap`)) {
      const newCategory = categoriesTypes.mkCategory({
        clientId: newCategoryClientId,
        parentId: parentNode ? parentNode.id : '0',
        name: newCatLabel.trim(),
        type,
      });
      this.props.dispatchCreateCategoryAction(newCategory);
      this.setState({
        newCategoryClientId,
        showMenu: false,
        query: null,
        placeholder: newCatLabel,
        inProgress: true,
      });
      setTimeout(() => {
        if (this.state.newCategoryClientId === newCategoryClientId && this.state.inProgress) {
          this.setState({
            inProgress: false,
          });
        }
      }, 8000);
    }
  };

  treeHasMainCategory = (name) => this.state.categoriesList
    ?.some((coaNode) => coaNode.name?.toLowerCase().trim() === name?.toLowerCase().trim());

  optionHasSubCategory = (parent, label) => parent.subMenu && parent.subMenu.some((option) => option.label && option.label?.toLowerCase() === label?.toLowerCase());

  render() {
    const { state } = this;
    const { editable, allowNone, clickable, classNameWhenNotEditable, name, inputStyle, margin, numPopularCats, header, id,
      autoFocus, fontSize, popularCats, variant, filterFn, InputProps, label, textFieldProps, theme, width, textFieldVariant } = this.props;
    const toolTipTitle = this.state.isTransfer ? `[${this.state.tfValue}]` : this.state.tfValue;

    let menuItems = [];
    let selectedMenuItem;

    const popularCatsFiltered = filterFn ? popularCats.filter((x) => filterFn(x.coa)) : popularCats;

    const disableUnderlineObj = textFieldVariant === 'outlined' ? {} : { disableUnderline: this.props.disableUnderline };

    if (allowNone) {
      menuItems.push(this.renderCOAMenuNode(NONE_COA_NODE));
      menuItems.push({ nop: true, divider: true });
    }

    if (!this.state.query && popularCatsFiltered && popularCatsFiltered.size > 0) {

      if (numPopularCats > 0) {
        menuItems.push(
          {
            isSubheader: true,
            label: 'Most Used Categories',
          },
        );
      }
      popularCatsFiltered.slice(0, numPopularCats).forEach((catRecord, index) => {
        menuItems.push({
          label: chartOfAccountsSelectors.getCoaStringSelector(undefined, catRecord.coa, true),
          value: catRecord,
          subIndent: true,
          divider: index === (popularCatsFiltered.slice(0, numPopularCats).size - 1),
        });
      });
    }

    if (editable) {
      menuItems = menuItems.concat([{ isSubheader: true, label: 'All Categories' }]);
      menuItems = menuItems.concat(this.props.coaTree.map((coaNode) =>
        (!filterFn || filterFn(coaNode.coa)) ? this.renderCOAMenuNode(coaNode) : null).toJS());
    }
    menuItems = menuItems.filter((x) => x !== null);

    if (this.state.query && this.state.query.trim()) {
      let addCatOption = this.props.createEnabled && !this.treeHasMainCategory(this.state.query);
      if (addCatOption) {
        addCatOption = {
          customRender: (<QTypography>Create<span style={{ fontWeight: theme.typography.fontWeightMedium }}>{` "${this.state.query}"`}</span></QTypography>),
          onSelect: noop,
          value: 'create-category-bucket',
          label: 'create-category',
          subMenu: [
            {
              label: 'Main Category',
              value: 'create-parent-category',
              onSelect: () => this.handleNewCategoryClick(this.state.query),
            },
            {
              label: 'Subcategory of',
              value: 'create-child-category',
              onSelect: noop,
              subMenu: this.props.coaTree
                .filter((coaNode) => coaNode.coa.type === chartOfAccountsTypes.CoaTypeEnum.CATEGORY)
                .map((coaNode) => {
                  const menuNode = this.renderCOAMenuNode(coaNode);
                  return {
                    ...menuNode,
                    subMenu: null,
                    onSelect: () => this.handleNewCategoryClick(this.state.query, menuNode.value),
                  };
                }),
            },
          ],
        };
      }

      menuItems = this.filterOptions(menuItems, this.state.query.trim());

      if (menuItems && menuItems.length) {
        selectedMenuItem = menuItems[0];
      } else if (addCatOption) {
        selectedMenuItem = addCatOption;
      }

      if (addCatOption) {
        menuItems.unshift(addCatOption);
      }

      menuItems.push({
        label: 'See All Categories',
        value: 'see-all',
        onSelect: () => {
          this.setState({ query: null });
          if (this.inputRef) {
            this.inputRef.focus();
            this.inputRef.select();
          }
        },
      });
    }

    const flipIcon = this.state.showMenu || this.state.query !== null;

    return (
      <>
        {editable &&
        <div
          id={id ? `${id}-root` : 'category-root'}
          ref={(x) => { this.fieldRef = x; }}
          style={{ ...this.props.style, width }}
          className={this.props.classes?.root}
        >
          <TextField
            disabled={state.inProgress}
            onClick={this.dropDownClick}
            {...textFieldProps}
            variant={textFieldVariant}
            className={this.props.className || ''}
            label={label}
            value={this.state.query !== null ? this.state.query : this.state.placeholder || this.state.tfValue || ''}
            onChange={this.handleQueryChange}
            onKeyDown={this.handleKeyDown}
            onFocus={this.handleInputFocus}
            onBlur={this.state.showMenu ? undefined : this.props.onBlur}
            autoFocus={autoFocus}
            style={{ width: '100%' }}
            error={this.props.error}
            name={name}
            margin={margin}
            autoComplete="off"
            InputProps={{
              ...InputProps,
              ...disableUnderlineObj,
              id: id ? `${id}-input` : 'category-input',
              endAdornment: (
                <>
                  {state.inProgress &&  // dont take up precious space for a 5% use case (on create cat)
                  <CircularProgress
                    size={16}
                    style={{ visibility: state.inProgress ? 'visible' : 'hidden' }}
                  />}

                  {this.props.error &&
                  <ErrorIcon style={{ color: theme.palette.number.negative }} />}

                  <QIconButton
                    id={id ? `${id}-button` : 'category-button'}
                    disabled={state.inProgress}
                    style={{ color: theme.palette.grey.level6, cursor: 'pointer', fontSize: '23px', opacity: flipIcon && 1 }}
                    aria-label="Open Category List"
                    focusRipple
                    IconComponent={flipIcon ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon}
                    onClick={this.dropDownClick}
                    size="small-no-padding"
                    TooltipProps={{ enterDelay: 10 }}
                    tabIndex={-1}
                  />
                </>),
              sx: {
                paddingTop: 0,
                paddingBottom: 0,
                height: textFieldVariant === 'outlined' ? 'auto' : inputHeight,
                lineHeight: '22px',
                fontSize,
                color: (state.query && state.query !== state.tfValue) || (state.placeholder && state.placeholder !== state.tfValue) ?
                  theme.palette.text.secondary : undefined,
                ...(InputProps ? InputProps.style : {}),
              },
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              className: inputStyle || '',
              style: { height: 23 },
            }}
            inputRef={(x) => {
              if (this.props.inputRef) {
                this.props.inputRef(x);
              }
              this.inputRef = x;
            }}
          />
          <div style={{ margin: 'auto' }}>
            <NestedQMenu
              anchorEl={this.fieldRef}
              header={header}
              name="catmenu"
              options={menuItems}
              selectedOption={selectedMenuItem}
              onChange={this.menuOnChange}
              open={this.state.showMenu || this.state.query !== null}
              onClose={() => {
                this.setState({ query: null });
                this.handleRequestClose();
                this.props.blurOnMenuClose && this.props.onBlur({ relatedTarget: 'categoryfield' });
              }}
              currentHover={this.handleCurrentHover}
            />
          </div>
        </div>}

        {!editable &&
        <QTip
          wrapOnly
          title={this.props.tooltip ? toolTipTitle : null}
        >
          <QTypography
            variant={variant}
            clickable={clickable}
            className={classNameWhenNotEditable}
          >
            {this.state.isTransfer ? `[${this.state.tfValue}]` : this.state.tfValue}
          </QTypography>
        </QTip>}
      </>);
  }
}

CategoryFieldEditable.propTypes = {
  theme: PropTypes.object,
  style: PropTypes.object,
  // props from parent
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.string,
  textFieldvariant: PropTypes.string,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.object,
  initialFocus: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  clickable: PropTypes.bool,
  returnSelectionMethod: PropTypes.bool,
  longCats: PropTypes.bool,
  onlyL1: PropTypes.bool,
  allowBlank: PropTypes.bool,
  allowNone: PropTypes.bool,
  classNameWhenNotEditable: PropTypes.string,
  tooltip: PropTypes.bool, // show a tooltip on static value?
  disableUnderline: PropTypes.bool,
  autoFocus: PropTypes.bool,
  history: PropTypes.object,
  fontSize: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  inputRef: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  createEnabled: PropTypes.bool,
  recommendedCategoryType: PropTypes.string,
  filterFn: PropTypes.func,
  inputStyle: PropTypes.string,
  transferOnly: PropTypes.bool,
  stType: PropTypes.string,
  InputProps: PropTypes.object,
  textFieldProps: PropTypes.object,
  className: PropTypes.string,
  numPopularCats: PropTypes.number,
  openMenu: PropTypes.bool,
  blurOnMenuClose: PropTypes.bool,
  classes: PropTypes.object,
  header: PropTypes.object,
  menuClose: PropTypes.func,
  textFieldVariant: PropTypes.string,

  // props from redux
  coaTree: PropTypes.object,
  coaNodesById: PropTypes.object,
  categoriesById: PropTypes.object,
  accountsById: PropTypes.object,
  popularCats: PropTypes.object,
  dispatchCreateCategoryAction: PropTypes.func,
  context: PropTypes.string,
};

CategoryFieldEditable.defaultProps = {
  variant: 'body2',
  transferOnly: false,
  numPopularCats: 5,
  width: '100%',
  textFieldVariant: 'standard',
  context: '',
};

function mapStateToProps(state, ownProps) {
  return {
    coaTree: ownProps.transferOnly ? chartOfAccountsSelectors.getTransferCOATree(state) : chartOfAccountsSelectors.getChartOfAccountsTree(state, ownProps),
    categoriesById: categoriesSelectors.getCategoriesById(state),
    accountsById: accountsSelectors.getAccountsById(state),
    popularCats: getMostPopularCatsByAccountId(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    dispatchCreateCategoryAction: (data) => dispatch(categoriesActions.createCategory(data, { undo: { userMessage: 'Category created.' }, context: ownProps.context })),
  };
}

// note: order-matters - props flow from top to bottom
export default compose(withTheme)(withRouterLegacy(connect(mapStateToProps, mapDispatchToProps)(CategoryFieldEditable)));
