
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as TippyTip } from 'react-tippy';
import Tooltip from '@mui/material/Tooltip';
import 'react-tippy/dist/tippy.css';
import { withStyles } from 'tss-react/mui';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';

const styles = (theme) => ({
  tooltip: {
    fontSize: theme.typography.caption.fontSize,
    background: theme.palette.tooltip,
    color: 'white',
  },
});

class QTip extends React.Component {

  constructor(props) {
    super(props);
    this.wrapRef = null;
    this.state = {
      id: props.wrapId || uuidv4(),
      overflow: false,
    };
  }

  componentDidMount() {
    if (this.props.wrapOnly) {
      this.checkOverflow();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    this.wrapRef = null;
    if (nextProps.wrapOnly) {
      this.setState({ overflow: false });
      this.checkOverflow();
    }
    if (nextProps.wrapId !== this.props.wrapId) {
      this.setState({ id: nextProps.wrapId });
    }
  }

  isElementOverflow = (element) =>
    element && (element.clientWidth < element.scrollWidth);

  checkOverflow = () => {

    let element = this.wrapRef || document.getElementById(`QtipId:${this.state.id}`);
    this.wrapRef = element;

    if (element && element.children) {
      element = element.firstElementChild;
    }

    const overflow = this.isElementOverflow(element);
    this.setState({ overflow });
  };

  render() {
    const { children, title, html, classes, className, wrapId, wrapOnly, popperProps, arrowClass, noTippyTip, enableOnMobile, ...other } = this.props;

    if ((!isMobile || enableOnMobile) && ((html || (typeof title === 'string' && title.trim().length > 0)) && (!this.props.wrapOnly || this.state.overflow))) {
      return (
        (html && !noTippyTip) ?
          <TippyTip
            animation="fade"
            id={`tooltip-id:${this.state.id}`}
            theme="transparent"
            hideOnClick
            size="small"
            duration={200}
            distance={15}
            position={this.props.placement || 'bottom-end'}
            title={html ? null : (title || '')}
            html={html}
            delay={500}
            // style={{ width: 'inherit', height: 'inherit' }}  // this line breaks ie-11
            {...other}
          >
            <span style={{ height: '100%' }} id={`QtipId:${this.state.id}`} className={className}>
              {children}
            </span>
          </TippyTip>
          :
          <Tooltip
            enterDelay={500}
            id={`tooltip:${this.state.id}`}
            leaveDelay={200}
            placement={this.props.position || 'bottom-end'}
            //  title={title || ''}
            title={html || (title || '')}
            classes={{ tooltip: classes.tooltip, arrow: arrowClass }}
            PopperProps={popperProps}
            {...other}
          >
            <span id={`QtipId:${this.state.id}`} className={className}>
              {children}
            </span>
          </Tooltip>
      );
    }
    return (
      <span id={`QtipId:${this.state.id}`} className={className}>
        {children}
      </span>
    );
  }
}

QTip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  html: PropTypes.object,
  wrapOnly: PropTypes.bool,
  wrapId: PropTypes.string,
  classes: PropTypes.object,
  arrowClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  enterDelay: PropTypes.number,
  placement: PropTypes.string,
  position: PropTypes.string, // accepts either one of these as an override
  popperProps: PropTypes.object,
  noTippyTip: PropTypes.bool,
  enableOnMobile: PropTypes.bool,
};

export default withStyles(QTip, styles);
