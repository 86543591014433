import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List as ImmutableList } from 'immutable';
import { v4 as uuidv4 } from 'uuid';
import memoizeOne from 'memoize-one';
import isEqual from 'lodash/isEqual';

import Card from '@mui/material/Card';

import { getPreferences } from 'companion-app-components/flux/preferences/actions';
import { getSharedPreferencesByPath } from 'companion-app-components/flux/preferences/selectors';

import QPureComponent from 'QPureComponent';
import ErrorBoundary from 'components/ErrorBoundary';
import TransactionRegister from 'components/TransactionRegister';
import withRouterLegacy from 'components/withRouterLegacy';

// get document coordinates of the element
/* eslint-disable no-restricted-globals */
function getCoords(elem) {
  const box = elem.getBoundingClientRect();

  return {
    top: box.top + pageYOffset,
    left: box.left + pageXOffset,
  };
}

const regFieldsOrderDefault = ImmutableList(
  [
    'accountColor',
    'select',
    'account',
    'postedOn',
    'payee',
    'category',
    'split',
    'expense',
    'income',
    'amount',
    'save',
    'close',
    'menu',
  ],
);

class EmbeddedRegister extends QPureComponent {

  uniqueName = uuidv4();

  constructor(props) {
    super(props);

    this.state = {
      regWinHeight: 0,
      sortBy: props.sortBy || 'date',
      sortOrder: props.sortOrder || 'descending',
    };

    if (!this.props.datasetPreferences) {
      this.prop.getDatasetPreferences();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    setTimeout(this.updateDimensions, 100);
    if (this.props.getInsideRef) {
      this.props.getInsideRef(this);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sortBy !== this.props.sortBy) {
      this.setState({ sortBy: nextProps.sortBy });
    }
    if (nextProps.sortOrder !== this.props.sortOrder) {
      this.setState({ sortOrder: nextProps.sortOrder });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = (force) => {
    if (force || this.props.fitToWindow) {
      this.setState({ regWinHeight: this.calcRegisterHeight() });
    }
  };

  regRef = () => {
    if (this.props.fitToWindow) {
      setTimeout(this.updateDimensions, 100);
    }
  };

  navigateTo = (accountId, txnId) => {
    this.props.router.navigate(`/transactions?displayNode=${accountId}&txnId=${txnId}`, { replace: true });
  };


  calcRegisterHeight = () => {
    const node = document.getElementById(this.props.name);
    if (node) {
      const { top } = getCoords(node);
      return window.innerHeight - top - this.props.bottomMargin;
    }
    return window.innerHeight;
  };

  onSortChange = (sortBy, sortOrder) => {
    if (this.props.onSortChange) {
      return this.props.onSortChange(sortBy, sortOrder);
    }
    return this.setState({ sortBy, sortOrder });
  };

  cardStyleCached = memoizeOne((obj) => obj, isEqual);

  render() {
    super.render();

    const { name, accountIds, overrideTxns, regFieldsOrder, fitToWindow, hideFooter, sizeToContent, reminderSetting,
      datasetPreferences, zeroStateMessage, zeroStateStyle, calendar, addNewTransactionRef, webFirstCatUncategorize, scrollToId, onTxnUpdate } = this.props;
    const { transactionRegister: regPrefs } = datasetPreferences;

    return (
      <ErrorBoundary>
        <Card
          id={name || this.uniqueName}
          elevation={this.props.elevation}
          style={this.cardStyleCached({
            height: fitToWindow ? `${this.state.regWinHeight || this.calcRegisterHeight()}px` : 'inherit',
            ...this.props.style,
          })}
          className={this.props.className}
        >
          <TransactionRegister
            wrapperId={name || this.uniqueName}
            accountIds={accountIds}
            overrideTxns={overrideTxns}
            doubleAmounts={this.props.doubleAmounts === undefined ? regPrefs.doubleAmounts : this.props.doubleAmounts}
            editDownloaded={this.props.editDownloaded === undefined ? regPrefs.editDownloaded : this.props.editDownloaded}
            deleteDownloaded={this.props.deleteDownloaded === undefined ? regPrefs.deleteDownloaded : this.props.deleteDownloaded}
            splitsInDialog={this.props.splitsInDialog === undefined ? regPrefs.splitsInDialog : this.props.splitsInDialog}
            autoSave={this.props.autoSave === undefined ? regPrefs.autoSave : this.props.autoSave}
            registerComfort={this.props.registerComfort === undefined ? regPrefs.registerComfort : this.props.registerComfort}
            wrapText={this.props.wrapText === undefined ? regPrefs.wrapText : this.props.wrapText}
            autoSearch={false}
            longCats={this.props.longCats === undefined ? regPrefs.longCats : this.props.longCats}
            continuousCreate={false}
            showCurrencySymbol={this.props.showCurrencySymbol === undefined ? regPrefs.showCurrencySymbol : this.props.showCurrencySymbol}
            showAccountColors={this.props.showAccountColors === undefined ? regPrefs.showAccountColors : this.props.showAccountColors}
            currency={this.props.currency || 'USD'}
            getRef={this.regRef}
            navFn={this.navigateTo}
            showControls
            hideDividers={this.props.hideDividers}
            hideSearch={this.props.hideSearch}
            hideColumnsHeader={this.props.hideColumnsHeader}
            noNew
            regFieldsOrder={regFieldsOrder || regFieldsOrderDefault}
            hideFooter={hideFooter}
            sizeToContent={sizeToContent}
            zeroStateMessage={zeroStateMessage}
            zeroStateStyle={zeroStateStyle}
            reminderSetting={reminderSetting}
            calendar={calendar}
            sortOrder={this.state.sortOrder}
            sortBy={this.state.sortBy}
            onSortChange={this.onSortChange}
            addNewTransactionRef={addNewTransactionRef}
            webFirstCatUncategorize={webFirstCatUncategorize}
            scrollToId={scrollToId}
            onTxnUpdate={onTxnUpdate}
          />
        </Card>
      </ErrorBoundary>
    );
  }
}

EmbeddedRegister.propTypes = {
  name: PropTypes.string,
  fitToWindow: PropTypes.bool,
  accountIds: PropTypes.object,
  overrideTxns: PropTypes.object,
  bottomMargin: PropTypes.number,
  regFieldsOrder: PropTypes.object,
  hideDividers: PropTypes.bool,
  hideSearch: PropTypes.bool,
  currency: PropTypes.string,
  hideFooter: PropTypes.bool,
  sizeToContent: PropTypes.bool,
  doubleAmounts: PropTypes.bool,
  editDownloaded: PropTypes.bool,
  deleteDownloaded: PropTypes.bool,
  splitsInDialog: PropTypes.bool,
  autoSave: PropTypes.bool,
  registerComfort: PropTypes.string,
  wrapText: PropTypes.bool,
  longCats: PropTypes.bool,
  showCurrencySymbol: PropTypes.bool,
  showAccountColors: PropTypes.bool,
  hideColumnsHeader: PropTypes.bool,
  datasetPreferences: PropTypes.object,
  zeroStateMessage: PropTypes.string,
  zeroStateStyle: PropTypes.object,
  style: PropTypes.object,
  elevation: PropTypes.number,
  getInsideRef: PropTypes.func,
  reminderSetting: PropTypes.string,
  sortOrder: PropTypes.string,
  sortBy: PropTypes.string,
  calendar: PropTypes.bool,
  addNewTransactionRef: PropTypes.func,
  className: PropTypes.any,
  webFirstCatUncategorize: PropTypes.bool,
  scrollToId: PropTypes.string, // ID of transaction to scroll to
  onTxnUpdate: PropTypes.func,
};

EmbeddedRegister.defaultProps = {
  registerComfort: 'normal',
  hideSearch: true,
  hideDividers: true,
  hideFooter: true,
  reminderSetting: '999',
  zeroStateStyle: {},
};

function mapStateToProps(state) {
  return {
    datasetPreferences: getSharedPreferencesByPath(state, { group: 'dataset', path: ['webApp'] }),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDatasetPreferences: () => dispatch(getPreferences({ section: 'shared', group: 'dataset' })),
  };
}

export default withRouterLegacy(connect(mapStateToProps, mapDispatchToProps)(EmbeddedRegister));
