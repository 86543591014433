import React, { useEffect, useState, useReducer, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { OrderedMap } from 'immutable';

import { accountsSelectors } from 'companion-app-components/flux/accounts';

import { makeStyles } from 'tss-react/mui';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Fade from '@mui/material/Fade';
import AccountField from 'components/QuickenControls/AccountField';
import cloudImport from 'assets/cloud-import.svg';
import QPanelButton from 'components/QPanelButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DialogActions from '@mui/material/DialogActions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import StdDialog from 'components/Dialogs/StdDialog';
import { importData } from 'data/import/importActions';
import { mkDocument } from 'data/documents/types';
import { csvImportFilesInProgress, csvImportFilesSucceed, csvImportFilesFailed } from 'data/app/selectors';
import LoadingView from 'components/LoadingView';
import QButton, { QButtonVariantEnum } from 'components/QButton';

import '!file-loader?name=[folder]/[name].[ext]!../../files/import template.csv';
import IconButton from '@mui/material/IconButton';
import { DeleteForever } from '@mui/icons-material';

const ViewStateEnum = Object.freeze({
  IMPORT_FROM: 'IMPORT_FROM',
  MINT_INSTRUCTIONS: 'MINT_INSTRUCTIONS',
  PERSONAL_CAPITAL_INSTRUCTIONS: 'PERSONAL_CAPITAL_INSTRUCTIONS',
  APPLE_CARD_INSTRUCTIONS: 'APPLE_CARD_INSTRUCTIONS',
  OTHER_INSTRUCTIONS: 'OTHER_INSTRUCTIONS',
  FILE_PROCESSING: 'FILE_PROCESSING',
  FILE_SUCCEED: 'FILE_SUCCEED',
  FILE_PARTIALLY_SUCCEED: 'FILE_PARTIALLY_SUCCEED',
  FILE_FAILED: 'FILE_FAILED',
});

const contentStyle = {
  width: '100%',
  height: '100%',
};

const useStyles = makeStyles()((theme) => ({
  dialog: {
    //  width: 1500,
    //  margin: 'auto',
  },
  importFromPageStyles: {
    width: 750,
    display: 'flex',
    flexDirection: 'row',
    //  maxHeight: 400,
  },
  importFromPageRight: {
    marginLeft: '20px',
    width: '100%',
    height: '100%',
  },
  cloudIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'start',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    '&.showScrolling': {
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
  },
  contentWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  contentPlaceholder: {
    ...contentStyle,
    position: 'static',
    visibility: 'hidden',
  },
  content: {
    ...contentStyle,
    position: 'absolute',
    top: 0,
    transition: 'all 300ms',
  },
  instructions: {
    maxWidth: 810,
    width: 700,
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 40,
  },
  statusImage: {
    pointerEvents: 'none',
    width: 128,
    height: 128,
    marginTop: 24,
    marginBottom: 24,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  listItem: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: 20,
    marginBottom: 20,
  },
  step: {
    marginRight: '20px',
    minWidth: 60,
  },
  table: {
    marginTop: 8,
    marginBottom: 8,
  },
  header: {
    backgroundColor: theme.palette.greyScaleDeprecated[5],
    border: `1px solid ${theme.palette.background.default}`,
    padding: 6,
  },
  cell: {
    padding: 6,
  },
  dropZonePlaceholder: {
    position: 'absolute',
    width: '568px',
    height: '140px',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  dropZoneContent: {
    width: '568px',
    height: '140px',
    flexGrow: 1,
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    border: `2px dashed ${theme.palette.greyScaleDeprecated[3]}`,
    backgroundColor: theme.palette.greyScaleDeprecated[6],
    borderRadius: 8,
    margin: 30,
  },
  option: {
    paddingLeft: 0,
  },
  fromHeader: {
    width: 610,
    color: theme.palette.text.secondary,
    marginBottom: '30px',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    marginTop: '20px',
  },
  selectAcctRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  selectAcctLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  miniHeader: {
    fontSize: '14px',
    color: theme.palette.text.secondary,
  },
  selectAcctRightTxt: {
    marginTop: '40px',
    marginLeft: '20px',
    width: '240px',
    letterSpacing: '0.25px',
  },
  paragraph: {
    width: '520px',
  },
  bottomButton: {
    width: '96px',
    height: '36px',
  },
  dragAndDropInnerItems: {
    textAlign: 'center',
    margin: 'auto',
    alignItems: 'center',
  },
  dragAndDropTopTxt: {
    pointerEvents: 'none',
    marginBottom: 5,
    fontSize: 16,
    color: theme.palette.text.secondary,
  },
  dragAndDropMiddleTxt: {
    pointerEvents: 'none',
    marginBottom: 5,
    fontSize: 14,
  },
  dragAndDropBrowseBtn: {
    width: '96px',
    height: '36px',
    backgroundColor: theme.palette.greyScaleDeprecated[7],
  },
  boldText: {
    fontWeight: 500,
  },
  acceptedFile: {
    backgroundColor: theme.palette.greyScaleDeprecated[7],
    border: `1px solid ${theme.palette.greyScaleDeprecated[3]}`,
    borderRadius: '4px',
    padding: '8px',
  },
  deleteIcon: {
    color: theme.components.trashDelete,
    padding: '1px',
    marginTop: -2,
    marginLeft: 5,
  },
}));

const FileImportDialog = (props) => {
  const { account, onHandleClose, open, ...otherProps } = props;
  const accountId = account ? account.id : undefined;

  const { classes, cx } = useStyles();

  const dispatch = useDispatch();

  const [myAcceptedFiles, setMyAcceptedFiles] = useState([]);

  const dropzone = useDropzone();
  const { acceptedFiles, getRootProps, open: dropzoneOpen, getInputProps, isDragActive, isFileDialogActive } = dropzone;
  const { onClick, ...dropzoneRootProps } = getRootProps();
  const [files, setFiles] = useState(OrderedMap());

  const acceptableAccounts = useSelector(accountsSelectors.getTransactionAccounts);
  const [importAccount, setImportAccount] = useState(acceptableAccounts.get(accountId) ? accountId : undefined);

  useEffect(() => {
    if (acceptedFiles.length - 1 >= 0) {
      setMyAcceptedFiles([acceptedFiles[acceptedFiles.length - 1]]);
    }
  }, [acceptedFiles]);

  const filesInProgress = useSelector((state) => csvImportFilesInProgress(state, files));
  const filesComplete = useSelector((state) => csvImportFilesSucceed(state, files));
  const filesFailed = useSelector((state) => csvImportFilesFailed(state, files));

  const [errors, setErrors] = useState([]);
  useEffect(() => {
    const newErrors = [];
    filesComplete.forEach((file) => file.data?.errors?.forEach?.((error) => {
      newErrors.push(
        <Typography display="block" paragraph>
          <Typography variant="body" display="block">
            {`${newErrors.length + 1}. ${error.message}`}
          </Typography>
          <Typography variant="body2" display="block">
            Row: {error.recordNumber}&nbsp;&nbsp;&nbsp;
            Column: {error.columnName}&nbsp;&nbsp;&nbsp;
            Value: <Box bgcolor="warning.light" display="inline">{error.value}</Box>
          </Typography>
          <Typography variant="body2" display="block">
            [{error.row?.map?.((value, index) => (
              <>
                <Box bgcolor={error.columnIndex === index ? 'warning.light' : undefined} display="inline">
                  {value}
                </Box>
                {index !== (error.row.length - 1) && ', '}
              </>
            ))}]
          </Typography>
        </Typography>
      );
    }));
    setErrors(newErrors);
  }, [filesComplete]);

  const onHandleCloseOverride = useCallback((event, reason) => {
    setMyAcceptedFiles([]);
    if (onHandleClose) {
      onHandleClose(event, reason);
    }
  }, [onHandleClose]);

  const [viewState, dispatchViewStateAction] = useReducer((currentViewState, action) => {
    let newViewState = currentViewState;
    switch (action) {
      case 'importFrom':
        newViewState = {
          previousView: currentViewState.currentView,
          previousLeft: '+200%',
          currentView: ViewStateEnum.IMPORT_FROM,
        };
        break;
      case 'mintInstructions':
        newViewState = {
          previousView: currentViewState.currentView,
          previousLeft: '-100%',
          currentView: ViewStateEnum.MINT_INSTRUCTIONS,
        };
        break;
      case 'personalCapitalInstructions':
        newViewState = {
          previousView: currentViewState.currentView,
          previousLeft: '-100%',
          currentView: ViewStateEnum.PERSONAL_CAPITAL_INSTRUCTIONS,
        };
        break;
      case 'appleCardInstructions':
        newViewState = {
          previousView: currentViewState.currentView,
          previousLeft: '-100%',
          currentView: ViewStateEnum.APPLE_CARD_INSTRUCTIONS,
        };
        break;
      case 'otherInstructions':
        newViewState = {
          previousView: currentViewState.currentView,
          previousLeft: '-100%',
          currentView: ViewStateEnum.OTHER_INSTRUCTIONS,
        };
        break;
      case 'updateFileState':
        if (filesInProgress.size) {
          newViewState = {
            previousView: undefined,
            currentView: ViewStateEnum.FILE_PROCESSING,
          };
        } else if (filesFailed.size) {
          newViewState = {
            previousView: currentViewState.currentView,
            currentView: ViewStateEnum.FILE_FAILED,
          };
        } else if (filesComplete.size) {
          if (errors?.length) {
            newViewState = {
              previousView: currentViewState.currentView,
              currentView: ViewStateEnum.FILE_PARTIALLY_SUCCEED,
            };
          } else {
            newViewState = {
              previousView: currentViewState.currentView,
              currentView: ViewStateEnum.FILE_SUCCEED,
            };
            onHandleCloseOverride(undefined, 'auto');
          }
        }
        break;
      case 'tryAgain':
        newViewState = {
          previousView: currentViewState.currentView,
          currentView: ViewStateEnum.IMPORT_FROM,
        };
        break;
      default:
        assert(false, `'${action}' - unknown action`);
        newViewState = {
          previousView: currentViewState.currentView,
          currentView: ViewStateEnum.IMPORT_FROM,
        };
    }
    const view = document.getElementById('import-dialog');
    if (view) {
      view.scrollTo(0, 0);
    }
    return newViewState;
  }, {
    previousView: undefined,
    previousLeft: undefined,
    currentView: ViewStateEnum.IMPORT_FROM,
  });

  const handleImport = useCallback(() => {
    if (myAcceptedFiles?.length && dispatch) {
      let provider;
      switch (viewState.currentView) {
        case ViewStateEnum.MINT_INSTRUCTIONS:
          provider = 'MINT';
          break;
        case ViewStateEnum.PERSONAL_CAPITAL_INSTRUCTIONS:
          provider = 'PERSONAL_CAPITAL';
          break;
        case ViewStateEnum.APPLE_CARD_INSTRUCTIONS:
          provider = 'APPLE_CARD';
          break;
        case ViewStateEnum.OTHER_INSTRUCTIONS:
          provider = 'SIMPLIFI';
          break;
        default:
          provider = undefined;
      }
      if (provider) {
        myAcceptedFiles.forEach((file) => {
          const clientId = uuidv4();
          setFiles((filesMap) => filesMap.set(clientId, file));
          dispatch(importData({
            clientId,
            accountId: importAccount,
            provider,
            document: mkDocument({
              name: file.name,
              documentType: 'OTHER',
              contentType: file.type || 'text/csv',
              clientId: uuidv4(),
              size: file.size,
            }),
            data: file,
          }));
        });
      }
    }
  }, [myAcceptedFiles, dispatch, importAccount, viewState]);

  const handleDeleteFile = useCallback(() => {
    setMyAcceptedFiles([]);
  }, []);

  useEffect(() => {
    if (open) {
      setFiles(OrderedMap());
      dispatchViewStateAction('importFrom');
    }
  }, [open]);

  useEffect(() => { dispatchViewStateAction('updateFileState'); },
    [filesInProgress, filesComplete, filesFailed]);

  const dragDropComponent = useMemo(() => (
    <div
      className={classes.dropZoneContent}
      {...(isFileDialogActive ? {} : dropzoneRootProps)}
    >
      {isDragActive ?
        <CloudUploadIcon className={classes.statusImage} />
        :
        <div className={classes.dragAndDropInnerItems}>
          {(myAcceptedFiles.length) ?
            <div className={classes.acceptedFile}>
              {myAcceptedFiles[0].name}
              <IconButton
                className={classes.deleteIcon}
                aria-label="Delete"
                id="delete-file"
                onClick={handleDeleteFile}
                size="large"
              >
                <DeleteForever />
              </IconButton>
            </div>
            :
            <>
              <Typography variant="subtitle2" className={classes.dragAndDropTopTxt}>Drag and drop your file here</Typography>
              <Typography variant="body2" className={classes.dragAndDropMiddleTxt}>or</Typography>
              <QButton
                variant="outlined"
                onClick={() => dropzoneOpen()}
                className={classes.dragAndDropBrowseBtn}
              >
                BROWSE
              </QButton>
            </>}
        </div>}
    </div>
  ), [isDragActive, isFileDialogActive, dropzoneRootProps, myAcceptedFiles, dropzoneOpen, handleDeleteFile,
    classes.acceptedFile, classes.deleteIcon, classes.dragAndDropBrowseBtn, classes.dragAndDropInnerItems, classes.dragAndDropMiddleTxt,
    classes.dragAndDropTopTxt, classes.dropZoneContent, classes.statusImage]);

  const accountDropdown = useMemo(() => (
    <AccountField
      initialAccountId={importAccount}
      onSelected={(selectedAccount) => setImportAccount(selectedAccount?.id)}
      label="Account"
      variant="outlined"
      margin="normal"
      required
      fullWidth
    />
  ), [importAccount]);

  const importFromPage = useMemo(() => (
    <div className={classes.importFromPageStyles}>
      <img src={cloudImport} alt={'upload-to-cloud'} className={classes.cloudIcon} />
      <div className={classes.importFromPageRight}>
        <Typography variant="body2" className={classes.fromHeader}>
          You can manually import transactions from from other financial apps or from accounts that you can’t automatically sync to Simplifi.
        </Typography>
        <div>
          <QPanelButton
            id="import-csv"
            title="Import from a Comma Separated Value (CSV) file"
            subtitle="Import from any source or create your own file with our template"
            onClick={() => dispatchViewStateAction('otherInstructions')}
            tabIndex={0}
          />
          <QPanelButton
            id="import-mint"
            title="Importing from Mint"
            subtitle="Step-by-step instructions"
            onClick={() => dispatchViewStateAction('mintInstructions')}
            tabIndex={0}
          />
          <QPanelButton
            id="import-personal-capital"
            title="Importing from Personal Capital"
            subtitle="Step-by-step instructions"
            onClick={() => dispatchViewStateAction('personalCapitalInstructions')}
            tabIndex={0}
          />
          <QPanelButton
            id="import-apple-card"
            title="Importing from Apple Card"
            subtitle="Step-by-step instructions"
            onClick={() => dispatchViewStateAction('appleCardInstructions')}
            tabIndex={0}
          />
        </div>
      </div>
    </div>
  ), [dispatchViewStateAction, classes.fromHeader, classes.cloudIcon, classes.importFromPageRight,
    classes.importFromPageStyles]);

  const mintInstructionsPage = useMemo(() => (
    <div className={classes.instructions}>
      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 1
        </Typography>
        <div className={classes.selectAcctRow}>
          <div className={classes.selectAcctLeft}>
            <Typography variant="body2"> Choose which account to import to </Typography>
            {accountDropdown}
          </div>
          <Typography variant="body2" className={classes.selectAcctRightTxt}>Transactions can only be imported to one account at a time.</Typography>
        </div>
      </div>

      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 2
        </Typography>
        <Typography variant="body2">
          Go to the transaction page for the specific account you want to import from in Mint.
        </Typography>
      </div>

      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 3
        </Typography>
        <Typography variant="body2">
          Scroll to the bottom of transaction list and click <span className={classes.boldText}>Export all transactions.</span>
        </Typography>
      </div>

      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 4
        </Typography>
        <Typography variant="body2" component="div">
          {'Select the transaction file you just created to import.'}
        </Typography>
      </div>
      {dragDropComponent}
    </div>
  ), [classes.listItem, classes.step, accountDropdown, dragDropComponent,
    classes.boldText, classes.instructions, classes.selectAcctLeft, classes.selectAcctRightTxt, classes.selectAcctRow]);

  const personalCapitalInstructionsPage = useMemo(() => (
    <div className={classes.instructions}>
      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 1
        </Typography>
        <div className={classes.selectAcctRow}>
          <div className={classes.selectAcctLeft}>
            <Typography variant="body2"> Choose which account to import to </Typography>
            {accountDropdown}
          </div>
          <Typography variant="body2" className={classes.selectAcctRightTxt}>Transactions can only be imported to one account at a time.</Typography>
        </div>
      </div>

      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 2
        </Typography>
        <Typography variant="body2">
          Go to the transaction page for Credit Card in Personal Capital.
        </Typography>
      </div>

      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 3
        </Typography>
        <Typography variant="body2">
          On the top of the transaction list, click the CSV icon.
        </Typography>
      </div>

      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 4
        </Typography>
        <Typography variant="body2" component="div">
          {'Select the transaction file you just created to import.'}
        </Typography>
      </div>
      {dragDropComponent}
    </div>
  ), [classes.listItem, classes.step, accountDropdown, dragDropComponent,
    classes.instructions, classes.selectAcctLeft, classes.selectAcctRightTxt, classes.selectAcctRow]);

  const appleCardInstructionsPage = useMemo(() => (
    <div className={classes.instructions}>
      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 1
        </Typography>
        <div className={classes.selectAcctRow}>
          <div className={classes.selectAcctLeft}>
            <Typography variant="body2"> Choose which account to import to </Typography>
            {accountDropdown}
          </div>
          <Typography variant="body2" className={classes.selectAcctRightTxt}>Transactions can only be imported to one account at a time.</Typography>
        </div>
      </div>

      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 2
        </Typography>
        <Typography variant="body2">
          Open your Apple Card app and tap on <span className={classes.boldText}>Card Balance.</span>
        </Typography>
      </div>

      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 3
        </Typography>
        <Typography variant="body2">
          Select the statement that you want to import transactions from.
        </Typography>
      </div>

      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 4
        </Typography>
        <Typography variant="body2">
          Tap on <span className={classes.boldText}>Export Transactions</span> then select <span className={classes.boldText}>Comma Separated Values (CSV).</span>
        </Typography>
      </div>

      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 5
        </Typography>
        <Typography variant="body2">
          Tap the share icon on the top right of your screen and send to your computer.
        </Typography>
      </div>

      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 6
        </Typography>
        <Typography variant="body2" component="div">
          {'Select the transaction file you just created to import.'}
        </Typography>
      </div>
      {dragDropComponent}
    </div>
  ), [classes.listItem, classes.step, accountDropdown, dragDropComponent, classes.boldText,
    classes.instructions, classes.selectAcctLeft, classes.selectAcctRightTxt, classes.selectAcctRow]);

  const otherInstructionsPage = useMemo(() => (
    <div className={classes.instructions}>
      <Typography variant="body2" className={classes.miniHeader}>
        How to import from any Comma Separated Value (CSV) file.
      </Typography>
      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 1
        </Typography>
        <div className={classes.selectAcctRow}>
          <div className={classes.selectAcctLeft}>
            <Typography variant="body2"> Choose which account to import to </Typography>
            {accountDropdown}
          </div>
          <Typography variant="body2" className={classes.selectAcctRightTxt}>Transactions can only be imported to one account at a time.</Typography>
        </div>
      </div>
      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 2
        </Typography>

        <div>
          <Typography variant="body2">
            Create a spreadsheet using these exact column headers.
          </Typography>
          <Typography variant="body2" component="div">
            (Or you can&nbsp;
            <Link
              href="files/import template.csv"
              download="import template.csv"
              type="text/csv"
            >
              download our template
            </Link>
            )
          </Typography>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {['Date', 'Payee', 'Amount', 'Tags'].map((label) => (
                  <TableCell align="center" className={classes.header} key={label}>
                    <Typography variant="body2">
                      {label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {[['3/13/2020', 'Starbucks', '-7', 'Vacation'],
                ['3/15/2020', 'Uber', '-21.12', 'Hawaii, Vacation'],
                ['3/17/2020', 'Acme', '1735.21', ''],
              ].map((row) => (
                <TableRow key={row[1]}>
                  {row.map((cell, index) => (
                    <TableCell
                      align={'left'}
                      component={index ? undefined : 'th'}
                      scope={index ? undefined : 'row'}
                      className={classes.cell}
                      key={`${index}:${cell}`} // eslint-disable-line react/no-array-index-key
                    >
                      <Typography variant="body2">
                        {cell}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

      </div>
      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 3
        </Typography>
        <Typography variant="body2" className={classes.paragraph}>
          {'Enter or paste your transactions into the spreadsheet. '
          + 'Make sure your spreadsheet uses the exact same column headers that we\'ve provided '
          + 'and your dates and amounts are formatted like the above examples.'}
          <br />
          <br />
          {'Once imported to Simplifi, you can manually assign categories. '
          + '(Only needed for payees that you don’t already have category rules set up for.)'}
        </Typography>
      </div>

      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 4
        </Typography>
        <Typography variant="body2">
          Save your spreadsheet as a .csv file.
        </Typography>
      </div>

      <div className={classes.listItem}>
        <Typography variant="subtitle2" className={classes.step}>
          Step 5
        </Typography>
        <Typography variant="body2" component="div">
          {'Select the transaction file you just created to import.'}
        </Typography>
      </div>
      {dragDropComponent}
    </div>
  ), [classes.cell, classes.header, classes.listItem, classes.step, classes.table, accountDropdown, dragDropComponent,
    classes.instructions, classes.miniHeader, classes.paragraph, classes.selectAcctLeft, classes.selectAcctRightTxt,
    classes.selectAcctRow]);

  let showScrollBar = false;
  let dialogHeaderTitle = 'Import Transactions';
  switch (viewState.currentView) {
    case ViewStateEnum.MINT_INSTRUCTIONS:
      dialogHeaderTitle = 'Import from Mint';
      break;
    case ViewStateEnum.PERSONAL_CAPITAL_INSTRUCTIONS:
      dialogHeaderTitle = 'Import from Personal Capital';
      break;
    case ViewStateEnum.APPLE_CARD_INSTRUCTIONS:
      dialogHeaderTitle = 'Import from Apple Card';
      break;
    case ViewStateEnum.OTHER_INSTRUCTIONS:
      dialogHeaderTitle = 'Import from a CSV file';
      showScrollBar = true;
      break;
    case ViewStateEnum.FILE_PROCESSING:
      dialogHeaderTitle = 'Importing Transactions';
      break;

    default:
  }
  //  console.log('AccountFirst: ', accountId);
  //  console.log('Account: ', importAccount);
  return (
    <StdDialog
      title={dialogHeaderTitle}
      sharedcomponentid={'FILE_IMPORT_DIALOG'}
      classes={{ root: classes.dialog }}
      maxWidth="lg"
      open={open}
      {...otherProps}
      disableBackdropClick={viewState.currentView === ViewStateEnum.FILE_PROCESSING}
      showCloseButton={viewState.currentView !== ViewStateEnum.FILE_PROCESSING}
      showBackButton={viewState.currentView !== 'IMPORT_FROM' && viewState.currentView !== ViewStateEnum.FILE_PROCESSING}
      onBack={() => dispatchViewStateAction('importFrom')}
      onClose={onHandleCloseOverride}
    >
      <>
        <DialogContent id="import-dialog" className={cx(classes.container, showScrollBar ? 'showScrolling' : '')}>
          <div className={classes.contentWrapper}>

            {viewState.currentView === ViewStateEnum.IMPORT_FROM &&
            <div className={classes.contentPlaceholder}>
              {importFromPage}
            </div>}
            <div
              className={classes.content}
              style={{
                visibility: viewState.currentView === ViewStateEnum.IMPORT_FROM ? 'visible' : 'hidden',
                left: viewState.currentView === ViewStateEnum.IMPORT_FROM ? 0 : (viewState.previousView === ViewStateEnum.IMPORT_FROM && viewState.previousLeft) || '+200%',
              }}
            >
              {importFromPage}
            </div>

            {viewState.currentView === ViewStateEnum.MINT_INSTRUCTIONS &&
            <div className={classes.contentPlaceholder}>
              {mintInstructionsPage}
            </div>}
            <div
              className={classes.content}
              style={{
                visibility: viewState.currentView === ViewStateEnum.MINT_INSTRUCTIONS ? 'visible' : 'hidden',
                left: viewState.currentView === ViewStateEnum.MINT_INSTRUCTIONS ? 0 : (viewState.previousView === ViewStateEnum.MINT_INSTRUCTIONS && viewState.previousLeft) || '+200%',
              }}
            >
              {mintInstructionsPage}
            </div>

            {viewState.currentView === ViewStateEnum.PERSONAL_CAPITAL_INSTRUCTIONS &&
            <div className={classes.contentPlaceholder}>
              {personalCapitalInstructionsPage}
            </div>}
            <div
              className={classes.content}
              style={{
                visibility: viewState.currentView === ViewStateEnum.PERSONAL_CAPITAL_INSTRUCTIONS ? 'visible' : 'hidden',
                left: viewState.currentView === ViewStateEnum.PERSONAL_CAPITAL_INSTRUCTIONS ? 0 : (viewState.previousView === ViewStateEnum.PERSONAL_CAPITAL_INSTRUCTIONS && viewState.previousLeft) || '+200%',
              }}
            >
              {personalCapitalInstructionsPage}
            </div>

            {viewState.currentView === ViewStateEnum.APPLE_CARD_INSTRUCTIONS &&
            <div className={classes.contentPlaceholder}>
              {appleCardInstructionsPage}
            </div>}
            <div
              className={classes.content}
              style={{
                visibility: viewState.currentView === ViewStateEnum.APPLE_CARD_INSTRUCTIONS ? 'visible' : 'hidden',
                left: viewState.currentView === ViewStateEnum.APPLE_CARD_INSTRUCTIONS ? 0 : (viewState.previousView === ViewStateEnum.APPLE_CARD_INSTRUCTIONS && viewState.previousLeft) || '+200%',
              }}
            >
              {appleCardInstructionsPage}
            </div>

            {viewState.currentView === ViewStateEnum.OTHER_INSTRUCTIONS &&
            <div className={classes.contentPlaceholder}>
              {otherInstructionsPage}
            </div>}
            <div
              className={classes.content}
              style={{
                visibility: viewState.currentView === ViewStateEnum.OTHER_INSTRUCTIONS ? 'visible' : 'hidden',
                left: viewState.currentView === ViewStateEnum.OTHER_INSTRUCTIONS ? 0 : (viewState.previousView === ViewStateEnum.OTHER_INSTRUCTIONS && viewState.previousLeft) || '+200%',
              }}
            >
              {otherInstructionsPage}
            </div>

            {viewState.currentView === ViewStateEnum.FILE_PROCESSING &&
              <LoadingView
                title="Importing CSV"
                subTitle={filesInProgress.toIndexedSeq().map((file) => file.name).join(', ')}
                size={128}
                style={{ margin: 40 }}
              />}

            {viewState.currentView === ViewStateEnum.FILE_SUCCEED &&
            <Fade in mountOnEnter unmountOnExit>
              <div className={classes.statusContainer}>
                <CheckCircleOutlineIcon
                  color="primary"
                  className={classes.statusImage}
                />
                <Typography variant="h6">
                  CSV Uploaded successfully
                </Typography>
                <Typography variant="subtitle2">
                  {filesComplete.keySeq().filter((clientId) => files.get(clientId)).map((clientId) => files.get(clientId).name).join(', ')}
                </Typography>
              </div>
            </Fade>}

            {viewState.currentView === ViewStateEnum.FILE_PARTIALLY_SUCCEED &&
            <Fade in mountOnEnter unmountOnExit>
              <div className={classes.statusContainer}>
                <CancelOutlinedIcon
                  color="error"
                  className={classes.statusImage}
                />
                <Typography variant="h6">
                  Uh oh! The file you submitted has errors.
                </Typography>
                <Typography variant="subtitle2">
                  {'You can fix the errors and re-upload or, if the errors don’t matter to you, you can continue as is.'}
                </Typography>
                <Box
                  maxHeight={200}
                  border={1}
                  overflow="scroll"
                  borderRadius="4px"
                  borderColor="grey.A200"
                  width="100%"
                  p={2}
                  mt={3}
                  mb={3}
                >
                  {errors.map((error) => error)}
                </Box>
                <QButton
                  variant={QButtonVariantEnum.CONTAINED}
                  onClick={() => {
                    setFiles(OrderedMap());
                    dispatchViewStateAction('tryAgain');
                  }}
                >
                  Fix & Upload Again
                </QButton>
                <Box height={14} />
                <Button onClick={onHandleCloseOverride}>
                  Continue As Is
                </Button>
              </div>
            </Fade>}

            {viewState.currentView === ViewStateEnum.FILE_FAILED &&
            <Fade in mountOnEnter unmountOnExit>
              <div className={classes.statusContainer}>
                <CancelOutlinedIcon
                  color="error"
                  className={classes.statusImage}
                />
                <Typography variant="h6">
                  Unable to import
                </Typography>
                <Typography variant="subtitle2">
                  {filesFailed.keySeq().filter((clientId) => files.get(clientId)).map((clientId) => files.get(clientId).name).join(', ')}
                </Typography>
                <br />
                <QButton
                  variant={QButtonVariantEnum.CONTAINED}
                  onClick={() => {
                    setFiles(OrderedMap());
                    dispatchViewStateAction('tryAgain');
                  }}
                >
                  TRY AGAIN
                </QButton>
              </div>
            </Fade>}

          </div>
        </DialogContent>

        {(viewState.currentView === ViewStateEnum.MINT_INSTRUCTIONS
          || viewState.currentView === ViewStateEnum.PERSONAL_CAPITAL_INSTRUCTIONS
          || viewState.currentView === ViewStateEnum.APPLE_CARD_INSTRUCTIONS
          || viewState.currentView === ViewStateEnum.OTHER_INSTRUCTIONS
        ) &&
        <DialogActions>
          <QButton
            variant="outlined"
            onClick={onHandleCloseOverride}
            className={classes.bottomButton}
          >
            Cancel
          </QButton>
          <QButton
            variant={QButtonVariantEnum.CONTAINED}
            onClick={handleImport}
            className={classes.bottomButton}
            disabled={(!(myAcceptedFiles?.length && dispatch) || !importAccount)}
          >
            IMPORT
          </QButton>
        </DialogActions>}

        <input {...getInputProps()} />
      </>
    </StdDialog>
  );
};

FileImportDialog.propTypes = {
  open: PropTypes.bool,
  onHandleClose: PropTypes.func,
  account: PropTypes.object.isRequired,
};

export default FileImportDialog;
