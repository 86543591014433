import React, { useEffect, useRef, memo } from 'react';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from 'tss-react/mui';
import { withTheme } from '@emotion/react';

import compose from 'utils/compose';
import { theme } from 'themes/themeQuicken';

import styles from './styles';

const CHART_ID = 'barChart';
const BarChart = ({ chartId, data, range = 0, xAxisField, yAxisField, classes }) => {

  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const chartIdentifier = chartId || CHART_ID;
      chartRef.current = am4core.create(chartIdentifier, am4charts.XYChart);
      if (chartRef.current?.logo) {
        chartRef.current.logo.disabled = true;
      }
      chartRef.current.paddingLeft = 0;
      chartRef.current.paddingRight = 0;
      chartRef.current.paddingBottom = 0;
      chartRef.current.data = data;
      chartRef.current.events.on('beforedatavalidated', () => {
        chartRef.current.data.forEach((chartData, index) => {
          chartRef.current.data[index].category += ` (${index})`;
        });
      });
      
      const categoryAxis = chartRef.current.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = xAxisField;
      categoryAxis.renderer.minGridDistance = 20;
      categoryAxis.renderer.labels.template.adapter.add('textOutput', (text) => text ? moment(text).format('MMM') : text);
      categoryAxis.renderer.labels.template.fontSize = 14;
      categoryAxis.renderer.labels.template.fontWeight = 400;
      const valueAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis());
      valueAxis.numberFormatter = new am4core.NumberFormatter();
      valueAxis.numberFormatter.numberFormat = '$#,###';
      valueAxis.renderer.labels.template.fontSize = 14;
      valueAxis.renderer.labels.template.fontWeight = 400;
      
      // TODO : Needed after confirmation
      // if (range !== undefined) {
      //   const rangeLine = valueAxis.axisRanges.create();
      //   rangeLine.value = range;
      //   rangeLine.grid.stroke = am4core.color(theme.components.barChart.dangerColor);
      //   rangeLine.grid.strokeWidth = 2;
      //   rangeLine.grid.strokeOpacity = 1;
      // }

      const series = chartRef.current.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = yAxisField;
      series.dataFields.categoryX = xAxisField;
      series.columns.template.height = am4core.percent(100);

      const columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 1;
      columnTemplate.strokeOpacity = 1;
      columnTemplate.width = 17;
      columnTemplate.fill = am4core.color(theme.components.barChart.primaryColor);
    }

    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, [chartId, data, range, xAxisField, yAxisField]);

  return <div id={chartId || CHART_ID} ref={chartRef} className={classes.barChartWrapper} />;
};

BarChart.propTypes = {
  chartId: PropTypes.string,
  data: PropTypes.array,
  range: PropTypes.number,
  xAxisField: PropTypes.string,
  yAxisField: PropTypes.string,
  classes: PropTypes.any,
};

export default compose(
  withTheme,
)(withStyles(memo(BarChart), styles));
