export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const HELP_MENU_ITEMS = [
  { key: 'whatsNew', label: "What's New" },
  { key: 'releaseNotes', label: 'Release Notes' },
  { key: 'supportFAQ', label: 'Support & FAQ' },
];

export const APP_STRING_SPLIT = '--- Split ---';
export const APP_STRING_NOT_APPLICABLE = 'N/A';

