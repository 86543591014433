import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@emotion/react';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import { withStyles } from 'tss-react/mui';
import compose from 'utils/compose';
import { isAcme } from 'isAcme';

const styles = (theme) => ({
  labelStyle: {
    color: theme.palette.text.secondary,
  },
});

class MiniDatasetPicker extends PureComponent {
  constructor(props) {
    super(props);
    // eslint-disable-next-line babel/no-unused-expressions
    this.sortedDataset = isAcme ? props.datasets.valueSeq().sort((a, b) => a.name.localeCompare(b.name)) : props.datasets.valueSeq();
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (value) => {
    this.setState({ anchorEl: null });
    if (this.props.onChange && value) {
      this.props.onChange(value);
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { /*  datasets, */ dataset, classes, id } = this.props;
    return (
      <>
        <Button
          classes={this.props.labelStyle || { label: classes.labelStyle }}
          aria-owns={anchorEl ? 'dataset-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          style={{ height: '100%' }}
          id={id}
        >
          {this.props.label || 'CHANGE'}
        </Button>
        <Menu
          id="dataset-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          style={{ zIndex: 1302 }}
        >
          {this.sortedDataset.map((theDataset, index) => (
            <MenuItem
              button="true"
              key={theDataset.id}
              selected={dataset === theDataset}
              onClick={() => this.handleMenuItemClick(theDataset)}
            >
              <ListItemIcon
                style={{
                  visibility: `${dataset === theDataset ? 'visible' : 'hidden'}`,
                  marginRight: 0,
                }}
              >
                <CheckIcon />
              </ListItemIcon>
              <ListItemText primary={theDataset.name} id={index === 0 ? 'my-account-1st-data-set' : ''} />
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

MiniDatasetPicker.propTypes = {
  dataset: PropTypes.any.isRequired,
  datasets: PropTypes.any.isRequired,
  label: PropTypes.string,
  labelStyle: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,

  classes: PropTypes.object,
};

export default compose(
  withTheme,
)(withStyles(MiniDatasetPicker, styles, { name: { MiniDatasetPicker } }));
