
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Paper from '@mui/material/Paper/Paper';
import Button from '@mui/material/Button/Button';
import DateField from 'components/QuickenControls/DateField';
import AmountField, { formatNumber } from 'components/QuickenControls/AmountField';
import Typography from '@mui/material/Typography';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Divider from '@mui/material/Divider';
import moment from 'moment';
import classNames from 'classnames';

export class ReconcileSetup extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      useOnline: props.isOnline,
      statementEndBalance: props.isOnline ? Number(props.account.onlineBalance) : '',
      statementEndDate: props.isOnline ? props.account.onlineBalanceAt : moment().format('YYYY-MM-DD'),
    };
  }

  handleChange = (e, field) => {

    switch (field) {
      case 'postedOn':
        if (e) {
          this.setState({ statementEndDate: e.target.value });
        }
        break;

      case 'useOnline':
        this.setState({ useOnline: !this.state.useOnline });
        break;

      default:
      case 'amount':
        if (e) {
          this.setState({ [field]: e.target.value });
        }
        break;
    }
  };

  saveFn = () => {
    this.props.onSave(this.state);
  };

  closeAndExit = () => {
    this.props.onClose();
  };

  render() {

    const { classes, isOnline } = this.props;

    const title = 'Reconcile Transactions';

    return (
      <Dialog
        classes={{ paperWidthXs: classes.paperWidthSm }}
        open
        show="true"
        maxWidth="xs"
        onClose={this.closeAndExit}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <DialogTitle> {title} </DialogTitle>

        <div>
          {isOnline &&
            <FormGroup>
              <FormControlLabel
                classes={{ label: classes.labelBold }}
                control={
                  <Switch
                    checked={this.state.useOnline}
                    onChange={(e) => this.handleChange(e, 'useOnline')}
                    value="useOnline"
                    color="primary"
                  />
                }
                label={`Use Online Balance: ${formatNumber(this.props.account.onlineBalance, this.props.account.currency, '0,0.00')}`}
              />
            </FormGroup>}

          <Divider />
          <FormGroup classes={{ root: classes.formGroup }}>
            {!this.state.useOnline &&
              <FormControlLabel
                classes={{ root: classNames(classes.formControlLabel, 'bottomSpace') }}
                control={
                  <div style={{ marginLeft: 10 }}>
                    <AmountField
                      autoFocus
                      amountType="amount"
                      editable={false}
                      value={String(this.props.lastReconciledBalance)}
                      currencySymbol={this.props.account.currency}
                      className={this.state.useOnline ? classes.inputFieldsBold : classes.inputFields}
                      maxChars={16}  // support balances under 10 billion (with decimal and 2 decimal points)
                    >
                    </AmountField>
                  </div>
                }
                label="Previous Reconciled Balance:"
                labelPlacement="start"
              />}
            <FormControlLabel
              classes={{ root: classNames(classes.formControlLabel, 'bottomSpace') }}
              control={
                <div style={{ marginLeft: 10 }}>
                  <AmountField
                    autoFocus
                    amountType="amount"
                    onChange={(e) => this.handleChange(e, 'statementEndBalance')}
                    editable={!this.state.useOnline}
                    value={String(this.state.statementEndBalance)}
                    currencySymbol={this.props.account.currency}
                    className={this.state.useOnline ? classes.inputFieldsBold : classes.inputFields}
                  >
                  </AmountField>
                </div>
              }
              label="Statement Ending Balance:"
              labelPlacement="start"
            />
            <FormControlLabel
              classes={{ root: classes.formControlLabel }}
              control={
                <div style={{ marginLeft: 10 }}>
                  <DateField
                    fieldId="postedOn"
                    editable={!this.state.useOnline}
                    value={this.state.statementEndDate}
                    onChange={(e) => this.handleChange(e, 'postedOn')}
                    todaysDateIfWrong
                    customStyle={{ marginLeft: 10, fontWeight: this.state.useOnline ? 800 : null, fontSize: '16px' }}
                  />
                </div>
              }
              label="Statement Ending Date:"
              labelPlacement="start"
            />
          </FormGroup>
        </div>

        <Divider />
        <Typography variant="body2">
          Note: If there are any older unreconciled transactions on the desktop client that have not been synced,
          they are not considered for reconciliation
        </Typography>
        <Divider />
        <Paper elevation={0} className={classes.buttonHolder}>
          <div style={{ flex: 1 }} />
          <Button
            className={classes.buttons}
            onClick={this.closeAndExit}
            color="primary"
            id="bulk-edit-cancel"
          >
            Cancel
          </Button>
          <Button
            className={classes.buttons}
            onClick={this.saveFn}
            name="bulk-edit-last-tab"
            color="primary"
            id="bulk-edit-save"
          >
            Next
          </Button>
        </Paper>
      </Dialog>
    );
  }
}

ReconcileSetup.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  isOnline: PropTypes.bool,
  account: PropTypes.object,
  lastReconciledBalance: PropTypes.number,

  classes: PropTypes.object,
};

export default ReconcileSetup;

