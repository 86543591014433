import { call, put, takeEvery, select } from 'redux-saga/effects';
import { DateTime } from 'luxon';

import { resourceStoreTypes, resourceSagaUtils } from 'companion-app-components/flux/core';
import { getEnvironmentConfig, platform, crashReporterInterface } from 'companion-app-components/utils/core';
import { authActions, authSelectors } from 'companion-app-components/flux/auth';

import { mkEntitlement } from 'data/entitlements/entitlementsTypes';
import * as actions from 'data/entitlements/entitlementsActions';
import { getTopTierEntitlement, getEntitlements, getActiveEntitlements } from 'data/entitlements/entitlementsSelectors';
import { isAcme } from 'isAcme';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { navigate } from 'NavigateSetter';

const mkResourceConfig = (props) => resourceStoreTypes.mkQcsSyncResourceConfig({
  resourceName: 'entitlements',
  resourceBaseUrl: '/entitlements',
  axiosConfig: { headers: { 'qcs-dataset-id': null } },
  transformResponseToResources: (response: any) => response.data.entitlements.map((data) => mkEntitlement(data)),
  ...props,
});

export function* getEntitlementsActionWatcher() {
  const resourceConfig = mkResourceConfig({
    successAction: actions.getEntitlementsSuccess,
    failureAction: actions.getEntitlementsFailure,
  });
  yield takeEvery(actions.getEntitlements, resourceSagaUtils.qcsSyncGetResources, resourceConfig);
}

export function* getEntitlementsSuccessActionWatcher() {
  yield takeEvery(actions.getEntitlementsSuccess, function* getEntitlementsSuccess(_action) {
    const entitlement = yield select(getTopTierEntitlement);
    if (!entitlement) {
      const entitlements = yield select(getEntitlements);
      const activeEntitlements = yield select(getActiveEntitlements);
      const localTime = DateTime.local();
      crashReporterInterface.reportError(Error('no active entitlement found'), (event) => event.addMetadata('custom', {
        entitlement,
        activeEntitlements,
        entitlements,
        localTime,
      }));
      if (isAcme) {
        const playBilling = yield select(featureFlagsSelectors.getFeatureFlag, 'playBilling');
        const chromebook = yield call(platform.isChromebook);
        if (chromebook && playBilling) {
          navigate('/pick-your-plan');
        } else {
          const authSession = yield select(authSelectors.getAuthSession);
          yield put(authActions.authLogout({ reason: 'AUTH_LOGOUT_UNAUTHORIZED' }, { context: 'no entitlement simplifi' }));
          window.location.replace(`${getEnvironmentConfig().pick_your_plan_url}?bearer=${authSession.accessToken}`);
        }
      } else {
        yield put(authActions.authLogout({ reason: 'AUTH_LOGOUT_UNAUTHORIZED' }, { context: 'no entitlement quicken' }));
        window.location.replace(getEnvironmentConfig().pick_your_plan_url);
      }
    }
  });
}

export function* createEntitlementActionWatcher() {
  const config = mkResourceConfig({
    transformResourceWithResponse: (resource: any, response: any) => response.data.entitlements.map((data) => mkEntitlement(data)),
    successAction: actions.createEntitlementSuccess,
    failureAction: actions.createEntitlementFailure,
  });
  yield takeEvery(actions.createEntitlement, resourceSagaUtils.qcsSyncCreateResource, config);
}

export default [
  getEntitlementsActionWatcher,
  getEntitlementsSuccessActionWatcher,
  createEntitlementActionWatcher,
];
