import { isAcme } from 'isAcme';

const popOverWidth = 320;

export const styles = ({ palette, components, defaults, applyOpacityToHex, shadows }) => {
  const navMenuItem = {
    color: components.navigation.iconColor,
    opacity: components.navigation.rowOpacity,
    justifyContent: 'unset',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'none',
    alignItems: 'center',
    textAlign: 'center',
    outline: 'none',
    padding: `${components.navigation.iconVerticalSpacing}px 32px ${components.navigation.iconVerticalSpacing}px 16px`,
    width: components.navigation.barWidth + components.accountDrawer.width,
    '&:hover': {
      opacity: 1,
      color: `${components.navigation.iconHoverColor} !important`,
      background: `${components.navigation.hoverBackground} !important`,
      cursor: 'pointer',
    },
    transition: components.navigation.selectedIconAnimation,
  };
  return ({
    root: {
      width: '100%',
    },
    feedback: {
      color: `${palette.greyScaleDeprecated[7]} !important`,
    },
    outlined: {
      borderColor: palette.greyScaleDeprecated[7],
    },
    iconButtonRoot: {
      color: palette.greyScaleDeprecated[7],
      outline: 'none !important',
    },
    flex: {
      flex: 1,
    },
    navbar: {
      zIndex: 1201,
      backgroundColor: isAcme ? palette.background.paper : components.navigation.headerColor,
      width: components.navigation.barWidth,
      left: 0,
      height: '100%',
      overflow: 'hidden',
      transition: 'width .25s ease',
      boxShadow: isAcme ? shadows[8] : shadows[4],
      maxHeight: '100vh',
      '&.expand': {
        width: components.navigation.barWidthOpen,
        transitionDelay: '300ms',
      },
      '&.closed': {
        width: components.navigation.barWidth,
        transitionDelay: '300ms',
      },
    },
    staticNav: {
      zIndex: 1201,
      backgroundColor: isAcme ? palette.background.paper : components.navigation.headerColor,
      width: components.navigation.barWidth,
      left: 0,
      height: '100%',
    },
    navItemsHolder: {
      display: 'flex',
      flexDirection: 'column-reverse',
      marginTop: 0,
      textAlign: 'left',
      width: '100%',
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: 0,
      alignItems: 'baseline',
    },
    rootButton: {
      minWidth: isAcme ? 'unset' : 0,
    },
    textButton: {
      paddingLeft: `${components.navigation.iconVerticalSpacing}px`,
    },
    logoSection: {
      height: defaults.headers.subHeaderHeightTall,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      height: 40,
      marginRight: 8,
    },
    logoText: {
      height: 48,
      ...components.logoText,
    },
    betaBadge: {
      backgroundColor: '#F5A623',
      padding: '1px 2px',
      height: 'auto',
      borderRadius: 1,
      textTransform: 'none',
      lineHeight: 1,
      fontSize: 6,
    },
    navPopover: {
      display: 'flex',
      flexDirection: 'column',
      width: null, // theme.components.navigation.barWidth,
      overflow: 'hidden',
    },
    profilePopover: {
      minWidth: popOverWidth,
      backgroundColor: isAcme ? palette.background.default : 'inherit',
    },
    profileHeader: {
      fontFamily: 'Roboto, sans-serif',
      color: palette.greyScaleDeprecated[1],
      minHeight: 110,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'visible',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: 'center',
      '& h3': {
        margin: 0,
        fontSize: 16,
      },
      '& p': {
        margin: '0 0 10px',
        fontSize: 14,
      },
    },
    profileDataset: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: 12,
      color: palette.greyScaleDeprecated[1],
      backgroundColor: palette.greyScaleDeprecated[5],
      borderTop: `1px solid ${palette.greyScaleDeprecated[4]}`,
      borderBottom: `1px solid ${palette.greyScaleDeprecated[4]}`,
      padding: '10px 20px 6px',
      '& p': {
        margin: 0,
        fontSize: 14,
      },
      '& p:first-of-type': {
        fontWeight: 600,
      },
      '& i': {
        fontSize: 18,
        display: 'inlineBlock',
        verticalAlign: 'sub',
        marginRight: 5,
      },
      '& hr': {
        height: 0,
        borderTop: `1px solid ${palette.greyScaleDeprecated[4]}`,
        borderBottom: 'none',
      },
      '& a': {
        color: palette.greyScaleDeprecated[1],
        textDecoration: 'none',
      },
    },
    datasetListItemDefault: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    navMenuItem,
    navMenuItemSupport: {
      ...navMenuItem,
      '&:focus': {
        background: 'inherit',
        color: components.navigation.iconColor,
      },
    },
    selectedItem: {
      color: `${components.navigation.selectedIconColor} !important`,
      background: `${components.navigation.selectedBackground} !important`,
      '&:hover': {
        opacity: 1,
        color: `${components.navigation.selectedIconColor} !important`,
        background: `${components.navigation.selectedBackground} !important`,
        cursor: 'pointer',
      },
    },

    navMenuItemBottom: {
      cursor: 'pointer',
      ...navMenuItem,
      padding: 'none',
    },
    navText: {
      color: components.navigation.textColor,
      marginLeft: 24,
      textAlign: 'left',
    },
    selectedNavText: {
      color: components.navigation.selectedTextColor,
    },

    navButtonBottom: {
      width: !isAcme && components.navigation.barWidth,
      display: 'flex',
      flexDirection: 'column',
      flexFlow: 'column',
      textAlign: 'center',
      textColor: '#333',
    },
    selectedIcon: {
      background: components.navigation.iconSelectedColor,
      borderLeft: `solid ${components.navigation.iconColor} 2px`,
      paddingLeft: 16,
      width: components.navigation.barWidth + components.accountDrawer.width,
    },
    profileFinancesTitle: {
      fontSize: 16,
      fontWeight: 600,
    },
    disableHover: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    circleButtonContainer: {
      padding: '16px 0 16px 16px',
      minWidth: 0,
    },
    circleButton: {
      width: 24,
      height: 24,
      backgroundColor: components.navigation.iconColor,
      opacity: components.navigation.iconOpacity,
    },
    firstNameLetter: {
      fontFamily: 'Roboto, sans-serif',
      color: components.navigation.headerColor,
      fontSize: 16,
      fontWeight: 600,
    },
    betaLabel: {
      flex: 'auto',
      fontSize: '18px',
      fontWeight: 200,
      letterSpacing: '.05rem',
      position: 'absolute',
      right: 5,
      top: 10,
    },
    financesButtonStyle: {
      color: palette.link,
    },
    datasetSwitchingContent: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    datasetSwitchingProgress: {
      marginTop: '30px',
    },
    appDiv: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minWidth: 56,
    },
    appBarContainer: {
      overflowY: 'auto',
      maxWidth: '100%',
      overflowX: 'hidden',
      scrollbarWidth: 'thin',
      scrollbarColor: `${applyOpacityToHex(components.navigation.iconColor, 0.5)} ${components.navigation.headerColor}`,
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: applyOpacityToHex(components.navigation.iconColor, 0.5),
        borderRadius: 8,
      },
      '&::-webkit-scrollbar': {
        backgroundColor: components.navigation.headerColor,
        width: 5,
      },
    },
    navBottomSection: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: 'auto',
      marginBottom: 32,
      overflow: 'hidden',
      flexShrink: 0,
    },
    achievementsSpan: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      position: 'relative',
    },
    milestoneBadge: {
      height: 24,
      width: 24,
      marginRight: -8,
      position: 'relative',
    },
    mainNavSubMenu: {
      zIndex: 1200,
      top: '-58px',
      left: '192px',
    },
    mainNavChevronRightIcon: {
      position: 'absolute',
      left: '174px',
    },
    navItemText: {
      marginLeft: 24, 
      textAlign: 'left',
      fontSize: 16,
      lineHeight: '19px',
    },
    spinnerButton: {
      padding: 0,
      marginRight: 5,
    },
    moreNavSubMenu: {
      top: '-70px',
    },
  });
};
