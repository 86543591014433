// @flow
import { Record } from 'immutable';
import type { RecordOf, RecordFactory } from 'immutable';

export const PreferenceTypes = Object.freeze({
  SYSTEM: 'SYSTEM',
  USER: 'USER',
});
export type PreferenceType = $Values<typeof PreferenceTypes>; // eslint-disable-line no-undef

export const DataTypes = Object.freeze({
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
});
export type DataType = $Values<typeof DataTypes>; // eslint-disable-line no-undef

type PreferenceV2Type = {
  clientId: ?string,
  id: ?string,
  isDeleted: ?boolean,
  preferenceType: ?PreferenceType,
  name: ?string,
  dataType: ?DataType,
  label: ?string,
  value: ?any,
};

export type PreferenceV2 = RecordOf<PreferenceV2Type>;
export const mkPreferenceV2: RecordFactory<PreferenceV2> =
  Record({
    clientId: undefined,
    id: undefined,
    isDeleted: undefined,
    preferenceType: undefined,
    name: undefined,
    dataType: undefined,
    label: undefined,
    value: undefined,
  });

type PreferenceCriteriaType = {
  clientId: ?string,
  id: ?string,
  isDeleted: ?boolean,
  preferenceName: ?string,
  value: ?string,
  rollOutTarget: ?Array<string>,
  criteriaType: ?string,
  rollOutType: ?string,
  client: ?string,
  datasetPlatform: ?string,
};

export type PreferenceCriteria = RecordOf<PreferenceCriteriaType>;
export const mkPreferenceCriteria: RecordFactory<PreferenceCriteriaType> =
  Record({
    clientId: undefined,
    id: undefined,
    isDeleted: undefined,
    preferenceName: undefined,
    value: undefined,
    rollOutTarget: undefined,
    criteriaType: undefined,
    rollOutType: undefined,
    client: undefined,
    datasetPlatform: undefined,
  });

