// @flow
import React, { useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import classNames from 'classnames';

import { datasetsSelectors } from 'companion-app-components/flux/datasets';
import { authSelectors } from 'companion-app-components/flux/auth';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper/index';
import MenuList from '@mui/material/MenuList';

import SearchIcon from '@mui/icons-material/Search';

import Downshift from 'downshift';
import { Field, Formik, Form } from 'formik';

import { getLogger, tracker } from 'companion-app-components/utils/core';

import { featureList } from 'components/Dialogs/WFDesktopFeatures/featuresConfig';
import SingleFeatureModal from 'components/Dialogs/WFDesktopFeatures/SingleFeatureModal';
import FormikTextField from 'components/Formik/TextField';
import QButton from 'components/QButton';

import { getTopTierEntitlement } from 'data/entitlements/entitlementsSelectors';
import { getPopularInstitutions } from 'data/institutions/selectors';
import {
  getPopularInstitutions as getPopularInstitutionsAction,
  searchForInstitutions as searchForInstitutionsAction,
} from 'data/institutions/actions';

import { getDefaultChannel } from 'data/institutions/utils';
import { bindPromiseAction } from 'utils/actionHelpers';

import FiIcon from '../FiIcon';
import { institutionSelected } from '../actions';
import * as selectors from '../selectors';

import { BankImageCell, useStyles } from './styles';
import * as institutionsActions from '../../../../data/institutions/actions';

const log = getLogger('InstitutionPicker/index.js');

type Props = {
  aggregator: String,
  onAddUnconnectedAccount: () => void,
  onOpenInstitutionRequest: () => void,
  scope: string,
  trackingProperties: any,
  updateMode: boolean,
}

function extractHostname(url) {

  let hostname;

  // find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  // find & remove any port number
  hostname = hostname.split(':')[0];

  // find & remove any query "?"
  hostname = hostname.split('?')[0];

  // find & remove any beginning www.
  if (hostname.indexOf('www.') === 0) {
    hostname = hostname.slice(4);
  }

  return hostname;
}

function renderSuggestion(params, className) {
  const { suggestion, key, itemProps, highlightedIndex, selectedItem } = params;
  const isHighlighted = highlightedIndex === key;
  const isSelected = selectedItem && selectedItem.name === suggestion.name;
  const url = suggestion.url ? extractHostname(suggestion.url) : null;
  return (
    <MenuItem
      // sorry we can't override 'id' - downshift uses it for internal purpose
      {...itemProps}
      key={key}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      <ListItemText
        id={`institution-suggest-${key}`}
        primary={suggestion.name}
        secondary={url}
        className={className}
      />
    </MenuItem>
  );
}

const keyHandle = (highlightedIndex, selectHighlightedItem) => (e) => {
  if (e.keyCode === 9 && highlightedIndex !== null) {
    e.preventDefault();
    selectHighlightedItem();
  }
};

const handleSelect = async (selectedInstitution, aggregator, trackingProperties, scope, updateMode, dispatch) => {
  if (selectedInstitution) {

    const fetchInstitution = bindPromiseAction(dispatch, institutionsActions.getInstitution);
    const response = await fetchInstitution({ id: selectedInstitution.id }, { axiosConfig: { params: { aggregator } } });
    const institution = response.resource;

    const channel = getDefaultChannel(institution);
    dispatch(institutionSelected({ institution, channel }, { scope }));

    let institutionId;
    if (institution.ofx) {
      institutionId = institution.ofx?.bid;
    } else if (institution.fds) {
      institutionId = institution.fds?.id;
    } else {
      institutionId = institution.id;
    }
    tracker.track(!updateMode ? tracker.events.addFISelect : tracker.events.fixFISelect,
      {
        ...trackingProperties,
        institution_id: institutionId,
        institution_name: institution.name,
      });
  }
};

const handleSubmit = (values, actions) => {
  log.log('Called onSubmit... This does nothing so just resetting form');
  actions.setSubmitting(false);
};

const InstitutionSelectorForm = (props: Props) => {
  const {
    aggregator,
    onAddUnconnectedAccount,
    scope,
    trackingProperties,
    updateMode,
  } = props;

  const dispatch = useDispatch();
  const anchorRef = useRef();

  const isSubmitting = useSelector((state) => selectors.getIsSubmitting(state, { scope }));
  const popularInstitutions = useSelector(getPopularInstitutions);
  const tier = useSelector(getTopTierEntitlement);

  const datasetId = useSelector((state) => authSelectors.getDatasetId(state), shallowEqual);
  const datasetsById = useSelector((state) => datasetsSelectors.getDatasetsById(state), shallowEqual);

  const [featureModal, setFeatureModal] = useState(undefined);
  const [institutions, setInstitutions] = useState();
  const [lastSearchValue, setLastSearchValue] = useState();

  const { classes } = useStyles();

  useMount(() => {
    dispatch(getPopularInstitutionsAction(null, { axiosConfig: { params: { aggregator } } }));
  });

  const searchForInstitutionsPromise = bindPromiseAction(dispatch, searchForInstitutionsAction);
  const searchForInstitutions = (input) => {
    if (input && input.length > 0) {
      searchForInstitutionsPromise({ aggregator, searchString: input })
        .then((resolveInstitutions) => setInstitutions(resolveInstitutions?.[0]))
        .catch((error) => log.error(error));
      setLastSearchValue(input);
    } else {
      // Note: This gets called when the control leaves focus. This can happen clicking away or even by selecting
      // item. In this case, input will not exist. However, on screen, input is still left in control (perhaps
      // interaction with material-ui input isn't setup correctly, not really sure). Also, I don't clear last
      // search term here because when we select an item, then it will always be cleared due to this behavior.
      //
      setInstitutions(null);
    }
  };

  const datasetObject = datasetsById && datasetsById.get(datasetId);
  const OSBrandKey = (datasetObject?.platform || null) === 'QMAC_MWF' ? 'mac' : 'win';

  const getFeature = () => featureList && featureList[OSBrandKey].find((x) => x.id === 'investmentLoanAccount');
  const openDoMoreQuickenModal = (featureValue = getFeature()) => () => setFeatureModal(featureValue);
  const closeDoMoreQuickenModal = () => setFeatureModal(undefined);

  // determine logo-style by using first institution in popular list
  const isLogoStyleIcon = useMemo(
    () => (popularInstitutions && popularInstitutions.size && popularInstitutions.get(0).logoStyle !== 'BUTTON'),
    [popularInstitutions]
  );

  const renderPopularInstitution = (institution) => {
    if (!isLogoStyleIcon) {
      return renderPopularInstitutionButtonStyle(institution);
    }

    return (
      <BankImageCell key={institution.id}>
        <Button
          classes={{
            root: classes.popularButtonRoot,
            startIcon: classes.popularButtonStartIcon,
          }}
          onClick={() =>
            handleSelect(
              institution,
              aggregator,
              { ...trackingProperties, selected_by: 'icon' },
              scope,
              updateMode,
              dispatch
            )}
          startIcon={<FiIcon aggregator={aggregator} institution={institution} size="sm" />}
        >
          <span style={{ textAlign: 'left', width: '100%' }}>
            {institution.name}
          </span>
        </Button>
      </BankImageCell>
    );
  };

  const renderPopularInstitutionButtonStyle = (institution) => (
    <BankImageCell key={institution.id}>
      <button
        className={classes.pupularButtonIconOnly}
        onClick={() =>
          handleSelect(
            institution,
            aggregator,
            { ...trackingProperties, selected_by: 'icon' },
            scope,
            updateMode,
            dispatch
          )}
        type="button"
      >
        <FiIcon aggregator={aggregator} institution={institution} size="md" />
      </button>
    </BankImageCell>
  );

  const renderAddManuallyQuicken = (className) => ((!institutions || institutions.size === 0) ?
    <Typography variant="subtitle1" className={className}>
      Can&apos;t find your financial institution? &nbsp;
      <QButton id="add_manually_btn" onClick={onAddUnconnectedAccount} className={classes.manualButtonQuicken}>
        Add manually
      </QButton>
    </Typography> : null);


  return (
    <>
      {isSubmitting &&
        (
          <div className={classes.statusContainer}>
            <CircularProgress size={100} className={classes.progress} />
          </div>
        )}
      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        {(formikBag) => {
          const { handleSubmit: formikHandleSubmit } = formikBag;
          return (
            <Form onSubmit={formikHandleSubmit} className={classes.root}>
              <Typography variant="subtitle1" className={classes.subTitle}>
                Connect your financial institution to Quicken
              </Typography>
              <Downshift
                initialHighlightedIndex={0}
                defaultHighlightedIndex={0}
                itemToString={(item) => item ? item.label : ''}
                onInputValueChange={(input) => searchForInstitutions(input, dispatch)}
                onSelect={(item) =>
                  handleSelect(
                    item,
                    aggregator,
                    { ...trackingProperties, selected_by: 'search', last_search_value: lastSearchValue },
                    scope,
                    updateMode,
                    dispatch
                  )}
              >
                {({ getInputProps, getItemProps, reset, selectedItem, highlightedIndex,
                  selectHighlightedItem, isOpen, getMenuProps, getRootProps }) => {
                  const { onBlur, onChange, value, ...inputProps } = getInputProps();
                  return (
                    <div {...getRootProps({}, { suppressRefError: true })}>
                      <Field
                        inputRef={anchorRef}
                        component={FormikTextField}
                        onFieldBlur={onBlur}
                        onFieldChange={onChange}
                        onKeyDown={keyHandle(highlightedIndex, selectHighlightedItem)}
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          autoFocus: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton aria-label="Clear search" onClick={reset} size="large">
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          ...inputProps,
                          inputProps: {
                            autoCorrect: 'off',
                            id: 'bankSearch',
                          },
                        }}
                        label="Search all financial institutions"
                        name="institution"
                        className={classes.searchBar}
                        size="small"
                      />
                      <Popper
                        open={isOpen && value?.length > 0}
                        anchorEl={anchorRef.current}
                        placement="bottom-start"
                        className={classes.popper}
                        style={{ ...(anchorRef.current ? { width: anchorRef.current.parentElement?.clientWidth } : {}) }}
                      >
                        <Paper square>
                          <MenuList
                            className={classes.dropDown}
                            {...getMenuProps({}, { suppressRefError: true })}
                          >
                            {institutions?.size ?
                              institutions.map((institution, key) => (
                                renderSuggestion({
                                  suggestion: institution,
                                  key,
                                  itemProps: getItemProps({ item: institution }),
                                  highlightedIndex,
                                  selectedItem,
                                }, classes.searchResultItem)
                              )) :
                              (
                                <MenuItem
                                  id="no-results"
                                  component="div"
                                >
                                  <ListItemText
                                    primary="No results"
                                    className={classes.searchResultItem}
                                  />
                                </MenuItem>
                              )}
                          </MenuList>
                        </Paper>
                      </Popper>
                    </div>
                  );
                }}
              </Downshift>
              <div className={classes.belowSearchText}>
                <Typography variant="subtitle1" className={classes.popularCopy}>
                  {'Or select from popular ones'}
                </Typography>
              </div>
              {popularInstitutions && popularInstitutions.size &&
                (
                  <div
                    className={classNames(isLogoStyleIcon ? classes.popularGrid : classes.popularGridIconButtonStyle, classes.overFlowScroll)}
                  >
                    {popularInstitutions.map(renderPopularInstitution)}
                  </div>
                )}
            </Form>
          );
        }}
      </Formik>
      <div className={classes.doMoreQuicken}>
        {renderAddManuallyQuicken(classes.addNonConnectedAccountQuicken)}
        {tier && tier.id && ['US_QUICKEN_DELUXE', 'CA_QUICKEN_DELUXE'].includes(tier.id) &&
        (
          <>
            <QButton
              onClick={openDoMoreQuickenModal()}
              className={classes.doMoreQuickenButton}
            >
              How do I add my investments or loans?
            </QButton>
            <SingleFeatureModal feature={featureModal} onClose={closeDoMoreQuickenModal} showDownloadButton />
          </>
        )}
      </div>
    </>
  );
};

export default InstitutionSelectorForm;
