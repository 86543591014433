/* eslint react/prop-types: 0 */

import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Set, Record, Map as ImmutableMap } from 'immutable';
import { v4 as uuidv4 } from 'uuid';
import noop from 'lodash/noop';

import { withStyles } from 'tss-react/mui';

import { getLogger, tracker } from 'companion-app-components/utils/core';
import { accountsActions, accountsTypes, accountsUtils, accountsSelectors } from 'companion-app-components/flux/accounts';
import { tagsSelectors } from 'companion-app-components/flux/tags';
import { categoriesSelectors } from 'companion-app-components/flux/categories';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { chartOfAccountsTypes } from 'companion-app-components/flux/chart-of-accounts';
import { authSelectors } from 'companion-app-components/flux/auth';
import { transactionsActions, transactionsTypes, transactionsTransformers, transactionsUtils } from 'companion-app-components/flux/transactions';
import { scheduledTransactionsActions, scheduledTransactionsSelectors } from 'companion-app-components/flux/scheduled-transactions';
import { normalizePayeeQCS } from 'companion-app-components/flux/rename-rules/renameRulesUtils';
import { makeMemorizedRuleFromTransactionAction } from 'companion-app-components/flux/memorized-rules/memorizedRulesActions';

import store from 'store';
import * as transactionsSpecialActions from 'data/transactions/actions';
import { isCompareToRegisterEnabled } from 'data/preferences/selectors';
import {
  isUnbalancedSplitTxn, balanceSplitTxn,
  isSupportedTransferTxn, isUnacceptedScheduledTxn, getTxnDifferences,
  isActionableScheduledInstance, updateNewTags, isExistingTxn, isBankOwnedPending, isUnAcceptedTxnType,
  updateMemorizePayeeValues,
} from 'data/transactions/utils';
import * as transactionListSelectors from 'data/transactionList/selectors';
import * as transactionSelectors from 'data/transactions/selectors';
import { TxnPendingStates } from 'data/transactions/types';
import * as payeeSelectors from 'data/payees/selectors';
import { getFieldString } from 'data/transactions/searchFilter';
import * as documentActions from 'data/documents/actions';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';

import isAcme, { isQuicken } from 'isAcme';
import UIState from 'components/UIState';
import QDialogs from 'components/QDialogs';
import QDom from 'components/QDom';
import QData from 'components/QData';
import QPreferences from 'components/QPreferences';
import { showDeleteReminderDialog } from 'components/Dialogs/DeleteReminderDialog/actions';
import { showMatchTxnDialog } from 'components/Dialogs/MatchTxnDialog/actions';
import { showTxnDetailsDialog } from 'components/Transactions/DetailsDialog/actions';
import { DIALOG_TYPE_RENAME_RULE_EDIT } from 'components/Dialogs/RenameRuleEditDialog';
import { DIALOG_TYPE_MEMORIZED_RULE_EDIT } from 'components/Dialogs/MemorizedRuleEditDialog';
import { filterByKeys, copyObject, normalizeAmt, addEventListenerOnce } from 'utils/utils';
import compose from 'utils/compose';
import TransactionPage from './TransactionPage';
import * as transactionsConfig from './transactionsConfig';
import * as helpers from './helpers';
import { styles } from './styles';

const log = getLogger('components/TransactionRegister/index.js');

function newRegFields(doubleAmounts) {

  const obj = transactionsConfig.fieldData;
  const editables = Object.keys(filterByKeys(obj, (x) => x.editable));

  if (doubleAmounts) {
    editables.splice(editables.indexOf('amount'), 1);
  } else {
    editables.splice(editables.indexOf('income'), 1);
    editables.splice(editables.indexOf('expense'), 1);
  }

  return List(editables);
}

function txnListHasAttachment(txnMap, txnBeingEdited) {

  let foundAttachment = false;

  if (txnBeingEdited && txnBeingEdited.attachments && txnBeingEdited.attachments.length > 0) {
    return true;
  }

  if (txnMap) {
    txnMap.keySeq().forEach((key) => {
      if (!foundAttachment) {
        foundAttachment = txnMap.get(key).find((txn) => (txn.attachments && txn.attachments.length > 0));
      }
    });
  }
  return foundAttachment;
}

function firstBankAccount(accountIds) {

  const ret = accountIds.filter((id) =>
    !(accountsUtils.isLoanAccount(accountsSelectors.getAccountById(store.getState(), id))
      || accountsUtils.isInvestmentAccount(accountsSelectors.getAccountById(store.getState(), id))));

  const sorted = ret.sort((a, b) => {
    if (accountsUtils.isBankAccountFromId(a)) {
      return accountsUtils.isBankAccountFromId(b) ? 0 : -1;
    }
    return accountsUtils.isBankAccountFromId(b) ? 1 : 0;
  });

  return sorted.first();
}

// This is a globalState object that maintains state based on a map whose key
// is the account ID. As the register changes to view different accounts,
// the state will be persisted into and loaded from this global state object.
//
// TODO: This needs to be reworked.
//   - At a minimum, state should be stored in the global auth object so at
//     least it will get cleared when user logs out.
//   - Would be better to do this in the redux store...
//

// TODO put in types.js

/* eslint-disable react/sort-comp */

const RegisterUIState = Record({

  initNumberTxnsShown: 20,

  // Transient UI State
  txnBeingEdited: null,
  txnBeingEditedPrevBalance: null,
  txnDirty: false,
  currEditFieldName: 'payee',
  showOnlineBalance: false,
  showEditMenu: null,
  tryNextRow: null,
  showNewTxn: false,
  showDocumentAdd: false,
  showDocumentBrowserTxn: null,
  showSplitWindow: false,
  showBulkEdit: false,
  simpleEdit: true,
  regEditableFields: List(),
  selectedTxns: new Set(),
  sortAnimation: true,
  flashTx: null,
  openSections: new Set([]),
  showAttachmentColumnInHeader: false,
  txnsFirstLoaded: 'default',
  searchBoxFilter: '',
  scrollToId: null,
  dataToDownload: null,  // { headers: [], data: [] }
  allSelected: false,
  headerFields: null,
  lastAccountEntered: null,
  detailsRefSaveFn: null,
  reminderSetting: 0,
  filterObject: null,
});

function initShowTransactions(props, minNumVisible) {

  let openSections = new Set([]);

  if (props.acctTxns) {
    let visibleCount = 0;
    props.acctTxns.keySeq().forEach((key) => {
      if (visibleCount < minNumVisible) {
        const shortKey = key.slice(key.indexOf(':') + 1);
        openSections = openSections.add(shortKey);
        visibleCount += props.acctTxns.get(key).size;
      }
    });
  }
  return openSections;
}

function initialUIStateConfig(props) {
  const ret = (new RegisterUIState({
    regEditableFields: newRegFields(props.doubleAmounts),
    sections: initShowTransactions(props, 300),
    headerFields: props.regFieldsOrder || List(transactionsConfig.getColumnDefaults()),
    reminderSetting: props.reminderSetting || 0,
  }));

  log.log('INIT REGISTER STATE WITH ->', props, ret);
  return ret;
}

// NOTE: Only props established before USSTateConfig is called in the compose at the bottom of this
// class are going to be available to this section.
const uiStateConfig = {
  name: (props) => {
    let ret = `TxRegId_${props.wrapperId}:${props.accountNodeId}`;
    if (!props.accountNodeId) {
      if (props.accountIds && props.accountIds.size === 1) {
        ret = `${ret}${props.accountIds.first()}`;
      } else if (props.accountIds) {
        props.accountIds.forEach((v) => {
          ret = `${ret}:${v}`;
        });
      }
    }
    return ret;
  },

  state: initialUIStateConfig,

  persist: true,
};

//------------------------------------------------------------------
// MAIN COMPONENT CLASS  TransactionRegister
//
// expects props {
//  account
//  closeFn  = function to close register
//  onSave   = function to save a Transaction
//  navFn(srcItem)
//    function to open register containing of side of the transfer for srcItem
//
export class TransactionRegister extends React.Component {

  constructor(props) {
    super(props);

    // Create class objects that shouldn't trigger a render but may be used
    // within render.

    this.callbacks = {
      handleChange: this.handleChange,
      handleFocus: this.handleFocus,
      regFieldKey: this.regFieldKey,
      regFieldClick: this.regFieldClick,
      handleMenu: this.handleMenu,
      hideContextMenu: this.hideContextMenu,
      hideModal: this.hideModal,
      saveTransaction: this.txFormUpdate,
      saveDoc: this.saveDoc,
      delDocIds: this.delDocIds,
      headerClick: this.headerClick,
      showNewTxn: this.showNewTxnMaybePrompt,
      balanceClick: this.balanceClick,
      onPageChange: this.onPageChange,
      onRowSelect: this.onRowSelect,
      onCheckAll: this.onCheckAll,
      regMenuExiting: this.regMenuExiting,
      openCloseSection: this.openCloseSection,
      onSearchFilter: this.onSearchFilter,
      onAmountBlur: this.onAmountBlur,
      onFieldBlur: this.onFieldBlur,
      onFocusField: this.onFocusField,
      editSelectedTxns: this.editSelectedTxns,
      deleteSelectedTxns: this.deleteSelectedTxns,
      reviewSelectedTxns: this.reviewSelectedTxns,
      matchSelectedTxns: this.matchSelectedTxns,
      refreshTxnList: this.refreshTxnList,
      bulkEditSave: this.bulkEditSave,
      scrollToTransaction: this.scrollToTransaction,
      downloadFile: this.downloadFile,
      alterHeader: this.alterHeader,
      markTxnDirty: this.markTxnDirty,
      cancelEdit: this.cancelEdit,
      maybeSaveTxnBeingEdited: this.maybeSaveTxnBeingEdited,
      detailsRefSaveFn: this.detailsRefSaveFn,
      resetFocus: this.resetFocus,
      fastCategorize: this.fastCategorize,
      reminderChange: this.reminderChange,
      actOnScheduledTxn: this.actOnScheduledTxn,
      onApplyFilter: this.onApplyFilter,
      setCalendarDate: this.setCalendarDate,
    };

    this.deferUpdate = false; // eslint-disable-line react/no-unused-class-component-methods

    if (props.addNewTransactionRef) {
      props.addNewTransactionRef(this.showNewTxnMaybePrompt);
    }

    // Load scheduled transactions
    if (props.scheduledTransactionsLoadPending) {
      props.getScheduledTransactions();
    }
  }

  componentDidMount() {
    this.clearAllFilters(this.props);
    if (this.props.getRef) {
      this.props.getRef(this);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    const newState = nextProps.uiState;
    const oldState = this.props.uiState;


    let uiStateChanges = {};

    // log.log('REGISTER NEW PROPS - old,new', this.props, nextProps);

    /*
    // TODO DEBUG CODE FOR PERFORMANCE - REMOVE ================================
    log.log('REGUPDATE--------------------------------------------------------');
    Object.keys(nextProps).forEach((key) => {
      if (nextProps[key] !== this.props[key]) {
        log.debug(`keyvalue ${key} changed from ${this.props[key]} to ${nextProps[key]}`);
      }
    });
    if (newState) {
      Object.keys(newState.toJS()).forEach((key) => {
        if (newState.get(key) !== oldState.get(key)) {
          log.debug(`uiState keyvalue ${key}  changed from ${oldState.get(key)} to ${newState.get(key)}`);
        }
      });
    }
    log.log('----------------------------------------------------------------');
    */

    // If TAGS changed, we need to update the transaction being edited
    if (nextProps.tags !== this.props.tags) {
      if (newState.txnBeingEdited && newState.simpleEdit && !newState.showSplitWindow) {
        uiStateChanges = {
          ...uiStateChanges,
          txnBeingEdited: updateNewTags(newState.txnBeingEdited),
        };
      }
    }

    if (nextProps.reminderSetting !== this.props.reminderSetting) {
      if (nextProps.reminderSetting !== newState.reminderSetting) {
        uiStateChanges = {
          ...uiStateChanges,
          reminderSetting: nextProps.reminderSetting,
        };
      }
    }
    if (nextProps.acctTxns && (nextProps.acctTxns !== this.props.acctTxns)) {

      // log.info('Transaction List Changing...', nextProps.acctTxns, this.props.acctTxns, newState.txnBeingEdited);
      if (newState.txnBeingEdited) {
        if (newState.txnBeingEdited.id !== 0) {

          // need a "find transaction by id"
          const txnList = this.findTxnsForIds(nextProps, [newState.txnBeingEdited.id]);

          if (txnList && txnList.length > 0) {
            const { txn } = txnList[0];
            uiStateChanges = {
              ...uiStateChanges,
              txnBeingEdited: txn,  // TODO figure out why this used to say newState.txnBeingEdited
            };
          }
          log.log('TXN BEING EDITED NOW ', uiStateChanges.txnBeingEdited, nextProps.uiState.txnDirty);

        } else if (newState.showNewTxn) {
          let acct = oldState.lastAccountEntered || firstBankAccount(nextProps.accountIds);
          if (!nextProps.accountIds.includes(acct)) {
            acct = nextProps.accountIds.first();
          }
          const newTxn = this.makeNewTxn(acct);
          uiStateChanges = { ...uiStateChanges, lastAccountEntered: acct, txnBeingEdited: newTxn };
        }
      }

      // So if the user has made a change to the currently viewed register (accountNode) then we eliminate the
      // selection.  However, if they are just clicking between nodes, we do not
      let newSelectedTxns = newState.selectedTxns;
      let newSelectedAll = newState.allSelected;
      // if (this.props.accountNodeId === nextProps.accountNodeId) {
      // WE check the current selected transactions, and remove any that no longer exist in the acctTxns by ID
      newSelectedTxns = newSelectedTxns.filter((selTxn) => Boolean(this.findTxnById(nextProps, selTxn.id)?.txn));
      newSelectedAll = newSelectedAll && newSelectedTxns.size > 0;
      // }
      uiStateChanges = { ...uiStateChanges, allSelected: newSelectedAll, selectedTxns: newSelectedTxns, showAttachmentColumnInHeader: txnListHasAttachment(nextProps.acctTxns) };

    }

    // We do this so behavior is such that if you are using a search filter, we do not restrict the number
    // of transactions to display (we use 300 as the upper limit).  However, we want to go back to the
    // prescribed preference limit when the filter is empty (but did change)
    //
    const filterChanged = newState.searchBoxFilter !== oldState.searchBoxFilter;
    const filterChangedNonEmpty = filterChanged && newState.searchBoxFilter && newState.searchBoxFilter.length > 0;
    const scrollTxChanged = newState.scrollToId && ((newState.scrollToId !== oldState.scrollToId) ||
                            (newState.scrollToId && (nextProps.accountIds !== this.props.accountIds)));

    // Open sections on first time through
    // Here we are examining uiState changes
    if (nextProps.acctTxns && (nextProps.acctTxns.size > 0) && (
      // txnsFirstLoaded, if null, means ignore, but if it is a value, then re-init register if it is different from acctTxns
      // this is used to indicate a re-init is needed, but need to wait for acctTxns to change.  (see sort change)
      ((newState.txnsFirstLoaded && (newState.txnsFirstLoaded !== nextProps.acctTxns)) ||
      newState.accountIds !== oldState.accountIds ||
      scrollTxChanged || filterChanged))) {

      const scrollId = newState.scrollToId;
      const selectDestination = true;
      let addShortKeyToSections = null;

      if (scrollId) {
        const txnList = this.findTxnsForIds(nextProps, [scrollId], false);
        log.log('Transaction to scroll to is ', txnList);
        if (txnList && txnList.length > 0) {
          // save the shortKey to expand the register section where the transaction we are trying to view is located
          addShortKeyToSections = txnList[0].shortKey;
          uiStateChanges = {
            ...uiStateChanges,
            txnBeingEdited: selectDestination ? txnList[0].txn : null };
        }
      }

      // initialize open sections for default tx viewing
      let sections = initShowTransactions(nextProps, filterChangedNonEmpty ? 300 : newState.initNumberTxnsShown);

      // open the seciton that contains the transaction we may be trying to scroll to
      if (addShortKeyToSections) {
        log.log('ADDING SHORT KEY ', addShortKeyToSections);
        sections = sections.add(addShortKeyToSections);
      }
      uiStateChanges =
        {
          ...uiStateChanges,
          txnsFirstLoaded: null,
          openSections: sections,
          showAttachmentColumnInHeader: txnListHasAttachment(nextProps.acctTxns),
        };
    }

    // see if we need to reset regFields for preference change
    if (this.props.doubleAmounts !== nextProps.doubleAmounts) {
      uiStateChanges = { ...uiStateChanges, regEditableFields: newRegFields(nextProps.doubleAmounts) };
    }

    // did register fields change?
    if (this.props.regFieldsOrder !== nextProps.regFieldsOrder) {
      uiStateChanges = { ...uiStateChanges, headerFields: nextProps.regFieldsOrder };
    }

    // Weird case here, if the props changes make the current edit field invalid, we need to move it
    if (nextProps.uiState.txnBeingEdited) {
      if (this.isSkipField(nextProps, nextProps.uiState.currEditFieldName, nextProps.uiState.txnBeingEdited)) {
        const newField = this.getNextField(nextProps);
        log.log('CHANGING THE FIELD TO ', newField);
        uiStateChanges = { ...uiStateChanges, currEditFieldName: newField };
      }
    }

    // if (nextProps.overrideTxns !== this.props.overrideTxns) {
    //   uiStateChanges = {
    //     ...uiStateChanges,
    //     // txnBeingEdited: null,
    //     // txnDirty: false,
    //   };
    // }

    // if changes applied, update UI state
    if (uiStateChanges && (Object.keys(uiStateChanges).length !== 0)) {
      nextProps.setUIState({ ...uiStateChanges });
    }

  }

  shouldComponentUpdate(nextProps, nextState) {
    return !this.deferUpdates && shallowCompare(this, nextProps, nextState);
  }

  componentDidUpdate() {

    if (this.props.uiState.flashTx) {
      const animAmounts = document.querySelectorAll('.flashTx');
      animAmounts.forEach((item) => {
        addEventListenerOnce(item, 'animationend', () => {
          this.animationControl('flashTx');
        });
      });
      // if an animation isn't 'seen' within 1s, end it
      setTimeout(() => this.animationControl('flashTx'), 1000);

    }

    if (this.props.uiState.dataToDownload) {
      this.props.setUIState({ dataToDownload: null });
    }
    this.resetFocus();

  }


  componentWillUnmount() {
    log.debug('Transaction register will unmount...');
  }

  setCalendarDate = (year, month) => {
    if (this.props.setCalendarDate) {
      this.props.setCalendarDate(year, month);
    }
  };

  findTxnsForIds = (props, ids, isVisible = true) => {

    const txnIds = new Set(ids);
    let txnList = [];

    log.log('Find Transaction ', txnIds.toJS());
    props.acctTxns.forEach((section, key) => {
      const shortKey = key.slice(key.indexOf(':') + 1);
      if (!isVisible || props.uiState.openSections.has(shortKey) || this.props.hideDividers) {
        const txnArray = section.reduce((list, txn) => {
          if (txnIds.has(txn.id)) {
            list.push({ txn, shortKey });
          }
          return list;
        }, []);
        txnList = txnList.concat(txnArray);
      }
    });

    return txnList;

  };

  //  Primarily used to remove filters when returning to the transaction page from another page
  clearAllFilters = (props) => {
    this.props.setUIState({ 
      filterObject: null, 
      searchBoxFilter: '',
      ...(this.props.acctTxns?.size > 0 && this.props.uiState.openSections.size === 0 ? 
        { openSections: initShowTransactions(props, 300) } : {}
      ),
    });
  };

  findTxnById = (props, id) => {
    let txnList = [];
    props.acctTxns.forEach((section) => {
      const txnArray = section.reduce((list, txn) => {
        if (txn.id === id) {
          list.push({ txn });
        }
        return list;
      }, []);
      txnList = txnList.concat(txnArray);
    });

    return txnList.length ? txnList[0] : undefined;
  };

  findSimilarUncategorizedTxns = (props, currentTxn, isVisible = true) => {
    let txnList = [];

    props.acctTxns.forEach((section, key) => {
      const shortKey = key.slice(key.indexOf(':') + 1);
      if (!isVisible || props.uiState.openSections.has(shortKey)) {
        const txnArray = section.reduce((list, txn) => {
          if (!transactionsUtils.isSplitTxn(txn) && currentTxn.id !== txn.id && currentTxn.payee === txn.payee && (txn.coa && txn.coa.id === '0')) {
            list.push(txn);
          }
          return list;
        }, []);
        txnList = txnList.concat(txnArray);
      }
    });

    return txnList;
  };

  alterHeader = (newList) => {

    log.log('Alter Header', newList);
    const l1 = this.props.regFieldsOrder;
    if (JSON.stringify(l1.toJS()) !== JSON.stringify(newList.toJS())) {
      if (this.props.regFieldsOrderChange) {
        this.props.regFieldsOrderChange(newList);
      }
    }
  };

  scrollToTransaction = (txnId) => {
    this.props.setUIState({ scrollToId: txnId });
  };

  refreshTxnList = () => {
    this.props.getTransactions();
  };

  resetFocus = () => {
    if (this.props.uiState.simpleEdit && !this.props.uiState.showSplitWindow) {
      setTimeout(() => {
        if (this.currentFocusField && this.currentFocusField.focus && this.props.uiState.simpleEdit) {
          this.currentFocusField.focus();
        }
      }, 100);
    }
  };

  onFocusField = (e) => {
    this.currentFocusField = e;
  };

  animationControl = (field) => {
    this.props.setUIState({ [field]: null });
    // log.debug(`Animation Ending in Register: ${field}`);
  };

  onSearchFilter = (searchBoxFilter) => {

    if (!searchBoxFilter && this.props.uiState.searchBoxFilter) {
      this.props.setUIState({ searchBoxFilter });
    } else if (searchBoxFilter && searchBoxFilter.trim().length > 0) {
      this.props.setUIState({ showNewTxn: false, txnBeingEdited: null, searchBoxFilter });
    }
  };

  onApplyFilter = (filterObject) => {
    this.props.setUIState({ filterObject });
  };

  // download a CSV of the current contents of the tx list
  downloadFile = () => {
    helpers.downloadRegisterToCSV(this.props);
  };

  onRowSelect = (txn, isSet) => {

    let newSelectedTxns;
    const { selectedTxns } = this.props.uiState;
    if (isSet) {
      newSelectedTxns = selectedTxns.withMutations((mutable) => {
        mutable.add(txn);
      });
    } else {
      newSelectedTxns = selectedTxns.withMutations((mutable) => {
        mutable.remove(selectedTxns.find((t) => t.id === txn.id));
      });
    }
    const { acctTxns } = this.props;
    const totalTxnsCount = acctTxns.keySeq().reduce((aggCount, key) => aggCount + acctTxns.get(key).size, 0);
    this.props.setUIState({ allSelected: newSelectedTxns.size === totalTxnsCount, selectedTxns: newSelectedTxns });
    this.resetFocus();
  };

  onCheckAll = (on) => {
    const { scheduledTransactionsById, txnsByAccountId, allAccountIds } = this.props;
    if (!on) {
      this.props.setUIState({ allSelected: false, selectedTxns: this.props.uiState.selectedTxns.clear() });
    } else {
      const selectedTxns = this.props.uiState.selectedTxns.withMutations((mutable) => {

        this.props.acctTxns.forEach((section, key) => {
          const shortKey = key.slice(key.indexOf(':') + 1);
          if (this.props.uiState.openSections.has(shortKey) || this.props.hideDividers) {
            section.forEach((txn) => {
              const isTypeGrossPaycheck = transactionsUtils.isTxnGrossPaycheckType(
                txn,
                txnsByAccountId,
                scheduledTransactionsById,
              );
              const isAccountSynced = !(txn.id && txn?.coa?.type === 'ACCOUNT' && !allAccountIds.includes(txn.coa.id));
              if (!isTypeGrossPaycheck && isAccountSynced && (!this.props.isC2REnabled || !isUnAcceptedTxnType(txn))) {
                mutable.add(txn);
              }
            });
          }
        });
      });
      this.props.setUIState({ allSelected: true, selectedTxns });
    }
    this.resetFocus();
  };


  detailsRefSaveFn = (ref) => {
    this.props.setUIState({ detailsRefSaveFn: ref });
  };

  openCloseSection = (key) => {
    let newSections;
    if (this.props.uiState.openSections.has(key)) {
      newSections = this.props.uiState.openSections.delete(key);
    } else {
      newSections = this.props.uiState.openSections.add(key);
    }
    this.props.setUIState({ openSections: newSections });
    this.resetFocus();
  };

  markTxnDirty = () => {
    this.props.setUIState({ txnDirty: true });
  };

  saveBalanceChange = (txn) => {

    const dateStr = getFieldString('date', txn);
    const amtStr = getFieldString('balance', txn);
    this.props.dialogAlert(
      'Make a Balance Adjustment',
      `You made a change to the balance column.  Do you want to make the ending balance for ${dateStr} be ${amtStr}?`,
      this.makeQDialogCallback((retObj) => {
        if (retObj.btnPressed === 'Create Balance Adjustment') {
          //
          // need to get the running balance as of the date provided (cannot rely on the actual balance as
          // there may be other transactions on this date
          //
          this.props.qDataSetBalanceAdjust(txn.accountId, txn.postedOn, txn.balance);
        }
        this.cancelEdit();
      }),
      ['Cancel', 'Create Balance Adjustment'],
      'help',
    );
  };

  //------------------------------------------------------
  // txFormUpdate
  // replace the txForm state (current row being edited) with the
  // new txForm provided
  //
  // Use this when you have a JS structure (not immutable)
  //
  txFormUpdate = (txForm, autoSave = false, txnDirty = true, splitToggle = undefined) => {

    const newTxn = transactionsTransformers.transformApiDataToCashFlowTxn(txForm).set('balance', txForm.balance);

    // if this txn was selected, add the new one to the selection
    this.setTxnBeingEdited(newTxn);

    // If this transaction is not valid (this happens during Splits creation) do not try to autosave

    const doAutoSave = this.doAutoSaveRegister(newTxn) && this.txnSplitsAreValid(newTxn);

    // console.log("TX FORM UPDATE", newTxn, autoSave, txnDirty, doAutoSave);
    if (txnDirty && autoSave) {
      this._saveAndCancel(this.setTxnReviewed(newTxn));
    } else if (txnDirty && doAutoSave) {
      this.quickSaveTransaction(newTxn, true);
      this.props.setUIState({ txnDirty: false, txnBeingEdited: newTxn });
    } else if (!doAutoSave) {
      this.props.setUIState({ 
        txnDirty, 
        txnBeingEdited: newTxn, 
        showSplitWindow: splitToggle === undefined ? this.props.uiState.showSplitWindow : splitToggle,
      });
    }

  };

  txnSplitsAreValid = (txn) => {
    // if a valid split and coa is null, fine
    if (transactionsUtils.isSplitTxn(txn) && txn.coa === null) {
      return true;
    }
    // if there is a split item with one item existing
    if (txn.split && txn.split.items && txn.split.items.size > 0) {
      return (txn.coa === null); // if there is a trace of splits, and a coa, return false
    }
    return true;
  };

  setTxnBeingEdited = (txn, stateObj) => {
    const { selectedTxns } = this.props.uiState;
    if (selectedTxns.has(this.props.uiState.txnBeingEdited)) {
      if (stateObj) {
        return { ...stateObj, txnBeingEdited: txn, selectedTxns: selectedTxns.add(txn) };
      }
      return this.props.setUIState({ txnBeingEdited: txn, selectedTxns: selectedTxns.remove(this.props.uiState.txnBeingEdited).add(txn) });
    }
    return { ...stateObj, txnBeingEdited: txn };
  };

  clearTxnBeingEdited = (stateObj) => {
    const { selectedTxns, txnBeingEdited } = this.props.uiState;
    if (txnBeingEdited) {
      if (selectedTxns.has(txnBeingEdited)) {
        const txnsFound = this.findTxnsForIds(this.props, [txnBeingEdited.id]);
        if (txnsFound && txnsFound.length > 0) {
          const newSelectedTxns = selectedTxns.remove(txnBeingEdited).add(txnsFound[0].txn);
          if (stateObj) {
            return { ...stateObj, txnBeingEdited: null, selectedTxns: newSelectedTxns };
          }
          return this.setUiState({ txnBeingEdited: null, selectedTxns: newSelectedTxns });
        }
      }
    }
    return { ...stateObj, txnBeingEdited: null };
  };

  maybeSaveTxnBeingEdited = (cb) => {

    if (!this.props.uiState.txnBeingEdited || !this.props.uiState.txnDirty) {
      if (cb) cb(true);
    } else if (!this.props.autoSave) { // } || confirm('Do you want to save the changed transaction?')) {
      this.props.dialogAlert(
        'Save Transaction',
        'Do you want to save the changes to the transaction being edited?',
        this.makeQDialogCallback((retObj) => {
          if (retObj.btnPressed === 'Save Transaction') {
            return this.saveTxnBeingEdited(cb);
          }
          if (retObj.btnPressed === "Don't Save") {
            if (cb) {
              cb(true);
            }
          }
          if (cb) {
            cb(false);
          }
          return undefined;
        }),
        ['Save Transaction', "Don't Save", 'Cancel'],
        'help',
      );
    } else {
      this.saveTxnBeingEdited(cb);
    }
  };

  saveUnbalancedSplitTxn = (txn, cb) => {
    this._saveAndCancel(txn, cb);
  };

  // Save IF the transaction is dirty or is the new txn
  saveTxnBeingEdited = (cb) => {
    const txn = this.props.uiState.txnBeingEdited;

    if (txn && this.props.uiState.txnDirty) {
      //
      // Check to see if the user edited the balance, this is a special case

      if (this.props.uiState.txnBeingEditedPrevBalance !== null) {
        return this.saveBalanceChange(txn);
      }

      if (isUnbalancedSplitTxn(txn)) {
        return this.saveUnbalancedSplitTxn(txn, cb);
      }

      if (this.props.webFirstCatUncategorize) {
        return this.saveCategorizeSimilar(txn, cb);
      }

      this._saveAndCancel(txn, cb);

    } else {
      return this.cancelEdit();
    }
    return true;
  };

  saveCategorizeSimilar = (txn, cb) => {
    const { classes, categories } = this.props;
    const prevTxn = this.findTxnById(this.props, txn.id);
    const changed = getTxnDifferences(txn, prevTxn);
    const similarTxns = this.findSimilarUncategorizedTxns(this.props, txn);

    if (changed.includes('coa') && txn.coa.id !== '0' && similarTxns.length) {
      const catName = categories.get(txn.coa.id);

      if (catName && catName.name) {
        this.props.dialogAlert(
          'Categorize Transactions',
          (
            <>
              We found <strong className={classes.strong}>{similarTxns.length}</strong> other uncategorized transactions
              having the same payee as ‘<strong className={classes.strong}>{txn.payee}</strong>’. <br />
              Do you wish to change their category to ‘<strong className={classes.strong}>{catName.name}</strong>’ too?
            </>
          ),
          this.makeQDialogCallback((retObj) => {
            const changedSimilar = retObj.btnPressed === 'YES' ? [
              txn,
              ...similarTxns.map((sTxn) => sTxn.set('coa', chartOfAccountsTypes.mkChartOfAccount({
                id: txn.coa.id,
                type: txn.coa.type,
              }))),
            ] : [txn];

            this._saveSimilarTransactions(changedSimilar);
          }),
          ['NO', 'YES'],
          'help',
        );
      }
      return;
    }
    // default behavior
    this._saveAndCancel(txn, cb);
  };

  _saveAndCancel = (txn, cb = null) => {

    const { txnBeingEdited } = this.props.uiState;
    const savingTxnBeingEdited = txnBeingEdited && (txn.id === txnBeingEdited.id);
    const wasNew = this.props.uiState.showNewTxn && savingTxnBeingEdited;

    const options = {
      txnDirty: this.props.uiState.txnDirty && !savingTxnBeingEdited,
      flashTx: savingTxnBeingEdited,
      showNew: wasNew && this.props.continuousCreate,
      cancelEdit: true,
      lastAccountEntered: txn.accountId,
    };

    this._saveTransactionWithActions(txn, options, (saved) => {
      if (saved) {

        // this.cancelEdit({ txnDirty: false }); // this happens in saveTransaction below, why here?
        if (wasNew && this.props.continuousCreate) {
          // this.showNewTxn(txn.accountId);
          // const updateObj = {
          //   txnDirty: savingTxnBeingEdited ? false : this.props.uiState.txnDirty,
          //   lastAccountEntered: txn.accountId,
          // };
          // this.props.setUIState(updateObj);
        }
      }
      if (cb) {
        cb(saved);
      }
    });
  };

  // options
  // flashTx = show animation if dirty, and saved (default true)
  // txDirty = transaction has been changed (default true)
  // showNew = after save, open the new transaction form (default false)
  // cancelEdit = cancel current edit after save
  //
  // cb - callback, will send true if saved perform, otherwise false
  //
  _saveTransactionWithActions = (
    txn,
    { flashTx = true, txnDirty = true, showNew = false, cancelEdit = true, lastAccountEntered = null },
    cb,
  ) => {

    if (cancelEdit && !this.doAutoSaveRegister(txn)) {
      // this.props.setUIState({ txnBeingEdited: null });
    }

    this.deferUpdates = true;
    this._saveTransaction(txn, (saved) => {

      this.deferUpdates = false;
      if (saved) {

        const flashId = isExistingTxn(txn) ? txn.id : txn.clientId;

        let stateObj = { lastAccountEntered };
        if (cancelEdit) {
          stateObj = this.cancelEdit({ ...stateObj, txnDirty, flashTx: (flashTx && !txnDirty ? flashId : null) }, true);
        }
        if (showNew) {
          stateObj = this.showNewTxn(new transactionsTypes.CashFlowTransaction({ accountId: txn.accountId }), true);
          stateObj.currEditFieldName = 'payee';
        }
        this.props.setUiState(stateObj);
      }
      if (cb) {
        cb(saved);
      }
    });
  };

  _saveTransaction = (savingTxn, cb, overwriteReview = true) => {
    // log.debug('_SAVING TX', txn);
    let txn = overwriteReview ? this.setTxnReviewed(savingTxn) : savingTxn;
    txn = txn.source === TxnPendingStates.QCS_REFRESH_BANK_PENDING ? this.setTxnUserOwnedBankPending(txn) : txn;
    const txnToSave = txn; // .set('amount', normalizeAmt(txn.amount));
    this.deferUpdate = true; // eslint-disable-line react/no-unused-class-component-methods
    this.props.qDataSaveTransactions(
      [txnToSave],
      (saved) => {
        this.deferUpdate = false; // eslint-disable-line react/no-unused-class-component-methods
        if (cb) {
          cb(saved);
        }
      },
    );
  };

  _saveSimilarTransactions = (txnList, cb) => {
    this.deferUpdate = true; // eslint-disable-line react/no-unused-class-component-methods
    this.props.qDataSaveTransactions([...txnList], (saved) => {
      this.deferUpdate = false; // eslint-disable-line react/no-unused-class-component-methods
      if (cb) {
        cb(saved);
      }
    });
  };

  cancelEdit = (addState = {}, passive = false) => {
    const stateObj = this.clearTxnBeingEdited({
      showNewTxn: false,
      simpleEdit: true,
      showSplitWindow: false,
      txnBeingEditedPrevBalance: null,
      currEditFieldName: 'payee',
      ...addState,
    });

    this.currentFocusField = null;
    return passive ? stateObj : this.props.setUIState(stateObj);
  };

  fastCategorize = () => {
    // make a list of all uncategorized transactions with a suggestion

    const num = this.props.transactionsWithCategorySuggestions.size;
    if (num) {
      this.props.dialogAlert(
        `We found ${num} uncategorized transaction${num > 1 ? 's' : ''} we can categorize for you`,
        'This action will categorize all of them based on prior history.',
        this.makeQDialogCallback((retObj) => {
          if (retObj.btnPressed !== 'Cancel') {
            const newTransactions = this.props.transactionsWithCategorySuggestions.map((txn) => {
              const newTxn = this.setTxnReviewed(txn);
              return newTxn.set('coa', this.props.payeesForAccounts.get(txn.payee?.toLowerCase()).txn.coa);
            });

            // if (retObj.btnPressed.indexOf('memo') > 0) {
            // newTransactions = newTransactions.map((txn) =>
            //   txn.set('memo', `${txn.memo ? `${txn.memo} - ` : ''}Auto Categorized`));
            // }
            this.props.qDataSaveTransactions(newTransactions);
          }
        }),
        ['Cancel', 'Auto Categorize'],
        'info',
        false,
        true,
      );
    } else {
      this.props.dialogAlert(
        'We did not find any uncategorized transactions we can categorize for you',
        'We rely on prior history to determine a category suggestion',
        this.makeQDialogCallback(() => null),
        ['Ok'],
        'info',
        false,
        true,
      );
    }

  };

  reviewSelectedTxns = (setReviewed) => {
    const num = this.props.uiState.selectedTxns.size;

    tracker.track(tracker.events.txnReviewMany, {
      state: 'attempt',
      count: num,
      setReviewed,
    });

    const doReview = () => this.reviewTransactions(this.props.uiState.selectedTxns, setReviewed);

    if (isAcme) {
      doReview();
    } else {
      this.props.dialogAlert(
        `Mark ${num} Selected Transaction${num > 1 ? 's' : ''} as ${setReviewed ? 'Reviewed' : 'Not Reviewed'}`,
        'This action will mark all the selected transactions',
        this.makeQDialogCallback((retObj) => {
          if (retObj.btnPressed === 'Continue') {
            doReview();
          }
        }),
        ['Cancel', 'Continue'],
        'help',
        false,
      );
    }
  };

  reviewTransactions = (txns, value) => {
    if (txns) {
      const newTxns = txns.map((x) => x.set('isReviewed', value));
      this.props.qDataSaveTransactions(newTxns, (success) => {

        if (success) {
          tracker.track(tracker.events.txnReviewMany, {
            state: 'complete',
            setReviewed: value,
            count: txns.size,
          });
        }
      });
    }
  };

  deleteSelectedTxns = () => {
    const num = this.props.uiState.selectedTxns.size;

    this.props.uiState.selectedTxns.forEach((x) => {
      tracker.track(tracker.events.txnDelete, {
        state: 'attempt',
        type: x.cpData ? 'downloaded' : 'manual',
      });
    });

    if (!this.props.deleteDownloaded) {
      if (this.props.uiState.selectedTxns.find((x) => x.cpData)) {
        this.props.dialogAlert(
          'You cannot delete downloaded transactions',
          'You selected 1 or more transactions to delete which were downloaded' +
          ' by your financial institution.  It is not recommended that you delete data from your ' +
          ' bank as it will not be downloaded again. ' +
          ` ${this.props.openPrefs ? ' However, if you really want to remove it you can ' +
          ' alter the preference in your transaction settings ' : ''}`,
          this.makeQDialogCallback((retObj) => {
            if (retObj.btnPressed === 'Open Preferences') {
              this.props.openPrefs();
            }
          }),
          this.props.openPrefs ? ['Open Preferences', 'Cancel'] : ['Cancel'],
          'warning',
        );
        return;
      }
    }

    this.props.dialogAlert(
      `Delete ${num} Selected Transaction${num > 1 ? 's' : ''}`,
      'This action will delete all the selected transactions and cannot be undone.',
      this.makeQDialogCallback((retObj) => {
        if (retObj.btnPressed === 'Delete') {
          this.deleteTransactions(this.props.uiState.selectedTxns);
        }
      }),
      ['Cancel', 'Delete'],
      'delete',
      false,
    );

  };

  actOnScheduledTxn = (txn, action) => {
    const actionObj = {
      id: txn.id,
      action,
      overdueAction: action,
      instanceDate: txn.stDueOn,
    };

    this.props.qDataPerformTransactionAction(actionObj);
  };

  matchSelectedTxns = () => {
    const { classes } = this.props;
    tracker.track(tracker.events.txnManualMatch, {
      state: 'attempt',
    });
    const showCloseButton = isAcme;
    const buttonText = isAcme ? 'Merge' : 'Proceed with Match';
    const companionBodyText = ('This action will merge the two selected transactions into one and automatically mark it as "reviewed". The payee category, notes, and tags from the manual ' +
      'transaction will be preserved. You can "Unmatch" this transaction at any time.');

    this.props.dialogAlert(
      `${isAcme ? 'Merge transactions' : 'Match Selected Transactions'}`,
      (
        isAcme ?
          <div className={classes.mergeTxnsBody}>
            { 'Combines two transactions into one. The payee, category, notes, and tags from your manually-created transaction will ' +
            'be used for the merged transaction.' }
            <br /> <br />
            { 'You can unmerge them in the transaction detail view if you need to undo this in the future.' }
          </div>
          :
          companionBodyText
      ),
      this.makeQDialogCallback((retObj) => {
        if (retObj.btnPressed === buttonText) {
          this.props.qDataMatchTransactions(this.props.uiState.selectedTxns.toArray());
        }
      }),
      ['Cancel', buttonText],
      `${isAcme ? 'none' : 'match'}`,
      showCloseButton,
    );
  };

  //------------------------------------------------------
  // deleteTx
  //
  deleteTx = (txn) => {

    if (txn !== this.props.uiState.txnBeingEdited) {
      this.maybeSaveTxnBeingEdited((proceed) => {
        if (proceed) {
          this.deleteTransactions([txn]);
        }
      });
    } else {
      this.cancelEdit();
      this.deleteTransactions([txn]);
    }
  };

  deleteTransactions = (txns) => {
    this.props.qDataDeleteTransactions(txns);
    if (typeof this.props.onTxnUpdate === 'function') {
      setTimeout(() => this.props.onTxnUpdate(), 1000);
    }
  };

  //------------------------------------------------------
  // saveDoc
  //
  saveDoc = (props) => {

    const { txn, docId } = props;

    log.log('SAVING ATTACHMENTS', txn, docId);

    let newTxn = null;

    if (txn && docId) {
      if (txn.attachments) {
        const newArray = txn.attachments;
        newArray.push({ id: docId });
        newTxn = txn.set('attachments', newArray);
      } else {
        const newArray = [];
        newArray.push({ id: docId });
        newTxn = txn.set('attachments', newArray);
      }
      // TODO I could only get an update to happen by changing a field other than attachments
      // even though attachments is a different value, no update
      // but if I change the memo - it updates.  Bizarre.
      const { memo } = newTxn;
      newTxn = newTxn.set('memo', '2');
      newTxn = newTxn.set('memo', memo);

      this._saveTransaction(newTxn);

      this.props.setUIState(this.clearTxnBeingEdited({
        showDocumentAdd: false,
        txnDirty: false,
      }));
    }
  };

  //------------------------------------------------------
  // delDocIds
  //
  delDocIds = (idArray) => {
    let txn = this.props.uiState.txnBeingEdited;

    if (txn.attachments) {
      const newArray = txn.toJS().attachments;
      const pluralChar = idArray.length > 1 ? 's' : '';

      this.props.dialogAlert(
        `Delete Attachment${pluralChar}`,
        `This will delete the selected attachment${pluralChar}`,
        (retObj) => {

          if (retObj.btnPressed === 'Delete') {
            idArray.forEach((id) => {
              const idx = newArray.findIndex((obj) => obj.id === id);
              if (idx >= 0) {
                newArray.splice(idx, 1);
              } else {
                log.error('ERROR: Bad ID in list to delete', 'error');
              }
              // commenting this out orphans the id, but preserves if user does not save
              this.props.deleteDocument({ id });
              if (txn.attachments.length === 0) {
                txn = txn.set('attachments', null);
              }
            });

            const { memo } = txn;
            txn = txn.set('memo', '2');
            txn = txn.set('attachments', newArray);
            txn = txn.set('memo', memo);

            this.clearTxnBeingEdited();
            this.props.setUIState(this.setTxnBeingEdited(txn,
              {
                txnDirty: false,
                txnBeingEdited: txn,
              }));

            this._saveTransaction(txn);
          }
        },
        ['Cancel', 'Delete'],
        'delete',
      );
    }
  };

  blurCurrentEditField = () => {

    if (this.currentFocusField) this.currentFocusField.blur();
    if (['balance', 'amount', 'income', 'expense'].indexOf(this.props.uiState.currEditFieldName) !== -1) {
      return;
    }
    this.onFieldBlur();
  };

  onAmountBlur = (event, field, wasEnter) => {

    // const { autoSaveRegister } = this.props;

    const autoSaveRegister = this.doAutoSaveRegister(this.props.uiState.txnBeingEdited);

    let txn = this.props.uiState.txnBeingEdited || {};
    const newVal = normalizeAmt(Number(event.target.value), field === 'expense' ? -1 : 1);

    // console.log("on amount blur ", event.target.value, field, wasEnter, newVal, txn.amount);

    if (autoSaveRegister && wasEnter) {
      this.props.setUIState(this.setTxnBeingEdited(txn.set('amount', newVal), { txnDirty: true }));
      return;
    }

    if (field === 'balance') {
      const oldTxn = this.txnsById.get(txn.id); // this.findTxnsForIds(this.props, [txn.id])[0];

      if (!oldTxn || Number(oldTxn.balance) !== Number(newVal)) {
        // User is trying to set the balance

        txn = txn.set('balance', newVal);
        this.props.setUIState(this.setTxnBeingEdited(txn, {
          txnDirty: true,
          txnBeingEditedPrevBalance: oldTxn && oldTxn.balance,
        }));

        return;
      }
    } else if (Number(newVal) !== Number(txn.amount)) {

      if (field === 'amount' || Number(newVal)) { // if income/expense field, must have a value
        txn = txn.set('amount', newVal);
        txn = balanceSplitTxn(txn);
        this.props.setUIState(this.setTxnBeingEdited(txn, {
          txnDirty: true,
        }));
      }
    }

    if (autoSaveRegister) {
      // const oldTxn = this.props.txnsById.get(txn.id); // this.findTxnsForIds(this.props, [txn.id])[0];
      // if (!oldTxn || Number(oldTxn.amount) !== Number(newVal)) {
      if (this.props.uiState.txnDirty && !this.splitOrDetailsOpen()) {
        this.quickSaveTransaction(txn);
      }
    }
  };

  // This is now a CLICKAWAY handler, and does not know the field
  onFieldBlur = (cancelEdit = false) => {

    const field = this.props.uiState.currEditFieldName;


    // console.log("ON FIELD BLUR ", field, this.props.uiState.txnDirty);
    // const { autoSaveRegister } = this.props;
    const autoSaveRegister = this.doAutoSaveRegister(this.props.uiState.txnBeingEdited);

    if (autoSaveRegister && this.props.uiState.txnBeingEdited) {
      const txn = this.props.uiState.txnBeingEdited;
      const oldTxn = this.props.txnsById.get(txn.id); // this.findTxnsForIds(this.props, [txn.id])[0];

      // don't do autoSave if splits or details panel is open unless field actually changed
      if (this.splitOrDetailsOpen()) {
        if (oldTxn && txn.get(field) !== oldTxn.get(field)) {
          this.quickSaveTransaction(txn);
        }
      } else if (oldTxn && this.props.uiState.txnDirty) {
        this.quickSaveTransaction(txn);
      }

      if (cancelEdit && !this.splitOrDetailsOpen()) {
        this.cancelEdit();
      }

      /*
      if (oldTxn && this.props.uiState.txnDirty && !this.splitOrDetailsOpen()) {
        this.quickSaveTransaction(txn);
      } else if (oldTxn && txn.get(field) !== oldTxn.get(field)) {
        this.quickSaveTransaction(txn);
      }

       */
    }
  };

  doAutoSaveRegister = (txn) =>
    this.props.autoSaveRegister && txn && this.props.txnsById.get(txn.id);

  splitOrDetailsOpen = () =>
    this.props.uiState.showSplitWindow || !this.props.uiState.simpleEdit;

  quickSaveTransaction = (txnToSave, forceDirty, cancelEdit = false) => {
    const txn = this.setTxnReviewed(txnToSave);
    const oldTxn = this.props.txnsById.get(txn.id); // this.findTxnsForIds(this.props, [txn.id])[0];
    if (this.doAutoSaveRegister(oldTxn)) {
      if (this.props.uiState.txnDirty || forceDirty) {
        this._saveTransaction(
          txn, (saved) => {
            // TODO: updating UI state with this txn was removing any updates or changes that preProcess applied.
            // const stateObj = this.setTxnBeingEdited(txn, {
            //   flashTx: true,
            //   txnDirty: false,
            // });
            // I removed, but don't know if that would cause subsequent problems
            // still really doubt we would ever want to override the preprocess update

            // this lets PROPS be processed of state change, then sets properties as we want
            setTimeout(() => {
              if (!isAcme) { // clear dirty and play animation if Quicken
                this.props.setUIState({ flashTx: true, txnDirty: false });
              }
              if (!saved || cancelEdit) this.cancelEdit();
            }, 100);
          },
        );
      }
    } else {
      this.props.setUIState(this.setTxnBeingEdited(txn, { txnDirty: true }));
    }
  };


  //-------------------------------------------------------
  // handleChange
  //
  // This is where we update state of current edit field based on changes
  // global now with specific checks, TODO: make this more configuration driven, not if statements
  //
  handleChange = (event, field, rawValue) => {

    // don't deal with null events
    if (!event) {
      return;
    }

    let txn = this.props.uiState.txnBeingEdited;
    const value = event.target?.value || rawValue || null;
    const cancelEdit = false; // event.target?.selMethod && event.target?.selMethod === 'Menu' && this.props.doAutoSaveTransaction(txn) && this.props.uiState.simpleEdit && !this.props.uiState.showSplitsAndDetails;
    const doSimplifiRules = Boolean(isAcme && this.props.datasetPreferences?.defaultCreateRuleToChecked);
    if (txn) {
      switch (field) {
        case 'category':
          txn = txn.set('coa', value);
          if ((event.target.doPropogate || doSimplifiRules) && (Number(txn.id) !== 0) && !transactionsUtils.isSplitTxn(txn)) {
            // should apply rules
            const priorTxn = this.props.txnsById.get(txn.id);
            const priorCoa = priorTxn?.coa;
            if (priorCoa && priorCoa?.id !== txn?.coa?.id) {
              // has valid COA for rules
              if (doSimplifiRules) {
                // show Simplifi rule dialog
                this.props.showRootDialog({
                  id: 'add-memorized-rule',
                  type: DIALOG_TYPE_MEMORIZED_RULE_EDIT,
                  allowNesting: true,
                  props: ImmutableMap({
                    memorizedRule: {
                      payee: priorTxn.payee,
                      coa: value,
                    },
                    fromTxn: true,
                  }),
                });
                this.quickSaveTransaction(txn, true, cancelEdit); // then save current txn

              } else if (!transactionsUtils.isTransferTxn(txn)) {
                // auto-apply for Quicken
                this.props.updateTransactionCategoriesForPayeeAction({
                  priorCoa,
                  payee: txn.payee,
                  coa: txn.coa,
                });
                this.props.makeMemorizedRuleFromTransaction(txn);
              }
              return;
            }
          }
          this.quickSaveTransaction(txn, true, cancelEdit);
          return;

        case 'postedOn':
          txn = txn.set(field, event.target.value);
          this.quickSaveTransaction(txn, true);
          // this.props.setUIState(this.setTxnBeingEdited(txn, { txnDirty: false }));
          return;

        case 'amount':
        case 'balance':
          txn = txn.set(field, value);
          break;
        case 'income':
          txn = txn.set('amount', value);
          break;

        case 'expense':
          txn = txn.set('amount', value);
          break;

        case 'check':
          if (!txn.check) {
            txn = txn.set('check', {});
          }
          txn = txn.setIn(['check', 'number'], value);
          break;
        case 'tags':
          txn = txn.set('tags', event);
          // if any of the tags are not yet created, then delay the auto-save to minimize errors (no guarantee tho)
          if (event.find((x) => !x.id)) {
            this.props.setUIState(this.setTxnBeingEdited(txn, { txnDirty: true }));
            setTimeout(() => this.quickSaveTransaction(txn, true, cancelEdit), 200);
          } else {
            this.quickSaveTransaction(txn, true, cancelEdit);
          }
          return;

        case 'notes':
          txn = txn.set('memo', value);
          break;

        case 'account':
          if (event && event !== txn.accountId) {

            const { hideLoanTransactions, hideConnectedLoanTransactions } = this.props;
            // make sure this is an ok account to use
            if (accountsUtils.transactionsNotSupportedForAccountId(event, hideLoanTransactions, hideConnectedLoanTransactions)) {
              this.props.dialogAlert(
                'Cannot Change Account',
                'Sorry, but the account you selected does not support transactions.',
                this.makeQDialogCallback(noop),
                ['Close'],
                'warning',
              );
            } else {
              txn = txn.set('accountId', event);
              this.quickSaveTransaction(txn, true, cancelEdit);
              // this.props.setUIState(this.setTxnBeingEdited(txn, { txnDirty: false }));
              break;
            }
          } else {
            return;
          }
          break;

        case 'payee': {

          //
          // Payee item was chosen from the payee drop down, which includes the original transaction information
          // We "Quickfill" that information into the
          // log.debug('ON CHANGE PAYEE ', value, this.props.uiState.txnBeingEdited.payee);

          // IF the payee is returned as a structure, that means it was selected out of the payee list, or
          // it means that it was a tab out of the field, and the structure is returned to represent "SELECT"
          // different from on change

          txn = txn.set(field, value?.name ?? value);

          if (value && value.txn) {
            txn = updateMemorizePayeeValues(txn, value);
            this.quickSaveTransaction(txn, true);
            // this.props.setUIState(this.setTxnBeingEdited(txn, { txnDirty: false }));
            return;
          }
          if (value && value.name && value.action === 'Tab') {
            this.quickSaveTransaction(txn, true);
          }

          // Simplifi rename Rules
          const priorTxn = this.props.txnsById.get(txn.id);
          if (doSimplifiRules && this.props.showRules && priorTxn?.cpData?.inferredPayee && txn.payee) {
            const inferredPayee = normalizePayeeQCS(priorTxn.cpData.inferredPayee);

            this.props.showRootDialog({
              id: 'create-memorized-rule',
              type: DIALOG_TYPE_RENAME_RULE_EDIT,
              allowNesting: true,
              props: ImmutableMap({
                renameRule: {
                  renamePayeeFrom: inferredPayee,
                  renamePayeeTo: txn.payee,
                },
                transaction: txn,
                applyRule: true,
              }),
            });
          }
          break;
        }

        case 'state':
          txn = txn.set(field, event);
          this.quickSaveTransaction(txn);
          // this.props.setUIState(this.setTxnBeingEdited(txn, { txnDirty: false }));
          return;

        default:
          txn = txn.set(field, event.target.value);
          break;
      }
      txn = this.setTxnReviewed(txn);
      this.props.setUIState(this.setTxnBeingEdited(txn, { txnDirty: true }));
    }
    // log.log('Changing txn', txn, `>${field}<`);
  };

  setTxnReviewed = (txn) => {
    if (isQuicken && txn && this.props.uiState.txnBeingEdited !== txn && !txn.isReviewed) {
      return txn.set('isReviewed', true);
    }
    return txn;
  };

  setTxnUserOwnedBankPending = (txn) => txn.set('source', TxnPendingStates.USER_OWNED_BANK_PENDING);

  //------------------------------------------------------
  // bulkEditSave
  //
  // returns an object with new values to be applied to all
  // non-split transactions
  bulkEditSave = (fields) => {

    if (Object.keys(fields).length > 0) {
      const { coa, tags, ...otherFields } = fields;

      const newTxns = this.props.uiState.selectedTxns.map((item) => {
        let newTxn = this.setTxnReviewed(item);
        if (!transactionsUtils.isSplitTxn(item)) {
          if (coa) {
            newTxn = newTxn.set('coa', coa);
          }
          if (tags) {
            newTxn = newTxn.set('tags', newTxn.tags ? newTxn.tags.merge(tags) : tags);
          }
          if (item.source === TxnPendingStates.QCS_REFRESH_BANK_PENDING) {
            newTxn = newTxn.set('source', TxnPendingStates.USER_OWNED_BANK_PENDING);
          }
        }
        return newTxn.merge(otherFields);
      });
      const addS = newTxns.size > 1 ? 's' : '';

      if (isAcme) {
        log.log('SAVING TRANSACTIONS', newTxns.toJS());
        this.props.qDataSaveTransactions(newTxns);
        this.props.setUIState({ selectedTxns: new Set() });
      } else {
        this.props.dialogAlert(
          `Save Changes To ${newTxns.size} Transaction${addS}`,
          'This will apply the specified changes to all eligible transactions you selected and cannot be undone. ' +
          'Do you want to proceed?',
          (btn) => {
            if (btn.btnPressed === 'Yes') {
              log.log('SAVING TRANSACTIONS', newTxns.toJS());
              this.props.qDataSaveTransactions(newTxns);
              this.props.setUIState({ selectedTxns: new Set() });
            } else {
              this.forceUpdate();
            }
          },
          ['Cancel', 'Yes'],
          'warning',
          false,
        );
      }
    }
  };

  //------------------------------------------------------
  // editSelectedTxns
  //
  editSelectedTxns = () => {

    const { selectedTxns } = this.props.uiState;
    if (selectedTxns?.size > 0) {

      // warn and fail if the bulk edit contains both sides of a transfer

      this.props.setUIState({ showBulkEdit: true });
    }

  };

  showNewTxnMaybePrompt = (newTransaction = undefined) => {
    this.maybeSaveTxnBeingEdited((proceed) => {

      if (proceed) {
        this.showNewTxn(newTransaction, false);
      }
    });
  };

  makeNewTxn = (acct) => {

    const accountRec = this.props.accountsById.get(acct);
    const isConnected = accountsUtils.isActiveConnectedAccount(accountRec);

    return new transactionsTypes.CashFlowTransaction({
      accountId: acct,
      isReviewed: true,
      clientId: uuidv4().toUpperCase(),
      state: isConnected ? 'PENDING' : 'CLEARED',
    });
  };

  //------------------------------------------------------
  // showNewTxn
  //
  //

  showNewTxn = (newTransaction = undefined, passive = false) => {

    let acct = (newTransaction && newTransaction.accountId)
      || this.props.uiState.lastAccountEntered
      || firstBankAccount(this.props.accountIds);
    if (this.props.accountIds.size > 0 && !this.props.accountIds.includes(acct)) {
      acct = this.props.accountIds.first();
    }
    let newTxn = this.makeNewTxn(acct);
    if (newTransaction) {
      newTxn = newTxn.merge(newTransaction);
    }

    const uiStateObj = this.setTxnBeingEdited(
      newTxn,
      {
        lastAccountEntered: acct,
        scrollToId: '0', // todo: make sure this scrolls up on inline create
        showNewTxn: true,
        simpleEdit: true,
        showSplitsAndDetails: false,
        showSplitWindow: false,
      },
    );
    return passive ? uiStateObj : this.props.setUiState(uiStateObj);

  };

  //-------------------------------------------------------
  //-------------------------------------------------------
  // Handle Focus
  // When a field gets focus, select all it's contents
  // tr
  handleFocus = (event) => {
    event.target.select();
  };


  handleMenu = (action, txn, value) => {
    log.log('HANDLE MENU ==> ', action, txn);
    this.props.setUIState({ showEditMenu: null });

    const payeeField = 'payee'; // this.props.uiState.regEditableFields.indexOf('payee');

    let newTxn;

    /* eslint-disable no-case-declarations */
    switch (action) {

      case 'state':
        switch (value) {
          case 'delete':
            this.props.dispatchDeleteReminderDialog(txn);
            break;

          case 'match':
            this.props.dispatchShowMatchTxnDialog(txn);
            break;

          case 'deleteModel':
            this.props.dialogAlert(
              'Delete Recurring Bill/Income?',
              'This will remove this transaction from the recurring list, and will not create future instances any longer.',
              this.makeQDialogCallback((retObj) => {
                if (retObj.btnPressed === 'Delete') {
                  const resource = this.props.scheduledTransactionsById.get(txn.stModelId);
                  if (resource) {
                    this.props.deleteScheduledTransaction(resource);
                  } else {
                    this.props.dialogAlert(
                      'Recurring Model Not Found',
                      'Could not find the recurring model for this transaction.  Do you want to delete this reminder?',
                      this.makeQDialogCallback((ret) => {
                        if (ret.btnPressed === 'Delete Instance') {
                          this.deleteTransactions([txn]);
                        }
                      }),
                      ['Delete Instance', 'Close'],
                      'error',
                    );
                  }

                }
              }),
              ['Delete', 'Cancel'],
              'delete',
            );
            break;

          default:
            newTxn = txn.set('state', value);
            newTxn = this.setTxnReviewed(newTxn);
            if (isExistingTxn(txn) && txn !== this.props.uiState.txnBeingEdited) {
              this._saveTransaction(newTxn);
            } else {
              this.props.setUIState(this.setTxnBeingEdited(newTxn, { txnDirty: true }));
            }
        }
        break;

      case 'reviewed':
        newTxn = txn.set('isReviewed', !txn.isReviewed);
        const doAutoSave = this.doAutoSaveRegister(newTxn) && this.txnSplitsAreValid(newTxn);
        if (isExistingTxn(txn) && (txn !== this.props.uiState.txnBeingEdited || doAutoSave)) {
          this._saveTransaction(newTxn, undefined, false);
        } else {
          this.props.setUIState(this.setTxnBeingEdited(newTxn, { txnDirty: true }));
        }
        break;


      case 'userFlag':
        newTxn = txn.set('userFlag', value);
        newTxn = this.setTxnReviewed(newTxn);
        if (isExistingTxn(txn) && (txn !== this.props.uiState.txnBeingEdited)) {
          this._saveTransaction(newTxn);
        } else {
          this.props.setUIState(this.setTxnBeingEdited(newTxn, { txnDirty: true }));
        }
        break;

      case 'ignored':
        newTxn = txn.set('isExcludedFromReports', value.isExcludedFromReports);
        newTxn = newTxn.set('isExcludedFromF2S', value.isExcludedFromF2S);
        newTxn = this.setTxnReviewed(newTxn);
        if (isExistingTxn(txn)) {
          this._saveTransaction(newTxn);
        } else {
          this.props.setUIState(this.setTxnBeingEdited(newTxn, { txnDirty: true }));
        }
        break;

      case 'save':
        // if details/splits are open, then we need to save through them
        if (!this.props.uiState.simpleEdit || this.props.uiState.showSplitWindow) {
          if (this.props.uiState.detailsRefSaveFn) {
            // calls the save function of the SplitsPanel component, which houses both splits and edit details
            this.props.uiState.detailsRefSaveFn();
          }
          return;
        }
        this.saveTxnBeingEdited();
        if (typeof this.props.onTxnUpdate === 'function') {
          setTimeout(() => this.props.onTxnUpdate(), 1000);
        }
        break;
      case 'transfer':
        if (transactionsUtils.isTransferTxn(txn) && isSupportedTransferTxn(txn)) {

          let destAcct = null;
          let altAmount = null;
          if (transactionsUtils.isSplitTxn(txn)) {
            txn.split.items.forEach((item) => {
              if (item.coa.type === 'ACCOUNT') {
                destAcct = item.coa.id;
                altAmount = item.amount;
              }
            });
          } else {
            destAcct = txn.coa.id;
          }

          if (destAcct) {
            const matchObj = transactionsUtils.findMatchingTransferTx(txn, this.props.txnsByAccountId.get(destAcct), altAmount);

            if (matchObj && this.props.navFn) {
              this.props.navFn(matchObj.txn.accountId, matchObj.txn.id);
            }
          }
        }
        break;
      case 'showDetails':
        this.props.setUIState(this.setTxnBeingEdited(txn, {
          showSplitWindow: transactionsUtils.isSplitTxn(txn),
          simpleEdit: false,
          currEditFieldName: payeeField,
        }));
        break;
      case 'hideDetails':
        if (this.doAutoSaveRegister(txn)) {
          this.cancelEdit();
        } else {
          this.props.setUIState(this.setTxnBeingEdited(this.setTxnReviewed(txn), {
            showSplitWindow: false,
            simpleEdit: true,
            currEditFieldName: payeeField,
          }));
        }
        break;
      case 'addAttachments':
        this.props.setUIState(this.setTxnBeingEdited(this.setTxnReviewed(txn), { showDocumentAdd: true, currEditFieldName: payeeField }));
        break;
      case 'viewAttachments':
        this.props.setUIState({ showDocumentBrowserTxn: txn });
        break;
      case 'split':
        this.props.setUIState(this.setTxnBeingEdited(this.setTxnReviewed(txn), { showSplitWindow: true, currEditFieldName: payeeField }));
        break;
      case 'hideSplit':
        this.props.setUIState(this.setTxnBeingEdited(this.setTxnReviewed(txn), { showSplitWindow: false, currEditFieldName: payeeField }));
        break;
      case 'unMatch': {
        const fromReminder = (txn.source === 'SCHEDULED_TRANSACTION');
        const verb = txn.cpData && isAcme ? 'link' : 'match';
        const mainCopy = fromReminder ? 'This will unlink your reminder from this downloaded transaction.' :
          'This will unlink your manual transaction from this downloaded transaction';
        this.props.dialogAlert(
          `Un${verb} Transaction`,
          mainCopy,
          this.makeQDialogCallback((retObj) => {
            if (retObj.btnPressed === 'Continue') {
              this.props.qDataUnMatchTransaction(txn);
              this.cancelEdit();
            }
          }),
          ['Continue', 'Cancel'],
          'match',
        );
        break;
      }
      case 'delete':
        tracker.track(tracker.events.txnDelete, {
          type: txn.cpData ? 'downloaded' : 'manual',
          state: 'attempt',
        });
        if (txn.cpData && !this.props.deleteDownloaded) {
          this.props.dialogAlert(
            "Can't Delete Transaction",
            'This is a downloaded transaction and cannot be deleted.',
            this.makeQDialogCallback(noop),
            ['Close'],
            'delete',
            false,
            true,
          );
        } else {
          this.props.dialogAlert(
            'Delete Transaction',
            'Are you sure you want to delete this transaction?',
            this.makeQDialogCallback((retObj) => {
              if (retObj.btnPressed === 'Yes') {
                this.deleteTx(txn);
              }
            }),
            undefined,
            'delete',
            false,
            true,
          );
        }
        break;
      case 'xfer':
        // if (this.props.navFn) {
        //  this.props.navFn(this.props.uiState.txForm.coa.id, this.props.account.id, obj.id);
        // }
        break;
      default:
        break;
    }
  };

  reminderChange = (e) => {

    if (e !== this.props.reminderSetting) {
      //
      // if this is a change, write to preferences
      //
      if (this.props.accountIds && this.props.accountIds.size === 1) {
        const acctRec = this.props.accountsById.get(this.props.accountIds.first());
        // update the account record, but only update the critical fields
        if (acctRec) {
          const recurringTxn = accountsUtils.getReminderObjectFromDays(e);
          const newAcct = accountsTypes.mkUpdateAccount(acctRec.type, { id: acctRec.id, recurringTxn });
          this.props.updateAccount(newAcct);
          tracker.track(tracker.events.prefScheduleTransactionVisibility, recurringTxn);
        }
      }
    }
  };

  headerClick = (e, field) => {

    let sortField;
    let sortAnimation = false;
    let sortOrder;

    switch (field) {
      case 'postedOn':
        sortField = 'date';
        break;
      case 'state':
        sortField = 'status';
        break;

      case 'reviewed':
        return;

      case 'expense':
      case 'income':
        sortField = 'amount';
        break;

      default:
        sortField = field;
    }

    if (sortField === this.props.sortBy) {
      sortOrder = this.props.sortOrder === 'ascending' ? 'descending' : 'ascending';
      sortAnimation = true;
    }

    if ((sortField !== this.props.sortBy || sortOrder !== this.props.sortOrder) && this.props.onSortChange) {
      this.props.onSortChange(sortField, sortOrder);
    }
    this.props.setUIState({ txnsFirstLoaded: this.props.acctTxns, sortAnimation });
  };

  hideModal = (modalStr) => {

    const payeeField = 'payee'; // this.props.uiState.regEditableFields.indexOf('payee');

    switch (modalStr) {
      case 'bulkEdit':
        this.props.setUIState({ currEditFieldName: payeeField, showBulkEdit: false });
        this.resetFocus();
        break;
      case 'splits':
      case 'details':
        if (this.doAutoSaveRegister(this.props.uiState.txnBeingEdited)) {
          this.cancelEdit();
        } else {
          this.props.setUIState({
            currEditFieldName: payeeField,
            simpleEdit: true,
            showSplitWindow: false,
          });
          this.forceUpdate(() => {
            this.resetFocus();
          });
        }
        break;
      case 'documentBrowser':
        this.props.setUIState({ currEditFieldName: payeeField, showDocumentBrowserTxn: null });
        break;
      case 'addDocument':
        this.props.setUIState({ currEditFieldName: payeeField, showDocumentAdd: false });
        break;
      default:
        break;
    }
  };


  //
  // Routines to control the tabbing order (forward and back) taking into account fields that
  // should be skipped for this transaction
  //
  // Props must have:
  // accountIds, editRunningBalance, regEditableFields, headerFields, editDownloaded
  // showAccountColors, showSplitWindow, scheduledTransactionsById, txnExtraColumn
  isSkipField = (props, fld, txn) => {

    const { accountIds, editRunningBalance, scheduledTransactionsById, editDownloaded, txnCancelExtraColumn,
      showAccountColors } = props;

    const options = {
      accountIds,
      editRunningBalance,
      scheduledTransactionsById,
      editDownloaded,
      txnCancelExtraColumn,
      showAccountColors,
      headerFields: props.uiState.headerFields,
      regEditableFields: props.uiState.regEditableFields,
      showSplitWindow: props.uiState.showSplitWindow,
    };
    const ret = !helpers.fieldIsEditable(txn, fld, options);

    return ret;

  };

  getNextField = (props) => {

    const { headerFields } = props.uiState;

    const txn = props.uiState.txnBeingEdited;
    const currIndex = headerFields.indexOf(props.uiState.currEditFieldName);


    let nextFieldIndex = (currIndex + 1) >= headerFields.size ? 0 : currIndex + 1;
    // Skip the inactive fields

    while (this.isSkipField(props, headerFields.get(nextFieldIndex), txn)) {
      nextFieldIndex = nextFieldIndex + 1 >= headerFields.size ? 0 : nextFieldIndex + 1;
    }
    return headerFields.get(nextFieldIndex);
  };

  getPrevField = (props) => {

    const { headerFields } = props.uiState;

    const txn = props.uiState.txnBeingEdited;
    const currIndex = headerFields.indexOf(props.uiState.currEditFieldName);

    let nextFieldIndex = (currIndex - 1) < 0 ? headerFields.size - 1 : currIndex - 1;

    // Skip the inactive fields
    while (this.isSkipField(props, headerFields.get(nextFieldIndex), txn)) {
      nextFieldIndex = nextFieldIndex - 1 < 0 ? headerFields.size - 1 : nextFieldIndex - 1;
    }
    return headerFields.get(nextFieldIndex);
  };

  //-------------------------------------------------------
  // regFieldKey
  // called when current edit field gets a keypress, used to
  // process special keys like tab, enter, and ctrl
  //
  regFieldKey = (event) => {
    // log.log('RegFieldKey - ', event.key);

    const shifted = event.shiftKey;
    const field = this.props.uiState.currEditFieldName;

    // CTRL KEYS
    if (event.ctrlKey) {
      switch (event.key) {
        case 's':
          if (this.props.uiState.txnBeingEdited) {
            this.handleMenu('split', this.props.uiState.txnBeingEdited);
          }
          break;
        case 'l':
          if (this.props.uiState.txnBeingEdited) {
            this.handleMenu('showDetails', this.props.uiState.txnBeingEdited);
          }
          break;
        /*
        case 'd':
          if (this.props.uiState.txnBeingEdited) {
            this.handleMenu('delete', this.props.uiState.txnBeingEdited);
          }
          break;
        */
        case 'x':
          if (this.props.uiState.txnBeingEdited) {
            this.handleMenu('transfer', this.props.uiState.txnBeingEdited);
          }
          break;
        default:
          break;
      }
    }

    switch (event.key) {
      case 'Escape':
        this.cancelEdit();
        event.stopPropagation();
        break;

      case 'Tab':
      case 'Enter':

        if (this.props.uiState.txnBeingEdited) {
          event.preventDefault();
          event.stopPropagation();

          if (event.key === 'Tab') {
            // if (field !== 'payee') this.blurCurrentEditField();
            if (field !== 'payee') this.blurCurrentEditField();
            let target = field;
            if (!shifted) {
              // advance
              target = this.getNextField(this.props);
            } else {
              target = this.getPrevField(this.props);
            }
            this.changeEditableField(target);
          }
        }
        break;

      default:
    }

    if (event.key === 'Enter') {

      // should always be true for Quicken (not autoSave)
      if (!this.doAutoSaveRegister(this.props.uiState.txnBeingEdited)) { // } && event.ctrlKey) {
        // this is a 'nextTick', to allow the blur field above to execute before the save
        if (field === 'amount') {
          setTimeout(() => this.handleMenu('save'), 5);
        } else {
          this.handleMenu('save');
        }
      } else if (this.props.uiState.txnDirty) {
        setTimeout(() => this.handleMenu('save'), 5);
      } else {
        setTimeout(this.cancelEdit, 100);
      }
    }

  };

  // changeEditableField
  //
  // either will update an object you are preparing for a setUIState,
  // or else it will call setUIState directly
  //
  changeEditableField = (field, updateObject) => {

    if (updateObject) {
      const newObj = copyObject(updateObject);
      newObj.currEditFieldName = field;
      return newObj;
    }
    return this.props.setUIState({ currEditFieldName: field });
  };

  removeSplitsFromTxnsAndUpdateUiState = (txn) => {
    const newTxn = new transactionsTypes.CashFlowTransaction({
      ...txn,
      coa: txn.split?.items[0]?.coa || null,
      tags: txn.split?.items[0]?.tags || null,
      split: null,
    });
    this.props.setUIState({ txnBeingEdited: newTxn, showSplitWindow: false, txnDirty: true });
  };

  //-------------------------------------------------------
  // regFieldClick
  // when a click happens on a field, move to it if it is editable
  //
  regFieldClick = (vfield, txn, suggestTxn) => {
    log.debug('=================== Clicking on register field', vfield, txn.payee);

    let catSuggest = false;
    let openSplit = false;
    let field = vfield;

    if (vfield === 'categorySuggest') {
      field = 'category';
      catSuggest = true;
    } else if (vfield === 'split') {
      openSplit = true;
      field = 'category';
    }
    const fieldBeingClicked = this.isSkipField(this.props, field, txn) ? null : field;

    // CLICK IS WITHIN THE CURRENTLY BEING EDITED TRANSACTION
    if (txn === this.props.uiState.txnBeingEdited) {

      if (vfield === 'clearSplits') {
        if (txn.id) {
          this.props.dialogAlert(
            'Clear All Splits', 
            'This action will delete all the split categories within the transaction?', 
            ({ btnPressed }) => btnPressed === 'CLEAR SPLITS' ? this.removeSplitsFromTxnsAndUpdateUiState(txn.toJS()) : null, 
            ['CANCEL', 'CLEAR SPLITS'],
            'backspace',
            false,
          );
        } else {
          this.removeSplitsFromTxnsAndUpdateUiState(txn.toJS());
        }
        return;
      }

      // show warning if the selected instance is a bank pending transaction
      if (isBankOwnedPending(txn) && this.props.isC2REnabled) {
        this.props.dialogAlert('Pending Transaction', 'Pending transactions cannot be edited', undefined, ['ok']);
        return;
      }

      let { showSplitWindow } = this.props.uiState;

      if (openSplit || ((field === 'tags' || field === 'category') && transactionsUtils.isSplitTxn(txn))) {
        if (isAcme) {
          this.props.showTxnDetailsDialogForSplits(txn);
          return;
        }
        showSplitWindow = !showSplitWindow;
      }
      let updateObject = this.changeEditableField(fieldBeingClicked, { showSplitWindow });

      if (catSuggest) {
        updateObject = this.setTxnBeingEdited(this.setTxnReviewed(txn).set('coa', suggestTxn.coa), updateObject);
        updateObject.txnDirty = true;
      }

      this.props.setUIState(updateObject);

      if (catSuggest) {
        setTimeout(this.saveTxnBeingEdited, 50);
      }

      // }
      // CLICK IS IN  A NEW TX ROW
    } else {

      // if this is a scheduled transaction instance, and is not the "next" one, then we skip it, Quicken only
      if (!isAcme && isUnacceptedScheduledTxn(txn) && !isActionableScheduledInstance(txn, this.props.scheduledTransactionsById)) {
        return;
      }

      // show warning if the selected instance is a bank pending transaction
      if (isBankOwnedPending(txn) && this.props.isC2REnabled) {
        this.props.dialogAlert('Pending Transaction', 'Pending transactions cannot be edited', undefined, ['ok']);
        return;
      }

      let showSplitWindow = false;

      if (((field === 'tags' || field === 'category') && transactionsUtils.isSplitTxn(txn))) {
        if (isAcme) {
          this.props.showTxnDetailsDialogForSplits(txn);
          return;
        }
        showSplitWindow = true;
      }

      let editTxn = txn;
      if (catSuggest) {
        editTxn = this.setTxnReviewed(txn).set('coa', suggestTxn.coa);
      }
      const updateStateObj = this.setTxnBeingEdited(editTxn, {
        txnDirty: Boolean(suggestTxn),
        currEditFieldName: showSplitWindow ? null : (fieldBeingClicked || this.props.uiState.currEditFieldName),
        simpleEdit: true,
        showSplitWindow,
      });

      if (this.props.uiState.txnBeingEdited) {
        this.maybeSaveTxnBeingEdited((confirmed) => {
          if (confirmed) {
            this.props.setUIState(updateStateObj);
          } else {
            log.log('SAVE ABORTED');
            this.forceUpdate();
          }
        });
      } else {
        this.props.setUIState(updateStateObj);
        if (updateStateObj.txnDirty) {
          setTimeout(this.saveTxnBeingEdited, 50);
        }
      }
    }
  };

  regMenuExiting = () => {
    // this.props.setUIState({txnBeingEdited: null});
  };

  makeQDialogCallback(cb) {
    return ((x) => { this.forceUpdate(); cb(x); });
  }

  columnIsVisible = (col) =>
    (this.props.regFieldsOrder.includes(col));

  // ==============
  // RENDER
  // ==============
  render() {

    const { classes, scrollToId, calendarDate, zeroStateMessage, allAccountIds, ...other } = this.props;
    const { searchBoxFilter, filterObject } = this.props.uiState;

    const ret = (
      <div
        className={classes.wrapper}
        role="presentation"
      >
        <TransactionPage
          {...other}
          currEditFieldName={this.props.uiState.currEditFieldName || 'payee'}
          callbacks={this.callbacks}
          scrollToId={scrollToId || this.props.uiState.scrollToId}
          hideSearch={this.props.hideSearch}
          hideFooter={!this.columnIsVisible('reviewed')}
          headerFields={this.props.regFieldsOrder}
          showCurrencySymbol={this.props.showCurrencySymbol}
          reminderSetting={this.props.reminderSetting}
          showDebtErrorMessage={this.props.showDebtErrorMessage}
          zeroStateMessage={searchBoxFilter || filterObject ? 'No transactions match your search or filters.' : zeroStateMessage}
          calendarDate={calendarDate}
          allAccountIds={allAccountIds}
        />
      </div>
    );
    return ret;
  }
}

TransactionRegister.defaultProps = {
  registerComfort: 'normal',
  accountIds: List(),
  calendar: false,
  zeroStateMessage: null,
};

/* eslint-disable react/no-unused-prop-types */
TransactionRegister.propTypes = {

  accountIds: PropTypes.object,  // Precisely, an immutable OrderedMap or empty list for all accounts
  overrideTxns: PropTypes.object, // if provided, a set of transactions to display, bypassing retrieval from accounts
  navFn: PropTypes.func,  // called on "go to transfer"
  showControls: PropTypes.bool,
  tightMargins: PropTypes.bool,
  noNew: PropTypes.bool,
  hideSearch: PropTypes.bool,
  scrollToId: PropTypes.string, // ID of transaction to scroll to

  getRef: PropTypes.func,
  wrapperId: PropTypes.string.isRequired,   // required
  filterFunction: PropTypes.func,
  hideDividers: PropTypes.bool,
  openPrefs: PropTypes.func,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  onSortChange: PropTypes.func,
  reminderSetting: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  calendar: PropTypes.bool,
  earliestTransactionDate: PropTypes.string,
  calendarDate: PropTypes.object,
  setCalendarDate: PropTypes.func,
  zeroStateMessage: PropTypes.string,

  // GENERATED
  acctTxns: PropTypes.object,
  txnsByAccountId: PropTypes.object,
  accountsById: PropTypes.object,
  tags: PropTypes.object,
  classes: PropTypes.object,
  loadPending: PropTypes.bool,
  transactionsWithCategorySuggestions: PropTypes.object,
  payeesForAccounts: PropTypes.object,
  getScheduledTransactions: PropTypes.func,
  scheduledTransactionsLoadPending: PropTypes.bool,
  scheduledTransactionsById: PropTypes.object,
  makeMemorizedRuleFromTransaction: PropTypes.func,
  allAccountIds: PropTypes.object,

  // PREFS
  doubleAmounts: PropTypes.bool,
  editDownloaded: PropTypes.bool,
  deleteDownloaded: PropTypes.bool,
  autoSave: PropTypes.bool,
  splitsInDialog: PropTypes.bool,
  longCats: PropTypes.bool,
  continuousCreate: PropTypes.bool,
  showCurrencySymbol: PropTypes.bool,
  registerComfort: PropTypes.string,
  regFieldsOrder: PropTypes.object,
  regFieldsOrderChange: PropTypes.func,
  wrapText: PropTypes.bool,
  accountNodeId: PropTypes.string,

  // QPreferences
  setDatasetPreference: PropTypes.func,
  datasetPreferences: PropTypes.object,

  // uiState
  uiState: PropTypes.object,
  setUIState: PropTypes.func,
  onTxnUpdate: PropTypes.func,

  // QDialogs
  dialogAlert: PropTypes.func,
  dialogForm: PropTypes.func,

  // QDom
  scrollIntoViewIfNeeded: PropTypes.func,

  // QData
  qDataSaveTransactions: PropTypes.func,
  qDataDeleteTransactions: PropTypes.func,
  qDataUpdateTransactions: PropTypes.func,
  qDataMatchTransactions: PropTypes.func,
  qDataUnMatchTransaction: PropTypes.func,
  qDataPerformTransactionAction: PropTypes.func,

  // Feature Flags
  autoSaveRegister: PropTypes.bool,
  usePayeeField: PropTypes.bool,
  txnCancelExtraColumn: PropTypes.bool,
  editRunningBalance: PropTypes.bool,
  showRules: PropTypes.bool,
  // Dispatch Props
  getTransactions: PropTypes.func,
  deleteDocument: PropTypes.func,
  dispatchDeleteReminderDialog: PropTypes.func,
  dispatchShowMatchTxnDialog: PropTypes.func,
  showTxnDetailsDialogForSplits: PropTypes.func,

  // Refs
  addNewTransactionRef: PropTypes.func,

  // WebFirst
  webFirstCatUncategorize: PropTypes.bool,

  // Categories
  categories: PropTypes.object,

  // DEBT NOT SUPPORTED IN QUICKEN WEB
  showDebtErrorMessage: PropTypes.bool,
  
  // Dataset Object
  dataset: PropTypes.object,
};

function mapStateToProps(state, ownProps) {

  // create sortBy and sortOrder
  let sortBy = isAcme ? 'status' : 'date';
  let sortOrder = 'descending';

  let reminderSetting = 0;
  let nodePref = null;

  if (ownProps.datasetPreferences) {
    nodePref = ownProps.datasetPreferences.accountNodePrefs.get(ownProps.accountNodeId);
  }

  // Reminder Setting
  if (ownProps.accountsById && ownProps.accountIds && ownProps.accountIds.size <= 1) {
    if (ownProps.reminderSetting) {
      reminderSetting = ownProps.reminderSetting;
    } else {
      const acctRec = ownProps.accountsById.get(ownProps.accountIds.first());

      reminderSetting = accountsUtils.getReminderSettingInDays(acctRec?.recurringTxn);
    }
  } else {
    reminderSetting = ownProps.reminderSetting || 'account';  // use each accounts reminder setting preference to determine the list
  }

  nodePref = isAcme ? ownProps.datasetPreferences.accountNodePrefs.get('global') : nodePref;
  // For acme, we actually read the pref from the global location for sort
  if (nodePref) {
    sortBy = nodePref.sortBy || sortBy;
    sortOrder = nodePref.sortOrder || sortOrder;
  }

  // all overridden if props are passed in
  sortBy = ownProps.sortBy || sortBy;
  sortOrder = ownProps.sortOrder || sortOrder;

  const filterExpression = ownProps.uiState.searchBoxFilter;
  const { filterObject } = ownProps.uiState;

  const wrappedFilterFn = helpers.createFilterFromObjectAndExpression(filterObject, filterExpression);
  return {
    txnsByAccountId: transactionSelectors.getTransactionsByAccountId(state),
    txnsById: transactionSelectors.getTransactionsById(state),
    acctTxns: ownProps.calendar ? transactionListSelectors.processCalendarTxns(ownProps.overrideTxns) :
      transactionListSelectors.getTransactionListForAccountIds(
        state,
        {
          id: ownProps.wrapperId,
          accountIds: ownProps.accountIds,
          sortBy,
          sortOrder,
          filterFn: wrappedFilterFn,
          filterExpression: ownProps.filterFunction ? undefined : filterExpression,
          overrideTxns: ownProps.overrideTxns,
          reminderSetting,
          earliestTransactionDate: ownProps.earliestTransactionDate,
        },
      ),
    transactionsWithCategorySuggestions: isAcme ? List() :    // not needed for Acme, don't waste performance
      transactionListSelectors.getTransactionsWithCategorySuggestions(
        state,
        {
          accountIds: ownProps.accountIds,
        },
      ),
    payeesForAccounts: payeeSelectors.getPayeesForAccounts(state, { accountIds: ownProps.accountIds }),
    tags: tagsSelectors.getTags(state),
    loadPending: transactionSelectors.getLoadPending(state),
    usePayeeField: featureFlagsSelectors.getFeatureFlags(state).get('usePayeeField'),
    txnCancelExtraColumn: featureFlagsSelectors.getFeatureFlags(state).get('txnCancelExtraColumn'),
    editRunningBalance: featureFlagsSelectors.getFeatureFlags(state).get('editRunningBalance'),
    autoSaveRegister: featureFlagsSelectors.getFeatureFlags(state).get('autoSaveRegister'),
    hideLoanTransactions: featureFlagsSelectors.getFeatureFlags(state).get('hideLoanTransactions'),
    hideConnectedLoanTransactions: featureFlagsSelectors.getFeatureFlags(state).get('hideConnectedLoanTransactions'),
    showRules: featureFlagsSelectors.getFeatureFlags(state).get('rules'),
    sortBy,
    sortOrder,
    reminderSetting,
    scheduledTransactionsById: scheduledTransactionsSelectors.getScheduledTransactions(state),
    scheduledTransactionsLoadPending: scheduledTransactionsSelectors.getLoadPending(state),
    categories: categoriesSelectors.getCategoriesById(state),
    isC2REnabled: isCompareToRegisterEnabled(state),
    allAccountIds: accountsSelectors.getAllAccountIds(state),
    dataset: authSelectors.getCurrentDataset(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTransactions: () => dispatch(transactionsActions.getTransactions(undefined, { context: 'register' })),
    getScheduledTransactions: () => dispatch(scheduledTransactionsActions.getScheduledTransactions(undefined, { context: 'register' })),
    deleteDocument: (data) => dispatch(documentActions.deleteDocument(data)),
    updateAccount: (data) => dispatch(accountsActions.updateAccount(data)),
    deleteScheduledTransaction: (data) => dispatch(scheduledTransactionsActions.deleteScheduledTransaction(data, { context: 'register' })),
    dispatchDeleteReminderDialog: (txn) => dispatch(showDeleteReminderDialog(txn)),
    updateTransactionCategoriesForPayeeAction:
      (data) => dispatch(transactionsSpecialActions.updateTransactionCategoriesForPayeeAction(data, { context: 'register' })),
    showRootDialog: (dialogData) => dispatch(createDialog(mkRootUiData(dialogData))),
    dispatchShowMatchTxnDialog: (txn) => dispatch(showMatchTxnDialog(txn)),
    makeMemorizedRuleFromTransaction: (txn) => dispatch(makeMemorizedRuleFromTransactionAction(txn)),
    showTxnDetailsDialogForSplits: (txn) => dispatch(showTxnDetailsDialog({ txn, focusSplits: true })),
  };
}

TransactionRegister.whyDidYouRender = true;

export default compose(
  QDialogs(),
  QDom(),
  QData(),
  QPreferences(),
  UIState(uiStateConfig),
  connect(mapStateToProps, mapDispatchToProps),
)(withStyles(TransactionRegister, styles));
