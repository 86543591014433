import moment from 'moment';
import { createSelector } from 'reselect';
import { createCachedSelector, LruObjectCache } from 're-reselect';
import { Map as ImmutableMap, List as ImmutableList } from 'immutable';
import { createValueCache } from 'utils/selectorsHelpers';

import { getLogger } from 'companion-app-components/utils/core';
import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { categoriesSelectors } from 'companion-app-components/flux/categories';
import { transactionsUtils } from 'companion-app-components/flux/transactions';

import { getLoadPending as getTransactionsLoadPending, getTransactionsByAccountId } from 'data/transactions/selectors';
import { dateTimeFromTxn } from 'data/transactions/utils';
import { getAllBalancesByAccountId, getBalancesByAccountId } from 'data/accountBalances/selectors';
import { getTransactionListForAccountIds } from 'data/transactionList/selectors';
import { getOrCreateStringFromObject } from 'utils/objectToKeyUtils';
import { memoizeWithKeyRetrieveCache, memoizeWithKey } from 'utils/memoizeWithKey';

import {
  calculateValuesForPeriods,
  incomeFilters,
  monthlyFrequencyData,
  spendingFilters,
  createPastMonthPeriods,
  watchlistFilters,
} from 'data/otReports/utils';

import { DateTime } from 'luxon';

const log = getLogger('data/otReports/selectors');

/**
 * Get net worth data grouped by periods
 */
export const getNetWorthForPeriods = createCachedSelector(
  (state) => accountsSelectors.getLoadPending(state) || categoriesSelectors.getLoadPending(state) || getTransactionsLoadPending(state),
  getAllBalancesByAccountId,
  getTransactionListForAccountIds,
  (_, props) => props.numMonths,
  (_, props) => props.dateInterval,
  (isLoadPending, allBalancesByAccountId, transactionListForAccountIds, numMonths, dateInterval) => {

    // accounts, categories, and transactions must all be loaded before we attempt to calculate spending
    if (isLoadPending) {
      return null;
    }
    const txnList = {};
    for (let i = 0; i < numMonths; i++) {
      const key = DateTime.local().startOf('month').minus({ months: i }).toFormat('yyyy-MM:MMMM yyyy');
      txnList[key] = null;
    }
    Object.entries(transactionListForAccountIds.toJS()).forEach(([key, val]) => {
      if (key.split(':')[0].split('-')[1] !== '00') {
        const theKey = DateTime.fromFormat(key.split(':')[0] || key, 'yyyy-MM');
        if (dateInterval.contains(theKey)) {
          if (Object.prototype.hasOwnProperty.call(txnList, key)) {
            txnList[key] = val;
          }
        }
      }
    });
    /**
     * Get all balances and calculate total net worth
     */
    let totalBalance = 0;
    allBalancesByAccountId.forEach((account) => {
      if (account.accountType !== 'GOAL') {
        totalBalance += account.currentBalance || 0;
      }
    });

    /**
     * Calculate the transactions total per month - ignoring the txns where isExcludedFromReports = true
     */
    const totalsPerMonth = {};

    Object.entries(txnList).forEach(([month, txns]) => {
      if (txnList[month]) {
        let total = 0;
        txns.forEach((txn) => {
          if (!txn.isExcludedFromReports) {
            total += txn.amount;
          }
        });
        totalsPerMonth[month] = {};
        totalsPerMonth[month].txns = txns;
        totalsPerMonth[month].txnsTotal = total;
      } else {
        totalsPerMonth[month] = {};
        totalsPerMonth[month].txns = [];
        totalsPerMonth[month].txnsTotal = 0;
      }
    });

    /**
     * Calculate net worth per month -
     * We go backwards from the current month where the networth = totalBalance
     * Each month's net worth will be next month's net worth minus the next month's txns total
     */
    const totalsPerMonthKeys = Object.keys(totalsPerMonth);
    let nextMonthKey = null;
    let nextMonthNetWorth = null;
    nextMonthKey = null;

    /**
     * Calculate Net Worth per month and periods data
     */
    totalsPerMonthKeys.forEach((month) => {
      if (nextMonthNetWorth === null) {
        totalsPerMonth[month].netWorth = totalBalance;
        nextMonthNetWorth = totalBalance;
      } else {
        totalsPerMonth[month].netWorth = nextMonthNetWorth - totalsPerMonth[nextMonthKey].txnsTotal;
        nextMonthNetWorth = totalsPerMonth[month].netWorth;
      }

      nextMonthKey = month;
      const period = DateTime.fromISO(month.split(':')[0]);
      totalsPerMonth[month].period = period;
      totalsPerMonth[month].label = monthlyFrequencyData.labelFromPeriod(period);
      totalsPerMonth[month].shortLabel = monthlyFrequencyData.shortLabelFromPeriod(period);
      totalsPerMonth[month].year = monthlyFrequencyData.yearFromPeriod(period);
    });

    return {
      periods: ImmutableList(Object.values(totalsPerMonth).reverse()),
      totalNetWorth: totalBalance,
      currency: 'USD',
    };
  },
)({
  // this line sets the key to use for the cache
  keySelector: (state, props) => props.accountIds ? `${props.accountIds.toString()}${props.startDate}${props.endDate}` : 'any',
  cacheObject: new LruObjectCache({ cacheSize: 6 }),
});

/**
 * Get watchlist data grouped by frequency
 */
export const getWatchlistDataForPeriods = createCachedSelector(
  (state) => accountsSelectors.getLoadPending(state) || categoriesSelectors.getLoadPending(state) || getTransactionsLoadPending(state),
  accountsSelectors.getReportableAccountsById,
  (state, props) => props.accountIds,
  getTransactionsByAccountId,
  categoriesSelectors.getCategoriesById,
  (state, props) => props.dateInterval,
  (_, props) => props.frequency,
  (_, props) => props.filterCriteria,
  (_, props) => props.filterBySource,
  (isLoadPending, allAccountsByIds, accountIds, transactionsByAccountId, categoriesById, dateInterval, frequency, filterCriteria, filterBySource) => {

    // accounts, categories, and transactions must all be loaded before we attempt to caclculate spending
    if (isLoadPending) {
      return null;
    }
    const parsedFilterCriteria = JSON.parse(filterCriteria);
    // turn into trace statement after fixing issues with input properties changing all the time
    log.log('calculating watchlistData for', allAccountsByIds, accountIds, dateInterval);

    // will default to a monthly frequency
    let frequencyData = monthlyFrequencyData;
    switch (frequency) {
      case 'monthly':
        frequencyData = monthlyFrequencyData;
        break;
      default:
        break;
    }

    const accountIdsToUse = accountIds || allAccountsByIds.keySeq();
    return calculateValuesForPeriods(accountIdsToUse, allAccountsByIds, categoriesById, transactionsByAccountId, dateInterval, frequencyData, watchlistFilters, parsedFilterCriteria, filterBySource);
  },
)({
  // this line sets the key to use for the cache
  keySelector: (state, props) => `${props.filterCriteria}${props.accountIds}${props.frequency}`,
  cacheObject: new LruObjectCache({ cacheSize: 6 }),
});

/**
 * Get income data grouped by frequency
 */
export const getIncomeForPeriods = createCachedSelector(
  (state) => accountsSelectors.getLoadPending(state) || categoriesSelectors.getLoadPending(state) || getTransactionsLoadPending(state),
  accountsSelectors.getReportableAccountsById,
  (state, props) => props.accountIds,
  getTransactionsByAccountId,
  categoriesSelectors.getCategoriesById,
  (state, props) => props.dateInterval,
  (_, props) => props.frequency,
  (_, props) => props.filterCriteria,
  (_, props) => props.filterBySource,
  (isLoadPending, allAccountsByIds, accountIds, transactionsByAccountId, categoriesById, dateInterval, frequency, filterCriteria, filterBySource) => {

    // accounts, categories, and transactions must all be loaded before we attempt to calculate spending
    if (isLoadPending) {
      return null;
    }

    // turn into trace statement after fixing issues with input properties changing all the time
    log.log('calculating income for', allAccountsByIds, accountIds, dateInterval);

    // will default to a monthly frequency
    let frequencyData = monthlyFrequencyData;
    switch (frequency) {
      case 'monthly':
        frequencyData = monthlyFrequencyData;
        break;
      default:
        break;
    }
    // const parsedFilterCriteria = JSON.parse(filterCriteria); // quick fix for failing unit tests, seems unnecessary but was being passed to calculateValuesForPeriods
    const accountIdsToUse = accountIds || allAccountsByIds.keySeq();
    return calculateValuesForPeriods(accountIdsToUse, allAccountsByIds, categoriesById, transactionsByAccountId, dateInterval, frequencyData, incomeFilters, filterCriteria, filterBySource);
  },
)({
  // this line sets the key to use for the cache
  keySelector: (_, props) => props.accountIds ? `${props.accountIds.toString()}${props.startDate}${props.endDate}` : 'any',
  cacheObject: new LruObjectCache({ cacheSize: 6 }),
});

// Motivation - want to get the transactions for a specific coa within a certain date range (usually 6 months) so that we can take the average
export const getTransactionsForCoa = createSelector(
  (state) => accountsSelectors.getLoadPending(state) || categoriesSelectors.getLoadPending(state) || getTransactionsLoadPending(state),
  getTransactionsByAccountId,
  categoriesSelectors.getCategoriesById,
  (_, props) => props.coaId,
  (_, props) => props.pastCutoff,
  (_, props) => props.currCutoff,
  (isLoading, txnsByAccountId, categories, coaId, pastCutoff, currCutoff) => {
    if (isLoading || !coaId) {
      return null;
    }
    const cacheKey = getOrCreateStringFromObject(
      'planned-spending-calculation',
      { txnsByAccountId, categories, coaId, pastCutoff, currCutoff },
      6,
      memoizeWithKeyRetrieveCache,
    );

    return (memoizeWithKey(
      'planned-spending-calculation',
      () => {
        const retArr = [];
        let sum = 0;
        const localTime = DateTime.local();
        let firstTxnDate = localTime;
        const pastDateTime = DateTime.fromSeconds(pastCutoff);
        const currDateTime = DateTime.fromSeconds(currCutoff);
        txnsByAccountId?.forEach((account) => {
          account?.forEach((txn) => {
            const txnDate = dateTimeFromTxn(txn);
            if (firstTxnDate > txnDate) {
              firstTxnDate = txnDate;
            }
            const txnCat = categories.get(txn?.coa?.id);
            const searchedCat = categories.get(coaId);
            if ((txn?.coa?.id === coaId || txnCat?.parentId === searchedCat?.id) && txnDate > pastDateTime && txnDate < currDateTime && !txn.stModelId) {
              retArr.push(txn);
              sum += txn.amount;
            } else if (transactionsUtils.isSplitTxn(txn)) {
              txn?.split?.items?.forEach((x) => {
                if (x?.coa?.id === coaId) {
                  retArr.push(txn);
                  sum += x.amount;
                }
              });
            }
          });
        });
        // We don't wantscheduled to include anything past the 5th of the month
        const firstTxnDateCutoff = firstTxnDate.startOf('month').plus({ days: 4 }).endOf('day');
        let numMonths = 0;
        if (firstTxnDate > firstTxnDateCutoff && firstTxnDate > pastDateTime) {
          sum = 0;
          retArr.filter((x) => {
            const txnDate = dateTimeFromTxn(x);
            if (txnDate.monthLong !== firstTxnDate.monthLong) {
              sum += x.amount;
              return true;
            }
            return false;
          });
          numMonths = Math.round(localTime.startOf('month').diff(firstTxnDate.startOf('month'), 'months').months) - 1;
        } else if (firstTxnDate > pastDateTime) {
          numMonths = Math.round(localTime.startOf('month').diff(firstTxnDate.startOf('month'), 'months').months);
        } else {
          numMonths = 6;
        }
        return ({ txns: retArr, numMonths, sum });
      },
      cacheKey,
      6,
    ));
  },
);

export const getAverageSpendingForCategory = createSelector(
  getTransactionsForCoa,
  (transactions) => {
    const cacheKey = getOrCreateStringFromObject(
      'planned-average',
      { transactions },
      6,
      memoizeWithKeyRetrieveCache,
    );

    return (memoizeWithKey(
      'planned-average',
      () => {
        if (!transactions) return { averageSpending: 0, numMonths: 0 };
        const { sum, numMonths } = transactions;
        return { averageSpending: numMonths <= 0 ? 0 : sum / numMonths, numMonths };
      },
      cacheKey,
      6,
    ));

  },
);

/**
 * Get spending data grouped by frequency
 */
export const getSpendingForPeriods = createCachedSelector(
  (state) => accountsSelectors.getLoadPending(state) || categoriesSelectors.getLoadPending(state) || getTransactionsLoadPending(state),
  accountsSelectors.getReportableAccountsById,
  (_, props) => props.accountIds,
  getTransactionsByAccountId,
  categoriesSelectors.getCategoriesById,
  (_, props) => props.dateInterval,
  (_, props) => props.frequency,
  createValueCache((_, props) => props.filterCriteria),
  (_, props) => props.filterBySource,
  (isLoadPending, allAccountsByIds, accountIds, transactionsByAccountId, categoriesById, dateInterval, frequency, filterCriteria, filterBySource) => {
    // accounts, categories, and transactions must all be loaded before we attempt to calculate spending
    if (isLoadPending) {
      return null;
    }
    const stringifiedFilterCriteria = JSON.stringify(filterCriteria);
    const accountIdsToUse = accountIds || allAccountsByIds.keySeq();
    const cacheKey = getOrCreateStringFromObject(
      'spending-periods',
      {
        allAccountsByIds,
        accountIdsToUse,
        transactionsByAccountId,
        categoriesById,
        dateInterval,
        frequency,
        stringifiedFilterCriteria,
        filterBySource,
      },
      6,
      memoizeWithKeyRetrieveCache,
    );

    // turn into trace statement after fixing issues with input properties changing all the time
    log.log('calculating spending for', allAccountsByIds, accountIds, dateInterval, frequency);
    // const parsedFilterCriteria = JSON.parse(filterCriteria);

    return memoizeWithKey(
      'spending-periods',
      () => {
        if (filterCriteria) {
          const arr = [];
          categoriesById.forEach((x) => {
            if (x.parentId === filterCriteria.items?.[0]) {
              arr.push(x.id);
            }
          });

          if (arr.length > 0) {
            filterCriteria.items.concat(arr);
          }
        }
        return calculateValuesForPeriods(accountIdsToUse, allAccountsByIds, categoriesById, transactionsByAccountId, dateInterval, monthlyFrequencyData, spendingFilters, filterCriteria, filterBySource);
      },
      cacheKey,
      6,
    );
  },
)({
  // this line sets the key to use for the cache
  keySelector: (state, { accountIds, dateInterval, frequency, filterCriteria }) => {
    const accountIdsKey = accountIds ? `${accountIds.toString()}` : 'all';
    return `${accountIdsKey}_${dateInterval ? dateInterval.toString() : 'default'}_${frequency || ''}_${filterCriteria ? JSON.stringify(filterCriteria) : ''}`;
  },
  cacheObject: new LruObjectCache({ cacheSize: 10 }),
});

export const getPeriodsAndAverage = createSelector(
  (_, props) => props.dateInterval,
  createValueCache((_, props) => props.filterCriteria),
  getSpendingForPeriods,
  (dateInterval, filterCriteria, spendingOverTime) => {
    const stringifiedFilter = JSON.stringify(filterCriteria);
    const stringifiedSOT = JSON.stringify(spendingOverTime);
    const cacheKey = getOrCreateStringFromObject(
      'planned-graph-periods',
      { dateInterval, stringifiedFilter, stringifiedSOT },
      6,
      memoizeWithKeyRetrieveCache,
    );

    return (memoizeWithKey(
      'planned-graph-periods',
      () => {
        const periods = [];
        spendingOverTime?.periods.map((period) => periods?.push({
          month: period.shortLabel,
          totalAmount: -1 * period.amount,
          totalAmountString: `$${(-1 * period.amount) >= 1000 ? ((-1 * period.amount) / 1000).toFixed(1) : (Math.round(-1 * period.amount))}${(-1 * period.amount) > 1000 ? 'k' : ''}`,
          currentAmount: -1 * period.amount,
        }));

        let sum = 0;
        let activeMonths = 0;
        periods.forEach((period, i) => {
          if (period.totalAmount) activeMonths += 1;
          const newAmount = period.totalAmount;
          sum += newAmount;
          periods[i].totalAmount = newAmount;
        });
        if (activeMonths < 1) activeMonths = 1;
        return ({ periods, average: Math.round(sum / activeMonths) });
      },
      cacheKey,
      6,
    ));
  },
);

/**
 * Get the combined balance of all savings accounts over past X months
 * */
export const getMonthlySavingsBalances = createCachedSelector(
  (state) => accountsSelectors.getLoadPending(state) || categoriesSelectors.getLoadPending(state) || getTransactionsLoadPending(state),
  accountsSelectors.getSavingAccounts,
  getTransactionsByAccountId,
  accountsSelectors.getAccountsById,
  getBalancesByAccountId,
  (_, props) => props.numMonths,
  (isLoadPending, accounts, txnsByAccountId, accountsById, balancesByAccountId, numMonths) => {
    if (isLoadPending) return null;

    const balanceObjects = accounts.keySeq().map((accountId) => balancesByAccountId.get(accountId));
    // check to make sure we have balance objects or just return balances of 0
    if (balanceObjects.size === 0) {
      return ImmutableMap({
        periods: createPastMonthPeriods({ balance: 0 }, numMonths),
        allTxns: ImmutableList(),
      });
    }
    let currentTotalBalance = 0;
    balanceObjects.forEach((bal) => {
      currentTotalBalance += bal.currentBalance;
    });

    // leave 'txns' as basic arrays so we can build them easily and convert them to an ImmutableList later, same with 'allTxns'
    const periods = createPastMonthPeriods({ balance: currentTotalBalance }, numMonths);
    const allTxns = [];

    const rangeStart = moment(periods.get(0).date).startOf('month');
    const rangeEnd = moment(periods.get(periods.size - 1).date).endOf('month');

    accounts.keySeq().forEach((accountId) => {
      if (txnsByAccountId.get(accountId)) {
        txnsByAccountId.get(accountId).valueSeq().forEach((txn) => {
          const txnMoment = moment(txn.postedOn);
          // filter txns that aren't in the range
          if (txnMoment.isBefore(rangeStart) || txnMoment.isAfter(rangeEnd)) return;

          // add it to allTxns
          allTxns.push(txn);

          // now go through the periods and adjust balances and add txns if it's in the month
          periods.forEach((period) => {
            if (txnMoment.isSameOrAfter(moment(period.date)) && txnMoment.isSameOrBefore(moment(), 'day')) {
              // eslint-disable-next-line no-param-reassign
              period.balance -= txn.amount;
            } else if (txn.isExcludedFromReports) { // if txn occcurred before but we're excluding it
              // eslint-disable-next-line no-param-reassign
              period.balance -= txn.amount;  // uncomment to account for ignoring txn
            }
            if (txnMoment.isSameOrAfter(moment(period.date).startOf('month')) && txnMoment.isSameOrBefore(moment(period.date).endOf('month'))) {
              period.mutableTxns.push(txn);
            }
          });
        });
      }
    });

    // convert all the txn lists to ImmutableLists
    periods.forEach((period) => {
      // eslint-disable-next-line no-param-reassign
      period.txns = ImmutableList(period.mutableTxns);
    });

    // round off all the balances
    periods.forEach((period) => {
      // eslint-disable-next-line no-param-reassign
      period.balance = period.balance.toFixed(2);
    });

    return ImmutableMap({ periods, allTxns: ImmutableList(allTxns) });
  },
)({
  // this line sets the key to use for the cache
  keySelector: (_, { dateRange }) => `$six-month-savings-${dateRange.toString()}`,
  cacheObject: new LruObjectCache({ cacheSize: 5 }),
});
