import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEvent, createGlobalState } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import { Map as ImmutableMap } from 'immutable';
import { DateTime } from 'luxon';

import { getEnvironmentConfig, getHostConfig, getLogger, localPreferences, tracker } from 'companion-app-components/utils/core';
import { datasetsActions, datasetsSelectors } from 'companion-app-components/flux/datasets';
import { authActions, authSelectors } from 'companion-app-components/flux/auth';
import { profileActions, profileSelectors } from 'companion-app-components/flux/profile';

import { useFetchOnce } from 'companion-app-components/hooks/utils';

import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import * as sessionStorageEx from 'utils/sessionStorageEx';

import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { DIALOG_TYPE as DIALOG_CONFIRMATION, mkConfirmationDialogProps } from 'components/Dialogs/ConfirmationDialog';
import StdDialog from 'components/Dialogs/StdDialog'; // eslint-disable-line import/no-named-as-default-member
import ZeroStateView from 'components/ZeroStateView';
import paperAirplaneCrash from 'assets/zero-state-images/paperAirplaneCrash.svg';
import LoadingView from 'components/LoadingView';

import { findFeaturePageByPath } from 'containers/App/paths';
import { getEntitlements } from 'data/entitlements/entitlementsActions';
import { getSubscriptions } from 'data/subscriptions/subscriptionsActions';
import * as subscriptionsSelectors from 'data/subscriptions/subscriptionsSelectors';
import * as entitlemetsSelectors from 'data/entitlements/entitlementsSelectors';
import { getSessionStartedAt } from 'data/app/selectors';

const logger = getLogger('components/Dialogs/AuthDialog');

export const useGlobalSuppressAuthDialog = createGlobalState(true);
export const useGlobalAuthPath = createGlobalState('/signin');

// TODO: remove legacySessionStateJSON value after users migrate to iframe auth
let legacySessionState;
try {
  const legacySessionStateJSON = localStorage.getItem('sessionState');
  if (legacySessionStateJSON) {
    legacySessionState = JSON.parse(legacySessionStateJSON);
  }
} catch (e) {
  assert(false, e);
}

const useStyles = makeStyles()((_theme) => ({
  paper: {
    '@media (max-width: 500px)': {
      margin: 2,
    },
  },
  contentRoot: {
    '@media (max-width: 500px)': {
      padding: 1,
    },
  },
  loadingView: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
}));

const AuthStates = Object.freeze({
  DIALOG_INACTIVE: 'DIALOG_INACTIVE',
  WIDGET_LOADING: 'WIDGET_LOADING',
  WIDGET_FAILED: 'WIDGET_FAILED',
  WIDGET_READY: 'WIDGET_READY',
  TOKEN_LOADING: 'TOKEN_LOADING',
  TOKEN_FAILED: 'TOKEN_FAILED',
});

const AuthDialog = React.memo((props) => {
  const { ...otherProps } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [suppressAuthDialog] = useGlobalSuppressAuthDialog();
  const [authPath] = useGlobalAuthPath();
  const isAuthTokenValid = useSelector(authSelectors.isAuthTokenValid);
  const { accessToken } = useSelector(authSelectors.getAuthSession);
  const isLoadingToken = useSelector(authSelectors.getIsLoading);
  const authError = useSelector(authSelectors.getError);
  const sessionStartedAt = useSelector(getSessionStartedAt);

  const location = useLocation();
  const pathname = location?.pathname;
  const authNotNeeded = useMemo(() => {
    const featurePage = findFeaturePageByPath(pathname);
    return featurePage ? featurePage.authNotNeeded : true;
  }, [pathname]);

  const [authState, setAuthState] = useState(AuthStates.DIALOG_INACTIVE);
  useEffect(() => {
    if (accessToken || authNotNeeded || suppressAuthDialog) {
      setAuthState(AuthStates.DIALOG_INACTIVE); // auth not required
    } else if (authState === AuthStates.DIALOG_INACTIVE) {
      setAuthState(AuthStates.WIDGET_LOADING); // load widget
    } else if (isLoadingToken) {
      setAuthState(AuthStates.TOKEN_LOADING); // show /token progress
    } else if (authError && authState === AuthStates.TOKEN_LOADING) {
      setAuthState(AuthStates.TOKEN_FAILED);
    }
  }, [accessToken, authNotNeeded, suppressAuthDialog, authError, authState, isLoadingToken]);

  useEffect(() => {
    logger.log(`new auth widget state: ${authState}`);
    tracker.track(tracker.events.authWidget, { status: authState });
  }, [authState]);

  const login = useSelector(profileSelectors.getProfile)?.username;
  const [sessionState, setSessionState] = useState(legacySessionState);
  const [sessionMismatch, setSessionMismatch] = useState(false);
  const authURL = useMemo(() => {
    if (!accessToken) {
      const newSessionState = uuidv4();
      setSessionState(newSessionState);
      logger.debug('new auth session state generated: ', newSessionState);

      const logoutReason = localPreferences.getLogoutReason();

      const sandbox = sessionStorageEx.getSandbox();
      const signinUrl = getEnvironmentConfig().signin_url;
      const url = new URL(authPath, sandbox ? 'https://signin-stg.simplifimoney.com' : signinUrl);
      // const url = new URL(authPath, getEnvironmentConfig().signin_url);
      url.searchParams.set('client_id', getEnvironmentConfig().client_id);
      url.searchParams.set('response_type', 'code');
      url.searchParams.set('redirect_uri', getHostConfig()?.redirect_uri);
      url.searchParams.set('sign-in-only', 1);
      if (login) {
        url.searchParams.set('sign-in-only', 1);
        url.searchParams.set('quicken-id', login);
        url.searchParams.set('lock-id', 1);
      }
      if (newSessionState) {
        url.searchParams.set('session_state', btoa(newSessionState));
      }
      if (sessionMismatch) {
        url.searchParams.set('session_mismatch', 1);
      }
      switch (logoutReason) {
        case 'AUTH_LOGOUT_EXPIRED':
          url.searchParams.set('expired', 1);
          break;
        case 'AUTH_LOGOUT_INACTIVE':
          url.searchParams.set('inactive', 1);
          break;
        default:
      }
      const keepLoggedIn = localPreferences.getKeepLoggedIn();
      if (keepLoggedIn) {
        url.searchParams.set('remember_me', 1);
      }
      logger.log('auth widget url: ', url.href);

      localPreferences.setLogoutReason(null);

      return url;
    }
    return null;
  }, [accessToken, sessionMismatch, login, authPath]);

  const eventHandler = useCallback((event) => {
    if (event.data?.type === 'auth' && event.origin === window.origin) {
      let sessionStateSelected = sessionState;
      if (!event.data?.isIframe && legacySessionState) { // TODO: remove after users migrate to iframe auth
        sessionStateSelected = legacySessionState;
        logger.debug('legacy auth session state accepted: ', legacySessionState);
        try {
          localStorage.removeItem('sessionState');
          sessionStorage.removeItem('sessionState');
        } catch (e) {
          assert(false, e);
        }
        legacySessionState = undefined;
      }
      logger.debug('auth session state local/remote: ', sessionStateSelected, event.data?.sessionState);
      if (assert(event.data?.sessionState === sessionStateSelected, 'local sessionState does not match remote one')) {
        dispatch(authActions.getOAuthToken(event.data.code, { props: { sessionState: sessionStateSelected } }));
      } else {
        setSessionMismatch(true);
      }
    }
  }, [dispatch, sessionState]);
  useEvent('message', eventHandler);

  useFetchOnce(profileActions.getProfile, profileSelectors, false, true);
  useFetchOnce(datasetsActions.getDatasets, datasetsSelectors, false, true);
  useFetchOnce(getEntitlements, entitlemetsSelectors, true, true);
  useFetchOnce(getSubscriptions, subscriptionsSelectors, false, true);

  const datasets = useSelector(datasetsSelectors.getActiveSortedDatasets);
  const datasetId = useSelector(authSelectors.getDatasetId);
  useEffect(() => {
    if (datasets.size && !datasetId && !authNotNeeded) {
      if (assert(datasetId !== null, 'invalid dataset was reset')) { // datasetId === null means it used to be a dataset, but it was invalid and reset
        dispatch(authActions.authSelectDatasetAuto({ reload: false, location: null }));
      }
    }
  }, [datasets, datasetId, authNotNeeded, dispatch]);

  // all tokens expired - continue or logout
  useEffect(() => {
    if (!authNotNeeded && accessToken && !isAuthTokenValid) {
      const sessionStarted = DateTime.fromISO(sessionStartedAt);
      if (sessionStarted.diffNow('minutes').minutes < -1) { // app used for a while - not a new session
        dispatch(createDialog(mkRootUiData({
          id: 'TOKENS_EXPIRED',
          type: DIALOG_CONFIRMATION,
          allowNesting: true,
          props: ImmutableMap(mkConfirmationDialogProps({
            title: 'Session expired',
            content: (
              <DialogContent>
                <Typography variant="subtitle1">
                  You have been logged out. You need to sign in to continue.
                </Typography>
              </DialogContent>
            ),
            confirmLabel: 'Sign in',
            confirmAction: () => {
              dispatch(authActions.authLogin(undefined, { context: 'user choose to re-auth' }));
            },
            denyLabel: 'Keep me logged out',
            denyAction: () => {
              dispatch(authActions.authLogout({ reason: 'AUTH_LOGOUT_EXPIRED' }, { context: 'auth dialog: logout manually' }));
            },
          })),
        })));
      } else { // logout automatically
        dispatch(authActions.authLogout({ reason: 'AUTH_LOGOUT_EXPIRED' }, { context: 'auth dialog: logout automatically' }));
      }
    // } else {
    //   dispatch(removeDialog('TOKENS_EXPIRED'));
    }
  }, [accessToken, isAuthTokenValid, authNotNeeded, sessionStartedAt, dispatch]);

  const dialogProps = useMemo(() => ({ classes: { paper: classes.paper } }), [classes.paper]);

  return (
    <StdDialog
      id="auth-dialog"
      open={authState !== AuthStates.DIALOG_INACTIVE}
      disableBackdropClick
      disableEscapeKeyDown
      showCloseButton={false}
      {...dialogProps}
      {...otherProps}
    >
      <DialogContent className={classes.contentRoot}>
        <Box display="flex" flexDirection="column" position="relative">

          {[AuthStates.WIDGET_LOADING, AuthStates.TOKEN_LOADING].includes(authState) && (
            <LoadingView
              className={classes.loadingView}
            />
          )}

          {[AuthStates.WIDGET_LOADING, AuthStates.WIDGET_READY].includes(authState) && (
            <iframe
              id="login_frame"
              style={{
                opacity: authState === AuthStates.WIDGET_READY ? 1 : 0,
                transition: 'all 500ms',
              }}
              title="auth"
              aria-hidden="true"
              width={375}
              height={699}
              seamless
              loading="lazy"
              src={authURL?.href}
              onLoad={(event) => {
                // const isLoaded = Boolean(event.target.contentWindow?.window?.length);
                const isLoaded = true; // relax rules temporary
                if (isLoaded) {
                  setAuthState(AuthStates.WIDGET_READY);
                  tracker.track(tracker.events.authWidget, {
                    status: 'WIDGET_SUCCESS',
                    is_loaded: event.target.contentWindow?.window?.length,
                  });
                } else {
                  setAuthState(AuthStates.WIDGET_FAILED);
                  tracker.track(tracker.events.authWidget, {
                    status: 'WIDGET_ERROR',
                    is_loaded: event.target.contentWindow?.window?.length,
                  });
                  assert(false, `auth widget load failed - ${event.target.contentWindow?.window?.length}`);
                }
              }}
            />
          )}

          {[AuthStates.WIDGET_FAILED, AuthStates.TOKEN_FAILED].includes(authState) && (
            <ZeroStateView
              in
              icon={paperAirplaneCrash}
              primary="Whoops... sorry about that!"
              secondary="Try to sign in again"
              button="Sign In"
              report
              onClick={() => setAuthState(AuthStates.WIDGET_LOADING)}
            />
          )}

        </Box>
      </DialogContent>
    </StdDialog>
  );
});

AuthDialog.propTypes = {
};

export default AuthDialog;
