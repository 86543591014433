import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import html2canvas from 'html2canvas';

import { undoActions, undoTypes } from 'companion-app-components/flux/undo';
import { tracker, platform, crashReporterInterface } from 'companion-app-components/utils/core';
import { authSelectors } from 'companion-app-components/flux/auth';
import { useFetchOnce } from 'companion-app-components/hooks/utils';

import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Collapse from '@mui/material/Collapse';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

import { sanitizeData } from 'utils/axiosFactory';
import { getSubscriptions } from 'data/subscriptions/subscriptionsActions';
import { clientType, client } from 'isAcme';
import { sendFeedback } from 'data/feedback/feedbackActions';
import { uploadData } from 'data/documents/actions';
import * as subscriptionsSelectors from 'data/subscriptions/subscriptionsSelectors';
import { copyState } from 'utils/storeUtils';
import StdDialog from 'components/Dialogs/StdDialog'; // eslint-disable-line import/no-named-as-default-member
import QButton from 'components/QButton';
import consoleMirror from 'utils/consoleMirror';
import { getNetworkLogs } from 'utils/networkLogs';
import { v4 as uuidv4 } from 'uuid';

export const DIALOG_TYPE_REPORT_ERROR = 'DIALOG_TYPE_REPORT_ERROR';

const SendReportDialog = React.memo((props) => {
  const { feedbackSubject, ...otherProps } = props;

  const [screenShotPNG, setScreenShotPNG] = useState();
  const [screenShotBlob, setScreenShotBlob] = useState();

  useEffect(() => {
    html2canvas(document.body)
      .then((canvas) => {
        canvas.toBlob((blob) => setScreenShotBlob(blob), 'mage/png');
        setScreenShotPNG(canvas.toDataURL('image/png'));
      })
      .catch((error) => assert(false, error));
  }, []);

  const dispatch = useDispatch();
  const [attachLocalStorage, setAttachLocalStorage] = useState(true);
  const [attachSessionStorage, setAttachSessionStorage] = useState(true);
  const [attachScreenshot, setAttachScreenshot] = useState(true);
  const [attachConsoleLogs, setattachConsoleLogs] = useState(true);
  const [attachNetworkLogs, setattachNetworkLogs] = useState(true);
  const [attachFile, setAttachFile] = useState(true);
  const [sanitizeFile, setSanitizeFile] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState();
  const [message, setMessage] = useState('');

  const dataset = useSelector(authSelectors.getCurrentDataset);
  const authSession = useSelector(authSelectors.getAuthSession);
  useFetchOnce(getSubscriptions, subscriptionsSelectors);
  const subscription = useSelector(subscriptionsSelectors.getActiveSubscription);
  const stdDialogRef = useRef();

  return (
    <StdDialog
      stdDialogRef={stdDialogRef}
      title="Send Feedback"
      sharedcomponentid={'DIALOG_TYPE_REPORT_ERROR'}
      {...otherProps}
    >
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Typography>
            {'Send us the issue you’re experiencing. We will not respond to this feedback directly. '
            + 'This information is used for statistical and investigative purposes only. '
            + 'Have questions? Try '}<Link id="intercom-launcher">help or support</Link>.
          </Typography>
          <br />
          <TextField
            autoFocus
            id="message"
            label="Describe your issue"
            placeholder="Please describe the issue you are experiencing"
            multiline
            rows={4}
            maxRows={4}
            variant="outlined"
            onChange={(event) => setMessage(event.currentTarget.value)}
            inputProps={{ maxLength: 255 }}
            helperText={`You can enter up to 255 characters (${255 - message.length} remaining).`}
          />

          <br />

          <Box display="flex">

            <Box display="flex" flexDirection="column" m={2} minWidth={250} flexShrink={0}>
              <Typography color="textSecondary">
                What to include
              </Typography>
              <FormControlLabel
                value="local-storage"
                control={<Checkbox color="primary" />}
                label="Local Storage"
                labelPlacement="end"
                checked={attachLocalStorage}
                onChange={(event, value) => setAttachLocalStorage(value)}
              />

              <FormControlLabel
                value="session-storage"
                control={<Checkbox color="primary" />}
                label="Session Storage"
                labelPlacement="end"
                checked={attachSessionStorage}
                onChange={(event, value) => setAttachSessionStorage(value)}
              />

              <FormControlLabel
                value="console-logs"
                control={<Checkbox color="primary" />}
                label="Console Logs"
                labelPlacement="end"
                checked={attachConsoleLogs}
                onChange={(event, value) => setattachConsoleLogs(value)}
              />

              <FormControlLabel
                value="network-logs"
                control={<Checkbox color="primary" />}
                label="Network logs"
                labelPlacement="end"
                checked={attachNetworkLogs}
                onChange={(event, value) => setattachNetworkLogs(value)}
              />

              <FormControlLabel
                value="file"
                control={<Checkbox color="primary" />}
                label={
                  <>
                    {client} file&nbsp;
                    <Tooltip
                      arrow
                      placement="right"
                      title={
                        <>
                          <p>
                            Your local {client} file could include all your personal data including payees, accounts
                            names, transactions, etc.
                          </p>
                          <p>
                            <b>Send as is:</b><br />
                            We will receive your file with nothing hidden.
                          </p>
                          <p>
                            <b>Sanitize before sending:</b><br />
                            All your personal data will be hidden/un-readable before it is sent to us.
                          </p>
                        </>
                      }
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                  </>
                }
                labelPlacement="end"
                checked={attachFile}
                onChange={(event, value) => setAttachFile(value)}
              />
              <Collapse in={attachFile} mountOnEnter unmountOnExit>
                <Box paddingLeft={4}>
                  <RadioGroup
                    aria-label="sanitize"
                    name="sanitize"
                    value={sanitizeFile ? 'sanitize' : 'as-is'}
                    onChange={(event, value) => setSanitizeFile(value === 'sanitize')}
                  >
                    <FormControlLabel
                      disabled={!attachFile}
                      value="as-is"
                      control={<Radio color="primary" />}
                      label="Send as is"
                    />
                    <FormControlLabel
                      disabled={!attachFile}
                      value="sanitize"
                      control={<Radio color="primary" />}
                      label="Sanitize before sending"
                    />
                  </RadioGroup>
                </Box>
              </Collapse>

            </Box>

            <Box display="flex" flexDirection="column" p={2}>
              <Typography color="textSecondary">
                Screenshots
              </Typography>
              <FormControlLabel
                value="screenshot"
                control={<Checkbox color="primary" />}
                label="Include Screenshot"
                labelPlacement="end"
                checked={attachScreenshot}
                onChange={(event, value) => setAttachScreenshot(value)}
              />
              <Collapse in={attachScreenshot} mountOnEnter unmountOnExit>
                <Paper variant="outlined">
                  <img
                    alt="Capturing screenshot..."
                    height={240}
                    src={screenShotPNG}
                  />
                </Paper>
              </Collapse>
            </Box>

          </Box>

        </Box>
      </DialogContent>

      <DialogActions>
        <QButton
          variant="outlined"
          onClick={() => stdDialogRef.current?.closeDialog?.()}
        >
          Cancel
        </QButton>

        <QButton
          id="refund-track-submit-button"
          variant="contained"
          disabled={submitDisabled}
          onClick={() => {
            setSubmitDisabled(true);
            const fileName = `${dataset?.name || 'no-dataset'} [${DateTime.local().toISODate()}].json`;
            const consoleLogsName = 'console logs.txt';
            const networkLogsName = 'network logs.txt';
            const screenshotName = 'screenshot.png';
            const localStorageName = 'localStorage.json';
            const sessionStorageName = 'sessionStorage.json';

            new Promise((resolve, reject) => {
              dispatch(sendFeedback({
                subject: feedbackSubject,
                message,
                ownerId: authSession?.qcsId,
                category: feedbackSubject,
                clientType,
                os: `${platform.os.name} ${platform.os.version}`,
                buildNumber: `${process.env.APP_VERSION} (${process.env.BUILD_NUMBER})`,
                deviceType: platform.device.type,
                tier: subscription?.tierName,
                usage: window?.location?.href?.substr(0, 100), // usage 100 chars max
                attachments: [
                  ...(!attachLocalStorage ? [] : [{
                    mimeType: 'application/json',
                    name: localStorageName,
                  }]),
                  ...(!attachSessionStorage ? [] : [{
                    mimeType: 'application/json',
                    name: sessionStorageName,
                  }]),
                  ...(!attachFile ? [] : [{
                    mimeType: 'application/json',
                    name: fileName,
                  }]),
                  ...(!attachConsoleLogs ? [] : [{
                    mimeType: 'text/plain',
                    name: consoleLogsName,
                  }]),
                  ...(!attachNetworkLogs ? [] : [{
                    mimeType: 'text/plain',
                    name: networkLogsName,
                  }]),
                  ...(!attachScreenshot ? [] : [{
                    mimeType: 'image/png',
                    name: screenshotName,
                  }]),
                ],
              }, {
                resolve,
                reject,
                axiosConfig: {
                  headers: {
                    ...(authSession?.datasetId && { 'qcs-dataset-id': authSession?.datasetId }),
                  },
                },
              }));
            })
              .then((feedbackResult) => {

                dispatch(undoActions.addUndo(undoTypes.mkUndo({ userMessage: `report created - reference id ${feedbackResult?.data?.id}`, key: uuidv4() })));

                // feedback UI is not usable, so send a copy to mixpanel and bugsnag
                tracker.track(tracker.events.feedback, {
                  feedbackId: feedbackResult?.data?.id,
                  subject: feedbackSubject,
                  message,
                  category: feedbackSubject,
                  usage: window?.location?.href?.substr(0, 100), // usage 100 chars max
                });

                const feedbackError = new Error(message);
                feedbackError.name = 'Feedback';
                crashReporterInterface.reportError(feedbackError, (event) => {
                  event.context = feedbackSubject; // eslint-disable-line no-param-reassign
                  event.addMetadata('custom', {
                    feedbackId: feedbackResult?.data?.id,
                    subject: feedbackSubject,
                    message,
                    category: feedbackSubject,
                  });
                });

                if (attachLocalStorage) {
                  const attachment = feedbackResult?.data?.attachments?.find((anAttachment) => anAttachment.name === localStorageName);
                  const data = JSON.stringify(localStorage, null, 2);
                  if (assert(attachment?.uploadUri && data, 'can not upload local storage file attachment')) {
                    new Promise((resolve, reject) =>
                      // eslint-disable-next-line no-promise-executor-return
                      dispatch(uploadData({
                        url: attachment.uploadUri,
                        data,
                        contentType: data.type || 'application/json',
                      }, { resolve, reject })))
                      .then((_fileUploadResult) => {
                        // debugger;
                        // assert(false, 'file uploaded successfully')
                      })
                      .catch((error) => assert(false, error));
                  }
                }

                if (attachSessionStorage) {
                  const attachment = feedbackResult?.data?.attachments?.find((anAttachment) => anAttachment.name === sessionStorageName);
                  const dataObj = Object.fromEntries(Object.entries(sessionStorage).map(([key, value]) => {
                    let parsedValue = value;
                    try { parsedValue = JSON.parse(value); } catch (_error) { /* do nothing */ }
                    return [key, parsedValue];
                  }));
                  if (dataObj.auth_session?.accessToken) {
                    dataObj.auth_session.accessToken = '***';
                  }
                  if (dataObj.auth_session?.refreshToken) {
                    dataObj.auth_session.refreshToken = '***';
                  }
                  const data = JSON.stringify(dataObj, null, 2);
                  if (assert(attachment?.uploadUri && data, 'can not upload session storage file attachment')) {
                    new Promise((resolve, reject) =>
                      // eslint-disable-next-line no-promise-executor-return
                      dispatch(uploadData({
                        url: attachment.uploadUri,
                        data,
                        contentType: data.type || 'application/json',
                      }, { resolve, reject })))
                      .then((_fileUploadResult) => {
                        // debugger;
                        // assert(false, 'file uploaded successfully')
                      })
                      .catch((error) => assert(false, error));
                  }
                }

                if (attachFile) {
                  const attachment = feedbackResult?.data?.attachments?.find((anAttachment) => anAttachment.name === fileName);
                  const data = copyState(undefined, sanitizeFile);
                  if (assert(attachment?.uploadUri && data, 'can not upload feedback file attachment')) {
                    new Promise((resolve, reject) =>
                      // eslint-disable-next-line no-promise-executor-return
                      dispatch(uploadData({
                        url: attachment.uploadUri,
                        data,
                        contentType: data.type || 'application/json',
                      }, { resolve, reject })))
                      .then((_fileUploadResult) => {
                        // debugger;
                        // assert(false, 'file uploaded successfully')
                      })
                      .catch((error) => assert(false, error));
                  }
                }

                if (attachConsoleLogs) {
                  const attachment = feedbackResult?.data?.attachments?.find((anAttachment) => anAttachment.name === consoleLogsName);
                  const consoleLogs = consoleMirror.log.join('\n');
                  const data = new Blob([consoleLogs], { type: 'text/plain' });
                  if (assert(attachment?.uploadUri && data, 'can not upload feedback file attachment')) {
                    new Promise((resolve, reject) =>
                      // eslint-disable-next-line no-promise-executor-return
                      dispatch(uploadData({
                        url: attachment.uploadUri,
                        data,
                        contentType: data.type || 'text/plain',
                      }, { resolve, reject })))
                      .then((_fileUploadResult) => {
                        // debugger;
                        // assert(false, 'console logs uploaded successfully')
                      })
                      .catch((error) => assert(false, error));
                  }
                }

                if (attachScreenshot) {
                  const attachment = feedbackResult?.data?.attachments?.find((anAttachment) => anAttachment.name === screenshotName);
                  const data = screenShotBlob;
                  if (assert(attachment?.uploadUri && data, 'can not upload feedback screenshot attachment')) {
                    new Promise((resolve, reject) =>
                      // eslint-disable-next-line no-promise-executor-return
                      dispatch(uploadData({
                        url: attachment.uploadUri,
                        data,
                        contentType: data.type || 'image/png',
                      }, { resolve, reject })))
                      .then((_fileUploadResult) => {
                        // debugger;
                        // assert(false, 'network logs uploaded successfully')
                      })
                      .catch((error) => assert(false, error));
                  }
                }

                if (attachNetworkLogs) {
                  const attachment = feedbackResult?.data?.attachments?.find((anAttachment) => anAttachment.name === networkLogsName);
                  const networkLogs = getNetworkLogs();
                  const networkLogsString = JSON.stringify(networkLogs, null, 2);
                  const networkLogsStringSanitized = sanitizeData(networkLogsString);
                  const data = new Blob([networkLogsStringSanitized], { type: 'text/plain' });
                  if (assert(attachment?.uploadUri && data, 'can not upload feedback network logs attachment')) {
                    new Promise((resolve, reject) =>
                      // eslint-disable-next-line no-promise-executor-return
                      dispatch(uploadData({
                        url: attachment.uploadUri,
                        data,
                        contentType: data.type || 'text/plain',
                      }, { resolve, reject })))
                      .then((_fileUploadResult) => {
                        // debugger;
                        // assert(false, 'network logs uploaded successfully')
                      })
                      .catch((error) => assert(false, error));
                  }
                }
              })
              .catch((error) => assert(false, error));

            stdDialogRef.current?.closeDialog?.();
          }}
        >
          Send
        </QButton>
      </DialogActions>
    </StdDialog>
  );
});

SendReportDialog.propTypes = {
  transaction: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool, // set manually for controlled dialog
  dialogId: PropTypes.string, // set automatically for uncontrolled dialog
  feedbackSubject: PropTypes.string,
};

SendReportDialog.whyDidYouRender = true;

export default SendReportDialog;
