
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import compose from 'utils/compose';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import QButton from 'components/QButton';
import QIconButton from 'components/QIconButton';
import CloseIcon from '@mui/icons-material/Close';

import { POINTER_SIZE, styles } from './styles';

const REFRESH_INTERVAL = 250;
const REFRESH_INTERVAL_SMALL = 100;

class QCard extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      currentCard: props.initialCard,
      activeElement: null,
      open: props.open,
      qCardCoords: {},
    };
    this.intervalTimer = null;
    this.intervalTimeout = REFRESH_INTERVAL;

    if (props.open && props.cards) {
      this.intervalTimer = setInterval(this.checkCardPosition, this.intervalTimeout);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (nextProps.cards) {

      if ((nextProps.initialCard !== this.props.initialCard) ||
        nextProps.cards !== this.props.cards) {
        if (nextProps.cards) {
          this.setState({ currentCard: nextProps.initialCard });
        }
      }

      if (nextProps.open !== this.state.open) {

        if (!nextProps.open && this.intervalTimer) {
          clearInterval(this.intervalTimer);
          this.intervalTimer = null;
        }

        if (nextProps.open && !this.intervalTimer) {
          this.intervalTimer = setInterval(this.checkCardPosition, this.intervalTimeout);
        }

        if (nextProps.open) {
          this.setState({ currentCard: nextProps.initialCard });
        }
        if (!nextProps.open && this.state.activeElement) {
          this.state.activeElement.classList.remove(this.props.classes.highlight);
          this.state.activeElement = null;
        }
        this.setState({ open: nextProps.open });
      }
    } else if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
      this.intervalTimer = null;
    }
  }

  componentDidUpdate() {
    if (this.props.cards) {
      setTimeout(this.checkCardUpdate, 10);
    }
  }

  componentWillUnmount() {
    if (this.state.activeElement) {
      this.state.activeElement.classList.remove(this.props.classes.highlight);
    }
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
      this.intervalTimer = null;
    }
  }

  checkCardUpdate = () => {

    if (this.props.cards) {
      const qCard = this.props.cards[this.state.currentCard];
      if (qCard && this.state.open) {
        const activeElement = document.getElementById(qCard.elementId);
        if ((activeElement !== this.state.activeElement)) {
          this.setActiveElement(activeElement);
          if (!activeElement) {
            if (qCard.next) {
              this.setState({ currentCard: qCard.next });
            } else {
              this.props.onClose && this.props.onClose();
            }
          }
        }
      }
    }
  };

  checkCardPosition = () => {
    this.setQCardCoords();
  };

  setActiveElement = (el) => {

    const { classes } = this.props;

    if (this.state.activeElement) {
      this.state.activeElement.classList.remove(classes.highlight);
    }
    if (el) {
      el.classList.add(classes.highlight);
    }
    this.setState({ activeElement: el }, this.setQCardCoords);
  };

  calcCoords = (edge, qCard, coords) => {

    const pointerSize = POINTER_SIZE;

    let { left, top } = coords;

    // we try to place the qCard at it's preferred location
    switch (edge) {

      case 'left':
        left = coords.left - qCard.width - 8;
        top = coords.top + ((coords.bottom - coords.top) / 2 - qCard.height / 2);
        break;
      case 'right':
        left = coords.right + 8;
        top = coords.top + ((coords.bottom - coords.top) / 2 - qCard.height / 2);
        break;
      case 'righttop':
        left = coords.right + 8;
        top = coords.top;
        break;
      case 'rightbottom':
        left = coords.right + 8;
        top = coords.bottom - qCard.height + pointerSize * 1.5;
        break;
      case 'lefttop':
        left = coords.left - qCard.width - 8;
        top = coords.top;
        break;
      case 'leftbottom':
        left = coords.left - qCard.width - 8;
        top = coords.bottom - qCard.height + pointerSize * 1.5;
        break;
      case 'top':
        break;
      case 'bottom':
        break;

      default:
        break;
    }

    if (qCard.nudge) {
      left += qCard.nudge.left || 0;
      top += qCard.nudge.top || 0;
    }

    // don't let the QCard go off the left edge of the screen
    if (left < 0) left = 0;

    // Avoid the Qcard to go off the screen
    const maxTopAllowed = window.innerHeight - qCard.height;
    if (top < 0) {
      top = 0;
    } else if (top > maxTopAllowed) {
      top = maxTopAllowed;
    }

    return { left, top, right: left + qCard.width, bottom: top + qCard.height };
  };

  setQCardCoords = () => {
    const { qCardCoords, currentCard, activeElement } = this.state;
    const { cards } = this.props;
    if (cards) {
      const qCard = this.props.cards[currentCard];
      const newCoords = this.getQCardCoords(activeElement, qCard);

      if (qCardCoords?.top !== newCoords?.top || qCardCoords?.left !== newCoords?.left) {
        this.setState({ qCardCoords: newCoords });
      }
    }

    return qCardCoords;
  };

  getQCardCoords = (el, qCard) => {

    if (!qCard) {
      return null;
    }
    let edge = qCard.edge || 'left';

    if (el) {
      const coords = el.getBoundingClientRect();

      let coordsObj = this.calcCoords(edge, qCard, coords);

      if (coordsObj.left < 0) {
        edge = edge.replace('left', 'right');
        coordsObj = this.calcCoords(edge, qCard, coords);
      } else if (coordsObj.right > window.innerWidth) {
        edge = edge.replace('right', 'left');
        coordsObj = this.calcCoords(edge, qCard, coords);
      }

      return { ...coords, ...coordsObj, edge };

    }
    return null;
  };

  moveCard = (direction, qCard) => {
    const nextCard = qCard[direction];

    if (nextCard) {
      const nextCardData = this.props.cards[nextCard];
      const activeElement = document.getElementById(nextCardData.elementId);

      if (nextCardData?.scrollIntoView) {
        this.setMovingCard(activeElement, nextCard);
        this.intervalTimeout = REFRESH_INTERVAL_SMALL;
        activeElement.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'auto' });
        this.setQCardCoords();
        return;
      }
      this.setMovingCard(activeElement, nextCard);
    }
  };

  setMovingCard = (activeElement, currentCard) => {
    this.setActiveElement(activeElement);
    this.setState({ currentCard }, this.setQCardCoords);
  };

  closeClick = () => {
    // remove highlighting
    // if (this.state.activeElement) {
    //   this.state.activeElement.classList.remove(this.props.classes.highlight);
    // }
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { children, classes, cards, onClose, name } = this.props;
    const { open, qCardCoords } = this.state;
    let coords = { ...qCardCoords };

    if (Object.keys(coords).length === 0) {
      coords = this.setQCardCoords();
    }


    // do nothing if there are no cards to show
    if (!cards) {
      return <div id="QCard-Wrapper-off"> {children} </div>;
    }

    const qCard = cards[this.state.currentCard];
    const showQCard = Boolean(open && Object.keys(coords ?? {}).length && qCard);

    return (
      <div
        id={`QCard-Wrapper-${name || 'default'}-${showQCard ? 'showCard' : 'off'}`}
        className={classNames({ [classes.wrapperOff]: !showQCard })}
      >
        {showQCard &&
          <div
            key="qcard-template"
            className={classNames(classes.qcard, qCardCoords.edge)}
            style={{
              left: coords?.left,
              top: coords?.top,
              width: qCard.width,
              height: qCard.height,
            }}
          >
            {onClose &&
              <div className={classes.closeBox}>
                <QIconButton
                  size="small-target"
                  onClick={this.closeClick}
                >
                  <CloseIcon className={classes.closeIcon} id="qcard-closex" />
                </QIconButton>
              </div>}
            <div className={classes.contentArea}>
              <Typography variant="h6" className={classes.title}>
                {cards[this.state.currentCard].title}
              </Typography>
              <Typography variant="body1" className={classes.content}>
                {cards[this.state.currentCard].content}
              </Typography>
            </div>
            <div className={classes.buttonArea}>
              {qCard.prev &&
                <QButton
                  onClick={() => this.moveCard('prev', qCard)}
                  className={classes.buttonText}
                >
                  {'< Prev'}
                </QButton>}
              {qCard.next &&
                <QButton
                  onClick={() => this.moveCard('next', qCard)}
                  className={classes.buttonText}
                >
                  {'Next >'}
                </QButton>}
            </div>
          </div>}
        {children}
      </div>
    );
  }
}

function mapStateToProps(_state) {
  return {
  };
}

function mapDispatchToProps() {
  return {
  };
}

QCard.propTypes = {
  open: PropTypes.bool,
  cards: PropTypes.object,
  initialCard: PropTypes.string,
  onClose: PropTypes.func,
  name: PropTypes.string,

  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),

  classes: PropTypes.object,
};

QCard.whyDidYouRender = true;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(withStyles(QCard, styles));
