import { handleActions } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';

import { resourceUpserters, resourceStoreTypes } from 'companion-app-components/flux/core';
import { undoActions, undoTypes } from 'companion-app-components/flux/undo';

import * as actions from './preferencesV2Actions';

const preferencesV2Reducer = handleActions({

  [actions.getPreferencesV2]:
    (state) => state.merge({ isLoading: true }),
  [actions.getPreferencesV2Success]:
    (state, { payload }) => resourceUpserters.upsertResources(state, payload),
  [actions.getPreferencesV2Failure]:
    (state, { payload: error }) => resourceUpserters.completeWithError(state, error),

  [actions.updatePreferenceCriteriaSuccess]:
    (state, { meta, asyncDispatch }) => {
      const undo = undoTypes.mkUndo({
        userMessage: meta?.undo?.userMessage,
        key: uuidv4(),
      });
      asyncDispatch(undoActions.addUndo(undo));
      asyncDispatch(actions.getPreferencesV2());
      return state;
    },

}, resourceStoreTypes.mkQcsSyncResourceStore());

export const PREFERENCES_V2_REDUCER_KEY = 'preferencesV2Store';
export default preferencesV2Reducer;
