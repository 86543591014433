import { takeLatest, put, select } from 'redux-saga/effects';
import { Map as ImmutableMap } from 'immutable';

import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { transactionsActions } from 'companion-app-components/flux/transactions';

import { getTransactionsByAccountId } from 'data/transactions/selectors';
import { getFieldString } from 'data/transactions/searchFilter';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { mkPayee } from './types';
import * as actions from './actions';


export function buildPayeeList(accountsById, transactionsByAccountId, resources) {

  let changedAccounts;
  if (resources) {
    changedAccounts = resources.map((x) => x.accountId);
    changedAccounts = changedAccounts.filter((item, pos) => changedAccounts.indexOf(item) === pos);
  } else {
    changedAccounts = accountsById.map((x) => x.id);
  }

  let payeeListById = ImmutableMap();

  // now for each account changed, rebuild the payee list
  changedAccounts.forEach((accountId) => {

    const acct = accountsById.get(accountId);
    if (acct && acct.type !== 'LOAN') {
      let payeeList = ImmutableMap();
      const txns = transactionsByAccountId.get(accountId);
      txns && txns.forEach((txn) => {
        if (!txn.isDeleted && txn.payee?.length > 0) {

          const payeeObject = {
            id: uuidv4(),
            name: txn.payee,
            amountString: getFieldString('amount', txn),
            catString: getFieldString('category', txn),
            tagString: getFieldString('tags', txn),
            txn,
            count: 1,
          };

          const key = txn.payee ? txn.payee?.toLowerCase() : '';
          const prevPayee = payeeList.get(key);
          if (prevPayee) {
            if (moment(prevPayee.txn.postedOn).isBefore(moment(txn.postedOn))) {
              payeeList = payeeList.set(key, mkPayee({ ...payeeObject, count: payeeObject.count + 1 }));
            }
          } else {
            payeeList = payeeList.set(key, mkPayee(payeeObject));
          }
        }
      });
      if (payeeList.size > 0) {
        payeeListById = payeeListById.set(accountId, payeeList);
      }
    }
  });

  return payeeListById;

}

export function* createPayeeList() {
  const transactionsByAccountId = yield select(getTransactionsByAccountId);
  const accountsById = yield select(accountsSelectors.getAccountsById);
  const payeeListById = buildPayeeList(accountsById, transactionsByAccountId, null);

  yield put(actions.updatePayeeListSuccess(payeeListById));

}

export function* updatePayeeList(data) {

  if (data && data.payload && data.payload.resources) {

    const transactionsByAccountId = yield select(getTransactionsByAccountId);
    const accountsById = yield select(accountsSelectors.getAccountsById);
    const payeeListById = buildPayeeList(accountsById, transactionsByAccountId, data.payload.resources);

    yield put(actions.updatePayeeListSuccess(payeeListById));

  }
}

export function* triggerPayeeListUpdate(data) {
  let payload = { resources: [data.payload] };
  if (data.type === transactionsActions.getTransactionsSuccess().type) {
    payload = data.payload;
  } else if (data.type === transactionsActions.batchTransactions().type) {
    payload = { resources: data.payload };
  }

  yield put(actions.updatePayeeListAsync(payload));
}

export function* updatePayeeListActionWatcher() {
  yield takeLatest(actions.updatePayeeList, updatePayeeList);
}
export function* createPayeeListActionWatcher() {
  yield takeLatest(actions.createPayeeList, createPayeeList);
}

export function* transactionChangeActionWatcher() {
  yield takeLatest([
    transactionsActions.batchTransactions,
    transactionsActions.createTransaction,
    transactionsActions.updateTransaction,
    transactionsActions.deleteTransaction,
    transactionsActions.getTransactionsSuccess,
  ], triggerPayeeListUpdate);

}

// ====================================================
// EXPORTS

export default [
  updatePayeeListActionWatcher,
  transactionChangeActionWatcher,
];
