import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Routes, Route, Redirect } from 'react-router-dom';
import withRouterLegacy from 'components/withRouterLegacy';
import memoizeOne from 'memoize-one';
import IdleTimer from 'react-idle-timer';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import * as am4core from '@amcharts/amcharts4/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { datasetsSelectors } from 'companion-app-components/flux/datasets';
import { profileSelectors } from 'companion-app-components/flux/profile';
import { authSelectors } from 'companion-app-components/flux/auth';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { accountsSelectors } from 'companion-app-components/flux/accounts';
import * as preferencesActions from 'companion-app-components/flux/preferences/actions';
import * as preferencesSelectors from 'companion-app-components/flux/preferences/selectors';
import {
  postponedActionsActions,
  postponedActionsSelectors,
} from 'companion-app-components/flux/postponed-actions';
import { getLogger, platform, tracker, localPreferences } from 'companion-app-components/utils/core';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { activateUpdatedApp, register as swRegister, unregister as swUnregister } from 'swMain';
import { initPushNotificationsIfAccepted } from 'swPushNotifications';

import AccountDrawer from 'components/AccountDrawer';
import ErrorBoundary from 'components/ErrorBoundary';
import MobileDirectDialog from 'components/MobileDirectDialog';
import QCard from 'components/QCard';
import ResolveDatasetDialog from 'components/ResolveDatasetDialog';
import RootModal from 'components/RootModal';
import SetupStatusBar from 'components/SetupStatusBar';
import BumpConnector from 'components/BumpConnector';
import BrowserMinVersion from 'components/BrowserMinVersion';
import AuthDialog from 'components/Dialogs/AuthDialog';
import StorageAccessChecker from 'components/StorageAccessChecker';

import { ActionsSnackBar } from 'containers/App/ActionsSnackBar';
import { ErrorHandler } from 'containers/App/ErrorHandler';
import OfflinePage from 'containers/OfflinePage';
import SetupPage from 'containers/SetupPage';
import UnauthenticatedHomePage from 'containers/UnauthenticatedHomePage/loadable';

import { appRefreshAll } from 'data/app/actions';
import {
  getIsOffline,
  getServiceWorkerIsRegistered,
  getServiceWorkerIsRegistering,
  getServiceWorkerUpdateIsAvailable,
} from 'data/app/selectors';
import * as entitlementSelectors from 'data/entitlements/entitlementsSelectors';
import { isAuthenticatedOrElse, isAuthenticatedWithDatasetOrElse } from 'utils/auth';
import Cobrowse from 'utils/cobrowse';
import Intercom from 'utils/intercom';
import Mixpanel from 'utils/mixpanel';
import PaperAirplane from 'assets/zero-state-images/paperAirplaneCrash.svg';
import Bugsnag from 'utils/bugsnag';
import ClientConfig from 'utils/ClientConfig';
import { getQueryByName } from 'utils/queryUtils';

import { getCurrentTheme } from 'themes/themesSelectors';

import { featurePages, findFeaturePageByPath } from './paths';
import type { FeaturePage } from './paths';
import Header from './Header';
import ShowPwaPrompts from './ShowPwaPrompts';
import Main from './Main';
import Notifications from './Notifications';
import UsersnapLoader from './UsersnapLoader';
import InvestmentCategoriesInit from './InvestmentCategoriesInit';

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
import DevMenuOptional from './DevMenu';

/////////

let DevMenu = null;

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
DevMenu = DevMenuOptional;
/////////

const isIframe = window !== window.top;

// set the idle timeout to 5 mins before auto-updating
const autoUpdateIdleTimeout = 1000 * 60 * 5;

const wrappedComponents = new Map(featurePages.map((x) => {
  if (x.authNotNeeded) {
    return [x.path, x.component];
  }
  if (x.datasetNotNeeded) {
    return [x.path, (isAuthenticatedOrElse(x.component, UnauthenticatedHomePage))];
  }
  return [x.path, (isAuthenticatedOrElse(
    isAuthenticatedWithDatasetOrElse(x.component, SetupPage),
    UnauthenticatedHomePage,
  )),
  ];
}));

const memoizedPageFind = memoizeOne(findFeaturePageByPath);

const log = getLogger('App');

const ShowUpdate = Object.freeze({
  SCHEDULED: 'SCHEDULED',
  SHOW: 'SHOW',
  DISMISSED: 'DISMISSED',
});

export class App extends React.PureComponent {

  // we don't want to setup new routes every time when App re-renders
  cachedSwitch = memoizeOne((isAuthenticated, resolveDatasetsNeeded, _featureFlags) => (
    <Routes>
      {this.props?.isWebFirstDataset === false && (
        <Route 
          path="/getting-started-webfirst" 
          render={() => <Redirect to="/" />} 
        />
      )}
      {featurePages
        .filter((page) => !(isAuthenticated && this.isFeatureDisabled(page)))
        .map((page) => {
          const Page = resolveDatasetsNeeded ? ResolveDatasetDialog : wrappedComponents.get(page.path);
          // console.log('paths', [page.path, ...page.pathParams.map((p) => `${page.path}${p}`)][-1] || page.path);
          return (
            <Route
              exact={page.exact}
              key={page.path}
              path={`${page.path}${page.pathParams}` || page.path}
              element={<Page />}
            />
          );
        })}
    </Routes>
  ));

  constructor(props) {
    super(props);

    const loadTime = performance.now();
    log.log(`Page load time is ${loadTime}ms`);
    tracker.track(tracker.events.load, { ...performance?.timing, $duration: loadTime / 1000 });

    this.state = {
      showUpdatedServiceWorkerIsAvailable: this.handleShowUpdate(),
      isAccountDrawerOpen: props.accountDrawerDefaultOpen, // selFeatureObj && selFeatureObj.open,
      selectedAccountNodes: {
        '/transactions': 'all',
        '/investments': 'INVESTMENT',
      },
      selectedFeature: props.router.location.pathname,
      showFailedActions: false,
      dimAccountList: false,
      numAccounts: 1,  // assume there are accounts
      qCardsOffByDefault: props.qCardsOffByDefault ? props.qCardsOffByDefault : '{}',
      refreshPending: true,
    };

    this.preserveDisplayNode(props, true);
    this.trackPageView(props.router.location.pathname);
  }

  componentDidMount() {
    if (this.state.refreshPending && this.props.isAuthenticatedWithDatasetId) {
      this.props.dispatchAppRefreshAllAction({ context: 'init' });
      this.setState({ refreshPending: false });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.router.location?.pathname !== nextProps.router.location?.pathname) {
      this.trackPageView(nextProps.router.location?.pathname);
    }
    if ((nextProps.router.location?.pathname !== this.state.selectedFeature)
      || (nextProps.router.location?.search !== this.state.search)) {
      this.preserveDisplayNode(nextProps);
      this.setState({ selectedFeature: nextProps.router.location?.pathname });
    }
    if (nextProps.accountDrawerDefaultOpen !== this.props.accountDrawerDefaultOpen) {
      this.setState({ isAccountDrawerOpen: nextProps.accountDrawerDefaultOpen });
    }
    if (nextProps.qCardsOffByDefault && (nextProps.qCardsOffByDefault !== this.state.qCardsOffByDefault)) {
      this.setState({ qCardsOffByDefault: nextProps.qCardsOffByDefault });
    }
    if (nextProps.accountsById.size !== this.state.numAccounts) {
      this.setState({ numAccounts: nextProps.accountsById.size });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      serviceWorkerEnable,
      serviceWorkerIsRegistered,
      serviceWorkerIsRegistering,
      serviceWorkerUpdateAvailable,
      serviceWorkerAutoUpdateMethod,
      serviceWorkerUpdateOnNavigation,
    } = this.props;

    // TODO: Should we also check if logged-in before we update SW?
    if (!serviceWorkerIsRegistered && !serviceWorkerIsRegistering && serviceWorkerEnable) {
      this.registerServiceWorker();
    } else if (serviceWorkerIsRegistered && !serviceWorkerEnable) {
      this.unregisterServiceWorker();
    }

    // service-worker just finished registering...
    if (!prevProps.serviceWorkerIsRegistered && serviceWorkerIsRegistered) {
      initPushNotificationsIfAccepted();
    }

    if (serviceWorkerUpdateAvailable) {
      if ((serviceWorkerUpdateOnNavigation || serviceWorkerAutoUpdateMethod === 'onNavigation')
        && prevState.selectedFeature !== this.state.selectedFeature) {
        this.activateUpdatedServiceWorker('navigation');
      }
    }

    if (this.state.refreshPending && !prevProps.isAuthenticatedWithDatasetId && this.props.isAuthenticatedWithDatasetId) {
      this.props.dispatchAppRefreshAllAction({ context: 'init' });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ refreshPending: false });
    }

    this.handleShowUpdate();
  }

  componentWillUnmount() {
    am4core.disposeAllCharts();
  }

  trackPageView = (pathname) => {
    const featurePage = findFeaturePageByPath(pathname);
    const name = featurePage?.title;
    const tag = pathname.replace(/\/\d+(?=\/|$)/g, '/xxx');

    if (window.requestIdleCallback) {
      tracker.timeEvent(tracker.events.pageView);
      window.requestIdleCallback(() => {
        tracker.track(tracker.events.pageView, { tag, name });
      });
    } else {
      tracker.track(tracker.events.pageView, { tag, name });
    }
  };

  handleShowUpdate = () => {
    let showUpdatedServiceWorkerIsAvailable;
    if (this.props.serviceWorkerUpdateAvailable) {
      if (this.props.serviceWorkerUserUpdateTimeout && !this.state?.showUpdatedServiceWorkerIsAvailable) {
        // Wait at least <serviceWorkerUserUpdateTimeout> ms before displaying user-update prompt. Ideally, either the navigtation or idle method
        // would have triggered an update in this time (and hard-refresh which should clear this timeout).
        showUpdatedServiceWorkerIsAvailable = ShowUpdate.SCHEDULED;
        this.setState({ showUpdatedServiceWorkerIsAvailable });
        setTimeout(() => {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ showUpdatedServiceWorkerIsAvailable: ShowUpdate.SHOW });
        }, this.props.serviceWorkerUserUpdateTimeout);
      }
    }
    return showUpdatedServiceWorkerIsAvailable;
  };
  // //////////////////////////////////////////////////////////////////////////////////////////////
  // Service Worker Related
  // //////////////////////////////////////////////////////////////////////////////////////////////

  registerServiceWorker = () => {
    swRegister();
  };

  unregisterServiceWorker = () => {
    log.debug('Calling unregisterServiceWorker');
    swUnregister();
    tracker.track(tracker.events.serviceWorker, { action: 'unregistered' });
  };

  activateUpdatedServiceWorker = (method) => {
    activateUpdatedApp(method);
    this.setState({ showUpdatedServiceWorkerIsAvailable: ShowUpdate.DISMISSED });
  };

  // //////////////////////////////////////////////////////////////////////////////////////////////
  // Other
  // //////////////////////////////////////////////////////////////////////////////////////////////

  preserveDisplayNode(props, init = false) {
    const query = queryString.parse(props.router.location?.search);
    if (query.displayNode) {
      const newNodes = this.state.selectedAccountNodes;
      newNodes[props.router.location?.pathname] = query.displayNode;
      if (init) {
        this.state = { ...this.state, selectedAccountNodes: newNodes };
      } else {
        this.setState({ selectedAccountNodes: newNodes });
      }

      // this.state.selectedAccountNodes[props.location.pathname] = query.displayNode;
    }
  }

  drawerChange = (open) => {
    this.setState({ isAccountDrawerOpen: open });
  };

  handleAccountNodeSelect = (node) => {
    let path = `${node.routeTo}?displayNode=${node.id}`;
    if (node.id === 'HIDDEN') {
      path += `&type=${node.type}`;
    }
    this.props.router.navigate(path);
  };

  isFeatureDisabled = (featurePage: FeaturePage) => {
    let featureDisabled = false;

    if (this.props.featureFlags && this.props.featureFlags.size > 0 && featurePage.featureFlag && featurePage.featureFlag.length > 0) {
      const featureFlag = this.props.featureFlags.get(featurePage.featureFlag);
      if (featureFlag !== undefined) {
        featureDisabled = Boolean(!featureFlag);
      }
    }

    return featureDisabled;
  };

  headerMouseEnter = () => {
    this.setState({ dimAccountList: true });
  };
  
  headerMouseLeave = () => {
    this.setState({ dimAccountList: false });
  };

  qCardsObj = () => {
    if (this.state.qCardsOffByDefault) {
      return JSON.parse(this.state.qCardsOffByDefault);
    }
    return {};
  };

  qCardsSetOffState = (path, forceSetting) => {

    const newVal = (forceSetting !== undefined) ? forceSetting : !this.qCardsObj()[path];
    const newObject = { ...this.qCardsObj(), [path]: newVal };
    this.props.setPreference({
      section: 'shared',
      group: 'dataset',
      preference: { webApp: { qCardsOffByDefault: JSON.stringify(newObject) } },
    }, { context: 'qCardsSetOffState' });
    this.setState({
      qCardsOffByDefault: JSON.stringify(newObject),
    });
  };

  handleCloseServiceAvailable = () => {
    this.setState({ showUpdatedServiceWorkerIsAvailable: ShowUpdate.DISMISSED });
  };

  renderServiceWorkerComponents = () => {
    const {
      serviceWorkerAutoUpdateMethod,
      serviceWorkerUpdateAvailable,
      serviceWorkerUpdateOnIdle,
    } = this.props;
    const { showUpdatedServiceWorkerIsAvailable } = this.state;

    return (
      <>
        {<ShowPwaPrompts />}
        {serviceWorkerUpdateAvailable && (serviceWorkerUpdateOnIdle || serviceWorkerAutoUpdateMethod === 'onIdle') && (
          <IdleTimer
            onIdle={() => this.activateUpdatedServiceWorker('idle')}
            timeout={autoUpdateIdleTimeout}
          />
        )}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={showUpdatedServiceWorkerIsAvailable === ShowUpdate.SHOW}
          autoHideDuration={7000}
          message="Updated app is available"
          action={
            <>
              <Button color="secondary" size="small" onClick={() => this.activateUpdatedServiceWorker('user')}>
                UPDATE
              </Button>
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseServiceAvailable}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      </>
    );
  };

  render() {
    if ((this.props.isOffline || !navigator.onLine) && !localPreferences.getOfflineSupport()) {
      return <OfflinePage />;
    }

    if (this.props.maintenance) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          width="100vw"
          height="100vh"
          justifyContent="center"
          alignItems="center"
        >
          <img
            alt="maintenance"
            src={PaperAirplane}
            style={{ width: '50%', height: '50%', objectFit: 'contain' }}
          />
          <Typography variant="h4">
            {"We're down for maintenance."}
          </Typography>
          <Typography variant="h4">
            {"We'll be back up shortly."}
          </Typography>
        </Box>
      );
    }

    const qCardsOnFlag = this.props.featureFlags.get('qCardsOn');

    const { selectedAccountNodes, dimAccountList } = this.state;
    const tempNodeId = selectedAccountNodes[this.props.router.location.pathname];
    const selectedAccountNodeId = tempNodeId !== 'HIDDEN' 
      ? tempNodeId : `${tempNodeId}_${getQueryByName(this.props.router.location, 'type')}`;

    const { isAuthenticated, accountsLoadPending, accountsById, accountDrawerDefaultOpen, datasetId, accountsIsLoading,
      launchPad, datasetsById, flagsLoadPending, preferencesLoading, datasetError, datasetsLoadPending,
      isWebFirstDataset } = this.props;
    const currentDataset = datasetId && datasetsById.get(datasetId);

    const selectedFeature = memoizedPageFind(this.state.selectedFeature) || {};

    const resolveDatasetsNeeded = !selectedFeature.authNotNeeded && isAuthenticated && datasetsLoadPending && datasetsById.size === 0 && datasetError;

    // Quicken getting-started redirects
    if (isAuthenticated && this.props.zeroStateOnNoAccounts && !datasetsLoadPending && !resolveDatasetsNeeded) {
      // Setup page might redirect to platformSelector, so we much check for that case here to not keep redirecting back and forth
      if (datasetsById.size === 0 && selectedFeature.path && selectedFeature.path !== '/platformSelector' && selectedFeature.path !== '/setup') {
        log.log('**APP redirect => /setup');
        log.log(selectedFeature.path);
        this.props.router.navigate('/setup', { replace: true });
        return <div />;
      }

      if (datasetsById.size >= 1 && currentDataset && !accountsIsLoading && !accountsLoadPending && accountsById &&
        !selectedFeature.ignoreZeroState && !selectedFeature.datasetNotNeeded) {
        if (['QWIN', 'QMAC'].includes(currentDataset.platform)) {
          if (accountsById.size === 0 && selectedFeature.path !== '/setup') {
            log.log('**APP redirect => /setup');
            // re-route in a timeout to prevent a render state update
            setTimeout(() => this.props.router.navigate('/setup'), 0);
          } else if (accountsById.size >= 1) { log.log('**APP redirect => dashboard1'); } // 'show dashboard' // do nothing?
        }
        if (['QWIN_MWF', 'QMAC_MWF', 'QWIN_CA_MWF'].includes(currentDataset.platform)) {
          if (accountsById.size === 0 && !this.props.entitlementIsLoading && this.props.entitlement && selectedFeature.path !== '/getting-started-webfirst') {
            log.log('**APP redirect => /getting-started-webfirst');
            // re-route in a timeout to prevent a render state update
            setTimeout(() => this.props.router.navigate('/getting-started-webfirst'), 10);
          } else if (accountsById.size >= 1) { log.log('**APP redirect => dashboard2'); } // 'show dashboard' // do nothing?
        }
      }
    }

    const showSetupBar = this.props.datasetsById && (this.props.datasetsById.size > 0) && this.props.datasetId;
    const SetupBarComponent = SetupStatusBar; // isAuthenticatedWithDatasetOrElse(SetupStatusBar, React.Fragment);
    const qCardsOn = !this.qCardsObj()[selectedFeature.path] && !flagsLoadPending && !preferencesLoading.shared.dataset;

    return (
      <DndProvider backend={HTML5Backend} key="1">
        <>
          {this.renderServiceWorkerComponents()}

          {selectedFeature.qCards && qCardsOn && qCardsOnFlag && (
            <QCard
              name="app"
              open={qCardsOn && qCardsOnFlag}
              initialCard="firstCard"
              cards={selectedFeature.qCards}
              onClose={() => this.qCardsSetOffState(selectedFeature.path, true)}
            />
          )}

          <Main
            {...this.props}
          >
            <Helmet defaultTitle={this.props.theme.project} titleTemplate={`${this.props.theme.project} Classic - %s`}>
              <meta name="description" content={`${this.props.theme.project} Web App`} />
              <link rel="icon" href="favicon.ico" />
              <link rel="apple-touch-icon" href="apple-touch-icon.png" />
            </Helmet>

            <ErrorBoundary>
              <Header
                showRefreshIcon={!this.state.isAccountDrawerOpen}
                selected={this.state.selectedFeature}
                selectedAccountNodes={this.state.selectedAccountNodes}
                onMouseEnter={this.headerMouseEnter}
                onMouseLeave={this.headerMouseLeave}
                qCardsOn={qCardsOn}
                onQCardsClick={(qCardsOnFlag && selectedFeature.qCards) ?
                  (val) => this.qCardsSetOffState(selectedFeature.path, !val) : null}
                hide={selectedFeature.hideNav || resolveDatasetsNeeded}
              />
            </ErrorBoundary>

            <div
              style={isAuthenticated && datasetId ?
                { marginLeft: this.props.theme.components.navigation.barWidth, display: 'flex', position: 'relative', height: '100vh' } : {}}
              onMouseMove={() => this.setState({ dimAccountList: false })}
            >
              {isAuthenticated && datasetId && selectedFeature.accountShowing && (
                <ErrorBoundary>
                  <AccountDrawer
                    dimAccountList={platform.isMouseSupported && dimAccountList}
                    router={this.props.router}
                    open
                    selectedNode={selectedFeature.useSelectedAccount && selectedAccountNodeId}
                    drawerChange={this.drawerChange}
                    nodeSelect={this.handleAccountNodeSelect}
                    accountDrawerDefaultOpen={accountDrawerDefaultOpen}
                  />
                </ErrorBoundary>
              )}

              <ErrorBoundary>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100vh',
                    backgroundColor: this.props.theme.palette.background.default,
                    overflowY: selectedFeature.noScroll ? null : 'auto',
                  }}
                >
                  {selectedFeature.showSetupBar &&
                    launchPad &&
                    isWebFirstDataset &&
                    showSetupBar &&
                  this.props.isAuthenticated && (
                    <div id="app-main-header-area" style={{ flex: 0 }}>
                      <SetupBarComponent
                        key="setupStatusMainKey"
                        name="appmain"
                      />
                    </div>
                  )}
                  <div id="app-main-feature-frame" style={{ flex: 1, height: '100%' }}>
                    {this.cachedSwitch(isAuthenticated, resolveDatasetsNeeded, this.props.featureFlags)}
                  </div>
                </div>
              </ErrorBoundary>
            </div>

            {!isIframe && (
              <>
                <ErrorBoundary>
                  <ActionsSnackBar
                    showFailedActionsHandler={() => this.setState({ showFailedActions: true })}
                    postponedActions={this.props.postponedActions}
                    dispatchDismissPostponedActionsAction={this.props.dispatchDismissPostponedActionsAction}
                    dispatchDispatchPostponedActionsAction={this.props.dispatchDispatchPostponedActionsAction}
                    theme={this.props.theme}
                  />
                </ErrorBoundary>

                <ErrorBoundary>
                  <ErrorHandler
                    open={this.state.showFailedActions}
                    onClose={() => this.setState({ showFailedActions: false })}
                    postponedActions={this.props.postponedActions}
                  />
                </ErrorBoundary>

                <ErrorBoundary>
                  <UsersnapLoader />
                </ErrorBoundary>

                <ErrorBoundary>
                  {DevMenu && <DevMenu />}
                </ErrorBoundary>

                <ErrorBoundary>
                  <RootModal />
                </ErrorBoundary>

                <ErrorBoundary>
                  <MobileDirectDialog
                    isAuthenticated={isAuthenticated}
                  />
                </ErrorBoundary>

                <ErrorBoundary>
                  <Notifications />
                </ErrorBoundary>

                <ErrorBoundary>
                  <ClientConfig />
                </ErrorBoundary>

                <ErrorBoundary>
                  {this.props.showIntercom && <Intercom />}
                </ErrorBoundary>

                <ErrorBoundary>
                  <Cobrowse />
                </ErrorBoundary>

                <ErrorBoundary>
                  <BumpConnector />
                </ErrorBoundary>

                <ErrorBoundary>
                  <Mixpanel />
                </ErrorBoundary>

                <ErrorBoundary>
                  <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={(this.props.isOffline || !navigator.onLine) && localPreferences.getOfflineSupport()}
                    message="Offline Mode"
                  />
                </ErrorBoundary>

                <ErrorBoundary>
                  {this.props.browserMinVersion?.size && <BrowserMinVersion />}
                </ErrorBoundary>

                <ErrorBoundary>
                  <Bugsnag />
                </ErrorBoundary>

                <ErrorBoundary>
                  <AuthDialog />
                </ErrorBoundary>

                <ErrorBoundary>
                  <StorageAccessChecker />
                </ErrorBoundary>

                <ErrorBoundary>
                  <InvestmentCategoriesInit />
                </ErrorBoundary>
              </>
            )}
          </Main>
        </>
      </DndProvider>
    );
  }
}

App.propTypes = {
  isOffline: PropTypes.bool,

  serviceWorkerEnable: PropTypes.bool,
  serviceWorkerEnableInstall: PropTypes.bool,
  serviceWorkerAutoUpdateMethod: PropTypes.string,
  serviceWorkerUpdateOnIdle: PropTypes.bool,
  serviceWorkerUpdateOnNavigation: PropTypes.bool,
  serviceWorkerUserUpdateTimeout: PropTypes.number,

  serviceWorkerIsRegistered: PropTypes.bool,
  serviceWorkerIsRegistering: PropTypes.bool,
  serviceWorkerUpdateAvailable: PropTypes.bool,

  updateAppCB: PropTypes.func,

  router: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  datasetId: PropTypes.string,
  qCardsOffByDefault: PropTypes.string,
  datasetsById: PropTypes.object,
  datasetsLoadPending: PropTypes.bool,
  datasetError: PropTypes.object,
  theme: PropTypes.object,
  postponedActions: PropTypes.object,
  accountsById: PropTypes.object,
  dispatchDispatchPostponedActionsAction: PropTypes.func,
  dispatchDismissPostponedActionsAction: PropTypes.func,
  dispatchAppRefreshAllAction: PropTypes.func,
  setPreference: PropTypes.func,
  featureFlags: PropTypes.object,
  hasSubscription: PropTypes.bool,
  entitlement: PropTypes.object,
  showIntercom: PropTypes.bool,
  subscriptionAlerts: PropTypes.bool,
  monthlyAnnualAlert: PropTypes.bool,
  browserMinVersion: PropTypes.object,
  entitlementLoadPending: PropTypes.bool,
  entitlementIsLoading: PropTypes.bool,
  setupStateIndex: PropTypes.number,
  completedGSF: PropTypes.bool,
  launchPad: PropTypes.bool,
  accountDrawerDefaultOpen: PropTypes.bool,
  accountsLoadPending: PropTypes.bool,
  accountsIsLoading: PropTypes.bool,
  flagsLoadPending: PropTypes.bool,
  preferencesLoading: PropTypes.any,
  zeroStateOnNoAccounts: PropTypes.bool,
  maintenance: PropTypes.bool,
  isAuthenticatedWithDatasetId: PropTypes.bool,
  achievements: PropTypes.bool,
  isWebFirstDataset: PropTypes.bool,
};

function mapStateToProps(state, _ownProps) {
  const featureFlags = featureFlagsSelectors.getFeatureFlags(state);
  const isAuthenticated = entitlementSelectors.isAuthenticated(state);
  const profile = profileSelectors.getProfile(state);
  const currentPrefs = preferencesSelectors.getSharedPreferencesByPath(state, { group: 'dataset', path: ['webApp'] });

  return {
    isOffline: getIsOffline(state),

    serviceWorkerEnable: featureFlags.get('serviceWorkerEnable'),
    serviceWorkerAutoUpdateMethod: featureFlags.get('serviceWorkerAutoUpdateMethod'),
    serviceWorkerUpdateOnIdle: featureFlags.get('serviceWorkerUpdateOnIdle'),
    serviceWorkerUpdateOnNavigation: featureFlags.get('serviceWorkerUpdateOnNavigation'),
    serviceWorkerUserUpdateTimeout: Number(featureFlags.get('serviceWorkerUserUpdateTimeout')),

    serviceWorkerIsRegistered: getServiceWorkerIsRegistered(state),
    serviceWorkeIsRegistering: getServiceWorkerIsRegistering(state),
    serviceWorkerUpdateAvailable: getServiceWorkerUpdateIsAvailable(state),

    isAuthenticated,
    datasetId: authSelectors.getDatasetId(state),
    isAuthenticatedWithDatasetId: entitlementSelectors.isAuthenticatedWithDataSetId(state),
    launchPad: featureFlags && featureFlags.get('launchPad'),
    zeroStateOnNoAccounts: featureFlags && featureFlags.get('zeroStateOnNoAccounts'),
    featureFlags,
    flagsLoadPending: featureFlagsSelectors.getLoadPending(state),
    preferencesLoading: preferencesSelectors.getLoadPendingState(state),
    accountDrawerDefaultOpen: currentPrefs.accountDrawerDefaultOpen,
    qCardsOffByDefault: currentPrefs.qCardsOffByDefault,
    setupStateIndex: currentPrefs.setupStateIndex,
    completedGSF: currentPrefs.completedGSF,
    postponedActions: postponedActionsSelectors.getPostponedActions(state),
    accountsById: accountsSelectors.getAccountsById(state),
    accountsLoadPending: accountsSelectors.getLoadPending(state),
    accountsIsLoading: accountsSelectors.getIsLoading(state),
    datasetsById: datasetsSelectors.getDatasetsById(state),
    datasetsLoadPending: datasetsSelectors.getLoadPending(state),
    datasetError: datasetsSelectors.getError(state),
    entitlement: entitlementSelectors.getTopTierEntitlement(state),
    entitlementIsLoading: entitlementSelectors.getIsLoading(state),
    entitlementLoadPending: entitlementSelectors.getLoadPending(state),
    showIntercom: featureFlags && featureFlags.get('intercom'),
    subscriptionAlerts: featureFlags?.get('subscriptionAlerts'),
    monthlyAnnualAlert: featureFlags?.get('monthlyAnnualAlert'),
    email: profile && profile.username,
    maintenance: featureFlags?.get('maintenance'),
    browserMinVersion: featureFlags?.get('browserMinVersion'),
    theme: getCurrentTheme(state),
    achievements: featureFlags.get('achievements'),
    isWebFirstDataset: authSelectors.getIsWebfirstDataset(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchDispatchPostponedActionsAction: (actions) => dispatch(postponedActionsActions.dispatchPostponedActions(actions)),
    dispatchDismissPostponedActionsAction: (actions) => dispatch(postponedActionsActions.dismissPostponedActions(actions)),
    setPreference: (data) => dispatch(preferencesActions.setPreference(data, { context: 'App' })),
    dispatchAppRefreshAllAction: (data) => dispatch(appRefreshAll(data)),
  };
}

// These are prop injectors
export default connect(mapStateToProps, mapDispatchToProps)(withRouterLegacy(App));

