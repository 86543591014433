import { createCachedSelector, LruObjectCache } from 're-reselect';
import { createSelector } from 'reselect';
import moment from 'moment';
import { Map as ImmutableMap, List, OrderedSet, Record } from 'immutable';
import _ from 'lodash';

import { selectorsHelpers } from 'companion-app-components/flux/core';
import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { configFeatureFlagsSelectors } from 'companion-app-components/flux/config-feature-flags';
import { getLogger } from 'companion-app-components/utils/core';
import { authSelectors } from 'companion-app-components/flux/auth';
import { transactionsTransformers } from 'companion-app-components/flux/transactions';

import { getLoadPending as getTransactionsLoadPending, getTransactionsById } from 'data/transactions/selectors';
import { getMemorizedRulesById } from 'companion-app-components/flux/memorized-rules/memorizedRulesSelectors';
import { getFieldString } from 'data/transactions/searchFilter';
import { ClientConfigFlags } from 'utils/clientConfigFlags';
import { isApplyMemorizedRulesEnabled } from 'data/preferences/selectors';

import { REDUCER_KEY } from './reducer';

const log = getLogger('data/payees/selectors');

export const getLoadPending = (state) => accountsSelectors.getLoadPending(state) || getTransactionsLoadPending(state);

export const getPayeesStore = (state) => state[REDUCER_KEY];

export const getPayeeTransactionsById = (state) => {
  const newState = getPayeesStore(state);
  return newState.payeesByAccountId;
};

export const getPayeesNameList = createSelector(
  getPayeeTransactionsById,
  (payeesById) => {

    let ret = null;
    const payeeNames = ImmutableMap().withMutations((map) => {
      payeesById.forEach((account) => account.forEach((payee) =>
        map.set(payee.name, { id: payee.id, name: payee.name, count: payee.count + (map.get(payee.name)?.count || 0) })));
      return map;
    });
    if (payeeNames) {
      ret = List(payeeNames.toList().toJS()).sort((a, b) => b.count - a.count);
    }
    return ret;
  },
);

/**
 * Get payee data for the requested account node
 */

export const getPayeesForAccounts = createCachedSelector(
  (state) => ({
    obj: getPayeeTransactionsById(state),
    deep: false,
  }),
  (state) => ({
    obj: accountsSelectors.getAccountsById(state),
    deep: false,
  }),
  (state, props) => ({
    obj: props && props.accountIds && props.accountIds.size > 0 ? props.accountIds.sort() : undefined,
    deep: true,
  }),
  (
    { obj: payeeTransactionsById },
    { obj: accountsById },
    { obj: accountIds },
  ) => {
    log.log('Selector - Getting Payees for ', accountIds);

    let payeeList = ImmutableMap();

    let accounts = accountIds;
    if (!accountIds) {
      accounts = accountsById.map((x) => x.id);
    }
    accounts.forEach((accountId) => {

      const pList = payeeTransactionsById.get(accountId);

      if (pList) {
        payeeList = payeeList.mergeWith((a, b) =>
          moment(b.txn.postedOn).isAfter(moment(a.txn.postedOn)) ? b : a, pList);
      }
    });

    // Now we trim the payee list down if it is too large based on dates
    payeeList = payeeList.sort((a, b) =>
      moment(b.txn.postedOn).unix() - moment(a.txn.postedOn).unix());

    // Now we sort it by payee name so the list is alphabetized
    payeeList = payeeList.take(150).sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });

    return payeeList;
  },
)({
  keySelector: (_state, props) => `${JSON.stringify(props && props.accountIds && props.accountIds.size > 0 ? props.accountIds.sort() : undefined)}`,
  cacheObject: new LruObjectCache({ cacheSize: 5 }),
  selectorCreator: selectorsHelpers.createFlexEqualSelector,
});

export const getPayeesForAccountsAsList = createSelector(
  (state, props) => getPayeesForAccounts(state, props),
  (payeesForAccounts) => {
    if (payeesForAccounts) {
      return payeesForAccounts.toList();
    }
    return null;
  },
);

export const getPayeesAsList = createSelector(
  (state, props) => getPayeesForAccounts(state, props),
  (state) => getMemorizedRulesById(state),
  (state) => configFeatureFlagsSelectors.getFeatureFlagForUserOrDataset(
    state,
    ClientConfigFlags.memorizedPayeeAutoPopulate,
  ),
  (state) => isApplyMemorizedRulesEnabled(state),
  (state) => authSelectors.getIsWinDataset(state),
  (payeesForAccounts, memorizedPayees, memorizedPayeeAutoPopulate, applyMemorizedRules, isQWinDataset) => {
    const txnPayeeList = payeesForAccounts?.toList() ?? List();

    // add memorized payee to payee list & remove redendant payee from txns list
    const addMemorizedPayees = () => {
      const memorizedPayeeList = memorizedPayees.valueSeq().toArray().map((memPayee) => {
        const { coa, split } = memPayee;

        const newSplitItems = split?.items.map((item) => ({ ...item, id: null }));        
        const txn = {
          coa,
          tags: memPayee.tags,
          amount: memPayee.amount,
          memo: memPayee.memo,
          ...(newSplitItems ? { split: transactionsTransformers.mkSplit({ items: newSplitItems }) } : {}),
        };
        const pRule = {
          id: memPayee.id,
          name: memPayee.payee,
          amountString: memPayee.amount,
          catString: getFieldString('category', txn),
          isMemorizedPayee: true,
          markAsCleared: memPayee.markAsCleared,
          txn,
        };
        return Record(pRule)(pRule);
      });
  
      return _.unionBy(memorizedPayeeList, txnPayeeList.toArray(), 'name');
    };

    // Enable memeorized payee autopopulate for QWIN only if quickfill enabled
    return memorizedPayeeAutoPopulate && ((isQWinDataset && applyMemorizedRules) || !isQWinDataset) ? addMemorizedPayees() : txnPayeeList;
  },
);


export const getPayeeByName = createCachedSelector(
  (state) => getPayeesForAccounts(state),
  (state, name) => name && name?.toLowerCase(),
  (payees, nameLowerCase) => payees.find((payee) => (payee.name && payee.name?.toLowerCase()) === nameLowerCase),
)({
  keySelector: (_state, name) => `${name && name?.toLowerCase()}`,
  cacheObject: new LruObjectCache({ cacheSize: 10 }),
});

export const getPayeesForTransactions = createCachedSelector(
  (state, transactions) => transactions || getTransactionsById(state),
  (transactions) => new OrderedSet().withMutations((payees) => transactions.forEach((transaction) => {
    if (transaction.payee && transaction.payee.length) {
      payees.add(transaction.payee?.toLowerCase());
    }
  })),
)({
  keySelector: (_state, transactions) => transactions && transactions.hashCode(),
  cacheObject: new LruObjectCache({ cacheSize: 10 }),
});

export const getPayeesForTransactionsAndKey = createCachedSelector(
  (state, props) => getPayeesForTransactions(state, props.transactions),
  (state, props) => props.key && props.key?.toLowerCase(),
  (payees, keyLowerCase) => payees.filter((payee) => (payee.includes(keyLowerCase))),
)({
  keySelector: (_state, props) => `${props.transactions && props.transactions.hashCode()}:${props.key && props.key?.toLowerCase()}`,
  cacheObject: new LruObjectCache({ cacheSize: 10 }),
});
