import React from 'react';
import PropTypes from 'prop-types';
import compose from 'utils/compose';
import classNames from 'classnames';
import { connect } from 'react-redux';
import UIState from 'components/UIState';
import { Record } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

import { getLogger } from 'companion-app-components/utils/core';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { scheduledTransactionsUtils, scheduledTransactionsActions, scheduledTransactionsSelectors } from 'companion-app-components/flux/scheduled-transactions';
import { transactionsActions, transactionsUtils } from 'companion-app-components/flux/transactions';

import { scheduledTransactionLightEditDialog } from 'components/Dialogs/ScheduledTransactionLightEditDialog/actions';
import QDialogs from 'components/QDialogs';
import QPopper from 'components/QPopper';
import QPureComponent from 'QPureComponent';
import SelectScheduledTransactionDialog from 'components/Dialogs/SelectScheduledTransactionDialog';
import * as transactionsSelectors from 'data/transactions/selectors';
import { getMatchState, isSupportedTransferTxn, isExistingTxn } from 'data/transactions/utils';

import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { Link } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import { withTheme } from '@emotion/react';
import AddAndBrowseDocuments from 'components/AddAndBrowseDocuments';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import RepeatIcon from '@mui/icons-material/RepeatRounded';
import RepeatOneIcon from '@mui/icons-material/RepeatOneRounded';
import LinkIcon from '@mui/icons-material/LinkRounded';
import LinkOffIcon from '@mui/icons-material/LinkOffRounded';
import NavigateNextIcon from '@mui/icons-material/NavigateNextRounded';
import MessageIcon from '@mui/icons-material/LocalOfferRounded';
import AssignmentIcon from '@mui/icons-material/AssignmentRounded';
import TransferIcon from '@mui/icons-material/ReplyRounded';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverRounded';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import Avatar from '@mui/material/Avatar';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownwardRounded';
import Switch from '@mui/material/Switch';

import QSwitch from 'components/QSwitch';
import checkNumIcon from 'assets/checknum_details.svg';
import TagsField from 'components/QuickenControls/TagsField';
import { isAcme, isQuicken } from 'isAcme';
import AmountField from 'components/QuickenControls/AmountField';

import { styles } from './styles';
import QTypography from '../MUIWrappers/QTypography';

const log = getLogger('component/DetailsPanel/index.js');

// ==============================================================
//
//
//
export class DetailsPanel extends QPureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = this.initState(props);
    this.fieldDirty = {};
    this.hasFoundMatchingTransfer = !transactionsUtils.isSplitTxn(props.txForm)
      ? Boolean(transactionsUtils.findMatchingTransferTx(
        props.txForm,
        this.props.txnsByAccountId.get(props.txForm.coa?.id)
      )) : true;
  }

  componentDidMount() {
    this._isMounted = true;

    this.setInitialFocus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.txForm !== this.props.txForm) {
      this.setState(this.initState(nextProps));
    }
  }

  componentDidUpdate(prevProps) {
    const { state, props } = this;
    if (state.sizesChanged) {
      state.sizesChanged = false;
      if (props.onSizeChange) {
        props.onSizeChange();
      }
    }

    if (state.showNewRecurrenceProgress) {
      if (props.scheduledTransaction?.id && props.uiState.newScheduledTransactionClientId
        && props.uiState.newScheduledTransactionClientId === props.scheduledTransaction.clientId
        && props.txForm.stModelId === props.scheduledTransaction.id
      ) {
        this.setState({ showNewRecurrenceProgress: false }, () => this.props.dispatchScheduledEditLightDialog(props.scheduledTransaction)); // eslint-disable-line react/no-did-update-set-state
        props.setUIState({ newScheduledTransactionClientId: undefined });
      } else if (!prevProps.scheduledTransactionsError && props.scheduledTransactionsError) {
        this.setState({ showNewRecurrenceProgress: false }); // eslint-disable-line react/no-did-update-set-state
        props.setUIState({ newScheduledTransactionClientId: undefined });
        this.props.dialogAlert('Sorry', "Can't create recurrence. Try again later.", undefined, ['ok']);
      }
    }

    if (state.showAssignRecurrenceProgress
      && ((!prevProps.transactionsError && props.transactionsError)
        || (props.txForm.stModelId && props.txForm.stModelId === state.selectedScheduledTransactionId))
    ) {
      this.setState({ showAssignRecurrenceProgress: false, selectedScheduledTransactionId: undefined }); // eslint-disable-line react/no-did-update-set-state
    }

    if (state.showDeleteRecurrenceProgress
      && ((!prevProps.scheduledTransactionsError && props.scheduledTransactionsError)
        || !props.txForm.stModelId)
    ) {
      this.setState({ showDeleteRecurrenceProgress: false }); // eslint-disable-line react/no-did-update-set-state
      props.setUIState({
        deleteScheduledTransactionId: undefined,
        recurrencePayee: undefined,
        recurrenceFrequency: undefined,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.createNewScheduledTxnTimeout) {
      clearTimeout(this.createNewScheduledTxnTimeout);
      this.createNewScheduledTxnTimeout = null;
    }
  }

  initState = (props) => ({
    memo: props.txForm.memo,
    tags: props.txForm.tags,
    check: props.txForm.check && props.txForm.check.number,
    sizesChanged: false,
    attachments: props.txForm.attachments,
    isExcludedFromReports: props.txForm.isExcludedFromReports,
    isExcludedFromF2S: props.txForm.isExcludedFromF2S,
    isBill: props.txForm.isBill,
    isSubscription: props.txForm.isSubscription,
    showNewRecurrenceProgress: Boolean(props.uiState.newScheduledTransactionClientId),
    showDeleteRecurrenceProgress: Boolean(props.uiState.deleteScheduledTransactionId),
    recurrencePayee: props.uiState.recurrencePayee,
    recurrenceFrequency: props.uiState.recurrenceFrequency,
  });

  onFocus = (e) => {
    e.target.select();
  };


  // Had to do this because componentDidMount was being called BEFORE the DOM was
  // finished being created.  The field does not exist in windowed mode (using Dialog)
  // until after CDM is called.
  //
  setInitialFocus = () => {
    const node = document.querySelectorAll('[name="details-field-notes"]');
    if (node && node.length > 0) {
      node[0].focus();
    } else {
      setTimeout(() => this.setInitialFocus(), 100);
    }
  };

  handleChange = (e, field) => {
    // this.setState({ [field]: e.target.value });
    const newState = { ...this.state, [field]: e.target.value };
    this.setState(newState);
    this.fieldDirty[field] = true;
    if (!this.props.autoSaveRegister) {
      this.callOnChange(newState);
    }
  };

  handleBlur = (e, field) => {
    if (this.props.autoSaveRegister && this.fieldDirty[field]) {
      this.callOnChange(this.state, false);
      this.fieldDirty[field] = null;
    }
  };

  callOnChange = (newState, forceSave) => {
    if (this.props.onChange) {
      this.props.onChange({ ...newState, check: { number: newState.check } }, forceSave);
    }
  };

  tagsChange = (tagSet) => {
    log.log('TAG CHANGE RECEIVED ', tagSet);
    const newState = { ...this.state, tags: tagSet, sizesChanged: true };
    this.setState(newState);
    this.callOnChange(newState);
  };

  onIgnoreChange = () => {
    const newState = {
      ...this.state,
      isExcludedFromReports: !this.state.isExcludedFromReports,
      isExcludedFromF2S: !this.state.isExcludedFromReports,
    };
    this.setState(newState);
    this.callOnChange(newState);
  };

  onIgnoreFromSpendingPlanChange = () => {
    const newState = { ...this.state, isExcludedFromF2S: !this.state.isExcludedFromF2S };
    this.setState(newState);
    this.callOnChange(newState);
  };

  documentsChange = (attachments, forceSave) => {
    const newState = { ...this.state, attachments, sizesChanged: true };

    log.log('SAVING ATTACHMENTS ');
    this.setState(newState);
    this.callOnChange(newState, forceSave);
  };

  unMatch = () => {
    if (this.props.unMatchAction) {
      this.props.unMatchAction();
    }
  };

  fieldKeyDown = (e, field) => {
    if (this.props.autoSaveRegister && e.key === 'Enter') {
      this.handleBlur(e, field);
      e.stopPropagation();
    }
  };

  getRecurrenceLabel = () => {
    let recurrenceLabel;

    const { props } = this;
    switch (this.props.coaBucket) {
      case transactionsSelectors.TransactionBucketEnum.EXPENSE:
        recurrenceLabel = props.txForm.stModelId ? 'Is recurring bill' : 'Is a bill';
        break;
      case transactionsSelectors.TransactionBucketEnum.INCOME:
        recurrenceLabel = 'Is recurring income';
        break;
      case transactionsSelectors.TransactionBucketEnum.TRANSFER:
        recurrenceLabel = 'Is recurring transfer';
        break;
      default:
        log.warn('Unexpected coa bucket = ', this.props.coaBucket);
        break;
    }

    return recurrenceLabel;
  }

  getNewRecurrenceLabel = () => {
    let newRecurrenceLabel;

    switch (this.props.coaBucket) {
      case transactionsSelectors.TransactionBucketEnum.EXPENSE:
        newRecurrenceLabel = 'New recurring bill';
        break;
      case transactionsSelectors.TransactionBucketEnum.INCOME:
        newRecurrenceLabel = 'New recurring income';
        break;
      case transactionsSelectors.TransactionBucketEnum.TRANSFER:
        newRecurrenceLabel = 'New recurring transfer';
        break;
      default:
        log.warn('Unexpected coa bucket = ', this.props.coaBucket);
        break;
    }

    return newRecurrenceLabel;
  }

  getDeleteRecurrenceLabel = () => {
    let newRecurrenceLabel;

    switch (this.props.coaBucket) {
      case transactionsSelectors.TransactionBucketEnum.EXPENSE:
        newRecurrenceLabel = 'Delete recurring bill';
        break;
      case transactionsSelectors.TransactionBucketEnum.INCOME:
        newRecurrenceLabel = 'Delete recurring income';
        break;
      case transactionsSelectors.TransactionBucketEnum.TRANSFER:
        newRecurrenceLabel = 'Delete recurring transfer';
        break;
      default:
        log.warn('Unexpected coa bucket = ', this.props.coaBucket);
        break;
    }

    return newRecurrenceLabel;
  }

  getAssignRecurrenceLabel = () => {
    let assignRecurrenceLabel;

    switch (this.props.coaBucket) {
      case transactionsSelectors.TransactionBucketEnum.EXPENSE:
        assignRecurrenceLabel = 'Assign to existing bill';
        break;
      case transactionsSelectors.TransactionBucketEnum.INCOME:
        assignRecurrenceLabel = 'Assign to existing income';
        break;
      case transactionsSelectors.TransactionBucketEnum.TRANSFER:
        assignRecurrenceLabel = 'Assign to existing transfer';
        break;
      default:
        log.warn('Unexpected coa bucket = ', this.props.coaBucket);
        break;
    }

    return assignRecurrenceLabel;
  }

  createNewScheduledTransaction = () => {
    const { props } = this;
    if (this.props.txForm.payee) {
      const newScheduledTransactionClientId = uuidv4().toUpperCase();
      props.setUIState({ newScheduledTransactionClientId });
      props.dispatchCreateBillScoutScheduleAction({
        clientId: newScheduledTransactionClientId,
        id: this.props.txForm.id,
      });
      this.setState({ showNewRecurrenceProgress: true });
      this.createNewScheduledTxnTimeout = setTimeout(() => {
        if (this.state.showNewRecurrenceProgress && newScheduledTransactionClientId === this.props.uiState.newScheduledTransactionClientId) {
          props.setUIState({ newScheduledTransactionClientId: undefined });
          if (this.state.showNewRecurrenceProgress) this.setState({ showNewRecurrenceProgress: false });
          this.props.dialogAlert('Sorry', "Can't create recurrence timeout. Try again later.", undefined, ['ok']);
        }
      }, 12000);
    } else {
      this.setState({ showNewRecurrenceProgress: true });
      this.props.dialogAlert('Sorry', 'Without payee name scheduled transactions cannot be created', () => {
        this.setState({ showNewRecurrenceProgress: false });
      }, ['ok']);
    }
  }

  linkToScheduledTransaction = (item) => {
    const { props } = this;
    if (assert(props.txForm.id && item.id, 'transaction id=%s or scheduled transaction id=%s missing', props.txForm.id, item.id)) {
      this.setState({
        showSelectScheduledTransactionDialog: false,
        showAssignRecurrenceProgress: true,
        selectedScheduledTransactionId: item.id,
        recurrencePayee: item.transaction.payee,
        recurrenceFrequency: scheduledTransactionsUtils.frequencyAliasFromRecurrence(item.recurrence),
      });
      props.dispatchPerformTransactionAction({ action: 'link-to-scheduled-txn', id: props.txForm.id, otherId: item.id },
        { undo: { userMessage: 'Transaction linked.' } });
      setTimeout(() => {
        if (this.state.showAssignRecurrenceProgress && item.id === this.state.selectedScheduledTransactionId) {
          if (this._isMounted) this.setState({ showAssignRecurrenceProgress: false });
          this.props.dialogAlert('Sorry', "Can't link to recurrence. Try again later.", undefined, ['ok']);
        }
      }, 8000);
    }
  }

  deleteScheduledTransaction = () => {
    const { props } = this;
    if (assert(props.scheduledTransaction.id, 'no scheduled transaction model id', props.scheduledTransaction.id)) {
      const { scheduledTransaction } = props;
      const recurrencePayee = scheduledTransaction.transaction?.payee;
      const recurrenceFrequency = scheduledTransactionsUtils.frequencyAliasFromRecurrence(scheduledTransaction.recurrence);
      const stateObj = {
        deleteScheduledTransactionId: props.scheduledTransaction.id,
        recurrencePayee,
        recurrenceFrequency,
      };
      props.setUIState(stateObj);
      props.dispatchDeleteScheduledTransactionAction({ id: props.scheduledTransaction.id });
      this.setState({
        ...stateObj,
        showDeleteRecurrenceProgress: true,
      });
      setTimeout(() => {
        if (this.state.showDeleteRecurrenceProgress
          && props.scheduledTransaction?.id === this.props.uiState.deleteScheduledTransactionId) {
          props.setUIState({
            deleteScheduledTransactionId: undefined,
            recurrencePayee: undefined,
            recurrenceFrequency: undefined,
          });
          if (this._isMounted) this.setState({ showDeleteRecurrenceProgress: false });
          this.props.dialogAlert('Sorry', "Can't delete recurrence. Try again later.", undefined, ['ok']);
        }
      }, 8000);
    }
  }

  setSetupRecurrenceRef = (ref) => {
    this.setState({ setupRecurrenceRef: ref });
  }

  renderInnerPanel = (classes) => {

    const { props, state } = this;

    // which field has the last tab?
    let lastTab = 'addDoc';
    if (this.props.txForm.cpData) {
      lastTab = (getMatchState(this.props.txForm) === 'matched' && this.props.unMatchAction) ? 'unmatch' : 'addDoc';
    }
    if (isSupportedTransferTxn(this.props.txForm) &&
      this.props.goToTransferAction) {
      lastTab = 'transfer';
    }

    const showSwitchProgress = this.state.showSetupRecurrence || this.state.showUnlinkRecurrence || this.state.showSelectScheduledTransactionDialog
      || this.state.showNewRecurrenceProgress || this.state.showAssignRecurrenceProgress || this.state.showDeleteRecurrenceProgress;

    return (
      <Paper
        elevation={0}
        className={classes.detailsContainer}
        onKeyDown={this.keyDown}
      >
        {this.props.transactionDetailsComprehensive &&
        <div className={!isAcme ? classes.webFirstContainer : null}>

          {/* **** MEMO/NOTES FIELD **** */}
          <div className={classes.detailsRow}>
            <div className={classes.rowIcon}>
              <AssignmentIcon fontSize="small" />
            </div>
            <div className={classes.fieldContainer}>
              <TextField
                id="details-notes-field"
                className={classes.textField}
                style={{ marginTop: 4 }}
                autoFocus
                onChange={(e) => this.handleChange(e, 'memo')}
                onBlur={(e) => this.handleBlur(e, 'memo')}
                placeholder="Add Note"
                value={this.state.memo ? this.state.memo : ''}
                inputProps={{ onFocus: this.onFocus, name: isAcme ? 'notes' : this.props.firstTabName }}
                disabled={!props.editable}
              />
            </div>
          </div>

          {/* **** TAGS FIELD (when no splits) **** */}
          {!transactionsUtils.isSplitTxn(this.props.txForm) &&
          <div className={classes.detailsRow}>
            <div className={classes.rowIcon}>
              <MessageIcon fontSize="small" />
            </div>
            <div className={classNames(classes.fieldContainer, classes.tagsFieldContainer)}>
              <TagsField
                className={classes.textField}
                value={this.state.tags}
                onChange={this.tagsChange}
                id="details-tags-field"
                editable={props.editable}
              />
            </div>
          </div>}

          {/* **** CHECK NUMBER FIELD **** */}
          <div className={classNames(classes.detailsRow, classes.checkRow)}>
            <div className={classes.rowIcon}>
              <img
                alt="Check Number"
                style={{ width: 20, marginTop: -7, opacity: 0.6 }}
                src={checkNumIcon}
              />
            </div>
            <div className={classes.fieldContainer}>
              <TextField
                id="details-check-field"
                className={classes.textField}
                onChange={(e) => this.handleChange(e, 'check')}
                onBlur={(e) => this.handleBlur(e, 'check')}
                placeholder="Check Number"
                value={this.state.check || ''}
                inputProps={{ onFocus: this.onFocus }}
                disabled={!props.editable}
              />

              {isAcme &&
              <span
                role="presentation"
                onClick={this.onIgnoreChange}
                style={{ cursor: 'pointer' }}
              >
                <Typography className={classes.simpleTitles}>
                  Ignore from Reports
                </Typography>
              </span>}
            </div>
          </div>
        </div>}

        {/* **** BILL SWITCH FIELD **** */}
        {props.scheduledTransactionsEnabled && isExistingTxn(props.txForm) &&
        <div className={classes.group}>
          <div className={classes.contentRow}>

            {props.scheduledTransaction && props.scheduledTransaction.id ?
              <Link
                to={isAcme ?
                  `/settings/Recurring/${props.scheduledTransaction && props.scheduledTransaction.id}` :
                  `/bills-and-income?selectNode=${props.scheduledTransaction && props.scheduledTransaction.id}`}
                style={{ textDecorationLine: 'none' }}
              >
                <QTypography variant="body1">
                  {this.getRecurrenceLabel()}
                </QTypography>
              </Link>
              :
              <QTypography variant="body1">
                {this.getRecurrenceLabel()}
              </QTypography>}

            <FormControlLabel
              checked={Boolean(props.scheduledTransaction || (isAcme && (props.txForm.isBill || props.txForm.isSubscription)))}
              labelPlacement="start"
              control={
                <QSwitch
                  id="is-recurring-toggle"
                  color="primary"
                  ref={this.setSetupRecurrenceRef}
                  showCheckProgress={showSwitchProgress}
                  showUncheckProgress={showSwitchProgress}
                />
              }
              label=""
              onChange={(event) => {
                if (isAcme) {
                  if (event.target.checked) {
                    this.setState({ showSetupRecurrence: true });
                  } else if (props.scheduledTransaction) {
                    this.setState({ showUnlinkRecurrence: true });
                  } else if (props.txForm.isBill) {
                    const newState = { ...this.state, isBill: false };
                    this.setState(newState);
                    this.callOnChange(newState);
                  } else if (props.txForm.isSubscription) {
                    const newState = { ...this.state, isSubscription: false };
                    this.setState(newState);
                    this.callOnChange(newState);
                  } else {
                    assert(false, 'unexpected transaction state for recurring OFF: %o', props.txForm);
                  }
                } else if (isQuicken) {
                  if (event.target.checked) {
                    this.createNewScheduledTransaction();
                  } else {
                    this.deleteScheduledTransaction();
                  }
                } else {
                  assert(false, 'unexpected project type neither Quicken nor Simplifi');
                }
              }}
            />
          </div>

          <QPopper
            anchorEl={this.state.setupRecurrenceRef}
            open={Boolean(this.state.showSetupRecurrence)}
            onClickAway={() => this.setState({ showSetupRecurrence: false })}
            placement="bottom-start"
            className={classes.recurringMenuPaper}
          >
            <List
              component="nav"
              aria-label="setup recurring options"
              className={classes.billMenu}
            >
              {isAcme && props.coaBucket === transactionsSelectors.TransactionBucketEnum.EXPENSE &&
                <>
                  <ListItem
                    id="create-one-time-button"
                    button
                    disabled={!this.state.showSetupRecurrence}
                    onClick={() => {
                      const newState = {
                        ...this.state,
                        isBill: true,
                        showSetupRecurrence: false,
                      };
                      this.setState(newState);
                      this.callOnChange(newState);
                    }}
                    className={classes.billMenuItem}
                  >
                    <ListItemIcon>
                      <RepeatOneIcon />
                    </ListItemIcon>
                    <ListItemText primary="One time bill" />
                  </ListItem>
                </>}

              <ListItem
                id="create-new-recurring-button"
                button
                onClick={() => {
                  this.createNewScheduledTransaction();
                  this.setState({ showSetupRecurrence: false });

                }}
                className={classes.billMenuItem}
              >
                <ListItemIcon>
                  <RepeatIcon />
                </ListItemIcon>
                <ListItemText primary={this.getNewRecurrenceLabel()} />
              </ListItem>

              {props.scheduledTransactions && props.scheduledTransactions.size > 0 &&
              <>
                <Divider />

                <ListItem
                  id="assign-to-existing-button"
                  button
                  onClick={() => this.setState({
                    showSetupRecurrence: false,
                    showSelectScheduledTransactionDialog: true,
                  })}
                  className={classes.billMenuItem}
                >
                  <ListItemIcon>
                    <LinkIcon />
                  </ListItemIcon>
                  <ListItemText primary={this.getAssignRecurrenceLabel()} />
                  <NavigateNextIcon />
                </ListItem>
              </>}

            </List>
          </QPopper>

          <Dialog open={Boolean(this.state.showNewRecurrenceProgress && this.props.uiState.newScheduledTransactionClientId)}>
            <DialogContent
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 20,
              }}
            >
              <Typography variant="h5">
                Creating New Recurring
              </Typography>
              <Paper className={classes.paper}>
                <Avatar style={{ margin: 20 }}>
                  {props.txForm.payee ? props.txForm.payee[0].toUpperCase() : '*'}
                </Avatar>

                <Typography variant="h6">
                  {props.txForm.payee}
                </Typography>
              </Paper>

              <CircularProgress />
            </DialogContent>
          </Dialog>

          <QPopper
            anchorEl={this.state.setupRecurrenceRef}
            open={Boolean(this.state.showUnlinkRecurrence)}
            onClickAway={() => this.setState({ showUnlinkRecurrence: false })}
            placement="bottom-start"
          >
            <List
              component="nav"
              aria-label="unlink recurring options"
              style={{ padding: 0 }}
            >
              <ListItem
                id="remove-from-transaction-button"
                style={{ display: props.txForm.source === 'SCHEDULED_TRANSACTION_PENDING' ? 'none' : undefined }}
                button
                disabled={!this.state.showUnlinkRecurrence}
                onClick={() => {
                  if (assert(props.txForm.id, 'remove from recurrence: no transaction id')) {
                    props.dispatchPerformTransactionAction({ action: 'unlink-to-scheduled-txn', id: props.txForm.id }, { undo: { userMessage: 'Transaction unlinked.' } });
                    this.setState({ showUnlinkRecurrence: false, selectedScheduledTransactionId: undefined });
                  }
                }}
              >
                <ListItemIcon>
                  <LinkOffIcon />
                </ListItemIcon>
                <ListItemText primary="Unlink from recurring series" />
              </ListItem>

              <Divider
                style={{ display: props.txForm.source === 'SCHEDULED_TRANSACTION_PENDING' ? 'none' : undefined }}
              />

              <ListItem
                id="delete-recurring-transaction-button"
                button
                onClick={() => {
                  this.deleteScheduledTransaction();
                  this.setState({ showUnlinkRecurrence: false });
                }}
              >
                <ListItemIcon>
                  <DeleteForeverOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={this.getDeleteRecurrenceLabel()} />
              </ListItem>
            </List>
          </QPopper>

          <SelectScheduledTransactionDialog
            open={Boolean(this.state.showSelectScheduledTransactionDialog)}
            onClose={() => this.setState({ showSelectScheduledTransactionDialog: false })}
            onSelected={this.linkToScheduledTransaction}
            maxWidth="md"
            classes={{ paper: classes.dialogPaper }}
          />

          <Dialog open={Boolean(this.state.showAssignRecurrenceProgress)}>
            <DialogContent
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 20,
              }}
            >
              <Typography variant="h5">
                Assigning
              </Typography>
              <Paper className={classes.paper}>
                <Typography variant="subtitle1">
                  {props.txForm.payee}
                </Typography>
                <AmountField value={props.txForm.amount} />
              </Paper>
              <ArrowDownwardIcon />
              <Paper className={classes.paper}>
                <Avatar style={{ margin: 20 }}>
                  {state.recurrencePayee && state.recurrencePayee.length ? state.recurrencePayee[0].toUpperCase() : '*'}
                </Avatar>

                <Typography variant="h6">
                  {state.recurrencePayee}
                </Typography>
                <Typography variant="caption">
                  {state.recurrenceFrequency}
                </Typography>
              </Paper>

              <CircularProgress />
            </DialogContent>
          </Dialog>

          <Dialog open={Boolean(this.state.showDeleteRecurrenceProgress)}>
            <DialogContent
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 20,
              }}
            >
              <Typography variant="h5">
                Deleting recurring
              </Typography>
              <Paper className={classes.paper}>
                <Avatar style={{ margin: 20 }}>
                  {state.recurrencePayee && state.recurrencePayee.length ? state.recurrencePayee[0].toUpperCase() : '*'}
                </Avatar>

                <Typography variant="h6">
                  {state.recurrencePayee}
                </Typography>
                <Typography variant="caption">
                  {state.recurrenceFrequency}
                </Typography>
              </Paper>

              <CircularProgress />
            </DialogContent>
          </Dialog>

        </div>}

        {isAcme &&
        <div className={classes.group}>

          <FormControlLabel
            className={classes.contentRow}
            control={
              <Switch id={`ignore-from-reports-toggle-${this.state.isExcludedFromReports ? 'active' : 'inactive'}`} />
            }
            label="Ignore from Reports"
            labelPlacement="start"
            onChange={this.onIgnoreChange}
            checked={this.state.isExcludedFromReports}
          />

          <FormControlLabel
            className={classes.contentRow}
            control={
              <Switch id={`ignore-from-spending-plan-toggle-${this.state.isExcludedFromF2S ? 'active' : 'inactive'}`} />
            }
            label="Ignore from Spending Plan"
            labelPlacement="start"
            disabled={this.state.isExcludedFromReports && this.state.isExcludedFromReports === this.state.isExcludedFromF2S}
            checked={this.state.isExcludedFromF2S}
            onChange={this.onIgnoreFromSpendingPlanChange}
          />

        </div>}

        {!this.props.transactionDetailsComprehensive &&
        <div className={classes.simpleEditContainer}>
          <Typography className={classes.simpleTitles}>
            Note
          </Typography>
          <TextField
            id="detail-panel-notes-field"
            className={classes.simpleTextField}
            autoFocus
            placeholder="Add your note here"
            onChange={(e) => this.handleChange(e, 'memo')}
            onBlur={(e) => this.handleBlur(e, 'memo')}
            onKeyDown={(e) => this.fieldKeyDown(e, 'memo')}
            value={this.state.memo ? this.state.memo : ''}
            InputProps={{
              disableUnderline: true,
              inputProps: {
                name: 'notes',
                onFocus: this.onFocus,
              },
            }}
          />
        </div>}

        {/* **** ADD DOCUMENT **** */}
        <AddAndBrowseDocuments
          id="attachments-upload-button"
          txn={{ ...this.props.txForm, attachments: this.state.attachments }}
          onChange={this.documentsChange}
          onSizeChange={this.props.onSizeChange}
          lastTabName={lastTab === 'addDoc' ? this.props.lastTabName : null}
        />
        {/* **** TRANSFER **** */}
        {this.hasFoundMatchingTransfer && isSupportedTransferTxn(this.props.txForm) && this.props.goToTransferAction &&
          <div className={classNames(classes.detailsRow, classes.txfrRow)}>
            <div className={classes.rowIcon}>
              <TransferIcon fontSize="small" />
            </div>
            <ButtonBase
              focusRipple
              label="go to transfer"
              className={classes.link}
              onClick={() => this.props.goToTransferAction()}
              name={lastTab === 'transfer' ? this.props.lastTabName : null}
            >
              <Typography
                className={classes.link}
              >
                {'Go to the other side of this transfer '}
              </Typography>
            </ButtonBase>
          </div>}

        {this.props.showTxnJson &&
          <div className={classNames(classes.detailsRow, classes.txfrRow)}>
            <div className={classes.rowIcon}>
              <TransferIcon fontSize="small" />
            </div>
            <ButtonBase
              focusRipple
              label="json view"
              className={classes.link}
              onClick={() => this.props.dialogAlert(
                'JSON View',
                JSON.stringify(this.props.txForm),
                null,
                ['Close'],
                'info'
              )}
              name={lastTab === 'transfer' ? this.props.lastTabName : null}
            >
              <Typography
                className={classes.link}
              >
                Display Transaction JSON
              </Typography>
            </ButtonBase>
          </div>}

      </Paper>
    );
  };

  render() {

    const { classes } = this.props;

    return this.renderInnerPanel(classes);
  }
}

const DetailsPanelUIState = Record({
  newScheduledTransactionClientId: undefined,
  deleteScheduledTransactionId: undefined,
  recurrencePayee: undefined,
  recurrenceFrequency: undefined,
});

const uiStateConfig = {
  name: (props) => `DetailsPanel:${props.txForm.id}`,
  state: () => DetailsPanelUIState(),
  persist: true,
};

DetailsPanel.propTypes = {
  txForm: PropTypes.object,
  onSizeChange: PropTypes.func,
  onChange: PropTypes.func,
  unMatchAction: PropTypes.func,
  goToTransferAction: PropTypes.func,
  firstTabName: PropTypes.string,
  lastTabName: PropTypes.string,
  transactionDetailsComprehensive: PropTypes.bool,
  dispatchCreateBillScoutScheduleAction: PropTypes.func,
  setUIState: PropTypes.func,
  scheduledTransaction: PropTypes.object,
  dialogAlert: PropTypes.func,
  autoSaveRegister: PropTypes.bool,
  coaBucket: PropTypes.string,
  classes: PropTypes.object,
  theme: PropTypes.object,
  showTxnJson: PropTypes.bool,
  editable: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  return {
    scheduledTransactionsEnabled: featureFlagsSelectors.getFeatureFlag(state, 'scheduledTransactions'),
    scheduledTransactions: scheduledTransactionsSelectors.getUserVerifiedScheduledTransactions(state),
    scheduledTransaction: ownProps.uiState.newScheduledTransactionClientId ?
      scheduledTransactionsSelectors.getScheduledTransactionByClientId(state, ownProps.uiState.newScheduledTransactionClientId) :
      scheduledTransactionsSelectors.getScheduledTransactionById(state, ownProps.txForm.stModelId),
    scheduledTransactionsError: scheduledTransactionsSelectors.getError(state),
    transactionsError: transactionsSelectors.getError(state),
    transactionDetailsComprehensive: featureFlagsSelectors.getFeatureFlag(state, 'transactionDetailsComprehensive'),
    autoSaveRegister: featureFlagsSelectors.getFeatureFlag(state, 'autoSaveRegister'),
    showTxnJson: featureFlagsSelectors.getFeatureFlag(state, 'showTxnJson'),
    coaBucket: transactionsSelectors.getTransactionBucket(state, ownProps.txForm),
    txnsByAccountId: transactionsSelectors.getTransactionsByAccountId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchDeleteScheduledTransactionAction: (data) => dispatch(scheduledTransactionsActions.deleteScheduledTransaction(data, { undo: { userMessage: 'Schedule Transaction deleted.' } })),
    dispatchCreateBillScoutScheduleAction: (data) => dispatch(scheduledTransactionsActions.createBillScoutSchedule(data)),
    dispatchPerformTransactionAction: (data, meta) => dispatch(transactionsActions.performTransactionAction(data, meta)),
    dispatchUpdateTransactionsAction: (data) => dispatch(transactionsActions.updateTransaction(data, { undo: { userMessage: 'Transaction(s) updated.' } })),
    dispatchScheduledEditLightDialog: (data) => dispatch(scheduledTransactionLightEditDialog(data)),
  };
}

DetailsPanel.whyDidYouRender = true;

// These are prop injectors
export default compose(
  UIState(uiStateConfig),
  withTheme,
  QDialogs(),
  connect(mapStateToProps, mapDispatchToProps),
)(withStyles(DetailsPanel, styles));
