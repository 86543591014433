// @flow
import React, { ComponentType, useEffect } from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getLogger } from 'companion-app-components/utils/core';

import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import * as entitlementsSelectors from 'data/entitlements/entitlementsSelectors';

import AccountDiscoveryDialog from 'components/Dialogs/AccountDiscovery';
import { DIALOG_TYPE_ACCOUNTS_DISCOVERY } from 'components/Dialogs/AccountDiscovery/types';

import { DIALOG_TYPE as DIALOG_TYPE_ALERT } from 'components/Dialogs/AlertDialog/types';
import AlertDialog from 'components/Dialogs/AlertDialog';

import ConfirmationDialog, { DIALOG_TYPE as DIALOG_TYPE_CONFIRMATION } from 'components/Dialogs/ConfirmationDialog';

import { DIALOG_TYPE as DIALOG_TYPE_FORM } from 'components/Dialogs/FormDialog/types';
import FormDialog from 'components/Dialogs/FormDialog';

import { DIALOG_TYPE as DIALOG_TYPE_NOTIFICATION } from 'components/Dialogs/NotificationDialog/types';
import NotificationDialog from 'components/Dialogs/NotificationDialog';

import RefreshTimeoutDialog, { DIALOG_TYPE as DIALOG_TYPE_REFRESH_TIMEOUT }
  from 'components/Dialogs/RefreshTimeoutDialog';

import { DIALOG_TYPE as DIALOG_TYPE_NPS_SURVEY } from 'components/Dialogs/NPSSurveyDialog/types';
import NPSSurveyDialog from 'components/Dialogs/NPSSurveyDialog';

import {
  DIALOG_TYPE_SYNC_SENTIMENTS_SURVEY,
  DIALOG_TYPE_SYNC_SENTIMENTS_THANK_YOU,
} from 'components/Dialogs/SyncSentiments/actions';
import SyncSentimentsSurveyDialog from 'components/Dialogs/SyncSentiments/SurveyDialog';
import SyncSentimentsThankYouDialog from 'components/Dialogs/SyncSentiments/ThankYouDialog';

import WFBillsScoutDialog from 'components/Dialogs/WfBillScout';
import { DIALOG_TYPE as DIALOG_TYPE_WF_BILLS_SCOUT } from 'components/Dialogs/WfBillScout/types';

import WFDesktop from 'components/Dialogs/WFDesktopFeatures';
import { DIALOG_TYPE as DIALOG_TYPE_WF_DESKTOP_FEATURES } from 'components/Dialogs/WFDesktopFeatures/types';

import HelpSupportDialog from 'components/Dialogs/HelpSupportDialog';
import { DIALOG_TYPE as DIALOG_HELP_SUPPORT } from 'components/Dialogs/HelpSupportDialog/types';

import { DIALOG_TYPE as DIALOG_TYPE_MCP } from 'components/Dialogs/MCPDialog/mcpDialogActions';
import MCPDialog from 'components/Dialogs/MCPDialog/mcpDialogIndex';

import { DIALOG_TYPE as DIALOG_TYPE_WF_BUDGET_SETUP } from 'components/Dialogs/WFBudgetSetup/actions';
import WFBudgetSetup from 'components/Dialogs/WFBudgetSetup/index';

import { getActiveModals } from 'data/rootUi/selectors';

import { DIALOG_TYPE as DIALOG_TYPE_DELETE_REMINDER } from 'components/Dialogs/DeleteReminderDialog/actions';
import DeleteReminderDialog from 'components/Dialogs/DeleteReminderDialog';

import { DIALOG_TYPE as DIALOG_TYPE_MATCH_TXN } from 'components/Dialogs/MatchTxnDialog/actions';
import MatchTxnDialog from 'components/Dialogs/MatchTxnDialog';

import { DIALOG_TYPE as DIALOG_TYPE_EDIT_REMINDER } from 'components/Dialogs/EditReminderDialog/actions';
import EditReminderDialog from 'components/Dialogs/EditReminderDialog';

import { DIALOG_TYPE as DIALOG_TYPE_SCHEDULED_TXN_LIGHT } from 'components/Dialogs/ScheduledTransactionLightEditDialog/actions';
import ScheduledTransactionLightEditDialog from 'components/Dialogs/ScheduledTransactionLightEditDialog';

import { DIALOG_TYPE as DIALOG_TYPE_BILL_PRESENTMENT } from 'components/Dialogs/BillPresentment/actions';
import BillPresentmentDialog from 'components/Dialogs/BillPresentment';

import { DIALOG_TYPE as DIALOG_TYPE_BILL_SCOUT } from 'components/Dialogs/BillScout/actions';
import BillScoutDialog from 'components/Dialogs/BillScout';

import { DIALOG_TYPE as DIALOG_TYPE_SCHEDULED_TRANSACTION_ADD } from 'components/Dialogs/ScheduledTransactionAddDialog/actions';
import ScheduledTransactionAddDialog from 'components/Dialogs/ScheduledTransactionAddDialog';

import { DIALOG_TYPE as DIALOG_TYPE_TRANSACTION_DETAILS } from 'components/Transactions/DetailsDialog/actions';
import DetailsDialog from 'components/Transactions/DetailsDialog';

import { DIALOG_TYPE as DIALOG_TYPE_YEARLY_BUDGET_EDIT } from 'components/Dialogs/BudgetV2/YearlyBudgetEdit/actions';
import YearlyBudgetEdit from 'components/Dialogs/BudgetV2/YearlyBudgetEdit';

import { DIALOG_TYPE as DIALOG_TYPE_INDIVIDUAL_ANNUAL_BUDGET } from 'components/Dialogs/BudgetV2/IndividualCategoryAnnualBudget/actions';
import IndividualCategoryAnnualBudget from 'components/Dialogs/BudgetV2/IndividualCategoryAnnualBudget';

import { DIALOG_TYPE as DIALOG_TYPE_WHATS_NEW } from 'components/Dialogs/WhatsNew/actions';
import WhatsNew from 'components/Dialogs/WhatsNew';

import { DIALOG_TYPE as DIALOG_TYPE_RELEASE_NOTES } from 'components/Dialogs/ReleaseNotes/actions';
import ReleaseNotes from 'components/Dialogs/ReleaseNotes';

import AddRuleDialog, { DIALOG_TYPE_ADD_RULE } from 'components/Dialogs/AddRuleDialog';
import RenameRuleEditDialog, { DIALOG_TYPE_RENAME_RULE_EDIT } from 'components/Dialogs/RenameRuleEditDialog';
import MemorizedRuleEditDialog, { DIALOG_TYPE_MEMORIZED_RULE_EDIT } from 'components/Dialogs/MemorizedRuleEditDialog';
import EmptyDialog, { DIALOG_TYPE_EMPTY_DIALOG } from 'components/Dialogs/EmptyDialog';
import ReportErrorDialog, { DIALOG_TYPE_REPORT_ERROR } from 'components/Dialogs/ReportErrorDialog';
import AccountEditDialog, { DIALOG_TYPE_ACCOUNT_EDIT } from 'components/Dialogs/AccountEditDialog';

const log = getLogger('components/RootModal/index');

export const MODAL_COMPONENTS = {};

export const RegisterModalComponent = (key: string, component: ComponentType<any>) => {
  MODAL_COMPONENTS[key] = component;
};

// Only register the dialog each app needs
// TODO: perhaps we can ifdef to not even build/bundle files?

// shared
RegisterModalComponent(DIALOG_TYPE_ACCOUNTS_DISCOVERY, AccountDiscoveryDialog);
RegisterModalComponent(DIALOG_TYPE_ALERT, AlertDialog);
RegisterModalComponent(DIALOG_TYPE_CONFIRMATION, ConfirmationDialog);
RegisterModalComponent(DIALOG_TYPE_FORM, FormDialog);
RegisterModalComponent(DIALOG_TYPE_NOTIFICATION, NotificationDialog);
RegisterModalComponent(DIALOG_TYPE_REFRESH_TIMEOUT, RefreshTimeoutDialog);
RegisterModalComponent(DIALOG_TYPE_NPS_SURVEY, NPSSurveyDialog);
RegisterModalComponent(DIALOG_TYPE_SYNC_SENTIMENTS_SURVEY, SyncSentimentsSurveyDialog);
RegisterModalComponent(DIALOG_TYPE_SYNC_SENTIMENTS_THANK_YOU, SyncSentimentsThankYouDialog);
RegisterModalComponent(DIALOG_HELP_SUPPORT, HelpSupportDialog);
RegisterModalComponent(DIALOG_TYPE_MCP, MCPDialog);

RegisterModalComponent(DIALOG_TYPE_WF_BILLS_SCOUT, WFBillsScoutDialog);
RegisterModalComponent(DIALOG_TYPE_WF_DESKTOP_FEATURES, WFDesktop);
RegisterModalComponent(DIALOG_TYPE_WF_BUDGET_SETUP, WFBudgetSetup);
RegisterModalComponent(DIALOG_TYPE_YEARLY_BUDGET_EDIT, YearlyBudgetEdit);
RegisterModalComponent(DIALOG_TYPE_INDIVIDUAL_ANNUAL_BUDGET, IndividualCategoryAnnualBudget);
RegisterModalComponent(DIALOG_TYPE_WHATS_NEW, WhatsNew);
RegisterModalComponent(DIALOG_TYPE_RELEASE_NOTES, ReleaseNotes);

// maybe shared? not too sure
RegisterModalComponent(DIALOG_TYPE_DELETE_REMINDER, DeleteReminderDialog);
RegisterModalComponent(DIALOG_TYPE_MATCH_TXN, MatchTxnDialog);
RegisterModalComponent(DIALOG_TYPE_EDIT_REMINDER, EditReminderDialog);
RegisterModalComponent(DIALOG_TYPE_SCHEDULED_TXN_LIGHT, ScheduledTransactionLightEditDialog);
RegisterModalComponent(DIALOG_TYPE_BILL_PRESENTMENT, BillPresentmentDialog);
RegisterModalComponent(DIALOG_TYPE_BILL_SCOUT, BillScoutDialog);
RegisterModalComponent(DIALOG_TYPE_SCHEDULED_TRANSACTION_ADD, ScheduledTransactionAddDialog);
RegisterModalComponent(DIALOG_TYPE_ADD_RULE, AddRuleDialog);
RegisterModalComponent(DIALOG_TYPE_RENAME_RULE_EDIT, RenameRuleEditDialog);
RegisterModalComponent(DIALOG_TYPE_MEMORIZED_RULE_EDIT, MemorizedRuleEditDialog);
RegisterModalComponent(DIALOG_TYPE_EMPTY_DIALOG, EmptyDialog);
RegisterModalComponent(DIALOG_TYPE_REPORT_ERROR, ReportErrorDialog);
RegisterModalComponent(DIALOG_TYPE_ACCOUNT_EDIT, AccountEditDialog);
RegisterModalComponent(DIALOG_TYPE_TRANSACTION_DETAILS, DetailsDialog);

const RootModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useSelector(entitlementsSelectors.isAuthenticated);
  useEffect(() => {
    log.log('location changed to: ', location.pathname + location.search);
    if (isAuthenticated) {
      const url = new URL(window.location.href);
      const dialogType = url.searchParams?.get('showDialog');
      if (dialogType) {
        switch (dialogType) {
          case 'SOME_SPECIAL_DIALOG_ID_EXAMPLE': // if we need special handling
            break;
          default:
            dispatch(createDialog(mkRootUiData({
              id: dialogType,
              type: dialogType,
              allowNesting: true,
            })));
        }

        url.searchParams.delete('showDialog');
        navigate(url.pathname + url.search, { replace: true });
      }
    }
  }, [location, dispatch, navigate, isAuthenticated]);

  const modals = useSelector(getActiveModals);

  if (modals.size === 0) {
    return null;
  }
  return (
    modals.map((modal: any) => {
      const modalProps = {};
      modal.props.forEach((value, key) => { modalProps[key] = value; });

      const ModalComponent = MODAL_COMPONENTS[modal.type];
      return (
        <ErrorBoundary key={modal.id}>
          <ModalComponent key={modal.id} dialogId={modal.id} {...modalProps} />;
        </ErrorBoundary>
      );
    })
  );
};

export default RootModal;
