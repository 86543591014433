import React from 'react';
import { DateTime } from 'luxon';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import HeightIcon from '@mui/icons-material/Height';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import EditIcon from '@mui/icons-material/Edit';
// import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

import {
  BUDGET_ANNUAL_MONTHS,
  BUDGET_ANNUAL_SUBVIEWS,
  BUDGET_VIEWS,
  BUDGET_ANNUAL_COPY_OPTIONS,
} from './constants';
import {
  BudgetAnnualDateObjectType,
  BudgetAnnualMonthsType,
  MonthlyAmountType,
} from './types';


export const budgetOptions = ({
  isToDateColumnEnabled,
  handleShowToDateSetting,
}: {
  isToDateColumnEnabled : boolean,
  handleShowToDateSetting: () => void,
}) => ({
  label: 'Budget Options',
  items: [
    {
      label: 'View Options',
      items: [
        {
          label: 'Show to-date column in annual view',
          leftIcon: isToDateColumnEnabled ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />,
          callback: () => handleShowToDateSetting(),
        },
      ],
    },
  ],
});

export const getProgressColor = (
  balanceAmount: number, 
  redColor: string, 
  greenColor: string,
) => balanceAmount < 0 ? redColor : greenColor;

export const getHighlightColor = (
  numMonth: number, 
  currentMonth: number, 
  currentYear: number, 
  budgetEndYear: number, 
  color: string, 
  isToDate: boolean = false,
) =>
  !isToDate && currentMonth === numMonth && budgetEndYear >= currentYear && color;

export const getMonthlyCellSxProps = (
  startDate: DateTime,
  currentMonth: number,
  currentYear: number,
  budgetEndYear: number,
  color: string,
  border: string,
  isToDate: boolean = false,
  isSelected: boolean = false,
  colorSelectedRow: string = '',
) => {
  const monthFromStartDate = DateTime.fromISO(startDate.toString()).month;
  return {
    border,
    p: 0,
    backgroundColor: isSelected
      ? colorSelectedRow
      : getHighlightColor(monthFromStartDate, currentMonth, currentYear, budgetEndYear, color, isToDate),
  };
};

const arrangeMonthlyCellsOrder = (
  arrMonths: BudgetAnnualMonthsType[], 
  currentMonth: number, 
  budgetEndMonth: number, 
  isToDateEnabled: boolean,
) => {
  const pushMonthCells: BudgetAnnualMonthsType[] = [];
  if (isToDateEnabled) {
    arrMonths.forEach((objMonth: BudgetAnnualMonthsType) => {
      if (objMonth.number === currentMonth && currentMonth !== budgetEndMonth) {
        pushMonthCells.push(objMonth, {
          number: -1,
          shortName: 'To Date',
          name: 'To Date',
        });
      } else {
        pushMonthCells.push(objMonth);
      }
    });
  } else {
    pushMonthCells.push(...arrMonths);
  }

  return pushMonthCells;
};

export const getIterableMonthCells = (
  budgetStartMonth: number,
  currentMonth: number,
  budgetEndMonth: number,
  budgetStartYear: number,
  budgetEndYear: number,
  isToDateEnabled = false,
) => {
  // IF it's staring from Jan month, then it's normal process
  if (budgetStartMonth === 1) {
    return arrangeMonthlyCellsOrder(BUDGET_ANNUAL_MONTHS, currentMonth, budgetEndMonth, isToDateEnabled);
  }

  // Otherwise, special treatment
  const passedMonths = BUDGET_ANNUAL_MONTHS.slice(0, budgetStartMonth - 1)
    .map((objMonth) => ({ ...objMonth, year: budgetEndYear }));
  const futureMonths = BUDGET_ANNUAL_MONTHS.slice(budgetStartMonth - 1)
    .map((objMonth) => ({ ...objMonth, year: budgetStartYear }));
  return arrangeMonthlyCellsOrder([...futureMonths, ...passedMonths], currentMonth, budgetEndMonth, isToDateEnabled);
};

export const hideToDateColumn = (
  isToDateColumnEnabled: boolean, 
  isToDate: boolean, 
  currentMonth: number, 
  budgetEndMonth: number,
) =>
  isToDate && (!isToDateColumnEnabled || currentMonth === budgetEndMonth);

export const extractDetailsForTracker = (sharedPreferences: Record<string, any>) => {
  const trackingDetails: Record<string, any> = {};
  if (sharedPreferences && sharedPreferences.budgetOptions && sharedPreferences.budgetOptions.lastViewedBudgetId) {
    const { lastViewedBudgetId } = sharedPreferences.budgetOptions;
    if (sharedPreferences && sharedPreferences.webApp && sharedPreferences.webApp.budgetOptions && sharedPreferences.webApp.budgetOptions.viewState) {
      const { viewState } = sharedPreferences.webApp.budgetOptions;
      const lastViewedBudgetToTrack = viewState[lastViewedBudgetId];
      if (lastViewedBudgetToTrack) {
        const { viewType, subViewType } = lastViewedBudgetToTrack;
        trackingDetails.budgetView = viewType;
        if (viewType === BUDGET_VIEWS.annual.key) {
          trackingDetails.annualViewType = BUDGET_ANNUAL_SUBVIEWS[subViewType].label;
        }
      }
    }
  }
  return trackingDetails;
};

export const hideDetailsViewCells = (
  prefShowFutureMonthDetails: boolean,
  { isToDate, startDate }: { isToDate: boolean, startDate: DateTime | BudgetAnnualDateObjectType },
  annualSubView: string,
  currentMonth: number,
  currentYear: number,
) => annualSubView === BUDGET_ANNUAL_SUBVIEWS.details.key && !prefShowFutureMonthDetails && !isToDate
  && ((Number(startDate?.month) > currentMonth && startDate?.year === currentYear) || Number(startDate?.year) > currentYear);

export const getIterableCells = (
  monthlyAmounts: MonthlyAmountType[],
  budgetEndMonth: number,
  currentMonth: number,
  currentYear: number,
  annualSubView: string,
  prefShowFutureMonthDetails: boolean,
  isDetailsView: boolean = false,
  isToDateEnabled: boolean = false,
): MonthlyAmountType[] => {
  const arrCellsObjects: MonthlyAmountType[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const objMonth of monthlyAmounts) {
    if (hideToDateColumn(isToDateEnabled, objMonth.isToDate, objMonth.startDate.month, budgetEndMonth)) {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (hideDetailsViewCells(
      prefShowFutureMonthDetails,
      objMonth,
      annualSubView,
      currentMonth,
      currentYear,
    )) {
      arrCellsObjects.push({ ...objMonth, amount: objMonth.budgetAmount });
    } else if (!isDetailsView || objMonth.isToDate) {
      arrCellsObjects.push(objMonth);
    } else {
      arrCellsObjects.push(
        { ...objMonth, amount: objMonth.budgetAmount },
        { ...objMonth, amount: objMonth.actualAmount },
        { ...objMonth, amount: objMonth.balanceAmount, isBalanceAmount: true },
      );
    }
  }

  return arrCellsObjects;
};

export const getAmountColor = (
  viewType: string,
  amount: number,
  colorActual: string,
  colorBalance: string,
  isBalanceAmount: boolean = false,
  isToDate: boolean = false,
  isTotalColumn: boolean = false,
): string => {
  let colorAmount = 'inherit';
  if (isToDate || isTotalColumn) {
    return amount < 0 ? colorBalance : colorAmount;
  }
  if (viewType === BUDGET_ANNUAL_SUBVIEWS.actual.key && !isTotalColumn) {
    colorAmount = colorActual;
  } else if ((isBalanceAmount || isToDate || viewType === BUDGET_ANNUAL_SUBVIEWS.balance.key) && amount < 0) {
    colorAmount = colorBalance;
  }

  return colorAmount;
};

export const getKeyFutureMonthsVisibility = (annualSubView: string): string => `${annualSubView}_isHiddenFutureMonths`;

export const getBudgetViewStateObject = (
  id: string, 
  name: string, 
  viewType: string, 
  subViewType: string, 
  showFutureMonthDetails: string | boolean,
) => ({
  budgetOptions: {
    viewState: {
      [id]: {
        name,
        viewType,
        subViewType,
        ...(typeof showFutureMonthDetails === 'boolean' && { [getKeyFutureMonthsVisibility(subViewType)]: !showFutureMonthDetails }),
      },
    },
  },
});

const getStringForOptions = (month: number, budgetStartYear: number, budgetEndYear: number) => ({
  monthShortName: BUDGET_ANNUAL_MONTHS[month - 1]?.shortName,
  suffixFY: budgetStartYear === budgetEndYear ? '' : '(FY)',
});

export const generateEditOptionsForAnnualTable = (
  monthSelected: DateTime,
  budgetStartYear: number,
  budgetEndYear: number,
  handleApplyAction: (option: string) => void,
  handleEditYearlyAction: (key: string | undefined) => void,
  // handleCalculateAverageAction
) => {
  const { monthShortName, suffixFY } = getStringForOptions(monthSelected.month, budgetStartYear, budgetEndYear);

  return [
    {
      label: `Apply ${monthShortName} budget forward to the end of ${budgetEndYear} ${suffixFY}`,
      value: BUDGET_ANNUAL_COPY_OPTIONS.restOfYear,
      action: handleApplyAction,
      icon: <UpgradeIcon sx={{ transform: 'rotate(90deg)' }} />,
    },
    {
      label: `Apply ${monthShortName} budget to all of ${budgetEndYear} ${suffixFY}`,
      value: BUDGET_ANNUAL_COPY_OPTIONS.wholeYear,
      action: handleApplyAction,
      icon: <HeightIcon sx={{ transform: 'rotate(90deg)' }} />,
    },
    {
      label: 'Edit yearly budget',
      value: 'editYearlyBudget',
      action: handleEditYearlyAction,
      icon: <EditIcon fontSize="small" />,
    },
    // TODO hide calculate average
    // {
    //   label: 'Calculate average budget',
    //   value: 'calculateAverageBudget',
    //   action: handleCalculateAverageAction,
    //   icon: <SettingsSuggestIcon fontSize="small" />,
    // },
  ];
};

export const generateMonthCopyOptions = (
  monthClicked: DateTime | null,
  budgetStartMonth: number,
  budgetEndMonth: number,
  budgetStartYear: number,
  budgetEndYear: number,
  handleMonthCopyAction: (option: string) => void,
) => {
  if (!monthClicked) { return []; }

  const { monthShortName, suffixFY } = getStringForOptions(monthClicked.month, budgetStartYear, budgetEndYear);
  return [
    {
      key: BUDGET_ANNUAL_COPY_OPTIONS.restOfYear,
      label: `Copy ${monthShortName} budgets to all future months in ${budgetEndYear} ${suffixFY}`,
      action: handleMonthCopyAction,
      icon: <UpgradeIcon sx={{ transform: 'rotate(90deg)' }} />,
      disabled: monthClicked.month === budgetEndMonth,
    },
    {
      key: BUDGET_ANNUAL_COPY_OPTIONS.pastMonths,
      label: `Copy ${monthShortName} budgets to all past months in ${budgetEndYear} ${suffixFY}`,
      action: handleMonthCopyAction,
      icon: <UpgradeIcon sx={{ transform: 'rotate(270deg)' }} />,
      disabled: monthClicked.month === budgetStartMonth,
    },
    {
      key: BUDGET_ANNUAL_COPY_OPTIONS.wholeYear,
      label: `Copy ${monthShortName} budgets to all months in ${budgetEndYear} ${suffixFY}`,
      action: handleMonthCopyAction,
      icon: <HeightIcon sx={{ transform: 'rotate(90deg)' }} />,
    },
  ];
};

export const validateAmount9Digits2Decimals = (value: string) => /^[+ -]?[0-9]{0,9}([.][0-9]{0,2})?$/.test(value);

export const hideBalanceViewFutureAmount = (
  prefShowFutureMonthDetails: boolean | undefined,
  { isToDate, startDate }: { isToDate: boolean, startDate: DateTime },
  annualSubView: string,
  currentMonth: number,
  currentYear: number,
  budgetEndMonth: number | undefined,
  budgetEndYear: number | undefined,
  isSummaryCell: boolean = false,
) => {
  let hideCell = !prefShowFutureMonthDetails && annualSubView === BUDGET_ANNUAL_SUBVIEWS.balance.key;
  if (isSummaryCell) {
    // Not to hide the cell when the budget end is equal the current month and year.
    if (budgetEndMonth === currentMonth && budgetEndYear === currentYear) { hideCell &&= false; }
    return hideCell;
  }

  hideCell &&= !isToDate 
    && ((startDate?.month > currentMonth && startDate?.year === currentYear) 
    || startDate?.year > currentYear);
  return hideCell;
};

export const generateRolloverBalanceCalculationTable = (monthlyDetails: Record<string, any>) => {
  const selectedDate = DateTime.fromISO(monthlyDetails?.objMonthlyAmounts?.startDate);
  const budgetItemOfSelectedDate = monthlyDetails?.budgetItem?.budgetItems?.find((budgetItemIterated) => 
    DateTime.fromISO(budgetItemIterated.startDate).month === selectedDate.month);
  const isIncome = monthlyDetails?.budgetItem?.negate > 0;
  const rolloverAmount = (budgetItemOfSelectedDate?.rolloverOverrideAmount || budgetItemOfSelectedDate?.calculatedRolloverAmount || 0) * -1;
  return [{
    key: 'Rollover',
    keyName: 'rolloverAmount',
    datedOn: 'Last Month',
    operation: undefined,
    value: rolloverAmount,
    edit: true,
  },
  {
    key: 'Budget',
    keyName: 'budgetAmount',
    datedOn: 'This Month',
    operation: isIncome ? '-' : '+',
    value: monthlyDetails?.objMonthlyAmounts?.budgetAmount,
    edit: false,
  },
  {
    key: 'Actual',
    keyName: 'actualAmount',
    datedOn: 'This Month',
    operation: isIncome ? '+' : '-',
    value: monthlyDetails?.objMonthlyAmounts?.actualAmount,
    edit: false,
  },
  {
    key: 'Balance',
    keyName: 'balanceAmount',
    datedOn: undefined,
    operation: '=',
    value: monthlyDetails?.objMonthlyAmounts?.balanceAmount,
    edit: false,
  }];
};
