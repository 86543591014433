import React, { useState, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import moment from 'moment';
import cn from 'classnames';
import { DateTime } from 'luxon';

import StdDialog from 'components/Dialogs/StdDialog';
import { formatNumber } from 'components/QuickenControls/AmountField';
import { theme } from 'themes/themeQuicken';

import BarChart from './barChart';

const tabs = ['history', 'transactions'];

const IndividualCategoryView = ({ classes, data, removeDialog, clickedMonthDate }) => {
  const [currentTab, setCurrentTab] = useState(tabs[1]);
  const selectedDate = clickedMonthDate?.toFormat?.('yyyy-MM');

  const cachedTxns = useMemo(() => (clickedMonthDate ? data.txns.filter((txn) => 
    DateTime.fromISO(txn.postedOn).toFormat('yyyy-MM') === selectedDate) : data.txns), [selectedDate, clickedMonthDate, data]);

  const cachedTotal = useMemo(() => (clickedMonthDate ? 
    cachedTxns.reduce((acct, txn) => (acct + txn.amount), 0) : data.total), [data, clickedMonthDate, cachedTxns]);

  const renderDialogHead = () => (
    <Typography className={classes.headingStyle}>
      {data.categoryName}
    </Typography>
  );

  const { currency = 'USD' } = data?.budgetDetails;
  const amountFormat = '0,00.00';

  const renderTable = (tableData) => (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Date</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {
          tableData.map((txn) => (
            <tr key={txn.id}>
              <td className={classes.ellipseStyle}> {txn.payee} </td>
              <td> {moment(txn.postedOn).format('MMM DD')} </td>
              <td> {formatNumber(txn.amount, '', amountFormat)} </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );

  const renderTransactions = () => (
    <>
      <div className={classes.qScrollStyle}>
        {(cachedTxns.length === 0 && data.txnReminders.length === 0) &&
          <Typography>
            There is no transactions
          </Typography>}
        {(cachedTxns.length > 0 || data.txnReminders.length > 0) &&
          <Box>
            <Typography className={classes.subHeadTextStyle}>
              {data.txnsType}
            </Typography>
            {renderTable(cachedTxns)}
            <br />
            {data.txnReminders.length > 0 &&
              <>
                <Typography className={classes.subHeadTextStyle}>
                  Reminders
                </Typography>
                {renderTable(data.txnReminders)}
              </>}
          </Box>}
      </div>
      <table className={classes.totalTable}>
        <thead>
          <tr className={classes.specialRow}>
            <td></td>
            <td>Total</td>
            <td>{formatNumber(cachedTotal, currency, amountFormat)}</td>
          </tr>
        </thead>
      </table>
    </>
  );

  const renderHistory = () => (
    <div className={cn(classes.qScrollStyle, classes.chartWrapper)}>
      <BarChart
        chartId={'individual-categort-chart-view'}
        data={data.graphData}
        range={data.monthlyAvarage}
        xAxisField={'datedOn'}
        yAxisField={'amount'}
      />
    </div>
  );

  const handleTabChange = (e, tab) => {
    setCurrentTab(tabs[tab]);
  };

  return (
    <StdDialog
      open
      onClose={removeDialog}
      title={renderDialogHead()}
      sharedcomponentid={'INDIVIDUAL_CATEGORY_ANNUAL_BUDGET'}
      titleClasses={classes.dialogTitle}
      colorBar={theme.components.categoryDialog.primary}
    >
      <DialogContent className={classes.dialogContentStyle}>
        {/* TODO : Needed after confirmation */}
        {/* <Typography className={cn(classes.subHeadTextStyle, classes.avarageWrapper)}>
          Monthly average: {formatNumber(data.monthlyAvarage)}
        </Typography> */}
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          TabIndicatorProps={{ sx: { top: 0 } }}
        >
          <Tab
            label="History"
            index={tabs[0]}
            className={cn(classes.tabStyle, currentTab === tabs[0] ? classes.currentTabStyle : undefined)}
          />
          <Tab
            label="Transactions"
            index={tabs[1]}
            className={cn(classes.tabStyle, currentTab === tabs[1] ? classes.currentTabStyle : undefined)}
          />
        </Tabs>
        {currentTab === tabs[0] && renderHistory()}
        {currentTab === tabs[1] && renderTransactions()}
      </DialogContent>
    </StdDialog>
  );
};

IndividualCategoryView.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  removeDialog: PropTypes.func,
  clickedMonthDate: PropTypes.object,
};

export default memo(IndividualCategoryView);
