import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';

import { getHostConfig, localPreferences, getLogger, tracker } from 'companion-app-components/utils/core';
import { datasetsSelectors } from 'companion-app-components/flux/datasets';
import { authActions as actions, authTypes, authSelectors, authSagas } from 'companion-app-components/flux/auth';

import { clearEntitlements } from 'data/entitlements/entitlementsUtils';
import { cancelTokenSource } from 'utils/axiosFactory';
import { loadPersistedDatasetData } from 'data/persist/actions';
import { getClientConfigFlags } from 'utils/clientConfigFlags';
import { navigate } from 'NavigateSetter';

import { isAcme, isQuicken } from 'isAcme';

import { clearGlobalCaches } from './caches';
import { clearAuthSession, saveAuthSession } from './storage';

const log = getLogger('utils/auth/sagas');

export function* authUpdated(action): Generator<*, *, *> {
  const { authSession, reload, location } = action.payload;
  log.log('Auth update...', authSession, reload, location);

  // save the auth session locally so it survives a reload
  yield call(() => saveAuthSession(authSession));

  if (reload) {
    if (location) {
      log.info('Hard Redirecting to', location);
      // eslint-disable-next-line no-return-assign
      yield call(() => { window.location.href = location; });
    }
  } else {
    // if we are not reloading, force update to client configs on custom user attributes
    yield call(() => getClientConfigFlags());

    // TODO: remove loadPersistedDatasetData once Quicken stop using credentials blob for Wells Fargo
    yield put(loadPersistedDatasetData());

    if (location) {
      log.info('Redirecting to', location);
      navigate(location, { replace: true });
    }
  }
}

export function* authUpdatedActionWatcher() {
  yield takeLatest(actions.authUpdated, authUpdated);
}

export function* authLogoutWatcher() {
  yield takeEvery(actions.authLogout, function* authLogout(action) {
    log.info('Auth Logout...', action.payload);
    tracker.track(tracker.events.logout, {
      description: action.payload.reason,
      context: action.meta?.context,
    });

    // clear axios requests queue
    cancelTokenSource.cancel('Operation cancelled by logout.');

    // clear the auth session locally and global caches
    //
    clearAuthSession();
    clearEntitlements();
    clearGlobalCaches();

    // trigger 'storage' event = logout other tabs
    localPreferences.setSessionStorage(null);
    localPreferences.removeSessionStorage(); // sessionStorage is in the event so remove it from localStorage

    // cancel sagas
    // yield cancel(sagaTasks);

    localPreferences.setLogoutReason(action.payload?.reason);
    const location = getHostConfig().homepage_url;
    window.location.href = location;
  });
}

export function* persistAuthSession({ payload: authSession }): Generator<*, *, *> {
  log.info('Peristing Refreshed OAuth Token...', authSession);

  // save the auth session locally so it survives a reload
  yield call(() => saveAuthSession(authSession));
}

export function* persistAuthSessionWatcher() {
  yield takeLatest(actions.persistAuthSession, persistAuthSession);
}

export function* authSelectDatasetAutoActionWatcher() {
  yield takeEvery(actions.authSelectDatasetAuto, function* authSelectDatasetAuto(action) {
    const datasets = yield select(datasetsSelectors.getActiveSortedDatasets);
    const currentDatasetId = yield select(authSelectors.getDatasetId);
    let dataset;
    if (currentDatasetId) {
      dataset = datasets.get(currentDatasetId);
    }
    dataset = dataset || datasets.filter((curDataset) => !curDataset.disableRemoteAccess).first();
    const numDatasets = datasets.size;
    let { location, reload } = action.payload || {};
    if (numDatasets === 0) {
      if (isAcme) {
        reload = reload ?? true;
        location = location ?? '/get-started';
      } else if (isQuicken) {
        reload = reload ?? true;
        location = location ?? '/setup';
      }
    } else if (isQuicken && datasets.every((curDataset) => curDataset.disableRemoteAccess)) {
      location = location ?? '/setup';
    }
    if (dataset?.id !== currentDatasetId
      || reload
      || (location && location !== window.location.pathname)
    ) {
      yield put(actions.authSelectDataset(authTypes.AuthSelectDatasetPayload({
        dataset,
        reload,
        location,
      })));
    }
  });
}

// for loading watcher sagas
export default [
  ...authSagas,
  authUpdatedActionWatcher,
  authLogoutWatcher,
  persistAuthSessionWatcher,
  authSelectDatasetAutoActionWatcher,
];
