import React, { FC, useEffect, LegacyRef } from 'react';
import { List } from 'immutable';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { tracker } from 'companion-app-components/utils/core';

import { MainNavItem } from 'containers/App/Header/MainNavItem';
import useQPreferences from 'components/QPreferences/useQPreferences';
import { showWhatsNewDialog } from 'components/Dialogs/WhatsNew/actions';
import { showReleaseNotesDialog } from 'components/Dialogs/ReleaseNotes/actions';
import { doHelpSupportModal as doHelpSupportModalAction } from 'components/Dialogs/HelpSupportDialog/actions';
import { getLoadPendingState as getPrefLoadPending } from 'companion-app-components/flux/preferences/selectors';

import { getAccountNodesTree } from 'data/accountNodes/selectors';

import { HELP_MENU_ITEMS } from 'utils/constants';

import { checkAndTriggerWhatsNewDialog } from '../utils';

const currentVersion = process.env.APP_VERSION;
let whatsNewData;
try {
  // eslint-disable-next-line
  whatsNewData = require(`/static/whats-new${currentVersion}/WhatsNew.json`);
  if (whatsNewData?.version === currentVersion) {
    whatsNewData = {
      count: whatsNewData.pages,
      pages: whatsNewData.order.split(','),
    };
  }
} catch {
  whatsNewData = {};
}

interface HelpSupportButtonProps {
  classes: Record<string, any>;
  selected: string;
  iconSize: number;
  settingsPageNavRef?: LegacyRef<HTMLSpanElement>;
  canOpenSubMenu: boolean;
  closeHeader: () => void;
  handleTracking: (path: string) => void;
}

const HelpSupportButton:FC<HelpSupportButtonProps> = ({
  classes,
  selected,
  iconSize,
  settingsPageNavRef,
  canOpenSubMenu,
  closeHeader,
  handleTracking,
}) => {
  const dispatch = useDispatch();
  const hasWhatsNewJSON = whatsNewData?.pages?.length > 0;
  const { userPreferences, setUserPreference } = useQPreferences();
  // @ts-expect-error: type assigning wont be needed once new app-component is installed
  const isUserPrefLoading = useSelector(getPrefLoadPending).shared.user;
  // TODO: type assigning wont be needed once new app-component is installed
  const accountNodesTree: List<any> = useSelector(getAccountNodesTree);
  const dispatchShowWhatsNewDialog = () => dispatch(showWhatsNewDialog(whatsNewData, () => dispatch(showReleaseNotesDialog())));
  const dispatchShowReleaseNotesDialog = () => dispatch(showReleaseNotesDialog());

  const handleItemClick = (menuItemClicked) => {
    closeHeader();
    const objMenuItemHandlers = {
      whatsNew: dispatchShowWhatsNewDialog,
      releaseNotes: dispatchShowReleaseNotesDialog,
      supportFAQ: () => dispatch(doHelpSupportModalAction()),
    };

    if (!objMenuItemHandlers[menuItemClicked]) { return; }
    objMenuItemHandlers[menuItemClicked]();
    tracker.track(tracker.events[menuItemClicked]);
  };

  useEffect(() => {
    const debounceDialogTrigger = debounce(() => {
      checkAndTriggerWhatsNewDialog({
        isUserPrefLoading,
        userPreferences,
        setUserPreference,
        currentVersion,
        hasWhatsNewJSON,
        dispatchShowReleaseNotesDialog,
        dispatchShowWhatsNewDialog,
      });
    }, 2000);

    const triggerDebounce = () => {
      debounceDialogTrigger();
    };

    if (accountNodesTree.size > 0) { 
      triggerDebounce(); 
    }
    return debounceDialogTrigger.cancel;
  }, [isUserPrefLoading, userPreferences?.whatsNewViewed, accountNodesTree.size]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainNavItem
      disableClick
      key="nav-item-help"
      nav={{
        path: '/help',
        component: null,
        nav: 'main',
        title: 'Help',
        alt: 'contains various Help menu items',
        img: HelpOutlineIcon,
        subMenuItems: [...HELP_MENU_ITEMS],
      }}
      selected={selected}
      classes={classes}
      canOpenSubMenu={canOpenSubMenu}
      iconSize={iconSize}
      iconRef={settingsPageNavRef}
      closeHeader={closeHeader}
      handleTracking={handleTracking}
      renderSubMenuItems={HELP_MENU_ITEMS.map(({ key, label }) => (
        <MenuItem
          key={key}
          onClick={() => handleItemClick(key)}
          disabled={!hasWhatsNewJSON && key === 'whatsNew'}
        >
          {label}
        </MenuItem>
      ))}
    />
  );
};

export default HelpSupportButton;
