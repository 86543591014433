
import React from 'react';
import { connect } from 'react-redux';
import compose from 'utils/compose';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { noop } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

import { withStyles } from 'tss-react/mui';
import QDialogs from 'components/QDialogs';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/DeleteForever';

import { getLogger } from 'companion-app-components/utils/core';

import * as actions from 'data/documents/actions';
import * as documentSelectors from 'data/documents/selectors';
import { mkDocument } from 'data/documents/types';

import { makeQCSApiCall, makeGeneralApiCall } from 'data/apiUtil/actions';
import Dropzone from 'react-dropzone';

import { isAcme } from 'isAcme';
import { FileName, ModalDocDiv, DropzoneStyled, HiddenInput, UploadFileList } from './styledComponents';


const log = getLogger('components/AddAndBrowseDocuments/index.js');

const { MAX_TX_ATTACHMENTS } = documentSelectors;
/* TODO
Word of caution when working with previews

  Important: react-dropzone doesn't manage dropped files. You need to destroy the object URL yourself
  whenever you don't need the preview by calling window.URL.revokeObjectURL(file.preview); to avoid memory leaks.
  Support
*/

export const styles = (theme) => ({
  root: {
    display: 'block',
    padding: 8,
  },
  deleteIcon: {
    color: theme.components.addAndBrowserDocuments.deleteIconColor,
    cursor: 'pointer',
    transition: '.3s ease',
    marginLeft: 4,
    '&:hover': {
      transform: 'scale(1.25)',
    },
  },
  attachTitle: {
    color: theme.palette.greyScaleDeprecated[0],
    textTransform: 'uppercase',
    letterSpacing: 1.25,
  },
  thumbRail: {
    textAlign: 'center',
    flex: 1,
    margin: 'auto',
  },
  boxDiv: {
    marginBottom: 8,
    backgroundColor: isAcme ? theme.palette.background.default : 'unset',
    padding: theme.spacing(1, 0.5),
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    border: `1px solid ${theme.palette.greyScaleDeprecated[5]}`,
    borderRadius: 4,
  },
});


export class AddAndBrowseDocuments extends React.Component {

  constructor(props) {
    super(props);

    log.log('Building Document Adder');

    this.state = {
      images: [],
      imagesLoading: [],
      sizeChanged: false,
    };
    this.accepted = [];
  }

  componentDidMount() {
    this.processProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.txn.attachments !== nextProps.txn.attachments) {
      this.processProps(nextProps);
    }
  }

  componentDidUpdate() {
    if (this.state.sizeChanged) {
      this.state.sizeChanged = false;
      if (this.props.onSizeChange) {
        this.props.onSizeChange();
      }
    }
  }

  /* eslint-disable react/sort-comp */
  processProps = (props) => {
    log.log('Process Props', props, this.state.images);

    const currentImages = this.state.images || [];
    this.state.images = [];

    if (props.txn.attachments && props.txn.attachments.length > 0) {
      props.txn.attachments.forEach((docObj) => {

        const document = this.props.documents.find((x) => x.id === docObj.id);
        const alreadyHere = currentImages.find((x) => x.id === docObj.id);

        if (document && !alreadyHere) {
          const path = `documents/${docObj.id}/original`;
          const obj = {
            reqId: docObj.id,
            path,
            data: null,
            verb: 'get',
            cb: (err, data, id) => this.reqReturn({ err, data, id, document }),
          };
          log.log('Make API Call to:', obj);
          this.props.makeQCSApiCall(obj);
        } else if (alreadyHere) {
          this.state.images.push(alreadyHere);
        } else {
          // the document referred is not available
          const newArray = this.state.images;
          newArray.push({ id: docObj.id, url: 'unavailable', name: '-unavailable-', document });
          this.setState({ images: newArray, sizeChanged: true });
        }
      });
    }
  };

  reqReturn = ({ err, data, id, document }) => {

    if (err) {
      log.log(err, 'error');
      // error 400 means the docID has been deleted
      if (!err.response || err.response.status === 400) {
        const newArray = this.state.images;
        newArray.push({ id, url: 'unavailable', name: '-unavailable-', document });
        this.setState({ images: newArray, sizeChanged: true });
      }
    } else {
      const newArray = this.state.images;
      if (!newArray.find((item) => item.id === id)) {
        newArray.push({ id, url: data.data.url, document });
        this.setState({ images: newArray, sizeChanged: true });
      }
    }
  };

  onDrop = (acceptedFiles) => {  // , rejectedFiles <= NOT USED!
    if (acceptedFiles.length + this.accepted.length > MAX_TX_ATTACHMENTS) {
      this.props.dialogAlert(
        'Too many attachments',
        `Sorry, you can only add ${MAX_TX_ATTACHMENTS} items per transaction.`,
        noop,
        ['Got it'],
        'warning',
      );
    } else {
      this.onUpload(acceptedFiles);
    }
  };

  onUpload = (accepted) => {
    //
    // upload files to QCS
    //

    /* eslint-disable no-param-reassign */
    log.log('UPLOAD', accepted);
    if (accepted.length > 0) {
      accepted.forEach((rec) => {
        rec.clientId = uuidv4().toUpperCase();
        const newDoc = mkDocument(
          { name: rec.name,
            documentSize: rec.size,
            clientId: rec.clientId,
            contentType: rec.type,
          }
        );

        newDoc.set('updateFn', this.updatefn);
        log.log('Create Document ', newDoc, accepted);

        this.setState({ imagesLoading: this.state.imagesLoading.concat(
          [{ isLoading: true, document: newDoc }]
        ) });

        this.props.createDocument(newDoc);

        this.accepted = accepted;
        this.checkRecords(true);

      });
    }
  };

  // -------- CHECK RECORDS -----------------------------------
  // Polling for 300ms*20 to determine when the document is successfully established wtih QCS,
  // only then can we actually download the document
  checkRecordsCount = 0;
  checkRecords = (restart = false) => {

    this.checkRecordsCount = restart ? 0 : this.checkRecordsCount + 1;
    log.log('Add Document: checking records', this.accepted, this.checkRecordsCount);

    const newAccepted = [];
    this.accepted.forEach((rec) => {

      const newDoc = this.props.documents.find((x) => x.clientId === rec.clientId);
      if (newDoc?.url) {
        log.log('Found document in store ', newDoc);
        this.updateFn(newDoc, rec);
      } else if (!newDoc) {
        newAccepted.push(rec);
      }
    });

    this.accepted = newAccepted;

    if (this.accepted.length > 0) {
      if (this.checkRecordsCount < 240) {  // wait up to 2 minutes
        setTimeout(this.checkRecords, 500);
      } else {
        this.props.dialogAlert(
          'It appears there was an issue uploading your attachment',
          'The upload timed out, you can try again.  You may want to check your network connection',
          noop,
          ['Got it']
        );
      }
    }
  };

  updateFn = (doc, file) => {

    const data = {
      reqId: { id: doc.id, clientId: doc.clientId, name: file.name, document: doc },
      path: doc.url,
      callData: file,
      verb: 'put',
      cb: this.fileSent,
      headers: { 'Content-Type': doc.contentType },
    };

    log.log(`Sending File for ${file.name}`, data);
    this.props.makeGeneralApiCall(data);
  };

  fileSent = (err, data, reqObj) => {
    if (err) {
      this.props.dialogAlert(
        'Error saving an attachment',
        `Error: ${err}`,
        noop,
        ['Got it']
      );
    } else {
      // need id, url, document
      log.log('File Sent ', reqObj);

      // create a new attachments array for the transaction
      const attachments = [].concat(this.props.txn.attachments || []);
      attachments.push({ id: reqObj.id });
      this.props.onChange(attachments);
      // remove from the loading array
      const index = this.state.imagesLoading.findIndex((x) => x.document.clientId === reqObj.document.clientId);
      this.state.imagesLoading.splice(index, 1);
      this.setState({ imagesLoading: [].concat(this.state.imagesLoading) });
    }
  };

  deleteFileEntry = (id) => {
    const document = this.props.documents.find((x) => x.id === id);
    this.props.dialogAlert(
      'Delete Attachment',
      `This will delete the attachment ${document ? document.name : '-unavailable-'}.  Are you sure?`,
      (retObj) => {

        if (retObj.btnPressed === 'Delete') {
          // this.props.deleteDocument({ id });  // leaves it orphaned, not cleaning up unused document id's
          // delete it from the transactions attachments list
          // create a new attachments array for the transaction
          const attachments = [].concat(this.props.txn.attachments || []);
          const docIndex = attachments.findIndex((x) => x.id === id);
          if (docIndex >= 0) {
            attachments.splice(docIndex, 1);
          }
          this.props.onChange(attachments, false);  // do an autosave in this case
        }
      },
      ['Cancel', 'Delete'],
      'delete',
    );
  };

  clickPic = (file) => {
    if (file.url !== 'unavailable') {
      window.open(file.url, '_blank');
    }
  };

  //-------------------------------------------------------
  // RenderWindow
  //

  render() {

    const { classes } = this.props;

    const imagesToDisplay = this.state.images.concat(this.state.imagesLoading);

    const showGeneralLoading = (imagesToDisplay.length === 0) && this.state.images.length > 0;

    return (
      <div>
        <ModalDocDiv onKeyDown={this.handleKeyDown} autoFocus>
          <div className={classes.boxDiv}>
            <Typography style={{ marginLeft: 8, display: 'flex', alignSelf: 'flex-start', flexDirection: 'column', whiteSpace: 'nowrap' }}>Attachments</Typography>
            <div className="dropzone" id={this.props.id}>
              <Dropzone
                onDrop={this.onDrop}
                accept="app/*, image/*, application/*, text/*"
              >
                {({ getRootProps, getInputProps }) => (
                  <DropzoneStyled
                    className="custom"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <CloudUploadIcon />
                    <Typography className={classes.attachTitle} variant="subtitle2">
                      Upload
                    </Typography>
                  </DropzoneStyled>
                )}
              </Dropzone>
            </div>
          </div>
          <div style={{ textAlign: 'center', flex: 1, margin: 'auto' }}>
            {showGeneralLoading &&
            <CircularProgress
              size={32}
              color="primary"
            />}
            <UploadFileList>
              { imagesToDisplay.length > 0 &&
              imagesToDisplay.map((f, index) =>
                (
                  /* eslint-disable react/no-array-index-key */
                  <li
                    key={`${f.name}${index}`}
                  >
                    <ButtonBase
                      focusRipple
                      classes={{ root: classes.root }}
                      onClick={f.isLoading ? null : () => this.clickPic(f)}
                    >
                      {f.isLoading &&
                      <CircularProgress
                        color="primary"
                      />}
                      <FileName>
                        {f.url === 'unavailable' ? '-unavailable-' : f.document.name}
                      </FileName>
                      {!f.isLoading &&
                      <DeleteIcon
                        className={classes.deleteIcon}
                        onClick={(e) => { e.stopPropagation(); this.deleteFileEntry(f.id); }}
                      />}
                    </ButtonBase>
                  </li>))}
            </UploadFileList>
          </div>
          <HiddenInput
            autoFocus
            onBlur={this.lostFocus}
            onKeyDown={this.handleKeyDown}
            name={this.props.lastTabName}
          >
          </HiddenInput>
        </ModalDocDiv>
      </div>
    );
  }
}

AddAndBrowseDocuments.propTypes = {

  /* provided */
  id: PropTypes.string,
  onChange: PropTypes.func,
  txn: PropTypes.object.isRequired,
  onSizeChange: PropTypes.func,
  lastTabName: PropTypes.string,

  /* generated */
  createDocument: PropTypes.func,
  // deleteDocument: PropTypes.func,
  makeGeneralApiCall: PropTypes.func,
  makeQCSApiCall: PropTypes.func,
  classes: PropTypes.object,
  dialogAlert: PropTypes.func,
  documents: PropTypes.object,
};


function mapStateToProps(state) {  // state, ownProps <= NOT USED!
  return {
    documents: documentSelectors.getDocuments(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createDocument: (data) => dispatch(actions.createDocument(data)),
    deleteDocument: (data) => dispatch(actions.deleteDocument(data)),
    makeGeneralApiCall: (data) => dispatch(makeGeneralApiCall(data)),
    makeQCSApiCall: (data) => dispatch(makeQCSApiCall(data)),
  };
}

export default compose(
  QDialogs(),
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(AddAndBrowseDocuments, styles));
