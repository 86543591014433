import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utils/compose';
import withRouterLegacy from 'components/withRouterLegacy';
import { withTheme } from '@emotion/react';

import { chartOfAccountsUtils, chartOfAccountsSelectors } from 'companion-app-components/flux/chart-of-accounts';

import QTip from 'components/QuickenControls/QTip';
import QTypography from 'components/MUIWrappers/QTypography';

import { getFilteredChartOfAccountList } from './selectors';

import CategoryFieldEditable from './CategoryFieldEditable';

class CategoryField extends PureComponent {

  constructor(props) {
    super(props);

    const coaId = chartOfAccountsUtils.getCoaId(props.value, props.allowBlank); // allow blank
    const coaNode = this.getCoaNode(coaId, props); // props.coaNodesById ? props.coaNodesById.get(coaId) : null;

    this.state = {
      tfValue: coaId === 'BLANK' ? '' : this.getDisplayName(coaNode),
      isTransfer: this.isTransfer(coaNode),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (nextProps.coaNodesById) {
      if (nextProps.value !== this.props.value
        || nextProps.coaNodesById !== this.props.coaNodesById
        || nextProps.longCats !== this.props.longCats) {

        const coaId = chartOfAccountsUtils.getCoaId(nextProps.value, nextProps.allowBlank);
        const coaNode = this.getCoaNode(coaId, nextProps); // (coaId === 'BLANK' ? '' : nextProps.coaNodesById.get(coaId)); // allowBlank
        // todo: if coaNode cannot be found, set it to uncategorized coaNode

        if (coaNode) {
          this.setState({ isTransfer: this.isTransfer(coaNode), tfValue: this.getDisplayName(coaNode, nextProps) });
        } else {
          this.setState({ isTransfer: false, tfValue: '' });
        }
      }
    }
  }

  getCoaNode = (coaId, props) => {
    let ret = props.coaNodesById ? props.coaNodesById.get(coaId) : null;

    if (chartOfAccountsUtils.isTransferCoaNotFound(props.value)) {
      ret = props.coaNodesById.get(chartOfAccountsUtils.TRANSFER_NOT_FOUND_COA_ID);
    }
    return ret;
  };

  getDisplayName = (node, props = this.props) => {
    if (node) {
      return props.longCats ? node.fullPathName : node.name;
    }
    return 'NOTFOUND';
  };

  isTransfer = (node) => node && node.coa.type === 'ACCOUNT';

  makeTransferName = (val) => `${this.props.theme.components.register.transferDelimeter1}${val}${this.props.theme.components.register.transferDelimeter2}`;


  render() {
    const { editable, clickable, classNameWhenNotEditable, variant, disableTooltip, name, noWrap } = this.props;
    const toolTipTitle = this.state.isTransfer ? this.makeTransferName(this.state.tfValue) : this.state.tfValue;

    const Wrap = disableTooltip ? React.Fragment : QTip;
    const wrapProps = disableTooltip ? {} : { wrapOnly: true, title: this.props.tooltip ? toolTipTitle : null };

    return (
      <>
        {editable &&
        <CategoryFieldEditable sharedcomponentid={'CATEGORY_FIELD'} className={this.props.className} {...this.props} />}

        {!editable &&
        <Wrap
          {...wrapProps}
        >
          <QTypography
            variant={variant}
            clickable={clickable}
            className={classNameWhenNotEditable}
            sharedcomponentid={'CATEGORY_FIELD'}
            name={name}
            noWrap={noWrap}
          >
            {this.state.isTransfer ? this.makeTransferName(this.state.tfValue) : this.state.tfValue}
          </QTypography>
        </Wrap>}
      </>
    );
  }
}

CategoryField.propTypes = {
  // props from parent
  variant: PropTypes.string,
  editable: PropTypes.bool,
  value: PropTypes.object,
  name: PropTypes.string,
  className: PropTypes.string,
  clickable: PropTypes.bool,
  returnSelectionMethod: PropTypes.bool,
  longCats: PropTypes.bool,
  allowBlank: PropTypes.bool,
  onlyL1: PropTypes.bool,
  classNameWhenNotEditable: PropTypes.string,
  tooltip: PropTypes.bool, // show a tooltip on static value?
  fontSize: PropTypes.string,
  filterFn: PropTypes.func,
  disableTooltip: PropTypes.bool,
  noWrap: PropTypes.bool,

  theme: PropTypes.object,

  // props from redux
  coaNodesById: PropTypes.object,
  filteredCOAList: PropTypes.array,
};

CategoryField.defaultProps = {
  variant: 'body2',
  disableTooltip: false,
};

function mapStateToProps(state, ownProps) {
  return {
    coaNodesById: chartOfAccountsSelectors.getChartOfAccountNodesById(state, ownProps),
    filteredCOAList: getFilteredChartOfAccountList(state, ownProps),
  };
}

// note: order-matters - props flow from top to bottom
export default compose(
  withTheme,
)(withRouterLegacy(connect(mapStateToProps)(CategoryField)));

