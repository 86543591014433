import { chartOfAccountsTypes, chartOfAccountsUtils } from 'companion-app-components/flux/chart-of-accounts';
import { scheduledTransactionsTypes } from 'companion-app-components/flux/scheduled-transactions';

export function coaIncludedInList(coa, categoriesById, accountsById, stType) {
  switch (coa.type) {
    case chartOfAccountsTypes.CoaTypeEnum.CATEGORY: {
      const category = categoriesById.get(coa.id);
      if (category && category.isExcludedFromCategoryList) {
        return false;
      }
      break;
    }

    case chartOfAccountsTypes.CoaTypeEnum.BALANCE_ADJUSTMENT: {
      if (
        stType === scheduledTransactionsTypes.STTypeEnum.TRANSFER &&
        chartOfAccountsUtils.getBalanceAdjustmentString(coa.id) !== 'Transfer'
      ) {
        return false;
      }
      break;
    }

    case chartOfAccountsTypes.CoaTypeEnum.UNCATEGORIZED: {
      if (stType === scheduledTransactionsTypes.STTypeEnum.INCOME) {
        return false;
      }
      break;
    }

    case chartOfAccountsTypes.CoaTypeEnum.ACCOUNT: {
      const account = accountsById.get(coa.id);
      if (account && account.type === 'INVESTMENT') {
        return false;
      }
      break;
    }

    default:
      break;
  }
  return true;
}
