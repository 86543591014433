import * as React from 'react';
import { FC, useEffect } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import { DateTime } from 'luxon';
import { makeStyles } from 'tss-react/mui';
import { Map } from 'immutable';

import { tracker } from 'companion-app-components/utils/core';
import { BudgetItemType } from 'companion-app-components/flux/budgets/budgetsTypes';

import QMonthNavigator from 'components/QMonthNavigator';
import QButton from 'components/QButton';
import { BUDGET_VIEWS, BUDGET_ANNUAL_SUBVIEWS } from 'containers/BudgetsV2/constants';

import { budgetOptions, getBudgetViewStateObject } from '../../utils';
import { useBudgetOptions } from '../../hooks';
import NestedDropdown from '../NestedDropDown';
import styles from './styles';

const budgetMonthEditable = true;

const useStyles = makeStyles()(styles as Record<string, any>);

interface BudgetHeaderProps {
  budgets: Map<string, BudgetItemType>;
  budgetsCreation: boolean;
  budget: BudgetItemType;
  goNextMonth: () => void;
  goPrevMonth: () => void;
  goThisMonth: () => void;
  handleBudgetChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  startDate: Date | null;
  onEdit: () => void;
  onDelete: () => void;
  handleViewChange: (budget: BudgetItemType, annualSubView: string, evt: React.ChangeEvent<HTMLInputElement>) => void;
  handleAnnualSubViewChange: (budget: BudgetItemType, budgetView: string, evt: React.ChangeEvent<HTMLInputElement>) => void;
  dashboardRef: string | string[] | null;
}

const BudgetHeader: FC<BudgetHeaderProps> = ({
  isLoading,
  budget,
  budgets,
  budgetsCreation,
  goNextMonth,
  goPrevMonth,
  goThisMonth,
  handleBudgetChange,
  startDate,
  onDelete,
  onEdit,
  handleViewChange,
  handleAnnualSubViewChange,
  dashboardRef,
}) => {

  const { classes, theme }: { classes: Record<string, any>, theme: Record<string, any> } = useStyles();

  const { id, name } = budget;
  const { isToDateColumnEnabled, budgetView, annualSubView, setDatasetPreference } = useBudgetOptions(id, dashboardRef);

  const handleShowToDateSetting = () => {
    setDatasetPreference({ budgetOptions: { showToDateColumn: !isToDateColumnEnabled } });
    tracker.track(tracker.events.viewBudgetOptions, { showToDateColumn: isToDateColumnEnabled ? 'Unchecked' : 'Checked' });
  };

  useEffect(() => {
    let budgetsBarObserver: Record<string, any> | null = null;
    const budgetsBarSentinel = document.querySelector('#budgetsBarSentinel');
    if (budgetsBarSentinel && typeof IntersectionObserver !== 'undefined') {
      budgetsBarObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // setBudgetMonthEditable(false);
          } else {
            // setBudgetMonthEditable(true);
          }
        });
      }, {
        root: null,
        rootMargin: `-${theme.defaults.headers.headerHeight}px 0px 0px 0px`,
        threshold: 0,
      });
      budgetsBarObserver.observe(budgetsBarSentinel);
    }
    // PUT the monthly view in datasetPrereference, ONLY when user redirects from dashboard card
    // So, it doesn't jump to annual view when user clicks on breadcrumb or any budgetItem in monthly view.
    if (dashboardRef === 'dashboard' && budgetView === BUDGET_VIEWS.monthly.key) {
      setTimeout(() => {
        setDatasetPreference(getBudgetViewStateObject(id!, name!, budgetView, annualSubView, false));
      }, 1000);
    }

    return () => {
      budgetsBarObserver?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isLoading && (
    <AppBar
      position="sticky"
      elevation={0}
      className={classes.header}
    >
      <Toolbar className={classes.headerContent}>
        <Box sx={{ flexGrow: 1 }}>
          {budgets && budgets?.size > 1 &&
            <FormControl id="budgets-page-dropdown">
              <Select
                id="budgets-page-select"
                value={budget ? `${id}` : ''}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => handleBudgetChange(evt)}
                variant="outlined"
                size="small"
              >
                {budgets && budgets.valueSeq().map((theBudget, index) => (
                  <MenuItem
                    id={`budgets-select-${index}`}
                    value={theBudget.id}
                    key={theBudget.id}
                  >
                    {theBudget.name}
                    {budgets
                      && theBudget.currency
                      && budgets.reduce((set, aBudget) => { set.add(aBudget.currency); return set; }, new Set()).size > 1
                      && ` (${getSymbolFromCurrency(theBudget.currency)})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>}
          <FormControl id="budgets-view-dropdown" sx={{ ml: 2 }}>
            <Select
              id="budgets-view-select"
              value={budgetView}
              variant="outlined"
              size="small"
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => handleViewChange(budget, annualSubView, evt)}
            >
              {Object.values(BUDGET_VIEWS).map(({ key, label }) =>
                <MenuItem value={key} key={key}>{label}</MenuItem>)}
            </Select>
          </FormControl>
          {budgetView === BUDGET_VIEWS.annual.key && (
            <FormControl id="budgets-annual-subview-dropdown" sx={{ ml: 2 }}>
              <Select
                id="budgets-annual-subview-select"
                value={annualSubView}
                variant="outlined"
                size="small"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => handleAnnualSubViewChange(budget, budgetView, evt)}
              >
                {Object.values(BUDGET_ANNUAL_SUBVIEWS).map(({ key, label }) =>
                  <MenuItem value={key} key={key}>{label}</MenuItem>)}
              </Select>
            </FormControl>
          )}
          {budgetView === BUDGET_VIEWS.monthly.key && (
            <FormControl id="budgets-date-navigator" sx={{ ml: 2 }}>
              <QMonthNavigator
                date={DateTime.fromJSDate(startDate!)}
                onPreviousClick={goPrevMonth}
                onCurrentClick={goThisMonth}
                onNextClick={goNextMonth}
              />
            </FormControl>
          )}
        </Box>

        {budgetsCreation && budgetView !== BUDGET_VIEWS.annual.key &&
          <>
            <QButton onClick={onDelete}>
              <DeleteIcon style={{ marginRight: 7 }} />
              {`Delete ${name}`}
            </QButton>

            {budgetMonthEditable &&
              <QButton onClick={onEdit} color="secondary">
                <EditIcon style={{ marginRight: 7 }} />
                {`Edit ${name} `}
              </QButton>}
          </>}
        {budgetView === BUDGET_VIEWS.annual.key && (
          <NestedDropdown
            menuItemsData={budgetOptions({
              isToDateColumnEnabled,
              handleShowToDateSetting,
            })}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            MenuProps={{ elevation: 3 }}
            ButtonProps={{ variant: 'outlined' }}
          />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(BudgetHeader);
