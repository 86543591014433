import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

import { dispatchSimpleNotification } from 'data/notifications/notificationsUtils';

type VersionDisplayStateType = {
  showVersion: boolean,
};

const VersionDisplay: FC = () => {
  const [state, setState] = useState<VersionDisplayStateType>({
    showVersion: false,
  });
  const date: string = DateTime.fromRFC2822(process.env.COMMIT_TIME ?? '').toLocaleString(DateTime.DATETIME_SHORT);
  const version: string = `Version: ${process.env.APP_VERSION} (${process.env.BUILD_NUMBER}/${process.env.COMMIT} - ${date})`;

  const downHandler = (e) => {
    if (e.altKey) { // can't use 'control' key because of MAC's control + click
      setState({ showVersion: true });
    }
  };

  const upHandler = () => {
    setState({ showVersion: false });
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return (state.showVersion ?
    <Box
      position="fixed"
      right={0}
      bottom={0}
      p={1}
      color="black"
      zIndex="13000"
      onClick={() => {
        navigator?.clipboard?.writeText(version);
        dispatchSimpleNotification('Version copied to clipboard');
      }}
    >
      {version}
      &nbsp;<FileCopyOutlinedIcon fontSize="inherit" />
    </Box>
    :
    null
  );
};

export default VersionDisplay;
