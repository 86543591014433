import React, { FC } from 'react';
import { List } from 'immutable';
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import CircularProgress from '@mui/material/CircularProgress';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

import { authSelectors } from 'companion-app-components/flux/auth';

import QTip from 'components/QuickenControls/QTip';
import ErrorLinearProgress from 'components/ErrorLinearProgress';

import * as selectors from 'data/app/selectors';
import { appRefreshAll as refreshAllAction } from 'data/app/actions';

import { styles } from './styles';

const useStyle = makeStyles()(styles as Record<string, any>);

const renderMultipleMessages = (updateAllMessages) =>
  updateAllMessages.map((message) => {
    if (updateAllMessages.size > 7) {
      return (
        <Box key={message} component="span" display="inline" mr={0.5}>
          {message}
        </Box>
      );
    }
    return <div key={message}>{message}</div>;
  });

interface RefreshButtonProps {
  size: number;
  isInAccountBar?: boolean;
  closeHeader: () => void;
}

const RefreshButton: FC<RefreshButtonProps> = ({ size, isInAccountBar, closeHeader }) => {

  const { classes, theme } = useStyle();

  const disableRefreshButton: boolean = useSelector(selectors.disableRefreshButton);
  const isRefreshing: boolean = useSelector(selectors.isRefreshing);
  const updateAllFailed: boolean = useSelector(selectors.getUpdateAllFailed);
  // TODO: remove type once new app-component is installed
  const updateAllMessages: List<any> = useSelector(selectors.getUpdateAllMessages);
  const datasetId = useSelector(authSelectors.getDatasetId);

  const dispatch = useDispatch();

  const refreshAction = (e) => {
    closeHeader?.();
    e.stopPropagation();
    dispatch(refreshAllAction(undefined, { context: 'refreshAll' }));
  };

  // Main icon button that acme and quicken use
  const refreshIconRoot = (
    <IconButton
      tabIndex={isInAccountBar ? -1 : 0}
      aria-label="Refresh All"
      disabled={disableRefreshButton}
      classes={{
        root: classes.buttonRoot,
        disabled: classes.refreshButtonDisabled,
      }}
      // @ts-expect-error: No overload matches this call.
      style={{ color: !isInAccountBar && theme.components?.navigation.iconColor, padding: !isInAccountBar && 0 }}
      onClick={refreshAction}
      size="large"
    >
      {updateAllFailed ? <SyncProblemIcon style={{ fontSize: size }} /> : <RefreshIcon style={{ fontSize: size }} />}
    </IconButton>
  );


  if (!datasetId) {
    return null;
  }

  return isInAccountBar ?
    <>
      {isRefreshing && (
        <IconButton
          aria-label="Refresh All Busy"
          disabled
          classes={{ root: classes.buttonRoot }}
          size="large"
        >
          <CircularProgress
            size={size || 22}
            // @ts-expect-error: Property 'accountDrawer' does not exist on type
            style={{ color: theme.components.accountDrawer.refreshIcon }}
          />
        </IconButton>
      )}
      {!isRefreshing && (
        <QTip
          html={updateAllMessages && updateAllMessages.size ?
            renderMultipleMessages(updateAllMessages) :
            <div>Update All</div>}
          placement="right"
        >
          {refreshIconRoot}
        </QTip>
      )}
    </> :
    <div
      role="button"
      tabIndex={0}
      onClick={refreshAction}
      onKeyPress={refreshAction}
      className={classNames(classes.refreshDivButton, disableRefreshButton && classes.disabledRefreshDivButton)}
    >
      {isRefreshing ? (
        <IconButton
          aria-label="Refresh All Busy"
          disabled
          classes={{ root: classes.buttonRoot }}
          style={{ padding: 0 }}
          size="large"
        >
          <CircularProgress
            size={size || 22}
            // @ts-expect-error: Property 'navigation' does not exist on type 
            style={{ color: theme.components.navigation.iconColor }}
          />
        </IconButton>
      ) : (
        <QTip
          html={updateAllMessages?.map?.((message) => <div key={message}>{message}</div>)}
          placement="right"
        >
          {refreshIconRoot}
        </QTip>
      )}

      <Typography style={{ marginLeft: 24, textAlign: 'left' }} className={classes.navText}>
        Refresh
      </Typography>

      {updateAllFailed && <ErrorLinearProgress />}
    </div>;
};

export default RefreshButton;
