import moment from 'moment';
import { DateTime } from 'luxon';
import numeral from 'numeral';
import { List as ImmutableList, Set as ImmutableSet } from 'immutable';
import store from 'store';

import { accountsUtils } from 'companion-app-components/flux/accounts';
import { tagsSelectors } from 'companion-app-components/flux/tags';
import { transactionsTypes, transactionsUtils, transactionsTransformers } from 'companion-app-components/flux/transactions';
import { chartOfAccountsTypes, chartOfAccountsUtils } from 'companion-app-components/flux/chart-of-accounts';
import { categoriesSelectors } from 'companion-app-components/flux/categories';

import { memoizeWithKey } from 'utils/memoizeWithKey';
import { noNaN } from 'utils/utils';

import { getCurrencyString } from 'data/accounts/retrievers';
import { sumSplitRows } from 'data/transactions/splitsHelpers';

import { TransactionStatus } from './types';

//
// given an immutable list of transactions, will return the currency symbol appropriate to represent
// the group.  Either the one shared currency, or "N/A" if they differ
//
// pass in an array of transaction arrays (support for multiple transaction groups), if you only have
// one transaction list, pass in [txList]
//
export function getSharedCurrency(txns, accounts) {

  let currency = null;
  if (txns && accounts && accounts.size > 0) {
    txns.forEach((txnList) => {
      txnList.forEach((x) => {
        const acctRec = accounts.get(x.accountId);
        if (acctRec) {
          const tc = acctRec.get('currency');
          if (tc !== currency) {
            currency = (!currency ? tc : 'N/A');
          }
        }
      });
    });
  }
  return currency;
}

/*
 * getTxnStateInfo
 *
 * This is a mapping from txn SOURCE and STATUS to an object that indicates display information for the transaction
 * object returns contains 'entry', which is either 'bank', 'matched', 'manual', or 'scheduled', and a status which is a combination of
 * transaction state and source, and is one of the following:
 * PENDING
 * CLEARED
 * UPCOMING
 * DUE (Due Today)
 * OVERDUE
 */
export function getTxnStateInfo(txn) {
  const today = DateTime.local().startOf('day');
  const txnDay = DateTime.fromISO(txn.postedOn).startOf('day');
  const future = txnDay > today;

  const retObj = {
    entry: getMatchState(txn) === 'matched' ? 'matched' : 'bank',
    status: txn.state,
  };

  switch (txn.source && txn.source.trim()) {

    case 'QCS_REFRESH':
    case 'QCS_BATCH_REFRESH':
    case 'CLIENT_AGGREGATED':
      break;

    case 'QCS_REFRESH_BANK_PENDING':
    case 'QCS_BATCH_REFRESH_BANK_PENDING':
    case 'REAL_TIME':
      break;

    case 'USER_ENTERED':
      retObj.entry = getMatchState(txn) === 'matched' ? 'matched' : 'manual';
      if (txn.state === 'CLEARED' || txn.state === 'RECONCILED') {
        retObj.status = txn.state;
      } else {
        retObj.status = future ? 'UPCOMING' : 'PENDING';
      }
      break;

    case 'USER_OWNED_BANK_PENDING':
    case 'USER_OWNED_REAL_TIME_BANK_PENDING':
      break;

    case 'SCHEDULED_TRANSACTION_PENDING':
      retObj.status = future ? 'UPCOMING' : 'OVERDUE';
      if (txnDay.hasSame(today, 'day')) {
        retObj.status = 'DUE';
      }
      retObj.entry = (retObj.entry === 'matched') ? 'matched' : 'scheduled';
      break;

    case 'SCHEDULED_TRANSACTION_UNKNOWN':
    case 'SCHEDULED_TRANSACTION': {
      if (txn.state === 'PENDING') {
        retObj.status = future ? 'UPCOMING' : 'PENDING';
      } else {
        retObj.status = 'CLEARED';
      }
      retObj.entry = (retObj.entry === 'matched') ? 'matched' : 'scheduled';
      break;
    }

    case 'SCHEDULED_TRANSACTION_SKIPPED':
      // not applicable, this transaction should not be shown (soft deleted)
      break;

    case 'UNKNOWN':
      // should never happen - throw an event?
      retObj.entry = 'manual';
      retObj.status = future ? 'UPCOMING' : 'CLEARED';
      break;

    default:
      // this should REALLY never happen
      break;
  }

  return retObj;
}


export function isExistingTxn(txn) {
  return (txn?.id && (txn.id !== '0'));
}


function currenciesAreValidAndDifferent(a, b) {
  if (a === 'N/A' || b === 'N/A') {
    return false;
  }
  return (a !== b);
}

// -------------------------------------------------------
// setReportsFlags
export function setReportsFlags(txn, value) {
  return txn && txn.set('isExcludedFromReports', Boolean(value)).set('isExcludedFromF2S', Boolean(value));
}

// -------------------------------------------------------
// isMultiCurrencyTransferTxn
export function isMultiCurrencyTransferTxn(txn, state) {

  let isMultiCurrency = false;
  const txnCurrency = getCurrencyString(txn.accountId, state);

  if (transactionsUtils.isTransferTxn(txn)) {
    if (transactionsUtils.isSplitTxn(txn)) {
      txn.split.items.forEach((item) => {
        if (item.coa.type === 'ACCOUNT') {
          isMultiCurrency = isMultiCurrency || currenciesAreValidAndDifferent(getCurrencyString(item.coa.id, state), txnCurrency);
        }
      });
    } else {
      isMultiCurrency = currenciesAreValidAndDifferent(getCurrencyString(txn.coa.id, state), txnCurrency);
    }
  }
  return isMultiCurrency;
}

const pendingStates = [
  'QCS_REFRESH_BANK_PENDING',
  'QCS_BATCH_REFRESH_BANK_PENDING',
  'USER_OWNED_BANK_PENDING',
  'REAL_TIME',
  'USER_OWNED_REAL_TIME_BANK_PENDING',
];
export function isBankPendingTxn(txn) {
  return pendingStates.indexOf(txn.source) >= 0;
}

export function isBankDownloadedTxn(txn) {
  return txn && txn.cpData;
}

export function isPendingTxn(txn) {
  return txn && txn.state === 'PENDING';
}

export function isActionableScheduledInstance(txn, scheduledTransactionsById) {
  const stateInfo = getTxnStateInfo(txn);
  const status = { stateInfo };
  return ((status === 'DUE' || status === 'OVERDUE') || isNextScheduledInstance(txn, scheduledTransactionsById));
}

export function isNextScheduledInstance(txn, scheduledTransactionsById) {
  if (isUnacceptedScheduledTxn(txn)) {
    const model = scheduledTransactionsById.get(txn.stModelId);
    return (model && (moment(model.dueOn).format('MM/DD/YYYY') === moment(txn.stDueOn).format('MM/DD/YYYY')));
  }
  return false;
}

function sortedScheduledInstancesForAccount(accountTransactions, modelId) {
  let scheduledInstances = accountTransactions.filter((x) => isUnacceptedScheduledTxn(x) && modelId === x.stModelId);
  if (scheduledInstances) {
    scheduledInstances = scheduledInstances.toList();
    scheduledInstances = scheduledInstances.sort((a, b) =>
      DateTime.fromISO(a.postedOn) - DateTime.fromISO(b.postedOn));
  }
  return scheduledInstances;
}

// let memoCache = [];

export function nextScheduledInstanceAfterDate(date, accountTransactions, modelId) {

  const key = `${moment(date).format('YYYY-MM-DD')}-${modelId}-${accountTransactions.hashCode()}`;
  return memoizeWithKey(
    'nextScheduled',
    () => internalNextScheduledInstanceAfterDate(date, accountTransactions, modelId),
    key,
    50,
  );

  /*
  const index = memoCache.findIndex((x) => x.key === key);
  if (index !== -1) return memoCache[index].value;

  const ret = internalNextScheduledInstanceAfterDate(date, accountTransactions, modelId);
  memoCache.push({key, value: ret});
  if (memoCache.size > 50) {
    memoCache = memoCache.slice(1);
  }
  return ret;
   */
}

function internalNextScheduledInstanceAfterDate(date, accountTransactions, modelId) {

  const scheduledInstances = sortedScheduledInstancesForAccount(accountTransactions, modelId);
  const index = scheduledInstances.findIndex((x) => moment(x.postedOn).isAfter(moment(date)));
  if (index !== -1) {
    return scheduledInstances.get(index);
  }
  return null;
}

export function nextScheduledInstanceAfterTransaction(txn, accountTransactions) {
  const scheduledInstances = sortedScheduledInstancesForAccount(accountTransactions, txn.stModelId);
  const index = scheduledInstances.findIndex((x) => x.id === txn.id);
  if (index !== -1 && (index + 1) < scheduledInstances.size) {
    return scheduledInstances.get(index + 1);
  }
  return null;
}

export const isAcceptedScheduledTxn = (txn) => txn.source && txn.source === 'SCHEDULED_TRANSACTION';
export const isUnacceptedScheduledTxn = (txn) => txn.source && txn.source === 'SCHEDULED_TRANSACTION_PENDING';

export const isGoalTxnInNonGoalAccount = (txn, goalAccountIds) =>
  txn.coa && txn.coa.type === 'ACCOUNT' && goalAccountIds.contains(txn.coa.id) && !goalAccountIds.contains(txn.accountId);



// # ================================================================ #
// # - - -            Split logic/functions                     - - - #
// # ================================================================ #

// ----------------
// adjustSplitLine
// ----------------
// given a txn and the index of the splitItem just edited, perform the balancing
// and return the updated split
//
// expects an immutable split
export function adjustSplitLine(txn, index) {
  assert(index !== undefined, 'FORGOT TO PASS IN AN INDEX');
  let newSplit = txn.split;
  const { items } = newSplit;

  const diff = Number((Number(txn.amount) - sumSplitItems(newSplit)).toFixed(2));

  function adjustAmountAtIndex(i) {
    const otherItem = items.get(i);
    const newAmount = Number(Number(otherItem.amount + diff).toFixed(2));
    newSplit = newSplit.setIn(['items', i, 'amount'], newAmount);
  }

  if (items.size === 2) { // 2 items
    const otherIndex = index === 0 ? 1 : 0;
    adjustAmountAtIndex(otherIndex);

  } else { // multiple items

    // look for uncategorized item to change (that isn't the item you just edited)
    const uncatIndex = items.findIndex((item, currentIndex) =>
      (currentIndex !== index) && (item.coa.type === 'UNCATEGORIZED'));

    if (uncatIndex >= 0) {
      adjustAmountAtIndex(uncatIndex);

    } else {
      // add uncat item with the difference
      newSplit = newSplit.set('items', items.push(transactionsTypes.mkSplitItem({
        amount: diff,
        coa: { type: 'UNCATEGORIZED', id: '0' },
      })));
    }
  }
  return newSplit;
}

// ----------------
// removeSplitLine
// ----------------
// given a txn and the index of the splitItem to remove, perform the balancing
// and return the updated split (and category)
//
// expects an immutable split
export function removeSplitLine(txn, index) {
  assert(index !== undefined, 'FORGOT TO PASS IN AN INDEX');
  let newSplit = txn.split;
  const { items } = newSplit;

  const itemToRemove = items.get(index);

  // exit early if deleting a zero amount
  if (itemToRemove.amount === 0) {
    return (newSplit.deleteIn(['items', index]));
  }

  function adjustAmountAtIndex(i) {
    const otherItem = items.get(i);
    const newAmount = Number(Number(otherItem.amount + itemToRemove.amount).toFixed(2));
    newSplit = newSplit.setIn(['items', i, 'amount'], newAmount);
  }

  // push the amount somewhere else

  // look for uncategorized item to change (that isn't the item to delete)
  const uncatIndex = items.findIndex((item, currentIndex) =>
    (currentIndex !== index) && (item.coa.type === 'UNCATEGORIZED'));

  if (uncatIndex !== -1) { // uncat found
    adjustAmountAtIndex(uncatIndex);

  } else {
    // look for same category item (that isn't the item to delete)
    const sameCatIndex = items.findIndex((item, currentIndex) =>
      (currentIndex !== index) && (item.coa.type === itemToRemove.coa.type) && (item.coa.id === itemToRemove.coa.id));

    if (sameCatIndex !== -1) { // same cat found
      adjustAmountAtIndex(sameCatIndex);

    } else { // push to the top
      const defaultIndex = index === 0 ? 1 : 0;
      adjustAmountAtIndex(defaultIndex);
    }
  }
  newSplit = newSplit.deleteIn(['items', index]);

  return (newSplit);
}

// -------------------------------------------------------
// balanceSplitTxn
// made to work with JS objects and immutables
export function balanceSplitTxn(txn) {

  let newTxn = txn;
  if (isUnbalancedSplitTxn(txn)) {
    const isImmutable = txn.split.items.size !== undefined;

    // set toFixed and then back to number to avoid fixed precision calculation error
    const diff = Number((Number(txn.amount) - sumSplitItems(txn.split)).toFixed(2));
    const uncatItem = txn.split.items.findIndex((item) => item.coa.type === 'UNCATEGORIZED');

    // if there is an uncategorized row already, add the difference to it
    if (uncatItem >= 0) {
      const newAmount =
        Number((Number(isImmutable ?
          txn.split.items.get(uncatItem).amount
          : txn.split.items[uncatItem].amount) + diff).toFixed(2));

      if (isImmutable) {
        if (txn.setIn) { // txn object is immutable
          newTxn = txn.setIn(['split', 'items', uncatItem, 'amount'], newAmount);
        } else { // txn object javascript but split immutable
          newTxn.split = txn.split.setIn(['items', uncatItem, 'amount'], newAmount);
        }
      } else {
        newTxn.split.items[uncatItem].amount = newAmount;
      }

    // otherwise create a new uncategorized row
    } else if (isImmutable) {
      const items = txn.split.items.push(transactionsTypes.mkSplitItem({ amount: diff, coa: { type: 'UNCATEGORIZED', id: '0' } }));
      if (txn.setIn) { // txn object is immutable
        newTxn = txn.setIn(['split', 'items'], items);
      } else { // txn object javascript but split immutable
        newTxn.split = txn.split.set('items', items);
      }
    } else {
      newTxn.split.items.push({ coa: { type: 'UNCATEGORIZED', id: '0' }, amount: diff });
    }
  }
  return newTxn;
}

// -------------------------------------------------------
// isUnbalancedSplitTxn
// made to work with JS objects and immutables
export function isUnbalancedSplitTxn(txn) {

  if (transactionsUtils.isSplitTxn(txn)) {
    return Number(txn.amount).toFixed(2) !== sumSplitItems(txn.split).toFixed(2);
  }
  return false;
}

export function sumSplitItems(split) {
  let sum = 0;
  if (split && split.items) {
    split.items.forEach((item) => {
      sum += Number(item.amount);
    });
  }
  return sum;
}

// -------------------------------------------------------
// isUncategorizedTxn
export function isUncategorizedTxn(txn) {
  return (!transactionsUtils.isSplitTxn(txn) && (!txn.coa || txn.coa.type === 'UNCATEGORIZED'));
}

// -------------------------------------------------------
// txnHasTags
export function txnHasTags(txn) {
  return Boolean(txn.tags && txn.tags.size > 0);
}

// -------------------------------------------------------
// isSupportedTransferTxn
//
export function isSupportedTransferTxn(txn, state) {
  if (transactionsUtils.isSplitTxn(txn)) {
    return Boolean(txn.split.items.find((item) => chartOfAccountsUtils.isSupportedTransferCoa(item.coa, state)));
  }
  return chartOfAccountsUtils.isSupportedTransferCoa(txn.coa, state);
}

export function isSupportedTransferTxnWithFlags(txn, featureFlags, state) {

  if (!transactionsUtils.isTransferTxn(txn)) return false;
  return !txnHasUnsupportedTransfer(txn, featureFlags, state);
}

// -------------------------------------------------------
// isUnsyncedTransferTxn
//
export function isUnsyncedTransferTxn(txn, state) {

  if (transactionsUtils.isSplitTxn(txn)) {
    return Boolean(txn.split.items.find((item) => chartOfAccountsUtils.isUnsyncedTransferCoa(item.coa, state)));
  }
  return chartOfAccountsUtils.isUnsyncedTransferCoa(txn.coa, state);
}

// -------------------------------------------------------
// isLoanAcctTransferTxn
//
export function isLoanAcctTransferTxn(txn, state) {

  if (transactionsUtils.isSplitTxn(txn)) {
    return Boolean(txn.split.items.find((item) => chartOfAccountsUtils.isLoanAcctTransferCoa(item.coa, state)));
  }
  return chartOfAccountsUtils.isLoanAcctTransferCoa(txn.coa, state);
}

// -------------------------------------------------------
// txnHasUnsupportedTransfer
//
export function txnHasUnsupportedTransfer(txn, featureFlags, state) {

  if (!transactionsUtils.isTransferTxn(txn)) return false;

  const hideLoanTransactions = featureFlags.get('hideLoanTransactions');
  const hideConnectedLoanTransactions = featureFlags.get('hideConnectedLoanTransactions');

  if (transactionsUtils.isSplitTxn(txn)) {
    return Boolean(txn.split.items.find((item) => item.coa.type === 'ACCOUNT' &&
      accountsUtils.transactionsNotSupportedForAccountId(item.coa.id, hideLoanTransactions, hideConnectedLoanTransactions, state)));
  }
  return accountsUtils.transactionsNotSupportedForAccountId(txn.coa.id, hideLoanTransactions, hideConnectedLoanTransactions, state);
}


// -------------------------------------------------------
// isInvestmentAcctTransferTxn
//
export function isInvestmentAcctTransferTxn(txn, state) {

  if (transactionsUtils.isSplitTxn(txn)) {
    return Boolean(txn.split.items.find((item) => chartOfAccountsUtils.isInvestmentAcctTransferCoa(item.coa, state)));
  }
  return chartOfAccountsUtils.isInvestmentAcctTransferCoa(txn.coa, state);
}

// -------------------------------------------------------
// Returns null if edits are allowed, otherwise returns
// a reason code
// UNSUPPORTED_SCHED_TRANSFER
// BILLPAY_TRANSACTION
//
export function transactionEditsNotAllowed(txn, state) {
  // if this is a transfer, and a scheduled instance make sure it is a legit transfer
  if (transactionsUtils.isTransferTxn(txn) && isUnacceptedScheduledTxn(txn, state) && !isSupportedTransferTxn(txn, state)) {
    return 'UNSUPPORTED_SCHED_TRANSFER';
  }
  if (txn.paymentProvider === 'OFX') {
    return 'BILLPAY_TRANSACTION';
  }
  return null;
}

//-----------------------------------------------------------------------
//
// getMatchState
//
// For any transaction will return the following
//
// 'manual' - the transaction has no cpData
// 'matched' - the transaction has cpData and is matchstate is one of the MATCHED states
// 'downloaded' - the transaction has cpData, is not matched, and is not reviewed
//
export function getMatchState(txn) {
  if (txn.cpData) {
    if ((txn.matchState === 'AUTO_MATCHED') || (txn.matchState === 'USER_MATCHED')) {
      return 'matched';
    }
    return ('downloaded');
  }
  return ('manual');
}

export function isBankPending(txn) {
  return isBankPendingTxn(txn); // /.+_BANK_PENDING$/.test(txn.source);
}

export function isUserOwned(txn) {
  // return (txn.source.indexOf('USER_') === 0 || txn.source.indexOf('SCHEDULED_TRANSACTION') === 0);
  return /^USER_.+/.test(txn.source) || /^SCHEDULED_TRANSACTIO.+/.test(txn.source);
}

export function isBankOwnedPending(txn) {
  return txn.source && isBankPendingTxn(txn) && !isUserOwned(txn);
}

// check is SCHEDULED and BANK_PENDING txn
export function isUnAcceptedTxnType(txn) {
  return isUnacceptedScheduledTxn(txn) || isBankOwnedPending(txn);
}

export function isUnAcceptedBankPendingTxn(account, txn) {
  return account.isBankPendingTxnsExcluded && isBankOwnedPending(txn);
}

// A "match" is one which has a user-owned txn matched to a posted-txn. This is specifically excluding a
// transactions that are user-owned and matched to bank-pending txn.
export function isMatchedToPostedTxn(txn) {
  return isUserOwned(txn) && txn.cpData && txn.cpData.id && txn.matchedTxn?.id;
}

export function isAssociatedWithBankPendingTxn(txn) {
  return txn.cpData && (txn.cpData.pendingId || txn.cpData.cpPendingId);  // cpPendingId will become pendingId, backward compatible
}

export function areMatchable(txn1, txn2) {
  let srcTxn;
  let dstTxn;

  // console.log("ARE MATCHABLE ", txn1, txn2);

  if (isUserOwned(txn1)) {
    if (isUserOwned(txn2)) {
      return false;  // one must be non user-owned
    }
    srcTxn = txn2;
    dstTxn = txn1;
  } else {
    if (!isUserOwned(txn2)) {
      return false;  // one must be user-owned
    }
    srcTxn = txn1;
    dstTxn = txn2;
  }
  if (srcTxn.accountId !== dstTxn.accountId) {
    return false;  // they must belong to the same account
  }
  if (isMatchedToPostedTxn(dstTxn)) {
    return false;  // already matched to posted-txn (need to first unmatch before re-matching)
  }
  if (isBankPending(srcTxn) && isAssociatedWithBankPendingTxn(dstTxn)) {
    return false;  // destination txn is already associated with a bank-pending txn
  }
  return true;
}

// used to update txns in preprocess (allTxnsToUpdate/allTxnsToCreate)
export function replaceInArray(array, itemToReplace) {
  const foundIndex = array.findIndex((item) => (item?.id === itemToReplace?.id) || (item?.clientId === itemToReplace?.clientId));
  const found = foundIndex > -1;
  if (found) {
    array.splice(foundIndex, 1, itemToReplace);
  }
  return found;
}


// When entering a new transfer transaction, looks for an already existing transaction that may
// be a possible match for user prompting
//
export function addPossibleMatch(match, baseTxn, xfrTxn, allMatches, forceWarn = false) {
  const possibleMatch = match.merge({
    coa: { id: baseTxn.accountId, type: 'ACCOUNT' },
    isExcludedFromF2S: true,
    isExcludedFromReports: true,
    transfer: { id: baseTxn?.id, clientId: baseTxn?.clientId },
  });
  const updatedBase = baseTxn.set('transfer', { clientId: possibleMatch?.clientId, id: possibleMatch?.id });
  allMatches.push({ matchingNewTxn: xfrTxn, possibleMatch, baseTxn: updatedBase, forceWarn });
}

//
// Given a user provided amount, proposedSign is either 1 or -1
//
export function quickenAmountToString(amountStr, proposedSign = 1) {

  // this just safety checks the value to ensure it is either 1 or -1
  let sign = ((proposedSign / proposedSign || 1) * Math.sign(proposedSign)) || 1;

  if (String(amountStr).charAt(0) === '+') {
    sign = 1;
  }
  if (String(amountStr).charAt(0) === '-') {
    sign = -1;
  }
  return numeral(Math.abs(Number(amountStr)) * sign).format('0.00');
}


//-------------------------------------------------------
// findTransaction
//
export function findTransaction(txnsByAccountId, txnId, accountId) {
  if (accountId) {
    const acctTxns = txnsByAccountId?.get(accountId);
    if (acctTxns) {
      const foundTxn = acctTxns.get(txnId);
      if (foundTxn) return foundTxn;
    }
  }

  // slow way
  let ret = null;
  txnsByAccountId.some((txnList) => {
    const foundTxn = txnList.get(txnId);
    if (foundTxn) {
      ret = foundTxn;
      return true;
    }
    return false;
  });
  return ret;
}

export function getTxnDifferences(txn, txnPrev) {

  const flds = ['accountId', 'postedOn', 'payee', 'coa', 'amount', 'userFlag',
    'memo', 'state', 'isReviewed', 'tags', 'check', 'attachments', 'split', 'isExcludedFromReports', 'isExcludedFromF2S', 'isDeleted'];
  const changed = [];
  flds.forEach((fld) => {
    if (fieldChanged(txn, txnPrev, fld)) {
      changed.push(fld);
    }
  });
  return changed;
}

export function fieldChanged(txn, txnPrev, field) {

  // this is checking if one or the other is null, however, we have to do a bit more
  // for splits since even though .split may not be null, it can still be a non-split if
  // there are no items

  // should not be called with either value being null
  if (!txn || !txnPrev) {
    return false;
  }

  if (!txn[field] || !txnPrev[field]) {
    return field === 'split' ?
      transactionsUtils.isSplitTxn(txn) !== transactionsUtils.isSplitTxn(txnPrev) :
      Boolean(txn[field]) !== Boolean(txnPrev[field]);
  }
  switch (field) {

    case 'amount': {
      return (Number(txnPrev.amount) !== Number(txn.amount));
    }

    case 'postedOn': {
      return (!moment(txnPrev.postedOn).isSame(moment(txn.postedOn), 'day'));
    }

    case 'coa': {
      return coasAreDifferent(txnPrev.coa, txn.coa); // ((txnPrev.coa.id !== txn.coa.id) || (txnPrev.coa.type !== txn.coa.type));
    }

    case 'check': {
      return (txnPrev.check.number !== txn.check.number);
    }

    case 'attachments': {
      const sum1 = txn[field].sort((a, b) => a > b ? 1 : -1).reduce((total, num) => `${total}-${num}`, '');
      const sum2 = txnPrev[field].sort((a, b) => a > b ? 1 : -1).reduce((total, num) => `${total}-${num}`, '');
      return sum1 !== sum2;
    }

    case 'tags': {
      return !areTagsEqual(txn[field], txnPrev[field]);
    }

    case 'split': {
      if (transactionsUtils.isSplitTxn(txn) !== transactionsUtils.isSplitTxn(txnPrev)) {
        return true;
      }
      const sum1 = txnPrev.split.items.sort((a, b) =>
        a.coa.id > b.coa.id ? 1 : -1).reduce((total, item) =>
        `${total}-${splitStringVal(item)}`, '');

      const sum2 = txn.split.items.sort((a, b) =>
        a.coa.id > b.coa.id ? 1 : -1).reduce((total, item) =>
        `${total}-${splitStringVal(item)}`, '');

      return sum1 !== sum2;
    }
    default:
      if (txnPrev) {
        return txn[field] !== txnPrev[field];
      }
  }
  return false;
}

function splitStringVal(item) {
  return `${item.coa.id}-${Number(item.amount).toFixed(2)}-${item.memo}-${item.tags ? item.tags.length : 'none'}`;
}

export function areTagsEqual(tags1, tags2) {
  if (!tags1 && !tags2) {
    return true;
  }
  if (!tags1 || !tags2) {
    return false;
  }
  const sum1 = tags1.sort((a, b) => a.id > b.id ? 1 : -1).reduce((total, num) =>
    `${total}-${num.id}`, '');
  const sum2 = tags2.sort((a, b) => a.id > b.id ? 1 : -1).reduce((total, num) =>
    `${total}-${num.id}`, '');
  return sum1 === sum2;
}


// The code below is used to find possible transfers for new transactions being inserted.  When a new
// transfer transaction is entered, we look in the transfer account for a possible transaction match that
// may already be there, and offer the user to match to that transaction rather than create a dup
//
function isPossibleMatch(txn, postedOn, amount, days = 31) {

  const diff = DateTime.fromISO(postedOn).diff(DateTime.fromISO(txn.postedOn), 'days').days;

  return !transactionsUtils.isSplitTxn(txn) && !transactionsUtils.isTransferTxn(txn) && (Math.abs(diff) <= days) && (Number(amount) === Number(txn.amount));
}

export function sortTxnsByDateDifference(txns, referenceDate) {
  const referenceDateTime = DateTime.fromISO(referenceDate);
  return txns.sort((a, b) => {
    const diff1 = Math.abs(DateTime.fromISO(a.postedOn).diff(referenceDateTime, 'days').days); // Math.abs(moment(a.postedOn).diff(moment(referenceDate), 'days'));
    const diff2 = Math.abs(DateTime.fromISO(b.postedOn).diff(referenceDateTime, 'days').days);
    if (diff1 === diff2) {
      return 0;
    }
    if (diff1 > diff2) {
      return 1;
    }
    return -1;
  });
}

export function findPossibleTransferCandidate(accountId, postedOn, amount, txnsByAccountId, days) {

  const acctTxns = txnsByAccountId.get(accountId) || ImmutableList([]);
  const txns = acctTxns.filter((tx) => isPossibleMatch(tx, postedOn, amount, days)).toList();
  if (txns) {
    return sortTxnsByDateDifference(txns, postedOn).first();
  }
  return null;
}

export function possiblyMatchTransfer(_txn, txnsByAccountId) {

  let txn = _txn;

  // console.log("Checking for L1 Transfer... ", txn.coa, isL1TransferCoa(txn.coa));
  if (!transactionsUtils.isSplitTxn(txn) && !transactionsUtils.isTransferTxn(txn) && chartOfAccountsUtils.isL1TransferCoa(txn.coa)) {
    let matches = ImmutableList();
    txnsByAccountId.forEach((acctTxns, acctId) => {
      if (acctId !== txn.accountId) {
        // check if there is a matching transaction and score it
        const match = findPossibleTransferCandidate(acctId, txn.postedOn, -txn.amount, txnsByAccountId, 5);
        if (match) {
          matches = matches.push(match);
        }
      }
    });
    if (matches.size > 0) {
      // console.log("ALL POSSIBLE MATCHES ARE ", matches.toJS());
      const bestMatch = sortTxnsByDateDifference(matches, txn.postedOn).first();

      txn = txn.set('coa', { type: 'ACCOUNT', id: bestMatch.accountId });
    }
  }
  return txn;
}

export function coasAreDifferent(coa1, coa2) {
  if (!coa1 && !coa2) {
    return false;
  }
  if (!coa1 || !coa2) {
    return true;
  }
  return (coa1.type !== coa2.type) || (coa1.id !== coa2.id);
}

export function transactionsSortFunction(a, b) {

  // note, these are day boundary values constructed by convertToUnixDay
  let cmpValue = DateTime.fromISO(a.postedOn) - DateTime.fromISO(b.postedOn);

  if (cmpValue === 0) {
    // we sort balance adjustments to the top
    if ((a.coa && a.coa.type === 'BALANCE_ADJUSTMENT') || b.id?.substr(0, 3) === 'new') {
      cmpValue = -1;
    } else if ((b.coa && b.coa.type === 'BALANCE_ADJUSTMENT') || a.id?.substr(0, 3) === 'new') {
      cmpValue = 1;
    } else {
      const va = Number(a.id) || Number(b.id) + 1;
      const vb = Number(b.id) || Number(a.id) + 1;
      cmpValue = va - vb;
    }
  }
  return cmpValue;
}

// TODO Need UNIT TESTS for this potentially data damaging functionality

// determine if this transaction has the given tag id in it
export function txnHasTag(txn, tag) {
  if (transactionsUtils.isSplitTxn(txn)) {
    const ret = txn.split.items.find((item) =>
      item.tags && item.tags.find((rec) => rec.id === tag.id));
    return Boolean(ret);
  }
  return (txn.tags && txn.tags.filter((t) => t.id === tag.id).size > 0);
}

// remove the reference to the given tag id from the transaction
export function removeTxnTag(txn, tag) {
  if (transactionsUtils.isSplitTxn(txn)) {
    return txn.setIn(['split', 'items'], txn.split.items.map((item) => item.tags ? item.set('tags', item.tags.filter((t) => t.id !== tag.id)) : item));
  }
  return txn.set('tags', txn.tags.filter((t) => t.id !== tag.id));
}

// simple protected dateTime check
export function dateTimeFromTxn(txn) {
  return ((txn.postedOn && DateTime.fromISO(txn.postedOn)) || (txn.stDueOn && DateTime.fromISO(txn.stDueOn)) || DateTime.local()).startOf('day');
}

/*
 * deDupeTxns
 *
 * Given a immutable list of transactions, will identify duplicates by txnId, and remove them so the returned list
 * contains only unique transactions
 */
export function deDupeTxns(txns) {
  let exclusionSet = new ImmutableSet();
  return txns.filter((txn) => {
    const dup = exclusionSet.includes(txn.id);
    exclusionSet = exclusionSet.add(txn.id);
    return !dup;
  });
}

/*
 * CalendarTxnType can be [income | expense | overdue | normal | more]
 */

export const getCalendarTxnType = (txn, incomeCOAs) => {
  const txnState = getTxnStateInfo(txn);
  const isIncome = transactionsUtils.isIncomeFromTxn(txn, incomeCOAs);

  let type = transactionsTypes.calTxnLabelTypes.normal;

  if (txnState.status === 'OVERDUE') {
    type = transactionsTypes.calTxnLabelTypes.overdue;
  }

  if (txnState.status !== 'OVERDUE' && txnState.entry === 'scheduled') {
    type = isIncome ? transactionsTypes.calTxnLabelTypes.income : transactionsTypes.calTxnLabelTypes.expense;

    if (isAcceptedScheduledTxn(txn)) {
      type = transactionsTypes.calTxnLabelTypes.normal; // Entered txn
    }
  }

  return type;
};

export const isRefundVirtualTxn = (txn) => txn?.subtype === 'REFUND_SHADOW_TXN' || (txn?.subtype === 'REFUND' && txn?.state === 'PENDING');
export const isRefundTxn = (txn) => txn?.subtype === 'REFUND_SHADOW_TXN' || txn?.subtype === 'REFUND';

export const RefundState = Object.freeze({
  EXPECTED: 'EXPECTED',
  OVERDUE: 'OVERDUE',
  COMPLETE: 'COMPLETE',
  SHADOW: 'SHADOW',
  NONREFUND: 'NONREFUND',
});

export const getRefundState = (transaction) => {
  let refundState;

  switch (transaction.subtype) {
    case 'REFUND':
      if (transaction.state === 'PENDING') {
        const dateNow = DateTime.local().startOf('day');
        const dateRefund = DateTime.fromISO(transaction.postedOn);
        if (dateRefund < dateNow) {
          refundState = RefundState.OVERDUE;
        } else {
          refundState = RefundState.EXPECTED;
        }
      } else {
        refundState = RefundState.COMPLETE;
      }
      break;
    case 'REFUND_SHADOW_TXN':
      refundState = RefundState.SHADOW;
      break;
    default:
      refundState = RefundState.NONREFUND;

  }

  return refundState;
};

// -------------------------------------------------------
// updateNewTags
//
// takes an immutable transaction, and checks for tags with ID="0" and tries to
// find a tag that is suitable with a real ID.  Returns an immutable transaction
// with updates if they were needed
//
export function updateNewTags(txn, dropPlaceholders = false, state = store.getState()) {

  let retTxn = txn;
  if (txn.tags) {
    const visited = [];
    const newTags = txn.tags.filter((tag) => { // remove dupes or empty entries
      if (tag) {
        const alreadyHas = visited.find((x) => (x.id === tag.id) && (x.id !== '0' || x.name === tag.name));
        if (!alreadyHas) {
          visited.push(tag);
          return true;
        }
      }
      return false;
    }).map((tag) => { // find current tag
      if (noNaN(tag?.id) === 0) {
        const ret = tagsSelectors.getTagByName(state, tag.name);
        return ret && ret.id ? { id: ret.id } : tag;
      }
      return tag;
    });
    retTxn = txn.set('tags', newTags.filter((x) => x !== null && (!dropPlaceholders || noNaN(x?.id) !== 0)));
  }
  if (transactionsUtils.isSplitTxn(retTxn)) {
    const newItems = retTxn.split.items.map((item) => {
      if (item?.tags) {
        const newSplitTags = item.tags.map((tag) => {
          if (noNaN(tag.id) === 0) {
            const ret = tagsSelectors.getTagByName(store.getState(), tag.name);
            if (ret && ret.id) {
              return { id: ret.id };
            }
            return dropPlaceholders ? null : tag;
          }
          return tag;
        });
        if (item.set && (typeof item.set === 'function')) { // immutable object
          return item?.set('tags', newSplitTags.filter((x) => x !== null && (!dropPlaceholders || noNaN(x.id) !== 0))) || item;
        }
        const newItem = item;
        newItem.tags = newSplitTags.filter((x) => x !== null && (!dropPlaceholders || noNaN(x.id) !== 0)) || item;
        return newItem;
      }
      return item;
    });

    retTxn = retTxn.setIn(['split', 'items'], newItems);
  }
  return retTxn;
}



/**
 * memorizedPayee list of memorized payees & payees list from txns
 * @param {*} txn 
 * @param {*} memorizedPayee 
 * @returns txn with memorized payee values
 */
export function updateMemorizePayeeValues(txn, memorizedPayee) {
  let retTxn = txn;
  // downloaded txn amount is not changed
  if (!retTxn.get('cpData') && memorizedPayee.isMemorizedPayee) {
    retTxn = retTxn.set('amount', memorizedPayee.txn.amount);
  }

  if (transactionsUtils.isSplitTxn(memorizedPayee.txn)) {
    // We need to null out the id's for the split items
    /* eslint-disable arrow-body-style */
    let newSplitItems = memorizedPayee.txn.split.items.map((item) => {
      return { ...(item.toJS()), id: null };
    });

    // add adjustment amount with split for downloaded txn
    if (retTxn.get('cpData')) {
      const adjAmount = txn.get('amount') - sumSplitRows(newSplitItems);

      const newRow = {
        memo: '',
        tags: [],
        coa: { type: chartOfAccountsTypes.CoaTypeEnum.UNCATEGORIZED, id: '0' },
        amount: numeral(adjAmount).format('0.00'),
      };
      newSplitItems = newSplitItems.push(newRow);
    } else {
      retTxn = retTxn.set('amount', memorizedPayee.txn.amount);
    }

    retTxn = retTxn.set('split', transactionsTransformers.mkSplit({ items: newSplitItems }));
    retTxn = retTxn.set('coa', null);
  } else {
    retTxn = retTxn.set('coa', memorizedPayee.txn.coa);
    retTxn = retTxn.set('split', null);
  }

  retTxn = retTxn.set('tags', memorizedPayee.isMemorizedPayee ? memorizedPayee.txn.tags : null);
  retTxn = retTxn.set('memo', memorizedPayee.isMemorizedPayee ? memorizedPayee.txn.memo : null);

  if (memorizedPayee.isMemorizedPayee) {
    retTxn = retTxn.set('state', memorizedPayee.markAsCleared ? TransactionStatus.CLEARED : TransactionStatus.PENDING);
  }

  return retTxn;
}

/**
 * txnCategorySign
 * @param {*} txn 
 * @returns 
 */
export function txnCategorySign(txn) {

  if (transactionsUtils.isSplitTxn(txn)) {
    return 1;
  }
  const isIncomeCat = txn.coa ? categoriesSelectors.isIncomeCat(null, txn.coa.id) : false;

  return isIncomeCat ? 1 : -1;
}
