////////////////////////////////////////////
import * as React from 'react';
import ReactDOM from 'react-dom';
import { withStyles } from 'tss-react/mui';
import { withTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { Map as ImmutableMap } from 'immutable';
import { DateTime } from 'luxon';
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import { useUpdate, useToggle, useMount, useKey } from 'react-use';

import Dialog from '@mui/material/Dialog';
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import BuildIcon from '@mui/icons-material/Build';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Radio from '@mui/material/Radio';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ClearIcon from '@mui/icons-material/Clear';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';

import { 
  getBuildConfig,
  getLogger,
  loggerConfigurator,
  crashReporterInterface,
  tracker,
  localPreferences,
} from 'companion-app-components/utils/core';
import { authActions, authSelectors } from 'companion-app-components/flux/auth';
import { profileSelectors } from 'companion-app-components/flux/profile';
import { featureFlagsActions, featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';
import { accountsSelectors } from 'companion-app-components/flux/accounts';
import { coreActions } from 'companion-app-components/flux/core-actions';
import { npsActions } from 'companion-app-components/flux/nps';
import * as prefsActions from 'companion-app-components/flux/preferences/actions';

import compose from 'utils/compose';
import { dispatchSimpleNotification } from 'data/notifications/notificationsUtils';
import { createDialog } from 'data/rootUi/actions';
import { mkRootUiData } from 'data/rootUi/types';
import { DIALOG_TYPE_EMPTY_DIALOG } from 'components/Dialogs/EmptyDialog';
import RequestDialog from 'components/RequestDialog';
import HighlightText from 'components/HighlightText';
import NestedMenuItem from 'components/QuickenControls/NestedMenuItem';
import QDialogs from 'components/QDialogs';
import QSwitch from 'components/QSwitch';
import QButton from 'components/QButton';
import QPreferences from 'components/QPreferences';
import * as budgetsSelectors from 'companion-app-components/flux/budgets/budgetsSelectors';
import { updatePreferenceCriteria } from 'data/preferencesV2/preferencesV2Actions';
import { bumpActions } from 'companion-app-components/flux/bump';
import * as entitlementsActions from 'data/entitlements/entitlementsActions';
import { getSubscriptionsOverride } from 'data/subscriptions/helpers';
import * as syncSentimentActions from 'data/syncSentiment/syncSentimentActions';
import QHelp from 'components/QHelp';
import { safeRefIn } from 'utils/utils';
import { mkSubscription } from 'data/subscriptions/subscriptionsTypes';
import { setAppProps } from 'data/app/actions';
import * as entitlementsSelectors from 'data/entitlements/entitlementsSelectors';
import { mkEntitlement } from 'data/entitlements/entitlementsTypes';
import * as subscriptionsSelectors from 'data/subscriptions/subscriptionsSelectors';
import store from 'companion-app-components/utils/redux-store';
import useQPreferences from 'components/QPreferences/useQPreferences';
import { hydrateImmutable } from 'companion-app-components/utils/redux-store/immutableSerialize';
import consoleMirror from 'utils/consoleMirror';
import { getDBUpdateTrigger } from 'data/app/selectors';
import { getPreferencesV2 } from 'data/preferencesV2/preferencesV2Selectors';
import { mkPreferenceCriteria } from 'data/preferencesV2/preferencesV2Types';
import * as selectorsProfile from 'utils/selectorsProfile';
import { saveStore } from 'utils/storeUtils';
import { defaultDatasetPreferences } from 'data/preferences/defaults';
import * as sessionStorageEx from 'utils/sessionStorageEx';
import { resetVersionWarning } from '../Main';
import { LIST_OF_COMPONENT_IDS } from '../../SharedComponentsPage/config';
import ReusableComponentTag from '../../SharedComponentsPage/ReusableComponentTag';
import investmentTransactions from './investmentTransactions';
import * as bankTransactions from './bankTransactions';

const logger = getLogger();

const styles = (_theme) => ({
  fullWidth: {
    width: '100%',
  },
});

interface RequestDataProps {
  showRequestDialog: boolean,
  requestMethod: 'GET' | 'PUT' | 'POST' | 'DELETE',
  requestPath: string,
  requestQuery: { limit: number } | undefined,
  requestBody: string | undefined
}

const DevMenu: React.FC = (props: any) => {

  const { dialogAlert, setUserPreference, showQHelp, theme } = props;

  const dispatch = useDispatch();

  const authSession: any = useSelector(authSelectors.getAuthSession);
  const datasetId = useSelector(authSelectors.getDatasetId);
  const profile = useSelector(profileSelectors.getProfile);
  const subscription: any = useSelector(subscriptionsSelectors.getActiveSubscription); // FIXME
  const entitlement: any = useSelector(entitlementsSelectors.getTopTierEntitlement);
  const accounts = useSelector(accountsSelectors.getAccountsById);
  const featureFlags: any = useSelector(featureFlagsSelectors.getFeatureFlags);
  const entitlements: any = useSelector(entitlementsSelectors.getEntitlements);
  const preferencesV2: any = useSelector(getPreferencesV2);
  const budgets: any = useSelector(budgetsSelectors.getBudgets);

  const forceUpdate = useUpdate();
  const [openMenu, toggleMenu] = useToggle(false);
  const [simulateCrash, setSimulateCrash] = React.useState(false);
  const [showLoadDB, toggleLoadDB] = useToggle(false);
  const [showDevMenu, toggleDevMenu] = useToggle(true);
  const [featureFlagFilter, setFeatureFlagFilter] = React.useState('');
  const [preferenceSearchKey, setPreferenceSearchKey] = React.useState<string | undefined>(undefined);
  const [datasetPreferenceSearchKey, setDatasetPreferenceSearchKey] = React.useState('');
  const [mcpTestMode, setMcpTestMode] = React.useState(localPreferences.getMCPTestMode() === 'true');
  
  const [requestData, setRequestData] = React.useState<RequestDataProps>({
    showRequestDialog: false,
    requestMethod: 'GET',
    requestPath: '/accounts',
    requestQuery: { limit: 200 },
    requestBody: undefined,
  });

  const menuButtonRef = React.useRef<any>();

  const { datasetPreferences, setDatasetPreference } = useQPreferences();

  const lastViewedBudgetId = datasetPreferences?.budgetOptions?.lastViewedBudgetId;
  let budgetFullCalcPath = '/budget-calc';
  if (lastViewedBudgetId) {
    budgetFullCalcPath += `/${lastViewedBudgetId}/full`;
  } else if (!lastViewedBudgetId && budgets?.size >= 1) {
    budgetFullCalcPath += `/${budgets.keySeq().first()}/full`;
  }

  const storageEventListener = (event) => {
    if (event.key === 'budgetsEnabled'
      || event.key === 'subscriptionsOverride'
      || event.key === 'suppressWindowError'
      || event.key === 'suppressAssertPopups'
    ) {
      forceUpdate();
    }
  };

  useMount(() => {
    window.addEventListener('storage', storageEventListener);
  });

  const onTest = React.useCallback(() => {
    dispatch(createDialog(mkRootUiData({
      id: 'login',
      type: DIALOG_TYPE_EMPTY_DIALOG,
      allowNesting: true,
      props: ImmutableMap({
        title: 'login',
        maxWidth: 'lg',
        content: (
          <iframe
            name="auth"
            title="some title"
            width={800}
            height={720}
            src="http://localhost:3000/login"
          />
        ),
      }),
    })));
  }, [dispatch]);

  useKey((event) => event.ctrlKey && event.code === 'KeyT', onTest, { event: 'keypress' });

  useKey((event) => event.ctrlKey && event.code === 'KeyR', () => {
    const counter = (getDBUpdateTrigger(store.getState()) ?? 0) + 1;
    store.dispatch(setAppProps({ dbUpdateTrigger: counter }));
    logger.debug(`trigger DB update (${counter})`);
  }, { event: 'keypress' });

  const onOpenMenu = (event) => {
    event.preventDefault();
    toggleMenu(!openMenu);
  };

  const onCloseMenu = () => {
    toggleMenu(false);
  };

  const dispatchUseRefreshToken = (data?: any, meta?:any) => dispatch(authActions.exchangeRefreshToken(data, meta));

  const dispatchUpdateAuth = (data) => dispatch(authActions.updateAuth(data));

  const onOverrideSubscriptionClick = (_event, productLineName, productLineUriName, tierName, expireAt) => {
    if (!productLineName) {
      localPreferences.removeItem('subscriptionsOverride');
    } else {
      const subscriptions = getSubscriptionsOverride() || [];
      let subscriptionData;
      if (Array.isArray(subscriptions)) {
        subscriptionData = subscriptions.find((obj) => obj.productLineName === productLineName);
      }
      if (subscriptionData) {
        subscriptions.splice(subscriptions.indexOf(subscriptionData), 1);
        if (!tierName) {
          subscriptionData = undefined;
        }
      } else if (tierName) {
        subscriptionData = mkSubscription({ id: uuidv4().toUpperCase(), productLineName, productLineUriName });
      }

      if (subscriptionData) {
        subscriptionData = subscriptionData.toJS();
        if (tierName) {
          subscriptionData.tierName = tierName;
        }
        if (expireAt) {
          subscriptionData.expireAt = expireAt;
          subscriptionData.active = false;
        } else {
          const expirationDate = new Date();
          expirationDate.setFullYear(expirationDate.getFullYear() + 1);
          subscriptionData.expireAt = expirationDate;
          subscriptionData.active = true;
        }
        subscriptions.push(subscriptionData);
      }

      localPreferences.setItem('subscriptionsOverride', JSON.stringify(subscriptions));
    }
  };

  const clearAccountPreferences = () => {
    dialogAlert(
      'You betta be sure Homey',
      'This will CLEAR the color preferences, but not delete them because that is not supported by QCS',
      (btns) => {
        if (btns.btnPressed === 'I am VERY sure') {
          setDatasetPreference({ accountsPrefs: null });
        }
      },
      ['I am VERY sure', 'I better ask Tim'],
    );
    toggleMenu(false);
  };

  const clearWebAppPreferences = () => {
    dialogAlert(
      'You betta be sure Homey',
      'This will CLEAR ALL WEB APP PREFERENCES',
      (btns) => {
        if (btns.btnPressed === 'I am VERY sure') {
          dispatch(prefsActions.resetAllPreferences());
        }
      },
      ['I am VERY sure', 'I better ask Tim'],
    );
    toggleMenu(false);
  };

  const resetWindowsReviewedPreference = () => {
    setUserPreference({ windowsReviewedWarningEnabled: true });
  };

  const corruptAccessToken = () => {
    dispatchUpdateAuth({ accessToken: 'corrupted!!!' });
  };

  const corruptRefreshToken = () => {
    dispatchUpdateAuth({ refreshToken: 'corrupted!!!' });
  };

  const corruptDatasetId = () => {
    dispatchUpdateAuth({ datasetId: 'corrupted!!!' });
  };

  const clearAccessToken = () => {
    dispatchUpdateAuth({
      accessToken: null,
      accessTokenExpired: null,
    });
  };

  const clearRefreshToken = () => {
    dispatchUpdateAuth({
      refreshToken: null,
      refreshTokenExpired: null,
    });
  };

  const clearDataset = () => {
    dispatchUpdateAuth({ datasetId: null });
  };

  const overrideEntitlement = (key, active) => {
    const entitlementsData = JSON.parse(localPreferences.getItem('entitlementsOverride')) || {};
    if (key === undefined) {
      localPreferences.removeItem('entitlementsOverride');
    } else {
      entitlementsData[key] = active;
    }
    localPreferences.setItem('entitlementsOverride', JSON.stringify(entitlementsData));
  };

  const subscriptionsOverride = getSubscriptionsOverride();
  const subscriptionOverrideUS = subscriptionsOverride ? subscriptionsOverride.find((subscriptionData) => subscriptionData.productLineName === 'Quicken US') : undefined;
  const subscriptionOverrideUSTierName = subscriptionOverrideUS ? subscriptionOverrideUS.tierName : undefined;
  const subscriptionOverrideCA = subscriptionsOverride ? subscriptionsOverride.find((subscriptionData) => subscriptionData.productLineName === 'Quicken Canada') : undefined;
  const subscriptionOverrideCATierName = subscriptionOverrideCA ? subscriptionOverrideCA.tierName : undefined;
  const suppressWindowError = localPreferences.getSuppressWindowError();
  const suppressAssertPopups = localPreferences.getSuppressAssertPopups();
  const disableBugsnag = localPreferences.getDisableBugsnag();
  const ignoreBugsnagReport = localPreferences.getIgnoreBugsnagReport();

  if (simulateCrash) {
    throw new Error('Simulate REACT unhandled exception!');
  }

  return (
    <div>
      {requestData.showRequestDialog && (
        <RequestDialog
          open={Boolean(requestData.showRequestDialog)}
          onHandleClose={() => setRequestData({ ...requestData, showRequestDialog: false })}
          method={requestData.requestMethod}
          path={requestData.requestPath}
          query={requestData.requestQuery}
          body={requestData.requestBody}
        />
      )}

      <Dialog open={Boolean(showLoadDB)} onClose={() => toggleLoadDB(false)}>
        <input
          style={{ margin: 40 }}
          id="file-input"
          type="file"
          onChange={(event) => {
            const file = event?.target?.files?.[0];
            file?.text().then((json) => {
              const state = hydrateImmutable(json);
              store.dispatch(coreActions.setRoot(state));
              toggleMenu(false);
              sessionStorageEx.setSandbox(true);
            });
          }}
        />
      </Dialog>

      <Zoom in={showDevMenu}>
        <Fab
          sx={{
            all: 'unset',
            '&:hover, &:focus, &:active': {
              all: 'unset',
            },
          }}
          disableRipple
          disableFocusRipple
        >
          <IconButton
            id="dev-menu"
            ref={menuButtonRef}
            aria-label="DevMenu"
            role="complementary"
            style={{
              zIndex: 9999,
              margin: '10px',
              position: 'fixed',
              right: 0,
              bottom: 10,
              color: theme.palette.greyScaleDeprecated[0],
              opacity: 0.2,
            }}
            onClick={onOpenMenu}
            size="large"
          >
            <BuildIcon />
            <ArrowDropDown style={{ transform: 'rotate(180deg)' }} />
          </IconButton>
        </Fab>
      </Zoom>

      <Menu
        style={{ opacity: 0.85 }}
        open={openMenu}
        onClose={onCloseMenu}
        anchorEl={menuButtonRef.current}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <NestedMenuItem
          key="Copy-User-Data-Menu"
          onClick={() => {
            toggleMenu(false);
            navigator.clipboard.writeText(
              `user id: ${authSession && authSession.qcsId}\n` +
                `dataset id: ${datasetId}\n` +
                `email: ${profile && profile.username}\n` +
                `subscription: ${subscription && subscription.productLineName} - ${
                  subscription && subscription.tierName
                } (${subscription && subscription.expireOn})\n` +
                `entitlement: ${entitlement && entitlement.id} (${
                  entitlement && entitlement.expiresOn
                })\n` +
                'accounts: \n' +
                `${accounts
                  .toIndexedSeq()
                  .sortBy(
                    (account) => account.name,
                    (a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }),
                  )
                  .map((account, index) => `${index + 1}. ${account.name} (${account.id})`)
                  .join('\n')})\n`,
            );
            dispatchSimpleNotification('User copied to clipboard');
          }}
          menuItems={[
            <MenuItem dense key="Save-DB" onClick={() => saveStore('store.json', false)}>
              Save DB
            </MenuItem>,
            <MenuItem dense key="Save-DB-Sanitized" onClick={() => saveStore('store.json', true)}>
              Save DB Sanitized
            </MenuItem>,
            <MenuItem
              dense
              key="Load-DB"
              onClick={() => {
                toggleLoadDB(true);
                toggleMenu(false);
              }}
            >
              Load DB
            </MenuItem>,
            <Divider />,
            <MenuItem
              dense
              key="copy-persistor-key"
              onClick={(event) => {
                toggleMenu(false);
                navigator.clipboard.writeText(CryptoJS.SHA256(datasetId));
                event.stopPropagation();
              }}
            >
              Copy Persistor Key
            </MenuItem>,
            <Divider />,
            <MenuItem
              dense
              key="Console-Logs"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={() => {
                const logs = consoleMirror.log.reverse().join('\n');
                alert(logs); // eslint-disable-line no-alert
              }}
            >
              Console Logs
            </MenuItem>,
          ]}
        >
          Copy User Data ({authSession && authSession.qcsId} - {datasetId})
        </NestedMenuItem>

        <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />

        <NestedMenuItem
          style={{ opacity: 0.85, minWidth: '600px !important' }}
          key="FeatureFlagMenu"
          menuItems={[
            <MenuItem dense key="searchbar" style={{ minHeight: 60, background: theme.palette.greyScaleDeprecated[7] }}>
              <TextField
                label="Type to Search"
                type="search"
                fullWidth
                autoFocus
                onChange={(event) => setFeatureFlagFilter(event.target.value?.toLowerCase())}
                onKeyDown={(event) => event.stopPropagation()}
                onKeyUp={(event) => event.stopPropagation()}
                onKeyPress={(event) => event.stopPropagation()}
              />
            </MenuItem>,
            <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />,
            ...featureFlags
              .filter((value, key) => !featureFlagFilter || key?.toLowerCase().includes(featureFlagFilter))
              .keySeq()
              .sort()
              .map((key) => (
                <MenuItem dense key={key} style={{ background: theme.palette.greyScaleDeprecated[7] }}>
                  <ListItemText
                    style={{ marginRight: 8 }}
                    primary={<HighlightText text={key} searchKeys={[featureFlagFilter]} />}
                  />
                  <TextField
                    style={{ maxWidth: 96 }}
                    value={String(featureFlags.get(key))}
                    onChange={(event) => {
                      dispatch(featureFlagsActions.applyFeatureFlagsChanges({
                        [key]: event.target.value,
                      }));
                    }}
                    onKeyDown={(event) => event.stopPropagation()}
                    onKeyUp={(event) => event.stopPropagation()}
                  />
                  <Switch
                    size="small"
                    color="primary"
                    onChange={(event, value) => {
                      dispatch(featureFlagsActions.applyFeatureFlagsChanges({
                        [key]: value,
                      }));
                    }}
                    checked={Boolean(featureFlags.get(key))}
                  />
                </MenuItem>
              )),
          ]}
        >
          <ListItem component="div" key="feature-flag-toggler">
            <ListItemText
              primary={`Feature Flags (${getBuildConfig().ld_client_id === '5b4cfcd09dc3df2d8d0d471c' ? 'prod' : ''}${
                getBuildConfig().ld_client_id === '5b4cfcd09dc3df2d8d0d471b' ? 'dev' : ''
              } - ${getBuildConfig().ld_client_id})`}
            />
            <Switch
              size="small"
              color="primary"
              onChange={(_event, value) => {
                localPreferences.setFeatureFlagsFreeze(!value);
                forceUpdate();
              }}
              checked={!localPreferences.getFeatureFlagsFreeze()}
            />
          </ListItem>
        </NestedMenuItem>

        <Divider />

        <NestedMenuItem
          onKeyDown={(event) => event.stopPropagation()}
          onKeyUp={(event) => event.stopPropagation()}
          onKeyPress={(event) => event.stopPropagation()}
          menuItems={[
            <MenuItem dense key="searchbar">
              <TextField
                label="Type to Search"
                type="search"
                fullWidth
                autoFocus
                onChange={(event) => setPreferenceSearchKey(event.target.value?.toLowerCase())}
              />
            </MenuItem>,
            <Divider />,
            preferencesV2
              .filter(
                (preference) =>
                  !preferenceSearchKey ||
                  preference.label?.toLowerCase().includes(preferenceSearchKey) ||
                  preference.name?.toLowerCase().includes(preferenceSearchKey) ||
                  preference.value?.toLowerCase().includes(preferenceSearchKey),
              )
              .valueSeq()
              .sort((preference1, preference2) => preference1.label?.localeCompare(preference2.label))
              .map((preference) => (
                <MenuItem dense key={preference.id}>
                  <Box display="flex" alignItems="center" justifyContent="flex-end" width="100%">
                    <HighlightText
                      text={`${preference.label} (${preference.name})`}
                      searchKeys={[preferenceSearchKey]}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <TextField
                      defaultValue={preference.value}
                      onBlur={(event) => {
                        if (preference.value !== event.target.value) {
                          dispatch(updatePreferenceCriteria(                            
                            mkPreferenceCriteria({
                              preferenceName: preference.name,
                              value: event.target.value,
                              rollOutTarget: [datasetId],
                              criteriaType: 'DATASET_ID',
                              rollOutType: 'INDIVIDUAL',
                            }),
                            { undo: { userMessage: `Preference "${preference.label}" updated.` } },
                          ));
                        }
                      }}
                      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                        if (event.key === 'Enter') {
                          const inputElement = event.target as HTMLInputElement;
                          inputElement.blur();
                        }
                      }}
                    />
                    <IconButton
                      size="small"
                      onClick={() => {
                        // TODO: delete criteria
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </MenuItem>
              )),
            <Divider />,
          ]}
        >
          Preferences
        </NestedMenuItem>

        <Divider />

        <NestedMenuItem
          onKeyDown={(event) => event.stopPropagation()}
          onKeyUp={(event) => event.stopPropagation()}
          onKeyPress={(event) => event.stopPropagation()}
          menuItems={[
            <MenuItem dense key="dataset-prefs-searchbar">
              <TextField
                label="Type to Search"
                type="search"
                fullWidth
                autoFocus
                onChange={(event) => setDatasetPreferenceSearchKey(event.target.value)}
              />
            </MenuItem>,
            <Divider />,
            <MenuItem key={'reset-dataset-preferences'} dense>
              <QButton variant="delete" onClick={() => setDatasetPreference({ ...defaultDatasetPreferences })}>
                RESET TO DEFAULT DATASET PREFERENCES
              </QButton>
            </MenuItem>,
            <Divider />,
            ...Object.entries(datasetPreferences)
              .filter(([prefKey]) => !datasetPreferenceSearchKey || prefKey.includes(datasetPreferenceSearchKey))
              .sort(([aKey], [bKey]) => aKey.localeCompare(bKey))
              .map(([prefKey, prefValue]) => {
                let updater;
                switch (typeof prefValue) {
                  case 'boolean': updater = <QSwitch checked={Boolean(prefValue)} onChange={() => setDatasetPreference({ [prefKey]: !prefValue })} />; break;
                  case 'string': updater = <span>&quot;{prefValue}&quot;</span>; break;
                  default: updater = <span>can&apos;t be edited yet</span>; break;
                }

                return (
                  <MenuItem dense>
                    <Box width="100%" display="flex" alignItems="center">
                      <span style={{ flex: 1 }}>{prefKey}:</span>
                      {updater}
                    </Box>
                    <Divider />
                  </MenuItem>
                );
              }),
          ]}
        >
          Dataset Preferences
        </NestedMenuItem>

        <Divider />

        <NestedMenuItem
          dense
          id="request-menu"
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              setRequestData({ ...requestData, showRequestDialog: true });
            }
          }}
          menuItems={[
            <NestedMenuItem
              id="qcs-web-socket-menu"
              dense
              menuItems={[
                <MenuItem
                  id="custom"
                  dense
                  onClick={(event) => {
                    event.stopPropagation();
                    dispatch(bumpActions.sendBumpMessage({
                      hello: 'world',
                    }));
                  }}
                >
                  Custom Message
                </MenuItem>,
              ]}
            >
              QCS web socket
            </NestedMenuItem>,
            <Divider />,
            <NestedMenuItem
              id="inject-transactions"
              dense
              menuItems={[
                <MenuItem
                  id="inject-investment-transactions"
                  dense
                  onClick={(event) => {
                    event.stopPropagation();
                    const account = accountsSelectors.getInvestmentAccounts(store.getState()).first();
                    setRequestData({
                      showRequestDialog: true,
                      requestMethod: 'POST',
                      requestPath: '/transactions/test-aggregated-importer',
                      requestQuery: undefined,
                      requestBody: JSON.stringify({
                        inTesting: true,
                        accountId: account?.id,
                        pendingAggregationType: 'CURRENT_PENDING_LIST',
                        transactions: investmentTransactions(account),
                        cpData: {
                          balance: 2000.01,
                          balanceAt: DateTime.local().toISO(),
                        },
                      }, null, 2),
                    });
                  }}
                >
                  Inject Investment Transaction
                </MenuItem>,
                <MenuItem
                  id="inject-transaction-pending"
                  dense
                  onClick={(event) => {
                    event.stopPropagation();
                    setRequestData({
                      showRequestDialog: true,
                      requestMethod: 'POST',
                      requestPath: '/transactions/test-aggregated-importer',
                      requestQuery: undefined,
                      requestBody: JSON.stringify(bankTransactions.getPendingTransactionMockData(), null, 2),
                    });
                  }}
                >
                  Inject Pending Transaction
                </MenuItem>,
                <MenuItem
                  id="inject-posted-transaction"
                  dense
                  onClick={(event) => {
                    event.stopPropagation();
                    setRequestData({
                      showRequestDialog: true,
                      requestMethod: 'POST',
                      requestPath: '/transactions/test-aggregated-importer',
                      requestQuery: undefined,
                      requestBody: JSON.stringify(bankTransactions.getPostedTransactionMockData(), null, 2),
                    });
                  }}
                >
                  Inject Posted Transaction
                </MenuItem>,
              ]}
            >
              Inject Transaction
            </NestedMenuItem>,
            <Divider />,
            <MenuItem
              id="delete-dataset"
              dense
              onClick={(event) => {
                event.stopPropagation();
                setRequestData({
                  showRequestDialog: true,
                  requestMethod: 'DELETE',
                  requestPath: `/datasets/${datasetId}`,
                  requestQuery: undefined,
                  requestBody: undefined,
                });
              }}
            >
              Delete Dataset
            </MenuItem>,
            <Divider />,
            <NestedMenuItem
              dense
              menuItems={[
                <MenuItem
                  id="get-all"
                  dense
                  onClick={() => {
                    setRequestData({
                      showRequestDialog: true,
                      requestMethod: 'GET',
                      requestPath: '/accounts',
                      requestQuery: undefined,
                      requestBody: undefined,
                    });
                  }}
                >
                  Get All
                </MenuItem>,
              ]}
            >
              Accounts
            </NestedMenuItem>,
            <NestedMenuItem
              id="alerts-menu"
              dense
              menuItems={[
                <MenuItem
                  id="alerts-all"
                  dense
                  onClick={(event) => {
                    event.stopPropagation();
                    setRequestData({
                      showRequestDialog: true,
                      requestMethod: 'GET',
                      requestPath: '/institution-logins/clear-aasr',
                      requestQuery: undefined,
                      requestBody: undefined,
                    });
                  }}
                >
                  Clear AASR
                </MenuItem>,
              ]}
            >
              Institution Logins
            </NestedMenuItem>,
            <MenuItem
              onClick={(event) => {
                event.stopPropagation();
                setRequestData({
                  showRequestDialog: true,
                  requestMethod: 'GET',
                  requestPath: '/preferences/user',
                  requestQuery: undefined,
                  requestBody: undefined,
                });
              }}
            >
              User Preferences
            </MenuItem>,
            <MenuItem
              onClick={(event) => {
                event.stopPropagation();
                setRequestData({
                  showRequestDialog: true,
                  requestMethod: 'GET',
                  requestPath: '/preferences',
                  requestQuery: undefined,
                  requestBody: undefined,
                });
              }}
            >
              Dataset Preferences
            </MenuItem>,
            <MenuItem
              onClick={(event) => {
                event.stopPropagation();
                setRequestData({
                  showRequestDialog: true,
                  requestMethod: 'GET',
                  requestPath: budgetFullCalcPath,
                  requestQuery: undefined,
                  requestBody: undefined,
                });
              }}
            >
              Budget force calc
            </MenuItem>,
          ]}
        >
          Request
        </NestedMenuItem>

        <Divider />

        <MenuItem
          onClick={() => {
            LIST_OF_COMPONENT_IDS.forEach((id) => {

              const elements = document.querySelectorAll(`[sharedcomponentid="${id}"]`);

              elements.forEach((node) => {
                if (window.getComputedStyle(node)?.position === 'static') {
                  (node as HTMLElement).style.position = 'relative'; // eslint-disable-line
                }
                const anchorElement = document.createElement('div');
                node.appendChild(anchorElement);
                // eslint-disable-next-line react/no-deprecated
                ReactDOM.render(React.createElement(ReusableComponentTag, { id }), anchorElement);
              });
            });
            onCloseMenu();
          }}
          dense
        >
          Find Shared Components
        </MenuItem>

        <Divider />

        <NestedMenuItem
          key="Why-Did-You-Render-Menu"
          menuItems={Object.entries(localPreferences.getWDYRSettings()).map(([key, value]) => (
            <ListItem component="div" key={key}>
              <ListItemText primary={key} />
              <Switch
                size="small"
                color="primary"
                onChange={(_event, newValue) => {
                  localPreferences.setWDYRSettings({
                    ...localPreferences.getWDYRSettings(),
                    [key]: newValue,
                  });
                  forceUpdate();
                }}
                checked={Boolean(value)}
                onClick={(event) => event.stopPropagation()}
              />
            </ListItem>
          ))}
        >
          <ListItem component="div" key="why-render">
            <ListItemIcon>
              <IconButton
                size="small"
                onClick={() => {
                  localPreferences.setWDYRSettings(undefined);
                  forceUpdate();
                }}
              >
                <ClearIcon />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary="Why Did You Render? (ctrl + r)" />
            <Switch
              size="small"
              color="primary"
              onChange={(_event, value) => {
                localPreferences.setWhyDidYouRender(value);
                forceUpdate();
              }}
              checked={localPreferences.getWhyDidYouRender()}
              onClick={(event) => event.stopPropagation()}
            />
          </ListItem>
        </NestedMenuItem>

        <Divider />

        <MenuItem>
          <ListItem
            component="div"
            onClick={() => {
              const profileData = selectorsProfile.getItems();
              // eslint-disable-next-line no-console
              console.table(Object.fromEntries(profileData));
              forceUpdate();
            }}
          >
            <ListItemIcon>
              <IconButton
                size="small"
                onClick={() => {
                  selectorsProfile.clear();
                  forceUpdate();
                }}
              >
                <ClearIcon />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary={`Profile Selectors (${selectorsProfile.getItems().size})`} />
            <Switch
              size="small"
              color="primary"
              onChange={(_event, value) => {
                localPreferences.setSelectorsProfiling(value);
                forceUpdate();
              }}
              checked={localPreferences.getSelectorsProfiling()}
              onClick={(event) => event.stopPropagation()}
            />
          </ListItem>
        </MenuItem>

        <Divider />

        <NestedMenuItem
          key="Crash-Menu"
          style={{ opacity: 0.85 }}
          menuItems={[
            <MenuItem
              dense
              key="simulate-JS"
              onClick={() => {
                throw new Error('Simulate JS unhandled exception!');
              }}
            >
              Simulate JS unhandled exception
            </MenuItem>,
            <MenuItem
              dense
              key="simulate-REACT"
              onClick={() => {
                setSimulateCrash(true);
              }}
            >
              Simulate REACT unhandled exception
            </MenuItem>,
            <MenuItem
              dense
              key="simulate-JS-handled"
              onClick={() => {
                try {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  null.simulateHandledException();
                } catch (e) {
                  crashReporterInterface.reportError(e, (event) => event.addMetadata('custom', { some: 'data' }));
                }
              }}
            >
              Simulate JS handled exception
            </MenuItem>,
            <MenuItem
              dense
              key="simulate-unhandled-rejection"
              onClick={() => {
                const promise = new Promise((_resolve, reject) => {
                  reject(Error('simulate unhandled rejection'));
                });
                promise.then(() => logger.debug('promiss fulfilled'));
              }}
            >
              Simulate unhandled rejection
            </MenuItem>,
            <MenuItem
              dense
              key="simulate-user-agent-error"
              onClick={() => {
                Object.defineProperty(navigator, 'userAgent', {
                  value: 'hello',
                  configurable: false,
                });
                // TypeError: Cannot redefine property: userAgent
                Object.defineProperty(navigator, 'userAgent', {
                  value: 'world',
                });
              }}
            >
              Simulate userAgent exception
            </MenuItem>,
            <MenuItem
              dense
              key="simulate-assert1"
              onClick={() => {
                assert(false, 'test assert1');
              }}
            >
              Simulate assert1
            </MenuItem>,
            <MenuItem
              dense
              key="simulate-assert2"
              onClick={() => {
                assert(false, 'test assert2');
              }}
            >
              Simulate assert2
            </MenuItem>,
            <MenuItem
              dense
              key="simulate-verify"
              onClick={() => {
                verify(false, 'test verify');
              }}
            >
              Simulate verify
            </MenuItem>,
            <MenuItem
              dense
              key="simulate-local-storage-full"
              onClick={() => {
                const pattern = 'full of junk full of junk full of junk full of junk full of junk full of junk full of junk';
                try {
                  for (let index = 1; true; index++) { // eslint-disable-line no-constant-condition
                    localStorage.setItem(
                      `${index}`,
                      pattern,
                    );
                  }
                } catch (_e) {
                  try {
                    for (let length = 0; length < pattern.length; length++) { // eslint-disable-line no-constant-condition
                      localStorage.setItem(
                        '0',
                        pattern.slice(0, length),
                      );
                    }
                  } catch (e) {
                    assert(false, e);
                  }
                }
              }}
            >
              Simulate localStorage full
            </MenuItem>,
            <MenuItem dense key="suppress-window">
              <ListItemText primary="Suppress Error Alert (show Uh Oh page)" />
              <Switch
                size="small"
                color="primary"
                onChange={() => {
                  localPreferences.setSuppressWindowError(!suppressWindowError);
                  forceUpdate();
                }}
                checked={suppressWindowError}
              />
            </MenuItem>,
            <MenuItem dense key="disable-bugsnag">
              <ListItemText primary="Disable Bugsnag (restart required)" />
              <Switch
                size="small"
                color="primary"
                onChange={() => {
                  localPreferences.setDisableBugsnag(!disableBugsnag);
                  forceUpdate();
                }}
                checked={disableBugsnag}
              />
            </MenuItem>,
            <MenuItem dense key="ignore-bugsnag">
              <ListItemText primary="Ignore Bugsnag Reports" />
              <Switch
                size="small"
                color="primary"
                onChange={() => {
                  localPreferences.setIgnoreBugsnagReport(!ignoreBugsnagReport);
                  forceUpdate();
                }}
                checked={ignoreBugsnagReport}
              />
            </MenuItem>,
            <MenuItem dense key="suppress-assert" style={{ background: theme.palette.greyScaleDeprecated[7] }}>
              <ListItemText primary="Suppress Asserts" />
              <Switch
                size="small"
                color="primary"
                onChange={() => {
                  localPreferences.setSuppressAssertPopups(!suppressAssertPopups);
                  forceUpdate();
                }}
                checked={suppressAssertPopups}
              />
            </MenuItem>,
          ]}
        >
          Crash ({getBuildConfig().bugsnag_release_stage} - {getBuildConfig().bugsnag_api_key})
        </NestedMenuItem>

        <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />
        <NestedMenuItem
          key="Tracking-Menu"
          style={{ opacity: 0.85 }}
          menuItems={[
            <MenuItem
              dense
              key="track-user"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={() => {
                tracker.trackActive();
                toggleMenu(false);
              }}
            >
              Track Active User
            </MenuItem>,
            <MenuItem
              dense
              key="track-user-forced"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={() => {
                tracker.track(tracker.events.active);
                toggleMenu(false);
              }}
            >
              Track Active User (forced)
            </MenuItem>,
          ]}
        >
          {`Tracking (${
            (getBuildConfig().mixpanel_token === 'aa163a00ce018fca28d192192ed4de8b' && 'prod') ||
            (getBuildConfig().mixpanel_token === 'f02e19059c63af74c567d59435e16a1d' && 'dev')
          } - ${getBuildConfig().mixpanel_token})`}
        </NestedMenuItem>
        <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />

        <NestedMenuItem
          key="subscriptions-menu"
          style={{ opacity: 0.85 }}
          menuItems={[
            <MenuItem
              dense
              key="use-real-subscription"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={(event) => onOverrideSubscriptionClick(event, undefined, undefined, undefined, undefined)}
            >
              {!subscriptionsOverride ? '[x]' : '[ ]'} Use real subscription
            </MenuItem>,
            <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />,
            <MenuItem dense key="override-US-subscription" style={{ background: theme.palette.greyScaleDeprecated[7] }} disabled>
              Override US Subscription
            </MenuItem>,
            <MenuItem
              dense
              key="Starter-US"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={(event) => onOverrideSubscriptionClick(event, 'Quicken US', 'quicken-us', 'Starter', undefined)}
            >
              {subscriptionOverrideUSTierName === 'Starter' ? '[x]' : '[ ]'} Starter US
            </MenuItem>,
            <MenuItem
              dense
              key="deluxe-US"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={(event) => onOverrideSubscriptionClick(event, 'Quicken US', 'quicken-us', 'Deluxe', undefined)}
            >
              {subscriptionOverrideUSTierName === 'Deluxe' ? '[x]' : '[ ]'} Deluxe US
            </MenuItem>,
            <MenuItem
              dense
              key="premier-US"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={(event) => onOverrideSubscriptionClick(event, 'Quicken US', 'quicken-us', 'Premier', undefined)}
            >
              {subscriptionOverrideUSTierName === 'Premier' ? '[x]' : '[ ]'} Premier US
            </MenuItem>,
            <MenuItem
              dense
              key="home-business-rental-US"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={(event) => {
                onOverrideSubscriptionClick(event, 'Quicken US', 'quicken-us', 'Home, Business & Rental Property', undefined);
              }}
            >
              {subscriptionOverrideUSTierName === 'Home, Business & Rental Property' ? '[x]' : '[ ]'} Home, Business & Rental
              Property US
            </MenuItem>,
            <MenuItem
              dense
              key="no-US-subscription"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={(event) => onOverrideSubscriptionClick(event, 'Quicken US', 'quicken-us', undefined, undefined)}
            >
              {!subscriptionOverrideUS ? '[x]' : '[ ]'} no US subscription
            </MenuItem>,
            <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />,
            <MenuItem
              dense
              key="Quicken-expired-US"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={(event) => onOverrideSubscriptionClick(event, 'Quicken US', 'quicken-us', undefined, new Date())}
            >
              expire US subscription
            </MenuItem>,
            <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />,
            <MenuItem dense key="override-CA-Subscription" style={{ background: theme.palette.greyScaleDeprecated[7] }} disabled>
              Override CA Subscription
            </MenuItem>,
            <MenuItem
              dense
              key="Quicken-starter-canada"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={(event) => onOverrideSubscriptionClick(event, 'Quicken Canada', 'quicken-ca', 'Starter', undefined)}
            >
              {subscriptionOverrideCATierName === 'Starter' ? '[x]' : '[ ]'} Starter CA
            </MenuItem>,
            <MenuItem
              dense
              key="Quicken-Canada-Deluxe"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={(event) => onOverrideSubscriptionClick(event, 'Quicken Canada', 'quicken-ca', 'Deluxe', undefined)}
            >
              {subscriptionOverrideCATierName === 'Deluxe' ? '[x]' : '[ ]'} Deluxe CA
            </MenuItem>,
            <MenuItem
              dense
              key="Quicken-canada-home-and-business"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={(event) => {
                onOverrideSubscriptionClick(event, 'Quicken Canada', 'quicken-ca', 'Home and Business', undefined);
              }}
            >
              {subscriptionOverrideCATierName === 'Home and Business' ? '[x]' : '[ ]'} Home and Business CA
            </MenuItem>,
            <MenuItem
              dense
              key="Quicken-no-subscription-canada"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={(event) => onOverrideSubscriptionClick(event, 'Quicken Canada', 'quicken-ca', undefined, undefined)}
            >
              {!subscriptionOverrideCA ? '[x]' : '[ ]'} no CA subscription
            </MenuItem>,
            <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />,
            <MenuItem
              dense
              key="Quicken-expired-canada"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={(event) => onOverrideSubscriptionClick(event, 'Quicken Canada', 'quicken-ca', undefined, new Date())}
            >
              expire CA subscription
            </MenuItem>,
            <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />,
          ]}
        >
          subscriptions
        </NestedMenuItem>

        <NestedMenuItem
          key="entitlements-menu"
          style={{ opacity: 0.85 }}
          menuItems={[
            <MenuItem dense key="use-real-entitles" style={{ background: theme.palette.greyScaleDeprecated[7] }}>
              <ListItemText primary="Use real entitlements" />
              <Switch
                size="small"
                color="primary"
                onChange={() => localPreferences.removeItem('entitlementsOverride')}
                checked={Boolean(!localPreferences.getItem('entitlementsOverride'))}
              />
            </MenuItem>,
            <Divider />,
            ...[
              { id: 'US_QUICKEN_STARTER', label: 'US_QUICKEN_STARTER' },
              { id: 'US_QUICKEN_DELUXE', label: 'US_QUICKEN_DELUXE' },
              { id: 'US_QUICKEN_PREMIER', label: 'US_QUICKEN_PREMIER' },
              { id: 'US_QUICKEN_HABRPM', label: 'US_QUICKEN_HABRPM' },
              { id: 'CA_QUICKEN_CASHMANAGER', label: 'CA_QUICKEN_CASHMANAGER' },
              { id: 'CA_QUICKEN_DELUXE', label: 'CA_QUICKEN_DELUXE' },
              { id: 'CA_QUICKEN_HAB', label: 'CA_QUICKEN_HAB' },
              { id: 'PREMIUM_SUPPORT', label: 'PREMIUM_SUPPORT' },
              { id: 'FREE_QUICKEN_BILLPAY', label: 'FREE_QUICKEN_BILLPAY' },
            ].map(({ id, label }) => (
              <MenuItem dense style={{ background: theme.palette.greyScaleDeprecated[7] }} key={label}>
                <ListItemText primary={label} />
                <Switch
                  size="small"
                  color="primary"
                  onChange={(event, value) => {
                    overrideEntitlement(id, value);
                    dispatch(entitlementsActions.setEntitlement(
                      mkEntitlement({
                        id,
                        active: value,
                        expiresOn: value ? DateTime.local().plus({ years: 1 }).toISODate() : DateTime.local().toISODate(),
                        expiresAt: value ? DateTime.local().plus({ years: 1 }).toISO() : DateTime.local().toISO(),
                      }),
                    ));
                  }}
                  checked={Boolean((entitlements.get(id) || {}).active)}
                />
              </MenuItem>
            )),
          ]}
        >
          entitlements
        </NestedMenuItem>
        <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />
        <NestedMenuItem
          key="auth-menu"
          style={{ opacity: 0.85 }}
          id="auth-menu"
          arrowid="auth-menu-arrow"
          menuItems={[
            <MenuItem
              dense
              key="clear-access-token-key"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              id="clear-access-token"
              onClick={() => clearAccessToken()}
            >
              Clear access token -&nbsp;
              <span style={{ display: 'inline-block', width: 300, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {authSession.accessToken}
              </span>
            </MenuItem>,
            <MenuItem
              dense
              key="clear-refresh-token-key"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              id="clear-refresh-token"
              onClick={() => clearRefreshToken()}
            >
              Clear refresh token -&nbsp;
              <span style={{ display: 'inline-block', width: 300, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {authSession.refreshToken}
              </span>
            </MenuItem>,
            <MenuItem
              dense
              key="clear-dataset-key"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              id="clear-dataset"
              onClick={() => clearDataset()}
            >
              Clear dataset -&nbsp;
              <span style={{ display: 'inline-block', width: 300, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {authSession.datasetId}
              </span>
            </MenuItem>,
            <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />,
            <MenuItem
              dense
              key="expire-access-token-key"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              id="expire-access-token"
              onClick={() => dispatch(authActions.invalidateAccessToken())}
            >
              Expire access token ({DateTime.fromISO(authSession.accessTokenExpired).toLocaleString(DateTime.DATETIME_MED)})
            </MenuItem>,
            <MenuItem
              dense
              key="expire-refresh-token-key"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              id="expire-refresh-token"
              onClick={() => {
                dispatch(authActions.invalidateRefreshToken());
              }}
            >
              Expire refresh token ({DateTime.fromISO(authSession.refreshTokenExpired).toLocaleString(DateTime.DATETIME_MED)})
            </MenuItem>,
            <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />,
            <MenuItem
              dense
              key="corrupt-access-token-key"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              id="corrupt-access-token"
              onClick={() => corruptAccessToken()}
            >
              Corrupt access token -&nbsp;
              <span style={{ display: 'inline-block', width: 300, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {authSession.accessToken}
              </span>
            </MenuItem>,
            <MenuItem
              dense
              key="corrupt-refresh-token-key"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              id="corrupt-refresh-token"
              onClick={() => corruptRefreshToken()}
            >
              Corrupt refresh token -&nbsp;
              <span style={{ display: 'inline-block', width: 300, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {authSession.refreshToken}
              </span>
            </MenuItem>,
            <MenuItem
              dense
              key="corrupt-dataset-key"
              id="corrupt-dataset"
              onClick={() => corruptDatasetId()}
            >
              Corrupt data set ({authSession.datasetId})
            </MenuItem>,
            <MenuItem
              dense
              key="not-existing-dataset-key"
              id="no-existing-dataset"
              onClick={() => dispatchUpdateAuth({ datasetId: '12345' })}
            >
              Set not existing data set ({authSession.datasetId})
            </MenuItem>,
            <Divider />,
            <MenuItem dense key="use-refresh-token" id="use-refresh-token" onClick={() => dispatchUseRefreshToken()}>
              Use Refresh Token&nbsp;
              <span style={{ display: 'inline-block', width: 300, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                ({authSession.refreshToken})
              </span>
            </MenuItem>,
          ]}
        >
          auth
        </NestedMenuItem>

        <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />
        <NestedMenuItem
          key="NPS-menu"
          style={{ opacity: 0.85 }}
          menuItems={[
            <MenuItem dense key="survey-request" style={{ background: theme.palette.greyScaleDeprecated[7] }}>
              <ListItemText primary="Survey Requested" />
              <Switch
                size="small"
                color="primary"
                disabled={!authSession}
                onChange={(event, value) => {
                  dispatchUpdateAuth({ surveyRequested: value });
                }}
                checked={Boolean(authSession && authSession.surveyRequested)}
              />
            </MenuItem>,
            <MenuItem
              dense
              key="check-for-survey"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={() => dispatch(npsActions.checkIfNPSSurveyRequestedAction())}
            >
              Check for Survey Now
            </MenuItem>,
          ]}
        >
          NPS
        </NestedMenuItem>
        <NestedMenuItem
          key="sync-sentiment-menu"
          style={{ opacity: 0.85 }}
          menuItems={[
            <MenuItem dense key="sync-survey-request" style={{ background: theme.palette.greyScaleDeprecated[7] }}>
              <ListItemText primary="Survey Requested" />
              <Switch
                size="small"
                color="primary"
                disabled={!authSession}
                onChange={(event, value) => {
                  dispatchUpdateAuth({ syncSurveyRequested: value });
                }}
                checked={Boolean(authSession && authSession.syncSurveyRequested)}
              />
            </MenuItem>,
            <MenuItem
              dense
              key="check-for-sync-sentiment"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={() => dispatch(syncSentimentActions.checkIfSyncSentimentSurveyRequestedAction())}
            >
              Check for Survey Now
            </MenuItem>,
          ]}
        >
          Sync Sentiment Survey
        </NestedMenuItem>
        <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />
        <NestedMenuItem
          key="QHelp-Menu"
          style={{ opacity: 0.85 }}
          menuItems={[
            <MenuItem
              dense
              key="upgrade"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={() => {
                showQHelp('upgradeVersion', {
                  ...safeRefIn(profile, ['product']),
                  minBuildNumber: featureFlags.get('minWindowsBuildNumber'),
                });
              }}
            >
              Version Number Check (Windows)
            </MenuItem>,
            <MenuItem
              dense
              key="upgrade-MAC"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={() => {
                showQHelp('upgradeVersion', {
                  ...safeRefIn(profile, ['product']),
                  clientType: 'MAC',
                  minBuildNumber: featureFlags.get('minMacReleaseNumber'),
                });
              }}
            >
              Version Number Check (Mac)
            </MenuItem>,
            <MenuItem
              dense
              key="upgrade-windows-rev"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={() => showQHelp('windowsReviewed')}
            >
              Using Reviewed Column
            </MenuItem>,
          ]}
        >
          QHelp
        </NestedMenuItem>

        <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />

        <MenuItem dense key="MCP-test-toggle">
          <ListItemText primary="MCP test mode" />
          <Switch
            size="small"
            color="primary"
            onChange={(_event, value) => {
              setMcpTestMode(value);
              localPreferences.setMCPTestMode(value);
            }}
            checked={mcpTestMode}
          />
        </MenuItem>

        <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />

        <NestedMenuItem
          key="Reset-Menu"
          style={{ opacity: 0.85 }}
          menuItems={[
            <MenuItem
              dense
              key="clear-acc-prefs"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={(_event) => clearAccountPreferences()}
            >
              Clear Account Preferences
            </MenuItem>,
            <MenuItem
              dense
              key="clear-webapp-prefs"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={(_event) => clearWebAppPreferences()}
            >
              Clear Web App Preferences
            </MenuItem>,
            <MenuItem
              dense
              key="reset-windows-rev"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={(_event) => resetWindowsReviewedPreference()}
            >
              Reset Windows Reviewed Preference
            </MenuItem>,
            <MenuItem
              dense
              key="reset-windows-ver"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={() => resetVersionWarning()}
            >
              Reset Windows Version Warning
            </MenuItem>,
          ]}
        >
          Reset
        </NestedMenuItem>

        <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />
        <NestedMenuItem
          key="Log-Level-Menu"
          menuItems={[
            <MenuItem dense key="local-override" style={{ background: theme.palette.greyScaleDeprecated[7] }}>
              <ListItemText primary="Local Override" />
              <Switch
                size="small"
                color="primary"
                onChange={(event, value) => {
                  if (!value) {
                    localPreferences.removeItem('loggingLevel');
                    forceUpdate();
                  }
                }}
                checked={localPreferences.getItem('loggingLevel')}
              />
            </MenuItem>,
            <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />,
            ...Object.keys(loggerConfigurator.LogLevelsEnum).map((logLevelName) => (
              <MenuItem
                dense
                key={logLevelName}
                style={{ background: theme.palette.greyScaleDeprecated[7] }}
                onClick={() => {
                  loggerConfigurator.storeLoggingLevel(loggerConfigurator.LogLevelsEnum[logLevelName]);
                  forceUpdate();
                }}
              >
                <Radio checked={loggerConfigurator.retrieveLoggingLevel() === loggerConfigurator.LogLevelsEnum[logLevelName]} />
                {logLevelName}+
              </MenuItem>
            )),
            <Divider style={{ backgroundColor: theme.palette.greyScaleDeprecated[5] }} />,
            <MenuItem
              dense
              key="debug-test"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={() => logger.debug('test loging: %s', 'debug')}
            >
              trigger debug logging
            </MenuItem>,
            <MenuItem
              dense
              key="log-test"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={() => logger.log('test logging: %s', 'log')}
            >
              trigger log logging
            </MenuItem>,
            <MenuItem
              dense
              key="info-test"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={() => logger.info('test logging: %s', 'info')}
            >
              trigger info log
            </MenuItem>,
            <MenuItem
              dense
              key="warn-test"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={() => logger.warn('test logging: %s', 'warn')}
            >
              trigger warn log
            </MenuItem>,
            <MenuItem
              dense
              key="error-test"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={() => logger.error('test logging: %s', 'error')}
            >
              trigger error log
            </MenuItem>,
            <MenuItem
              dense
              key="fatal-test"
              style={{ background: theme.palette.greyScaleDeprecated[7] }}
              onClick={() => logger.fatal('test logging: %s', 'fatal')}
            >
              trigger fatal log
            </MenuItem>,
          ]}
        >
          Log level ({loggerConfigurator.retrieveLoggingLevel()})
        </NestedMenuItem>

        <Divider />

        <Divider />

        <ListItem key="sandbox-toggle">
          <ListItemText primary="Sandbox" />
          <ListItemSecondaryAction>
            <Switch
              size="small"
              color="primary"
              onChange={(_event, value) => {
                sessionStorageEx.setSandbox(value);
                forceUpdate();
              }}
              checked={sessionStorageEx.getSandbox()}
            />
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem key="render-count">
          <ListItemText primary="Render Count" />
          <ListItemSecondaryAction>
            <Switch
              size="small"
              color="primary"
              onChange={(_event, value) => {
                localPreferences.setItem('render-count', value);
                forceUpdate();
              }}
              checked={localPreferences.getItem('render-count') ?? true}
            />
          </ListItemSecondaryAction>
        </ListItem>

        <Divider />

        <MenuItem dense onClick={() => logger.debug('current DB state:\n', store.getState())}>
          Dump DB to console
        </MenuItem>

        <Divider />

        <MenuItem 
          dense 
          key="hide-dev-menu" 
          onClick={() => {
            toggleDevMenu(false);
            toggleMenu(false);
          }}
        >
          Hide Dev Menu
        </MenuItem>

        <Divider />

        <MenuItem dense key="test-toggle" onClick={onTest}>
          Test
        </MenuItem>
      </Menu>
    </div>
  );
};

export default compose(
  QPreferences(),
  QDialogs(),
  QHelp(),
  withTheme,
)(withStyles(DevMenu, styles));
/////////
