import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import compose from 'utils/compose';
import PropTypes from 'prop-types';
import { List as ImmutableList } from 'immutable';

import { datasetsSelectors, datasetsUtils } from 'companion-app-components/flux/datasets';
import { authSelectors } from 'companion-app-components/flux/auth';

import { withStyles } from 'tss-react/mui';
import { withTheme } from '@emotion/react';
import QMenu from 'components/QMenu';
import QPreferences from 'components/QPreferences';
import SettingsIcon from '@mui/icons-material/SettingsRounded';
import QHelp from 'components/QHelp';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';

import { regFieldsSortType } from 'data/preferences/defaults';

import {
  getAvailableColumns, getColumnDefaults,
  fieldData,
} from 'components/TransactionRegister/transactionsConfig';

const styles = () => ({
});

class TxPrefMenu extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected,
    };
    this.displayColumnsKey = regFieldsSortType(props.datasetPreferences.transactionRegister.regFieldsSort, props.accountType);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.selected !== this.state.selected) {
      this.setState({ selected: newProps.selected });
    }
    if (this.props.datasetPreferences !== newProps.datasetPreferences) {
      this.forceUpdate();
    }
    if (newProps.accountType !== this.props.accountType) {
      this.displayColumnsKey = regFieldsSortType(newProps.datasetPreferences.transactionRegister.regFieldsSort, newProps.accountType);
    }
  }

  handleChange = (value, groupId) => {

    if (groupId === 'txnColumns') {

      let regSort = this.props.datasetPreferences.transactionRegister.regFieldsSort[this.displayColumnsKey];

      if (this.fieldIsChecked(value)) {
        const index = regSort.indexOf(value);
        if (index >= 0) {
          regSort = regSort.delete(index);
          const newPref = { transactionRegister: { regFieldsSort: { [this.displayColumnsKey]: regSort } } };
          this.props.setDatasetPreference(newPref);
        }
      } else {
        // const slot = Math.min(fieldData[field].position, regSort.size);

        const slot = this.calculateInsertPosition(regSort, fieldData[value]);
        regSort = regSort.insert(slot, value);
        const newPref = { transactionRegister: { regFieldsSort: { [this.displayColumnsKey]: regSort } } };
        this.props.setDatasetPreference(newPref);
      }

      return;
    }

    if (value === 'resetDefaults') {
      this.resetDefaults();
      return;
    }
    if (value === 'reviewedHelp') {
      this.props.showQHelp('windowsReviewed');
      return;
    }

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  resetDefaults = () => {
    const regSort = ImmutableList(getColumnDefaults(this.props.accountType));
    const newPref = { transactionRegister: { regFieldsSort: { [this.displayColumnsKey]: regSort } } };
    this.props.setDatasetPreference(newPref);
  };

  calculateInsertPosition = (regSort, fieldObj) =>
    //
    // We will look for the first field whose position is greater than the one provided, we slot
    // in before this location
    //
    regSort.findIndex((x) => fieldData[x].position > fieldObj.position);

  fieldIsChecked = (field) =>
    this.props.datasetPreferences.transactionRegister.regFieldsSort[this.displayColumnsKey].includes(field);

  render() {

    const { classes, accountType } = this.props;
    const menuItems = [
      { label: 'Transaction Columns',
        groupId: 'txnColumns',
        isGroupList: true,
        groupList: [
        ],
      },
    ];

    getAvailableColumns(accountType).forEach((field, index) => {
      const { label } = fieldData[field];
      menuItems[0].groupList.push(
        { noCloseOnClick: true,
          selected: this.fieldIsChecked(field),
          label,
          value: field,
          divider: index === (getAvailableColumns(accountType).length - 1),
        }
      );
    });

    menuItems.push({ label: 'Reset Defaults', value: 'resetDefaults' });

    if (datasetsUtils.isWindowsDataset(this.props.datasetsById.get(this.props.datasetId))) {
      menuItems.push({ label: 'About Reviewed...', value: 'reviewedHelp' });
    }
    let options = [
      {
        label: 'Transaction Display',
        groupId: 'txnDisplay',
        isGroupList: true,
        selected: this.state.selected,
        groupList: [
          { label: 'Comfortable', value: 'comfortable' },
          { label: 'Normal', value: 'normal' },
          { label: 'Compact', value: 'compact', divider: true },
        ],
      },
      {
        label: 'Show/Hide Columns',
        value: 'txcols',
        subMenu: menuItems,
        itemOpensMenu: true,
      },
    ];
    if (this.props.prefShowPreferences) {
      options.push(
        {
          label: 'Preferences...',
          value: 'preferences',
          divider: Boolean(this.props.customItems),
        },
      );
    }

    if (this.props.customItems) {
      options = options.concat(this.props.customItems);
    }

    return (
      <div className={classes.balanceMenu}>
        <QMenu
          name="txpref"
          title="Transactions Settings"
          menuIcon={SettingsIcon}
          menuIconButtonSize="small-target"
          options={options}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
TxPrefMenu.propTypes = {
  selected: PropTypes.string,
  classes: PropTypes.object,
  onChange: PropTypes.func,             // your onChange function, sends the new selection
  customItems: PropTypes.array,
  accountType: PropTypes.string,

  // QPrefs
  datasetPreferences: PropTypes.object,
  setDatasetPreference: PropTypes.func,

  // QDialog
  showQHelp: PropTypes.func,
  datasetId: PropTypes.string,
  datasetsById: PropTypes.object,

  prefShowPreferences: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  return {
    datasetId: authSelectors.getDatasetId(state),
    datasetsById: datasetsSelectors.getDatasetsById(state),
    prefShowPreferences: featureFlagsSelectors.getFeatureFlags(state).get('prefShowPreferences'),
    prefsIndex: ownProps.accountType,
  };
}

function mapDispatchToProps() {
  return {
  };
}

export default compose(
  QPreferences(),
  QHelp(),
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(withStyles(TxPrefMenu, styles));

