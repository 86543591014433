
import React, { PureComponent } from 'react';
import withRouterLegacy from 'components/withRouterLegacy';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import compose from 'utils/compose';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { withTheme } from '@emotion/react';

import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import QButton from 'components/QButton';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import QTip from 'components/QuickenControls/QTip';

import winGraphics from 'assets/setup-page/Win.png';
import macGraphics from 'assets/setup-page/Mac.png';
import macIcon from 'assets/setup-page/Mac Icon.png';
import winIcon from 'assets/setup-page/Win Icon.png';

import { isMac, safeRefIn } from 'utils/utils';

import { getLogger } from 'companion-app-components/utils/core';
const log = getLogger('containers/SetupPageWebFirst');

const styles = () => ({
  base: {
    fontWeight: 200,
    padding: 3,
  },
  title: {
    padding: 3,
    fontSize: '25px',
    fontWeight: 400,
  },
  subTitle: {
    fontSize: '20px',
    fontWeight: 200,
    padding: 3,
  },
  bodyText: {
    padding: 3,
    fontWeight: 200,
    fontSize: '14px',
  },
  choiceTabs: {
    display: 'flex',
    flexDirection: 'row',
    height: 50,
    lineHeight: '50px',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 30,
  },
  tab: {
    flex: 1,
    borderColor: '#777',
    '&.unselected': {
      borderBottom: 'solid',
      background: '#eee',
      borderBottomWidth: '1px',
    },
    '&.selected': {
      border: 'solid',
      borderBottom: 'none',
      borderWidth: '1px',
    },
  },
  stepBox: {
    flex: 1,
    paddingRight: 13,
  },
  stepHeading: {
    fontSize: '14px',
    fontWeight: 500,
  },
  stepDivider: {
    flex: 1,
    background: '#ccc',
    height: 1,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 14,
    marginRight: 14,
  },
  footer: {
    height: 50,
    marginTop: 30,
    marginBottom: 50,
  },
  vertBar: {
    display: 'inline-block',
    verticalAlign: 'middle',
    height: '14px',
    width: 1,
    background: '#777',
    marginLeft: 15,
    marginRight: 15,
  },
  link: {
    cursor: 'pointer',
  },
  startClean: {
    padding: 10,
    textAlign: 'center',
    marginTop: 10,
  },
});


class SetupPageWebFirst extends PureComponent { // eslint-disable-line react/prefer-stateless-function

  static propTypes = {
    profile: PropTypes.object,
    classes: PropTypes.object,
    authSession: PropTypes.object,
    defaultTabIsWindows: PropTypes.bool,
    onCreateDataset: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isWindows: props.defaultTabIsWindows !== undefined ? props.defaultTabIsWindows : !isMac(),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.defaultTabIsWindows !== nextProps.defaultTabIsWindows) {
      this.setState({ isWindows: nextProps.defaultTabIsWindows !== undefined ? nextProps.defaultTabIsWindows : !isMac() });
    }
  }

  selectTab = (isWindows) => {
    this.setState({ isWindows });
  };

  render() {

    const { classes, profile, authSession, onCreateDataset } = this.props;
    const { isWindows } = this.state;
    const userEmail = safeRefIn(profile, ['primaryEmail', 'address']) || '';
    const macDownload = `https://download.quicken.com/mac/Quicken.dmg?email=${userEmail}`;
    const winDownload = `https://download.quicken.com/windows/Quicken.exe?email=${userEmail}`;

    const downloadLink = isWindows ? winDownload : macDownload;

    const stepsImg = isWindows ? winGraphics : macGraphics;
    const suffix = isWindows ? 'for Windows' : 'Mac';

    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <div style={{ margin: 'auto', maxWidth: 891 }}>
          <Typography className={classes.title}>
            Hello.
          </Typography>
          <Typography className={classes.subTitle}>
            {'Welcome to Quicken!'}
          </Typography>
          <Typography className={classes.bodyText}>
            {'You can start on the web, and stay on the web (and mobile), but if you want to explore the full features on Quicken Desktop '}
            {'you will eventually want to sync your data into the desktop app.  We need to know which version you plan on using'}
          </Typography>
          <div style={{ width: 891, marginLeft: 'auto', marginRight: 'auto' }}>
            <div className={classes.choiceTabs}>

              <ButtonBase
                className={classNames(classes.tab, isWindows ? 'selected' : 'unselected')}
                onClick={() => this.selectTab(true)}
              >
                <div>
                  <img
                    alt="Windows Icon"
                    src={winIcon}
                    style={{
                      display: 'inline',
                      marginRight: 10 }}
                  />
                  <Typography style={{ display: 'inline' }}>
                    WINDOWS
                  </Typography>
                </div>
              </ButtonBase>

              <ButtonBase
                className={classNames(classes.tab, isWindows ? 'unselected' : 'selected')}
                onClick={() => this.selectTab(false)}
              >
                <div>
                  <img alt="Mac icon" src={macIcon} style={{ display: 'inline', marginRight: 10 }} />
                  <Typography style={{ display: 'inline' }}>
                    MAC
                  </Typography>
                </div>
              </ButtonBase>

            </div>
            <div id="content" style={{ marginLeft: 15, width: 'auto' }}>

              <div style={{ marginTop: 15 }}>
                <img alt="graphical steps" src={stepsImg} />
              </div>

              <div className={classes.footer}>
                <Divider style={{ marginBottom: 10 }} />
                <div className={classes.bodyText}>
                  <Link
                    className={classes.link}
                    onClick={() => window.open('https://www.quicken.com/support/quicken-web-frequently-asked-questions', '_blank')}
                  >
                    See this FAQ,
                  </Link>
                  {' on how to set your accounts up for Sync'}
                  <div className={classes.vertBar} />
                  <Link
                    className={classes.link}
                    onClick={() => window.open(`https://www.quicken.com/my-account?bearer=${authSession.accessToken}`, '_blank')}
                  >
                    Go to My Account
                  </Link>
                  <div className={classes.vertBar} />
                  <Link
                    className={classes.link}
                    onClick={() => {
                      log.log(`Opening: ${downloadLink}`);
                      window.open(downloadLink, '_blank');
                    }}
                  >
                    {`Download Quicken for ${isWindows ? 'Windows' : 'Mac'}`}
                  </Link>
                </div>

                <div className={classes.startClean}>
                  <QTip
                    title={`Creates a new cloud file that you can use on web and mobile, and then load into a new Quicken ${suffix} file when you want to use the desktop`}
                  >
                    <QButton
                      onClick={() => onCreateDataset(isWindows ? 'quicken_windows' : 'quicken_mac')}
                    >
                      {`Create New Quicken ${suffix} Dataset`}
                    </QButton>
                  </QTip>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(_state) {
  return {
  };
}

function mapDispatchToProps(_dispatch) {
  return {
  };
}

export default compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
  withRouterLegacy,
)(withStyles(SetupPageWebFirst, styles));
