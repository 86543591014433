import { ENTITLEMENTS_REDUCER_KEY } from 'data/entitlements/entitlementsTypes';
import { DateTime } from 'luxon';
import { createSelector } from 'reselect';
import { Map as ImmutableMap } from 'immutable';

import { authSelectors } from 'companion-app-components/flux/auth';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';

import { isQuicken } from 'isAcme';
import * as sessionStorageEx from 'utils/sessionStorageEx';

let appTiers;

////////////////////////////////////////
const USTiers = {
  US_QUICKEN_STARTER: 1,
  US_QUICKEN_DELUXE: 2,
  US_QUICKEN_PREMIER: 3,
  US_QUICKEN_HABRPM: 4,
};
const CATiers = {
  CA_QUICKEN_CASHMANAGER: 1,
  CA_QUICKEN_DELUXE: 2,
  CA_QUICKEN_HAB: 4,
};

appTiers = { ...USTiers, ...CATiers };
/////////

/////////////////////////////////////
////////////
////////////////
//////////////////
//////////////
//
/////////

const getStore = (state) => state[ENTITLEMENTS_REDUCER_KEY];
export const getLoadPending = (state) => getStore(state).loadPending;
export const getError = (state) => getStore(state).error;
export const getIsLoading = (state) => getStore(state).isLoading;
export const getEntitlements = (state) => getStore(state).resourcesById;

export const getActiveEntitlements = (function createActiveEntitlementsSelector() {
  let activeEntitlementsCache = ImmutableMap();
  return (state) => {
    const now = DateTime.local();
    const activeEntitlementsNew = getEntitlements(state).filter((entitlement) => {
///////////////////////////////////////////////////

      // a hack that allows to load user's dataset dumps with expired entitlements
      const sandbox = sessionStorageEx.getSandbox();
      if (sandbox) {
        return true;
      }

      const partnerExpirationDate = entitlement.partnerExpireAt && DateTime.fromISO(entitlement.partnerExpireAt); // Set for only appstore/playstore subscriptions.
      // If there is partnerExpirationDate, only use that to check subscription status to support sandbox testing.
      if (partnerExpirationDate) {
        if (now <= partnerExpirationDate) {
          return true;
        }
        // 'expiresAt' is deprecated, but we have to use it for scenario when user switched from partner to e-store
        // "expiresAt": "2025-08-02T07:59:59Z",
        // "partnerExpireAt": "2019-11-17T00:00:00Z",
        // "expiresOn": "2025-08-01",
        if (entitlement.expiresAt) {
          return now <= DateTime.fromISO(entitlement.expiresAt);
        }
      }
///////////////
      return now.startOf('day') <= DateTime.fromISO(entitlement.expiresOn);
    });
    const cacheMatches = activeEntitlementsCache.equals(activeEntitlementsNew);
    if (!cacheMatches) {
      activeEntitlementsCache = activeEntitlementsNew;
    }
    return activeEntitlementsCache;
  };
}());

export const getActiveEntitlement = (state, key) => getActiveEntitlements(state).get(key);

export const getActiveTierEntitlements = createSelector(
  getActiveEntitlements,
  (activeEntitlements) => activeEntitlements
    .filter((entitlement) => Object.prototype.hasOwnProperty.call(appTiers, (entitlement.id))),
);

export const hasWebFirstEntitlements = createSelector(
  (state) => featureFlagsSelectors.getFeatureFlags(state).get('webFirstTiers'),
  getActiveTierEntitlements,
  (webFirstTiers, entitlements) => {

    if (!webFirstTiers || !isQuicken) {
      return false;
    }
    const tiersArray = webFirstTiers.split(',');
    return entitlements && Boolean(tiersArray.find((webTier) => entitlements.has(webTier)));
  },
);

export const hasCanadaEntitlements = createSelector(
  getActiveTierEntitlements,
  (entitlements) => {
    if (!isQuicken) {
      return false;
    }
    return entitlements && [...entitlements.keys()].some((eKey) => Object.keys(CATiers).includes(eKey));
  },
);

export const hasUSEntitlements = createSelector(
  getActiveTierEntitlements,
  (entitlements) => entitlements.some((entitlement) => Object.keys(USTiers).includes(entitlement.id)),
);

export const getTopTierEntitlement = createSelector(
  getActiveTierEntitlements,
  (entitlements) => entitlements
    .sort((entitlement1, entitlement2) => Math.sign((appTiers[entitlement1.id] || 0) - (appTiers[entitlement2.id] || 0)))
    .last(),
);

export const isAuthenticated = (state) => {
  const entitlement = getTopTierEntitlement(state);
  return entitlement && authSelectors.isAuthTokenValid(state);
};

export const isAuthenticatedWithDataSetId = (state) => {
  const datasetId = authSelectors.getDatasetId(state);
  const dataset = authSelectors.getCurrentDataset(state);
  return Boolean(isAuthenticated(state) && datasetId && (!dataset || !dataset.disableRemoteAccess));
};
