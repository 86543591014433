
import { createSelector } from 'reselect';
import { Map as ImmutableMap, OrderedMap } from 'immutable';
import moment from 'moment';
import { DateTime } from 'luxon';
import { isAcme } from 'isAcme';

import { getLogger } from 'companion-app-components/utils/core';
import { accountsSelectors, accountsUtils } from 'companion-app-components/flux/accounts';
import { ShowBalance } from 'companion-app-components/flux/preferences/types';
import { getSharedPreferencesByPath } from 'companion-app-components/flux/preferences/selectors';

import { getRunningBalanceExcludeAccountIds } from 'data/preferences/selectors';
import { getPureManualAccounts } from 'data/institutionLogins/selectors';
import { getTransactionsStore, getTransactionsByAccountId, getIsLoading as transactionsLoading, TxnTypes } from 'data/transactions/selectors';
import { getIHoldsByAccountId } from 'companion-app-components/flux/investmentHoldings/selectors';
import { isUnacceptedScheduledTxn, nextScheduledInstanceAfterDate, isBankOwnedPending } from 'data/transactions/utils';
import { getOptimalCacheFromObject, setOptimalCacheFromObject } from 'data/optimalCacheManager';
import { noNaN } from 'utils/utils';
import { memoizeWithKey } from 'utils/memoizeWithKey';
import { featureFlagsSelectors } from 'companion-app-components/flux/feature-flags';

import { AccountBalances } from './types';

import { getBalMap } from './balCache';

const log = getLogger('data/accountBalances/selectors');

export function isConnectedAccount(account) {
  return account.institutionLoginId &&
    account.aggregators && account.aggregators.size > 0;
}

export function includeRecurringTxn(txn, recurringSetting, transactionsForAccountId) {

  // "next scheduled instance", but this needs to be the next scheduled instance after TODAY
  // so it still shows all prior instances

  // For Acme, we do not include ANY unaccepted (SCHEDULED_PENDING) transactions in the balance
  if (isAcme) {
    return false;
  }

  if (noNaN(recurringSetting) === -1) {
    // include all due and overdue
    if (moment(txn.postedOn).isSameOrBefore(moment(), 'day')) {
      return true;
    }
    const nextInstanceAfterToday = nextScheduledInstanceAfterDate(moment(), transactionsForAccountId, txn.stModelId);
    return nextInstanceAfterToday && (txn.id === nextInstanceAfterToday.id);
  }
  if (noNaN(recurringSetting) === 0) {
    return false;
  }
  const mostRecentSchedTxnDate = moment().add(noNaN(recurringSetting), 'days');
  return moment(txn.postedOn).isSameOrBefore(mostRecentSchedTxnDate, 'day');
}

function includeTransactionInBalance(
  txn,
  {
    recurringSetting,
    transactionsForAccountId,
    excludePendingTxn,
  },
) {
  if (excludePendingTxn && isBankOwnedPending(txn)) {
    return false;
  }

  if (isUnacceptedScheduledTxn(txn)) {
    return includeRecurringTxn(txn, recurringSetting, transactionsForAccountId);
  }

  return true;
}

function didBalanceCacheBreak(cacheRec, props) {
  const { acctReminderSetting, account, txns } = props;
  return (!cacheRec) ||
  (cacheRec.txns !== txns) ||
  (cacheRec.acctReminderSetting !== acctReminderSetting) ||
  (cacheRec.account.currentBalanceAsOf !== account.currentBalanceAsOf) ||
  (cacheRec.account.onlineBalance !== account.onlineBalance) ||
  (cacheRec.account.balanceAsOf !== account.balanceAsOf) ||
  (cacheRec.account.currentBalanceAsOfOn !== account.currentBalanceAsOfOn) ||
  (cacheRec.account.balanceAsOfOn !== account.balanceAsOfOn) ||
  (cacheRec.account.aggregators?.first?.()?.onlineBalance !== account.aggregators?.first?.()?.onlineBalance) ||
  (cacheRec.account.aggregators?.first?.()?.onlineBalanceAt !== account.aggregators?.first?.()?.onlineBalanceAt) ||
  (cacheRec.account.aggregators?.first?.()?.availableBalance !== account.aggregators?.first?.()?.availableBalance);
}

function createNewBalancesRecord(cacheRec, props) {

  const { account, isConnected } = props;
  const endBal = cacheRec ? cacheRec.endingBalance : 0;
  const endBalNoRecurring = cacheRec ? cacheRec.endingBalanceNoRecurring : 0;
  const currBal = cacheRec ? cacheRec.currentBalance : 0;
  const finalBal = cacheRec ? cacheRec.finalBalance : 0;
  const txnsDeltaFromCurrentBalance = cacheRec ? cacheRec.txnsDeltaFromCurrentBalance : 0;
  const eDate = cacheRec ? cacheRec.earliestTransactionDate : null;
  return new AccountBalances({
    accountId: account.id,
    accountType: account.type,
    endingBalance: endBal,
    endingBalanceNoRecurring: endBalNoRecurring,
    onlineBalance: !isConnected ? currBal : account.onlineBalance,
    currentBalance: currBal,
    finalBalance: finalBal,
    earliestTransactionDate: eDate,
    txnsDeltaFromCurrentBalance,
    cpOnlineBalance: cacheRec?.cpOnlineBalance,
    cpAvailableBalance: cacheRec?.cpAvailableBalance,
  });
}

const balRound = (val) => val && Number(val.toFixed(2));

const optimalCacheCompareFn = (oldTxn, newTxn, accountId) => {

  // if this txn change does not involve this account, then no recalc needed
  if (oldTxn.accountId !== accountId && newTxn.accountId !== accountId) {
    return true;
  }

  // if there is no oldTxn (add), then return false
  if (!oldTxn) return false;
  //
  // Need to return false for all cases in which a change in a transaction field can cause a balance to change
  //
  if (newTxn.accountId !== oldTxn.accountId) return false;
  if (newTxn.isDeleted) return false;
  // did we move forward or backwards relative to today?
  if ((moment(oldTxn.postedOn).isSameOrBefore(moment(), 'day') && moment(newTxn.postedOn).isAfter(moment(), 'day')) ||
    (moment(newTxn.postedOn).isSameOrBefore(moment(), 'day') && moment(oldTxn.postedOn).isAfter(moment(), 'day'))) {
    return false;
  }
  if (isAcme) return oldTxn.amount === newTxn.amount;
  return (oldTxn.amount === newTxn.amount && oldTxn.postedOn === newTxn.postedOn);
};

export const getBalancesByAccountId = createSelector(
  accountsSelectors.getAccountsByIdIncludingGoals,
  (state) => getTransactionsByAccountId(state, { txnTypes: TxnTypes.REGULAR_TXNS | TxnTypes.UNACCEPTED_SCHEDULED_TXNS }),
  transactionsLoading,
  accountsSelectors.getIsLoading,
  getPureManualAccounts,
  getTransactionsStore,
  getRunningBalanceExcludeAccountIds,

  ((function combinerClosure() {

    let overallMapCache;

    return ((accounts, transactionsByAccountId, transactionsAreLoading,
      accountsAreLoading, pureManualAccounts, transactionsStore, runningBalanceExcludeAccountIds) => {

      let atLeastOneAccountBrokeCache = false;
      const retVal = (ImmutableMap()).withMutations((balancesByAccountId) => {

        const balMap = getBalMap();

        accounts.forEach((account) => {
          if (account.type === 'INVESTMENT') return;

          const excludePendingTxn = runningBalanceExcludeAccountIds?.includes(account.id);

          const isConnected = isConnectedAccount(account);

          const txns = transactionsByAccountId.get(account.id);

          let acctReminderSetting = 0;
          acctReminderSetting = accountsUtils.getReminderSettingInDays(account.recurringTxn);

          /*
           * PER ACCOUNT CACHEING
           */
          const cacheKey = account.id;
          const cacheRec = balMap[cacheKey];
          const cacheBroke = didBalanceCacheBreak(cacheRec, {
            acctReminderSetting,
            account,
            txns,
          });
          if (!cacheBroke) {
            balancesByAccountId.set(account.id, createNewBalancesRecord(balMap[cacheKey], { account, isConnected }));
            // log.log('======================= CACHE HIT OR EMPTY TXNS FOR ', account.name);
            return;
          }

          const cacheObject = {
            accountId: account.id,
            acctReminderSetting,
            currentBalanceAsOf: account.currentBalanceAsOf,
            currentBalanceAsOfOn: account.currentBalanceAsOfOn,
            onlineBalance: account.onlineBalance,
            balanceAsOf: account.balanceAsOf,
            balanceAsOfOn: account.balanceAsOfOn,
            cpOnlineBalance: account.aggregators?.first?.()?.onlineBalance,
            cpOnlineBalanceAt: account.aggregators?.first?.()?.onlineBalanceAt,
            cpAvailableBalance: account.aggregators?.first?.()?.availableBalance,
          };
          const strictCacheObject = {
            ...cacheObject,
            txns,
          };

          const ocmCacheName = `acctBalances-${account.id}`;
          const cachedValues = getOptimalCacheFromObject(
            transactionsStore,
            ocmCacheName,
            cacheObject,
            strictCacheObject,
            (oldTxn, newTxn) => optimalCacheCompareFn(oldTxn, newTxn, account.id),
            (currentCache) => currentCache,
            2,
          );
          if (cachedValues) {
            // log.log('OPTIMAL CACHE USED =========================================', account.id);
            balancesByAccountId.set(account.id, cachedValues);
            return;
          }

          /*
           * FOR THIS ACCOUNT, the cache is broken or unavailable, so CALCULATIONS below
           */

          atLeastOneAccountBrokeCache = true;

          let endingBalance = 0;
          let endingBalanceNoRecurring = 0;
          let currentBalance = account.currentBalanceAsOf;
          let txnsDeltaFromCurrentBalance = null;
          let earliestTransactionDate = null;
          let finalBalance = 0;
          let excludePendingTxnTotal = 0;

          // const mostRecentSchedTxnDate = moment().add(Number(acctReminderSetting), 'days');

          let setCache = false;
          // just use defaults until transactions are available
          if (txns && !transactionsAreLoading && !accountsAreLoading) {

            setCache = true;

            currentBalance = 0;
            // if we have not already calculated the sum of transactions downloaded which match the accounts
            // currentBalance values, and the account has a currentBalance, use it to do the calc
            //
            const calculateTxnDelta =
              account.currentBalanceAsOf && (
                (!cacheRec) ||
                (cacheRec.txnsDeltaFromCurrentBalance === null) ||
                (account.currentBalanceAsOf && account.currentBalanceAsOf !== cacheRec.account.currentBalanceAsOf) ||
                (account.currentBalanceAsOfOn && account.currentBalanceAsOfOn !== cacheRec.account.currentBalanceAsOfOn)
              );

            log.log('======================= RECALCULATING FOR ', account.name, calculateTxnDelta, account.currentBalanceAsOf);
            /*
             * CALC METHOD 1 - 'R' Calculating the sum of original transactions associated with currentBalance
             */
            if (calculateTxnDelta || calculateTxnDelta === 0) {
              earliestTransactionDate = txns.size ? txns.first().postedOn : moment().format('YYYY-MM-DD');

              endingBalance = account.currentBalanceAsOf;
              endingBalanceNoRecurring = account.currentBalanceAsOf;
              currentBalance = 0;
              txnsDeltaFromCurrentBalance = endingBalance;
              finalBalance = endingBalance;

              txns.forEach((txn) => {
                if (!moment(txn.postedOn).isSameOrBefore(moment(account.currentBalanceAsOfOn))) {
                  finalBalance += Number(txn.amount);
                }

                if (includeTransactionInBalance(
                  txn,
                  {
                    recurringSetting: acctReminderSetting,
                    transactionsForAccountId: txns,
                  },
                )) {
                  if (moment(txn.postedOn).isBefore(earliestTransactionDate)) {
                    earliestTransactionDate = txn.postedOn;
                  }
                  if (!isUnacceptedScheduledTxn(txn) && moment(txn.postedOn).isSameOrBefore(moment(account.currentBalanceAsOfOn))) {
                    txnsDeltaFromCurrentBalance -= Number(txn.amount);
                  } else {
                    endingBalance += Number(txn.amount);
                    endingBalanceNoRecurring += (isUnacceptedScheduledTxn(txn) ? 0 : Number(txn.amount));
                  }
                  if (moment(txn.postedOn).isAfter(moment(), 'day') && (!isAcme || !isUnacceptedScheduledTxn(txn))) {
                    currentBalance += Number(txn.amount);
                  }
                  if (!account.isBankPendingTxnsExcluded && excludePendingTxn && isBankOwnedPending(txn)) {
                    excludePendingTxnTotal += Number(txn.amount);
                  }
                }
              });
              endingBalance -= excludePendingTxnTotal;
              currentBalance = endingBalance - currentBalance;
              assert(typeof currentBalance === 'number', 'currentBalance is not a number');
            } else {
              /*
               * CALC METHOD 2 and 3 - Calculating balance starting with either balanceAsOf or txnsDeltaFromCurrentBalance
               */
              // if we have a valid sum of original transactions in the cache, use it
              const useTxnDeltaAmount = balMap[cacheKey] && balMap[cacheKey].txnsDeltaFromCurrentBalance !== null;

              log.log('======================= CALC METHOD 2 FOR ', account.name, useTxnDeltaAmount, balMap[cacheKey] && balMap[cacheKey].txnsDeltaFromCurrentBalance);

              earliestTransactionDate = txns.size ? txns.first().postedOn : moment().format('YYYY-MM-DD');

              endingBalance = useTxnDeltaAmount ? balMap[cacheKey].txnsDeltaFromCurrentBalance : Number(account.balanceAsOf);
              txnsDeltaFromCurrentBalance = useTxnDeltaAmount ? balMap[cacheKey].txnsDeltaFromCurrentBalance : null;
              currentBalance = endingBalance;
              finalBalance = endingBalance;
              endingBalanceNoRecurring = endingBalance;

              txns.forEach((txn) => {
                if (useTxnDeltaAmount || (!account.balanceAsOfOn || moment(txn.postedOn).isAfter(moment(account.balanceAsOfOn)))) {
                  finalBalance += Number(txn.amount);
                }

                if (includeTransactionInBalance(
                  txn,
                  {
                    recurringSetting: acctReminderSetting,
                    transactionsForAccountId: txns,
                    excludePendingTxn,
                  },
                )) {
                  if (moment(txn.postedOn).isBefore(earliestTransactionDate)) {
                    earliestTransactionDate = txn.postedOn;
                  }
                  // if we are using the 'R' value, date does not matter, but if we are calculating from balanceAsOfOn, we
                  // only count transactions that are after the balanceAsOfOn date
                  if (useTxnDeltaAmount || (!account.balanceAsOfOn || moment(txn.postedOn).isAfter(moment(account.balanceAsOfOn)))) {
                    endingBalance += Number(txn.amount);
                    endingBalanceNoRecurring += (isUnacceptedScheduledTxn(txn) ? 0 : Number(txn.amount));
                    if (moment(txn.postedOn).isSameOrBefore(moment(), 'day')) {
                      currentBalance += Number(txn.amount);
                    }
                  }
                }
              });
            }
          }

          endingBalance = balRound(endingBalance);
          endingBalanceNoRecurring = balRound(endingBalanceNoRecurring);
          currentBalance = balRound(currentBalance);
          finalBalance = balRound(finalBalance);

          const balancesForAccountId = new AccountBalances({
            accountId: account.id,
            accountType: account.type,
            onlineBalance: !isConnected ? currentBalance : account.onlineBalance,
            endingBalance,
            endingBalanceNoRecurring,
            currentBalance,
            finalBalance,
            earliestTransactionDate,
            txnsDeltaFromCurrentBalance,  // 'R'
            cpOnlineBalance: account.aggregators?.first?.()?.onlineBalance,
            cpAvailableBalance: account.aggregators?.first?.()?.availableBalance,
          });
          balancesByAccountId.set(account.id, balancesForAccountId);

          // Set Cache
          if (setCache) {
            balMap[cacheKey] = {
              endingBalance,
              endingBalanceNoRecurring,
              currentBalance,
              finalBalance,
              txns,
              account,
              earliestTransactionDate,
              txnsDeltaFromCurrentBalance,
              acctReminderSetting,
              cpOnlineBalance: account.aggregators?.first?.()?.onlineBalance,
              cpAvailableBalance: account.aggregators?.first?.()?.availableBalance,
            };
            // Set the optimal Cache
            setOptimalCacheFromObject(transactionsStore, ocmCacheName, cacheObject, strictCacheObject, balancesForAccountId, 2);
          }
        });
      });

      const balanceMapToReturn = atLeastOneAccountBrokeCache ? retVal : overallMapCache || retVal;
      overallMapCache = balanceMapToReturn;
      return balanceMapToReturn;
    });
  })()),
);


// CMP-8174
// Running balance is balance today including due or overdue reminders if reminder prefrence is on for account
// For to calculate balace correctly, do the two steps
// case 1: for past dates, running balance is subtracted with amount of txns  and overdue schTxn instances between
// today and date for which balance is to be calculated
// case 2: for future dates, add amounts of due or future due schTxn and future txns till the date for
// which balance is to be calculated
const calcBalanceForAccountAtDate = (
  accountId,
  date,
  txnsByAccountId,
  balancesByAccountId,
  balanceStr,
  runningBalanceExcludeAccountIds,
) => {
  const balanceObject = balancesByAccountId.get(accountId);
  if (balanceObject) {
    let runningBalance = balanceObject?.[balanceStr];
    const txns = txnsByAccountId.get(accountId);
    if (txns) {
      const today = DateTime.local().toISODate();
      const balMap = getBalMap();
      const cacheRec = balMap[accountId];
      const excludePendingTxn = runningBalanceExcludeAccountIds?.includes(accountId);

      if (!moment(date).isSame(moment(today))) {
        txns.forEach((txn) => {
          if (!includeTransactionInBalance(
            txn,
            {
              recurringSetting: cacheRec?.acctReminderSetting,
              transactionsForAccountId: txnsByAccountId,
              excludePendingTxn,
            },
          )) {
            return;
          }
          
          const txnPostedDate = DateTime.fromISO(txn.postedOn).toFormat('yyyy-MM-dd');
          if (moment(date).isAfter(moment(today))) {
            if (moment(txnPostedDate).isAfter(moment(today)) && moment(txnPostedDate).isSameOrBefore(moment(date))) {
              runningBalance += Number(txn.amount);
            }
          } else if (moment(txnPostedDate).isSameOrBefore(moment(today)) && moment(txnPostedDate).isAfter(moment(date))) {
            runningBalance -= Number(txn.amount);
          }
        });
      }
    }
    return runningBalance?.toFixed(2);
  }
  return null;
};

export const getBalanceForAccountAtDate = createSelector(
  (state, props) => props.accountId,
  (state, props) => props.date,
  (state, props) => props.balanceType || 'endingBalance',
  getTransactionsByAccountId,
  getBalancesByAccountId,
  getRunningBalanceExcludeAccountIds,
  (accountId, date, balanceType, txnsByAccountId, balancesByAccountId, runningBalanceExcludeAccountIds) =>
    calcBalanceForAccountAtDate(
      accountId,
      date,
      txnsByAccountId,
      balancesByAccountId,
      balanceType,
      runningBalanceExcludeAccountIds,
    ),
);

export const getBalancesForAccountsAtDates = createSelector(
  (state, props) => props.accountIds,
  (state, props) => props.dates,
  getTransactionsByAccountId,
  getBalancesByAccountId,
  accountsSelectors.getAccountsById,
  getRunningBalanceExcludeAccountIds,
  (accountIds, dates, txnsByAccountId, balancesByAccountId, accountsById, runningBalanceExcludeAccountIds) => {
    const currBalanceStr = accountIds.map((id) => balancesByAccountId.get(id)?.currentBalance);
    const endingBalanceStr = accountIds.map((id) => balancesByAccountId.get(id)?.endingBalance);
    const cacheStr =
      accountIds &&
      dates &&
      `${accountIds.toJS().toString()}-${currBalanceStr.toJS().toString()}-${endingBalanceStr.toJS().toString()}-${dates[0]}-${dates[dates.length - 1]
      }`;
    return memoizeWithKey(cacheStr, () =>
      dates.reduce((dateBalances, date) => {
        let balanceTotal = 0;
        const accountsBalances = accountIds.reduce((acc, accountId) => {
          const account = accountsById.get(accountId);
          if (!account) return acc;
          if (account.type === 'INVESTMENT') return acc; // exclude investment accounts
          const balance = calcBalanceForAccountAtDate(
            accountId,
            date,
            txnsByAccountId,
            balancesByAccountId,
            'currentBalance',
            runningBalanceExcludeAccountIds,
          );
          balanceTotal = Number(parseFloat(balanceTotal) + parseFloat(balance ?? 0));
          return acc.set(accountId, { name: account.name, balance });
        }, OrderedMap());
        return dateBalances.set(date, { accountsBalances, total: balanceTotal });
      }, OrderedMap()));
  },
);

const investmentsBalMap = {};

const getInvestmentBalancesByAccountIdQuicken = createSelector(
  accountsSelectors.getAccountsById,
  getIHoldsByAccountId,
  (accounts, iHoldsByAccountId) => (
    (ImmutableMap()).withMutations((balancesByAccountId) => {
      accounts.forEach((account) => {

        if (account.type !== 'INVESTMENT') return;

        const holdings = iHoldsByAccountId?.get(account.id);

        // Try and use the cache. Holdings are just a one shot load unlike the other accounts so this doesn't get used.
        if (holdings && (investmentsBalMap[account.id] && investmentsBalMap[account.id].holdings === holdings)) {
          const endBal = investmentsBalMap[account.id] ? investmentsBalMap[account.id].endingBalance : 0;
          const currBal = investmentsBalMap[account.id] ? investmentsBalMap[account.id].currentBalance : 0;
          const eDate = investmentsBalMap[account.id] ? investmentsBalMap[account.id].earliestTransactionDate : null;
          balancesByAccountId.set(account.id, new AccountBalances({
            accountId: account.id,
            accountType: account.type,
            endingBalance: endBal,                // Total of everything
            onlineBalance: endBal,
            currentBalance: currBal,              // Today's balance
            earliestTransactionDate: eDate,
          }));
          // log.log('======================= CACHE HIT OR EMPTY HOLDINGS FOR ', account.name);
          return;
        }
        // log.log('======================= RECALCULATING HOLDING BALANCES FOR ', account.name);

        /*
          Loop over the investment holdings for this account and determine:
            1. The balanceAsOfOn from the latest modifiedAt date from each.
            2. The earliestTransactionDate from the earliest modifiedAt date from each.
            3. The endingBalance by summing latestPrice * numberOfShares + the account's cash balance
            4. The currentBalance = endingBalance
         */

        let balanceAsOfOn;
        let earliestTransactionDate = moment();
        let endingBalance = 0.0;

        if (holdings) {
          holdings.forEach((holding) => {
            if (!balanceAsOfOn) balanceAsOfOn = moment(holding.modifiedAt).format('MM-DD-YYYY');
            if (!earliestTransactionDate) earliestTransactionDate = moment(holding.modifiedAt).format('MM-DD-YYYY');
            if (moment(holding.modifiedAt).format('MM-DD-YYYY') > new Date(balanceAsOfOn)) {
              balanceAsOfOn = moment(holding.modifiedAt).format('MM-DD-YYYY');
            }
            if (moment(holding.modifiedAt).format('MM-DD-YYYY') < new Date(earliestTransactionDate)) {
              earliestTransactionDate = moment(holding.modifiedAt).format('MM-DD-YYYY');
            }
            endingBalance += (holding.numberOfShares * holding.latestPrice);
          });
        }

        endingBalance += account.cashBalance;

        const balancesForAccountId = new AccountBalances({
          accountId: account.id,
          accountType: account.type,
          currentBalance: endingBalance,
          onlineBalance: endingBalance,
          endingBalance,
          earliestTransactionDate,
        });

        balancesByAccountId.set(account.id, balancesForAccountId);
        investmentsBalMap[account.id] = {
          endingBalance,
          currentBalance: endingBalance,
          holdings,
          earliestTransactionDate,
        };
      });
    })
  ),
);

export const getAllBalancesByAccountId = createSelector(
  getBalancesByAccountId,
  getInvestmentBalancesByAccountIdQuicken,
  (balancesByAccountId, investmentBalancesByAccountId) => balancesByAccountId.merge(investmentBalancesByAccountId),
);

// add defaultBalance to the balances
export const getAllBalancesByAccountIdEx = createSelector(
  getAllBalancesByAccountId,
  (state) => getSharedPreferencesByPath(state, { group: 'dataset', path: ['shared', 'showBalance'] }) || ShowBalance.INCLUDE_PENDING,
  (state) => featureFlagsSelectors.getFeatureFlag(state, 'balanceToggle'),
  (balancesByAccountId, showBalance, balanceToggle) => balancesByAccountId.map((balances) => {
    let defaultBalance = balances.currentBalance;
    if (balanceToggle) {
      switch (showBalance) {
        case ShowBalance.INCLUDE_PENDING:
          defaultBalance = balances.currentBalance;
          break;
        case ShowBalance.BANK_REPORTED:
          defaultBalance = balances.cpOnlineBalance;
          break;
        default:
          verify(false, `unexpected showBalance value = ${showBalance}`);
      }
    }
    return balances.set('defaultBalance', defaultBalance);
  }),
);
